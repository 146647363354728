import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Chart } from '../../classes';
import { COLORS, DATA } from '../../OPTIONS';

@Component({
  selector: 'chart-preview',
  templateUrl: 'chart-preview.component.html',
  styleUrls: ['chart-preview.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartPreviewComponent {
  @Input() chart:Chart;

  options:any = {
    view: undefined,
    scheme: COLORS,
    data: DATA,
    autoScale: true,
    tooltipDisabled: true
  };
}
