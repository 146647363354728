import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Chart } from '../../classes';
import { COLORS } from '../../OPTIONS';
import { ChartService } from '../../services';

export interface IError {
  status:number;
  statusText:string;
  body:string;
}

@Component({
  selector: 'chart-view',
  templateUrl: 'chart-view.component.html',
  styleUrls: ['chart-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartViewComponent implements OnInit, OnDestroy {
  @Input() chart:Chart;
  @Input() manage:number[] = [];

  @Output() onBack = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  loading = true;
  error:IError = null;

  options:any = {
    showLegend: true,
    scheme: COLORS,
    showLabels: true,
    showXAxis: true,
    showYAxis: true,
    showXAxisLabel: true,
    showYAxisLabel: true,
    xAxisLabel: '',
    yAxisLabel: '',
    histoBarPadding: 1,
    histoLegend: false,
    autoScale: true,
    showGridLines: true,
    barPadding: 8,
    groupPadding: 16,
    data: [],
    legendTitle: 'Легенда',
    legendPosition: 'below',
    view: [200, 300]
  };

  constructor(private chartService:ChartService, private ref:ChangeDetectorRef, private el:ElementRef) {}

  ngOnInit() {
    this.getData(this.chart);
    window.addEventListener('resize', this.setView.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.setView);
  }

  get canEdit():boolean {
     return  this.manage.includes(this.chart.layer_id);
  }

  getData(chart:Chart, reload:boolean = false) {
    this.loading = true;
    this.chartService.getData(chart, reload).subscribe(
      data => {
        this.loading = false;
        this.error = null;

        // пересчет
        if (reload) {
          chart.changeStatus('pending');
          this.options.data = [];
          this.ref.markForCheck();
          return;
        }

        chart.status = data.status;

        if (chart.status === 'ready') {
          this.options.data = data.objects;

          // сужаем столбцы столбчатой диаграммы, если их > 30
          if (data.objects.length > 30) {
            this.options.barPadding = 2;
            this.options.groupPadding = 4;
          } else {
            this.options.barPadding = 8;
            this.options.groupPadding = 16;
          }
        }
        this.ref.markForCheck();
        this.setView();
      },
      error => {
        // ловит ошибки, возникшие вне celery
        this.loading = false;
        this.error = {
          status: error.status,
          statusText: error.statusText,
          body: error.json().error_message
        };
        this.ref.markForCheck();
      }
    );
  }

  back() {
    this.onBack.emit('');
  }

  remove() {
    this.chartService.deleteChart(this.chart).subscribe(data => {
      this.onDelete.emit('');
    });
  }

  private setView() {
    const chartContainer:HTMLElement = this.el.nativeElement.getElementsByClassName('chart-panel')[0];
    this.options.view = [chartContainer.offsetWidth, chartContainer.offsetHeight - 150];
  }
}
