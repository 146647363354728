import { Component, ElementRef, OnInit } from '@angular/core';
import { PluginClass } from '../../classes/Plugin';
import { Utils } from '../../classes/Utils';
import { IMenu, IPluginInterface } from '../../interfaces';
import { MapService } from '../../services/map.service';
import { Button } from '../button/button.component';

@Component({
  selector: 'zoom-control',
  template: ''
})
export class ZoomControlComponent extends PluginClass implements OnInit {
  currentScale:number;

  private btns = Array<Button>();
  private menu:IMenu;
  private zoomInfo:Button;

  constructor(private el:ElementRef, private mapService:MapService) {
    super();
    mapService.currentMapScale$.subscribe(scale => {
      this.currentScale = scale;
      if (this.zoomInfo) {
        this.zoomInfo.iconText = this.scaleString;
      }
    });
  }

  ngOnInit() {
    Utils.removeElement(this.el.nativeElement as HTMLElement);
  }

  createContent() {
    this.menu.createBtn().then(btn => {
      btn = btn.instance;
      btn.className = 'zoomIn';
      btn.title = 'Приблизить';
      btn.action = 'zoomIn';
      btn.onClickMe = () => {
        this.zoomIn();
      };
      btn.onDeactivate = () => {};
      this.btns.push(btn);
      this.menu.addBtn(btn);
    });

    this.menu.createBtn().then(btn => {
      this.zoomInfo = btn = btn.instance;
      btn.className = 'map-scale';
      btn.title = 'Уровень масштаба';
      btn.iconText = this.scaleString;
      btn.action = null;
      btn.onClickMe = () => {};
      btn.onDeactivate = () => {};
      this.btns.push(btn);
      this.menu.addBtn(btn);
    });

    this.menu.createBtn().then(btn => {
      btn = btn.instance;
      btn.className = 'zoomOut';
      btn.title = 'Отдалить';
      btn.action = 'zoomOut';
      btn.onClickMe = () => {
        this.zoomOut();
      };
      btn.onDeactivate = () => {};
      this.btns.push(btn);
      this.menu.addBtn(btn);
    });
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;
        this.createContent();
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.btns.forEach(btn => {
          this.menu.removeBtn(btn);
        });
        this.btns = [];
        this.menu = null;
        break;
    }
  }

  private zoomIn() {
    this.mapService.currentMapScale$.next(this.currentScale + 1);
  }

  private zoomOut() {
    this.mapService.currentMapScale$.next(this.currentScale - 1);
  }

  private get scaleString():string {
    let scaleStr = this.currentScale ? this.currentScale.toString() : '';
    scaleStr = scaleStr.replace(/[,.]0+$/g, '');
    const pos = scaleStr.indexOf('.') || scaleStr.indexOf(',');
    if (pos >= 0) {
      scaleStr = scaleStr.substring(0, pos + 2);
    }
    return scaleStr;
  }
}
