import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractChartFormClass } from '../../classes';
import { ChartService } from '../../services';

@Component({
  selector: 'geo-histogram-form',
  templateUrl: 'histogram-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistogramFormComponent extends AbstractChartFormClass {
  constructor(protected chartService:ChartService, protected builder:FormBuilder) {
    super(chartService, builder);
  }

  protected setForm() {
    this.form = this.builder.group({
      name: [null, [Validators.required, Validators.maxLength(255)]],
      resource: [null, [Validators.required]],
      colValue: [{ value: null, disabled: true }, [Validators.required]],
      colCount: [10, [Validators.required]]
    });

    this.form.get('resource').valueChanges.subscribe(value => {
      this.attributes = value.columns;
      this.form.get('colValue').enable();

      this.form.get('colValue').reset();
    });
  }

  protected getChartSettings(formModel:any) {
    return {
      attribute_field_name_joined: formModel.colValue.name,
      type: 'histogram',
      bin_count: formModel.colCount
    };
  }
}
