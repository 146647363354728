import { Component } from '@angular/core';
import { PluginClass } from '../../classes/Plugin';
import { ILoader, IPluginInterface } from '../../interfaces';

@Component({
  selector: 'loader',
  templateUrl: 'loader.component.html',
  styles: [
    `
      #loader {
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        z-index: 1000;
        transform: scale(0.7);
      }
    `
  ]
})
export class Loader extends PluginClass implements ILoader {
  active:boolean;

  addInterface(name:string, pi:IPluginInterface) {
    console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
  }

  removeInterface(name:string) {}

  showLoader() {
    this.active = true;
  }

  hideLoader() {
    this.active = false;
  }
}
