import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Feature } from 'shared/classes';

@Component({
  selector: 'geo-feature-chart',
  templateUrl: 'feature-chart.component.html',
  styleUrls: ['feature-chart.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureChartComponent {
  @Input() set feature(value:Feature) {
    // ожидаем рендера контейнера для определения размеров диаграммы
    setTimeout(() => {
      this._feature = value;
      this.ref.markForCheck();
    });
  }

  get feature():Feature {
    return this._feature;
  }

  @Input() preview = true;

  @Output() maximize:EventEmitter<Feature> = new EventEmitter();

  // TODO: вынести в plugins.json, как настройку компонента
  private roundNum = 2;
  private _feature:Feature;

  constructor(private ref:ChangeDetectorRef) {}

  maximizeChart() {
    this.maximize.emit(this._feature);
  }

  formatDate(d:Date) {
    return d.toLocaleDateString();
  }

  formatNumber(n:number) {
    return n.toFixed(this.roundNum);
  }
}
