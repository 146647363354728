import { GridsterItem } from 'angular-gridster2';
import {ILayer} from 'shared/interfaces';
import { curveMonotoneX } from 'd3-shape';
import {Bounds} from 'shared/classes';

export type ReportWidgetType = 'chart' | 'text' | 'map' | 'legend';

export interface StatWidget extends GridsterItem {
  id:string;
  type:ReportWidgetType;
  minItemRows:number;
  cols:number;
  rows:number;
  x:number;
  y:number;
  isNew:boolean;

  title?:string;
  subtitle?:string;
  fontSize?:number;

  childWidget?:StatWidget;
  parentWidget?:StatWidget;
  pageIdx?:number;
  widgetIdx?:number;
}

export abstract class AbstractWidget implements StatWidget {
  id:string;
  type:ReportWidgetType;
  minItemRows:number;
  cols:number;
  rows:number;
  x:number;
  y:number;
  isNew = true;

  title:string;
  subtitle:string;

  fontSize = 14;

  childWidget:StatWidget = null;
  parentWidget:StatWidget = null;
  pageIdx:number;
  widgetIdx:number;

  constructor(options?:any) {
    Object.assign(this, options);
  }

  protected setId() {
    this.id = this.getGuid();
  }

  // magic from https://stackoverflow.com/a/105074
  private getGuid():string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
}

export class MapWidget extends AbstractWidget {
  title:string;
  layers:ILayer[];
  bounds:Bounds;
}
export class LegendWidget extends AbstractWidget {}

export class ChartsWidget extends AbstractWidget {
  options:ChartOptions = new ChartOptions();

  setOptions(options:any) {
    this.options.type = options.type;
  }
}

export class TextWidget extends AbstractWidget {
  text:string;
}

export class ChartOptions {
  type:String = 'pie';
  view:any[] = [400, 300];
  colorScheme = {
    domain: ['#d10b41', '#fff200', '#5ac938']
  };
  multi:any[] = [
    {
      name: 'NDVI',
      series: []
    }
  ];
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  curve = curveMonotoneX;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel = '';
  yAxisLabel = 'NDVI';
  schemeType:string = 'linear';
  autoScale = true;

  dateTickFormatting(val:any):string {
    if (val instanceof Date) {
      return (<Date>val).toLocaleString('ru', { month: 'long' });
    }
  }
}

export interface Margins {
  left:number;
  right:number;
  top:number;
  bottom:number;
}

export interface Page {
  widgets:AbstractWidget[];
}
