import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { PluginClass, Point, Utils } from '../../classes';
import { IContainer, IContentChild, IPluginInterface, IPopup } from '../../interfaces';
import { Creator } from '../../services';

@Component({
  selector: 'app-popup',
  templateUrl: 'popup.html',
  styleUrls: ['popup.less'],
  encapsulation: ViewEncapsulation.None
})
export class PopupComponent extends PluginClass implements IPopup, IContainer, OnInit {
  @ViewChild('here', { read: ViewContainerRef }) popupContent:ViewContainerRef;
  @Input() deltaY = 44; // delta по y
  @Output() onHide = new EventEmitter();
  @Output() onShow = new EventEmitter();

  active = false;
  contentChildren = Array<IContentChild>();

  private domNode:HTMLElement;
  private popupPlugins:IPopup[] = [];

  constructor(private creator:Creator, el:ElementRef) {
    super();
    this.domNode = el.nativeElement;
  }

  ngOnInit() {
    Utils.removeElement(this.domNode);
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Popup':
        this.popupPlugins.push(pi as IPopup);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Popup':
        this.popupPlugins = [];
        break;
    }
  }

  createComponent(component:any):Promise<any> {
    return this.creator.createComponent(component, this.popupContent);
  }

  getContainer() {
    return this.popupContent;
  }

  showPopup(point:Point, showMarker:boolean) {
    this.active = true;
    this.popupPlugins.forEach(item => item.showPopup(point, showMarker, this.domNode));
  }

  closePopup() {
    this.active = false;
    this.popupPlugins.forEach(item => item.closePopup());
  }

  open() {}

  close() {
    this.closePopup();
    this.contentChildren.forEach(child => {
      child.deactivate();
    });
  }

  isOpen() {
    return this.active;
  }

  addChild(child:IContentChild) {
    this.contentChildren.push(child);
  }
}
