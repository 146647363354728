import { Component, ComponentRef, ElementRef, OnInit } from '@angular/core';
import { PluginClass, Utils } from 'shared/classes';
import { Button, DropMenuButtonComponent as DropMenuButton } from 'shared/components';
import { IActivator, IMeasuring, IMenu, IPluginInterface, ITool } from 'shared/interfaces';

@Component({
  template: '',
  selector: 'measuring'
})
export class Measuring extends PluginClass implements IMeasuring, ITool, OnInit {

  activateMode:string = null;
  groupName:string;
  private buttons = [
    {
      className: 'polyMeasureBtn',
      title: 'Измерение площади',
      action: 'area'
    },
    {
      className: 'lineMeasureBtn',
      title: 'Измерение длины',
      action: 'line'
    }
  ];

  private activator:IActivator; // плагин, отвечающий за отключение связанных плагинов
  private measurePlugins:IMeasuring[] = Array<IMeasuring>();
  private active = false;
  private menu:IMenu;
  private btns = Array<Button>();
  private menuBtn:Button;
  private action:string;

  constructor(private el:ElementRef) {
    super();
  }

  ngOnInit() {
    Utils.removeElement(this.el.nativeElement as HTMLElement);
  }

  // Если активация подтверждена, начать рисовать указанную геометрию
  activateTool():void {
    this.active = true;
    this.stopMeasure();
    this.startMeasure(this.action);
  }

  deactivateTool():Promise<boolean> {
    this.deactivate();
    if (this.menuBtn) {
      this.menuBtn.setActive(false);
    }
    this.action = null;
    return Promise.resolve(true);
  }

  isActive() {
    return this.active;
  }

  getGroup() {
    return this.groupName;
  }

  addInterface(name:string, pi:IPluginInterface) {
    const self = this;

    switch (name) {
      case 'Measure':
        this.measurePlugins.push(pi as IMeasuring);
        break;
      case 'Menu':
        const menu = pi as IMenu;
        menu.createComponent(DropMenuButton).then((m:ComponentRef<DropMenuButton>) => {
          self.menu = m.instance;
          m.instance.className = 'measuringBtn';
          m.instance.title = 'Измерения';
          m.instance.hideOnClickOutside = true;
          m.instance.position = 3;

          const oldInit = m.instance.ngAfterContentInit;
          m.instance.ngAfterContentInit = () => {
            oldInit.call(m.instance);
            const btn = (self.menuBtn = (m.instance as DropMenuButton).getMenuBtn());
            btn.onDeactivate = () => {};
            menu.addBtn(btn);
            // override: компонент не должен деактивироваться при скрытии дроп-меню (issue #2110)
            self.createBtns();
          };
        });
        break;
      case 'Activator':
        this.activator = pi as IActivator;
        this.activator.setConnect(this as ITool);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string) {
    switch (name) {
      case 'Measure':
        this.measurePlugins = [];
        break;
      case 'Menu':
        this.btns.forEach(btn => {
          this.menu.removeBtn(btn);
        });

        this.btns = [];
        this.menu = null;
        break;
      case 'Activator':
        this.activator = null;
        break;
    }
  }

  startMeasure(geomType:string) {
    this.measurePlugins.forEach(plugin => {
      plugin.startMeasure(geomType);
    });
  }

  stopMeasure() {
    this.measurePlugins.forEach(plugin => {
      plugin.stopMeasure();
    });
  }

  // создание клавиш в выпадающем меню
  private createBtns() {
    const menu = this.menu;
    this.buttons.forEach(opt => {
      menu.createBtn().then(btn => {
        btn = btn.instance;
        btn.title = opt.title;
        btn.className = opt.className;

        // Клик на кнопку в дроп-меню
        btn.onClickMe = () => {
          // Запоминаем текущее действие, чтобы вызвать его после
          // подтверждения деактивации
          this.action = opt.action;
          this.activate().then(() => {
            this.menuBtn.setActive(false);
          });
        };

        btn.onDeactivate = () => {
          this.stopMeasure();
        };

        // добавить кнопку в массив меню
        menu.addBtn(btn);
      });
    });
  }

  private activate():Promise<any> {
    if (this.activator) {
      return this.activator.activateComponent(this as ITool);
    }
  }

  private deactivate() {
    this.active = false;
    this.stopMeasure();
    this.menu.deactivateBtns();
  }
}
