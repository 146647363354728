import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ScrollWidget} from '@geoanalitika/widgets';
import {TMSLayer, Utils} from 'shared/classes';
import {Button, MenuComponent} from 'shared/components';
import {IDomPanel, ILayer, IManageableLayer, IMenu, IPluginInterface, IScene, ITool, IToolLayer} from 'shared/interfaces';
import {LayersStore} from 'shared/stores/LayersStore';
import {Creator, MapService} from '../../services/services';

@Component({
  selector: 'layers-manager-desktop',
  templateUrl: 'layer_manager.component.html',
  styleUrls: ['layer_manager.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerManagerDesktopComponent extends MenuComponent implements ITool {
  @Input() layers:ILayer[] = [];

  @ViewChild('topMenu', { read: ViewContainerRef }) topMenu:ViewContainerRef;
  @ViewChild('buttons', { read: ViewContainerRef }) buttonsDiv:ViewContainerRef;
  @ViewChild('dopTools') dopTools:ElementRef;
  @ViewChild('scroll') scroll:ScrollWidget;

  active = false;
  activateMode:string = null;
  toolPlugins:IToolLayer[] = [];
  opacityComponent:IToolLayer; // компонент для изменения цветовых характеристик слоя
  currentBtnDom:HTMLElement = null;
  showVisibleLayers = false;

  private _currentLayer:ILayer = null;
  private btn:Button;
  private menu:IMenu;
  private scenePlugs:IScene[] = [];
  private _layerFilter:string = '';
  private parentContainer:IDomPanel;

  constructor(creator:Creator, el:ElementRef, private layersStore:LayersStore,
              private mapService:MapService, private changeDetector:ChangeDetectorRef) {
    super(creator, el);
    layersStore.layersChanged.subscribe(layers => {
      layers.forEach(layer => {
        layer.showTooltip = layer.inTooltipDefault;
      });
      this.layers = layers;
      this.changeDetector.detectChanges();
    });
    layersStore.onOptionsLoaded().subscribe(() => {
      this.changeDetector.detectChanges();
    });
  }

  get layerFilter():string {
    return this._layerFilter;
  }
  set layerFilter(s:string) {
    this._layerFilter = s;
    this.changeDetector.detectChanges();
  }

  get currentLayer():ILayer {
    return this._currentLayer;
  }
  set currentLayer(layer:ILayer) {
    this._currentLayer = layer;
    for (const tool of this.toolPlugins) {
      if (tool.activateMode === 'layer-select') {
        if (layer) {
          (tool as IToolLayer).activateToolLayer(layer);
        } else {
          (tool as IToolLayer).deactivateTool();
        }
      }
    }
    this.layersStore.setSelectedLayer(layer);
    this.changeDetector.detectChanges();
  }

  onLayerClick(layer:ILayer) {
    this.currentLayer = this.currentLayer === layer ? null : layer;
    this.layersStore.selectedLayer = this.currentLayer;
  }

  onShowVisibleChange() {
    this.changeDetector.detectChanges();
  }

  onLayerMove(evt:any) {
    const targetArray = evt.targetArray as ILayer[];
    let targetIndex = evt.targetIndex as number;
    const movedLayer = evt.movedLayer as ILayer;
    // Удаление слоя
    let deleteArrayFrom:ILayer[] = null;
    let deleteIndex = -1;
    this.layersStore.walkLayersRecursive(this.layers, (layer:ILayer, layers:ILayer[]) => {
      if (layer === movedLayer) {
        const index = layers.indexOf(layer);
        deleteArrayFrom = layers;
        deleteIndex = index;
        layers.splice(index, 1);
        return false;
      } else {
        return true;
      }
    });
    // Вставка слоя
    if (targetIndex < 0) {
      targetArray.unshift(movedLayer);
    } else {
      // Коррекция индекса вставки
      if (deleteArrayFrom === targetArray && deleteIndex < targetIndex) {
        targetIndex--;
      }
      targetArray.splice(targetIndex + 1, 0, movedLayer);
    }
    this.layersStore.updateLayers();
    this.changeDetector.detectChanges();
  }

  isItemInclude(layer:ILayer):boolean {
    if (!layer.checked) {
      return false;
    }
    const filter = this.layerFilter.toLocaleLowerCase();
    let ret = false;
    if (layer.isGroup) {
      //Есть ли среди потомков слои по фильтру?
      this.layersStore.walkLayersRecursive(layer.subLayers, (item:ILayer) => {
        ret = ret || !filter || (!item.isGroup && item.name.toLocaleLowerCase().includes(filter));
        ret = ret && (!this.showVisibleLayers || (!item.isGroup && item.visible));
        return !ret || item.isGroup;
      });
    } else {
      //слой удовлетворяет фильтру?
      ret = (!filter || layer.name.toLocaleLowerCase().includes(filter))
        && (!this.showVisibleLayers || (!layer.isGroup && layer.visible));
    }
    return ret;
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Scene':
        this.scenePlugs.push(pi as IScene);
        break;
      case 'Tool':
        this.toolPlugins.push(pi as IToolLayer);
        break;
      case 'ColorWidget':
        this.opacityComponent = pi as IToolLayer;
        break;
      case 'ParentContainer':
        this.parentContainer = pi as IDomPanel;
        this.parentContainer.resizeStart.subscribe(() => {
          this.currentBtnDom = null;
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Scene':
        this.scenePlugs = [];
        break;
      case 'Tool':
        this.toolPlugins = [];
        break;
      case 'ColorWidget':
        this.opacityComponent = null;
        break;
    }
  }

  activateOtherTool(tool:ITool, layer:ILayer) {
    (tool as IToolLayer).activateToolLayer(layer);
    //this.currentLayer = null;
  }

  activateTool() {}

  deactivateTool():Promise<boolean> {
    this.currentLayer = null;
    return Promise.resolve(true);
  }

  isActive() {
    return this.active;
  }

  getGroup():string {
    return '';
  }

  // убрать слой из списка
  delClick(layer:ILayer) {
    if ((layer as TMSLayer).isEdited) {
      return;
    }

    layer.visible = false;
    this.layersStore.updateLayer(layer);
    this.changeDetector.detectChanges();
  }

  // Скрыть меню инструментов
  hideDopTools() {
    this.currentBtnDom = null;
    this.changeDetector.detectChanges();
  }

  // Приблизиться к слою
  goToLayer(evt:MouseEvent, layer:ILayer) {
    evt.stopPropagation();
    if (layer.filter) {
      this.layersStore.layersService.getExtentWithFilter(layer).subscribe((layerOut) => {
        this.mapService.changeMapBound$.next(layerOut.extent);
      });
    } else {
      this.mapService.changeMapBound$.next(layer.extent);
    }
  }

  getVisibility(visible:boolean):string {
    return visible ? 'visible' : 'hidden';
  }

  showTools(evt:MouseEvent, targetElement:HTMLElement, layer:IManageableLayer) {
    evt.stopPropagation();
    const button = evt.target as HTMLElement;
    // Скрыть меню на повторный клик
    if (this.currentLayer === layer && this.currentBtnDom) {
      this.hideDopTools();
    } else {
      this.currentLayer = layer;
      this.currentBtnDom = button;

      const pos = Utils.getRelativePosition(targetElement, '.layer-manager-container');

      // Расчет расположения меню инструментов относительно кнопки вызова
      const offsetX = targetElement.offsetLeft;
      const buttonOffsetX = button.offsetLeft;
      const paddingY = targetElement.offsetHeight / 2 - 45; //triangle position
      const paddingX = 33;
      this.dopTools.nativeElement.style.top = pos.top + paddingY + 'px';
      this.dopTools.nativeElement.style.left = offsetX + buttonOffsetX + paddingX + 'px';
      this.changeDetector.detectChanges();
    }
  }

  get currentLayerHideLegend():boolean {
    return this.currentLayer ? this.currentLayer.hideLegend : false;
  }
  set currentLayerHideLegend(flag:boolean) {
    if (this.currentLayer) {
      this.currentLayer.hideLegend = flag;
    }
  }

  get currentLayerShowTooltip():boolean {
    return this.currentLayer ? this.currentLayer.showTooltip : false;
  }
  set currentLayerShowTooltip(flag:boolean) {
    if (this.currentLayer) {
      this.currentLayer.showTooltip = flag;
    }
  }

  canTooltip():boolean {
    return this.currentLayer && this.currentLayer.columns &&
      this.currentLayer.columns.some(attr => attr.inTooltip);
  }

  onShowLegendChange() {
    if (this.currentLayer) {
      this.layersStore.updateLayer(this.currentLayer);
    }
  }

  showColorWidget(layer:ILayer):void {
    if (!this.opacityComponent) {
      return;
    }
    this.opacityComponent.activateToolLayer(layer);
  }

  onFilterClick(layer:ILayer):void {
    const tool:IToolLayer = this.toolPlugins.find((item:IToolLayer) => item.className === 'attributeFilterButton');

    if (tool) {
      tool.activateToolLayer(layer);
    }
  }

  changeLayerVis(evt:MouseEvent, layer:ILayer) {
    evt.stopPropagation();
    if (layer.notOnScale && layer.type !== 'cluster') {
      return;
    }
    layer.visible = !layer.visible;
    this.layersStore.updateLayer(layer);
  }

  // Скрыть меню инструментов на клик вне компонента
  @HostListener('document:click', ['$event'])
  private hideOnClick($event:MouseEvent) {
    if (this.currentBtnDom !== $event.target) {
      this.hideDopTools();
    }
  }
}
