import { Attribute } from './';

export type OperatorType = 'or' | 'and';

export class LogicFunction {
  title:string;
  sql:OperatorType;
}

export class Operation {
  title:string;
  sql:string;
  types:string[];
}

export class Operations {
  static operations:Operation[] = [
    {
      sql: '=',
      title: 'равно',
      types: [
        'integer',
        'float',
        'boolean',
        'bigdecimal',
        'bigint',
        'double precision',
        'long',
        'double',
        'string',
        'date',
        'datetime'
      ]
    },
    {
      sql: '!=',
      title: 'не равно',
      types: [
        'integer',
        'float',
        'boolean',
        'bigdecimal',
        'bigint',
        'double precision',
        'long',
        'double',
        'string',
        'date',
        'datetime'
      ]
    },
    {
      sql: '<',
      title: 'меньше',
      types: ['integer', 'float', 'bigdecimal', 'bigint', 'double precision', 'long', 'double', 'date', 'datetime']
    },
    {
      sql: '<=',
      title: 'меньше или равно',
      types: ['integer', 'float', 'bigdecimal', 'bigint', 'double precision', 'long', 'double', 'date', 'datetime']
    },
    {
      sql: '>',
      title: 'больше',
      types: ['integer', 'float', 'bigdecimal', 'bigint', 'double precision', 'long', 'double', 'date', 'datetime']
    },
    {
      sql: '>=',
      title: 'больше или равно',
      types: ['integer', 'float', 'bigdecimal', 'bigint', 'double precision', 'long', 'double', 'date', 'datetime']
    },
    {
      sql: 'between',
      title: 'между',
      types: ['integer', 'float', 'bigdecimal', 'bigint', 'double precision', 'long', 'double', 'date', 'datetime']
    },
    {
      sql: 'not between',
      title: 'не между',
      types: ['integer', 'float', 'bigdecimal', 'bigint', 'double precision', 'long', 'double', 'date', 'datetime']
    },
    {
      sql: 'ilike',
      title: 'содержит',
      types: ['string']
    },
    {
      sql: 'not ilike',
      title: 'не содержит',
      types: ['string']
    },
    {
      sql: '^',
      title: 'начинается с',
      types: ['string']
    },
    {
      sql: '$',
      title: 'заканчивается на',
      types: ['string']
    }
  ];

  static operationsByType(columnType:string):Operation[] {
    return Operations.operations.filter(item => {
      return item.types.indexOf(columnType) >= 0;
    });
  }
}

export class DataColumnFilter {
  column:Attribute;
  operator:OperatorType = 'or';
  conditions:DataFilterCondition[] = [];
}

export interface DataFilter {
  operator:OperatorType;
  filters:DataColumnFilter[];
}

export class DataFilterCondition {
  operator:string;
  value:string | number = null;
  values:string[] | number[] = []; // not between, between
}
