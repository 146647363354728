import { Component } from '@angular/core';
import { Attribute } from 'shared/classes';
import { LayersFactory } from 'shared/services';
import { LayersStore } from 'shared/stores/LayersStore';
import { AbstractCartometricOperation } from '../abstract_cartometric_operation_form';
import { CartometricService } from '../cartometric.service';
import { Cluster } from '../classes';

@Component({
  selector: 'spatial-cluster',
  templateUrl: 'spatial_cluster.component.html',
  styleUrls: ['spatial_cluster.component.less']
})
export class SpatialClusterComponent extends AbstractCartometricOperation {
  settings = new Cluster();

  numericAttributes:Attribute[] = [];

  constructor(layersStore:LayersStore, layersFactory:LayersFactory, cartometricService:CartometricService) {
    super(layersStore, layersFactory, cartometricService);
  }

  onSourceChange() {
    const source = this.settings.source;

    if (this.featuresLimit && source) {
      this.cartometricService.getFeatureCount(source.id).subscribe((data:any) => {
        const count = data[this.settings.source.id];
        if (count > this.featuresLimit) {
          this.displayError(
            `Количество объектов в слое ${this.settings.source.name}
             составляет ${count} и превышает лимит в ${this.featuresLimit} объектов,
             установленный для расчета кластеров. Выберите слой с меньшим количеством объектов.`,
            10000
          );
          this.settings.source = null;
        } else {
          this.numericAttributes = this.settings.source.columns.filter(attr => attr.inputType === 'number');
        }
      });
    }
  }

  protected reset() {
    this.form.resetForm();
    this.settings = new Cluster();
  }

  protected prepareData() {
    return {
      layerId: this.settings.source.id,
      filter: this.settings.source.filter,
      name: this.settings.name,
      mapStyle: 'cluster',
      clusterAttribute: this.settings.attribute.name
    };
  }
}
