import { Bounds } from 'shared/classes';

export class Bookmark {
  bounds:Bounds;
  name:string;

  constructor(name:string, bounds:Bounds) {
    this.name = name;
    this.bounds = bounds;
  }
}
