import { OnInit } from '@angular/core';
import { IButtonConfig, IPlugin, IPluginInterface } from '../interfaces';
import { Browser, Utils } from './Utils';

export abstract class PluginClass implements IPlugin, OnInit {
  buttonConfig:IButtonConfig = {} as IButtonConfig; // конфигурация кнопки для меню
  browsersSupport:{ [key:string]:string } = null; // поддержка браузеров
  componentName = '';
  componentId = '';
  isSupported = true; // поддерживается текущим браузером

  addInterface(name:string, pi:IPluginInterface):void {
    console.error(`Not implemented!`);
  }

  removeInterface(name:string):void {
    console.error('Not implemented!');
  }

  getOuterface(name?:string):IPluginInterface {
    return this as IPluginInterface;
  }

  onLoad() {}

  ngOnInit() {}

  destroy() {}

  afterConnect() {}

  checkBrowserSupport(browser?:Browser) {
    if (!this.browsersSupport) {
      return;
    }

    if (!browser) {
      browser = Utils.getBrowzerInfo();
    }

    const name:string = browser.name.toLowerCase();

    if (!this.browsersSupport.hasOwnProperty(name)) {
      return;
    }

    if (this.browsersSupport[name] === '') {
      this.isSupported = false;
    }

    if (parseFloat(browser.version) < parseFloat(this.browsersSupport[name])) {
      this.isSupported = false;
    }

    return this.isSupported;
  }
}
