import {ApplicationRef, ChangeDetectorRef, Component, ElementRef, Input} from '@angular/core';
import { SelectWidget } from '@geoanalitika/widgets';

@Component({
  selector: 'mv-geo-select',
  templateUrl: 'select.widget.html',
  styleUrls: ['select.widget.less']
})
export class AsyncSelect<T> extends SelectWidget<T> {
  @Input() async = false;
  @Input() placeholder = 'Выберите из списка';

  constructor(el:ElementRef) {
    super(el);
  }

  selectItem(item:T) {
    if (!this.async) {
      this.selectedItem = item;
      this.showOptions = false;
      this.select.emit(this.selectedItem);
    } else {
      this.select.emit(item);
    }
  }
}
