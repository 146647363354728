import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from 'shared/shared.module';
import { BufferZoneComponent } from './buffer_zone/buffer_zone.component';
import { CartometricService } from './cartometric.service';
import { GeoHeatMapComponent } from './heat_map/heat_map.component';
import { OverlayOperationsComponent } from './overlay_operations/overlay_operations.component';
import { SpatialClusterComponent } from './spatial_cluster/spatial_cluster.component';
import { ThematicMapComponent } from './thematic_map/thematic_map.component';
import { UnitsInput } from './units_input/units_input';
import { WidthAttrInput } from './width_attr_input/width_attr_input';
import { RangeEditorComponent } from './range_editor/range_editor.component';
import { PalettesListComponent } from './palettes_list/palettes_list.component';

@NgModule({
  declarations: [
    BufferZoneComponent,
    GeoHeatMapComponent,
    OverlayOperationsComponent,
    ThematicMapComponent,
    SpatialClusterComponent,
    WidthAttrInput,
    UnitsInput,
    RangeEditorComponent,
    PalettesListComponent
  ],
  imports: [BrowserModule, SharedModule, ColorPickerModule],
  exports: [
    BufferZoneComponent,
    GeoHeatMapComponent,
    OverlayOperationsComponent,
    ThematicMapComponent,
    SpatialClusterComponent
  ],
  entryComponents: [
    BufferZoneComponent,
    GeoHeatMapComponent,
    OverlayOperationsComponent,
    ThematicMapComponent,
    SpatialClusterComponent
  ],
  providers: [CartometricService]
})
export class CartometricModule {}
