import {
  DivIcon,
  DomEvent,
  DomUtil,
  LatLng,
  LatLngExpression,
  LayerGroup,
  LeafletMouseEvent,
  Map,
  Marker,
  Point,
  Polygon,
  Polyline,
  Util
} from 'leaflet';
import { ILeafletDraw } from './ILeafletDraw';

export class LeafletPolygon implements ILeafletDraw {
  private map:Map;
  private mapDiv:HTMLElement;
  private layerPaint:any; // слой, на котором рисуем
  private layerPaintPath:any; // нарисованный полигон
  private layerPaintPathTemp:any; // пунктирная линия, соединяет новую точку с последней
  private layerPaintPathTemp2:any; // пунктирная линия, соединяет новую точку с первой
  private areaPolygon:any; // по которому считаем площадь
  private drawing = true;
  private oldCursor:string;
  private doubleClickZoom:boolean;
  private points = Array<LatLng>();
  private lastCircle:any;
  private tooltip:Marker;
  private lastPoint:LatLngExpression;
  private firstPoint:LatLngExpression;
  private clearButtonClicked = false;

  private options:any = {
    needPoints: true, // нужно ли ставить точку по клику
    showArea: true, // показывать площадь

    defPolyStyle: {
      color: '#6289c4',
      weight: 3
    },

    defDeshLineStyle: {
      color: '#6289c4',
      weight: 2.5,
      clickable: false,
      dashArray: '5, 5'
    },

    defLineStrokeStyle: {
      color: '#FFFFFF',
      weight: 6,
      clickable: false
    },

    cursor: 'default'
  };

  constructor(options:any) {
    this.map = options.map;
    Util.setOptions(this, options);
    options.layer ? (this.layerPaint = options.layer) : (this.layerPaint = new LayerGroup([]).addTo(this.map));
    this.mapDiv = this.map.getContainer();
    const stylePolyOpt = this.options.polyStyle ? this.options.polyStyle : this.options.defPolyStyle;
    this.areaPolygon = new Polygon([], stylePolyOpt).addTo(this.layerPaint);
  }

  start() {
    if (!this.map) {
      console.error('Карта для класса leaflet.polygon не задана');
    }

    this.oldCursor = this.mapDiv.style.cursor;
    this.mapDiv.style.cursor = 'crosshair';

    this.doubleClickZoom = this.map.doubleClickZoom.enabled();
    this.map.doubleClickZoom.disable();

    this.map.on('mousemove', this.mouseMove, this);
    this.map.on('click', this.mouseClick, this);
    this.map.on('dblclick', this.finishPath, this);

    if (!this.points) { this.points = []; }
  }

  stop() {
    this.stopDraw();
    this.clearMap();
  }

  onDrawFinish() {}

  mouseMove(e:LeafletMouseEvent) {
    if (!e.latlng || !this.lastPoint || this.drawing === false) {
      return;
    }
    let latlngs:any;

    // если нет вспомогатeльных линий, создаём их
    if (!this.layerPaintPathTemp) {
      const dashLineStyle = this.options.dashLineStyle ? this.options.dashLineStyle : this.options.defDeshLineStyle;
      this.layerPaintPathTemp = new Polyline([this.lastPoint, e.latlng], dashLineStyle).addTo(this.layerPaint);
      this.layerPaintPathTemp2 = new Polyline([this.lastPoint, e.latlng], dashLineStyle).addTo(this.layerPaint);
    } else {
      latlngs = this.layerPaintPathTemp.getLatLngs();
      latlngs.splice(0, 2, this.lastPoint, e.latlng);
      this.layerPaintPathTemp.setLatLngs(latlngs);

      latlngs = this.layerPaintPathTemp2.getLatLngs();
      latlngs.splice(0, 2, e.latlng, this.firstPoint);
      this.layerPaintPathTemp2.setLatLngs(latlngs);
    }

    // если 3 точки и более
    if (this.points.length >= 3) {
      latlngs = this.areaPolygon.getLatLngs()[0];
      latlngs.splice(latlngs.length - 1, 1, e.latlng);
      //this.areaPolygon.setLatLngs(latlngs); // is it copy???
      this.createTooltip(e.latlng);
    }
  }

  private mouseClick(e:LeafletMouseEvent) {
    // Skip if no coordinates
    if (!e.latlng || this.drawing === false) {
      return;
    }

    if (!this.lastPoint) {
      this.firstPoint = e.latlng;
      if (this.clearButtonClicked) {
        this.clearButtonClicked = false;
        //return;
      } else {
        this.clearMap();
      }
    }

    // If this is already the second click, add the location to the fix path (create one first if we don't have one)
    if (this.lastPoint && !this.layerPaintPath) {
      const stylePolyOpt = this.options.polyStyle ? this.options.polyStyle : this.options.defPolyStyle;

      if (stylePolyOpt.clickable === undefined) {
        stylePolyOpt.clickable = false;
      }

      this.layerPaintPath = new Polygon([this.lastPoint, e.latlng], stylePolyOpt).addTo(this.layerPaint);
      this.areaPolygon.setLatLngs([this.lastPoint, e.latlng]);
      //this.areaPolygon.addLatLng(this.lastPoint);
    } else if (this.layerPaintPath) {
      this.layerPaintPath.addLatLng(e.latlng);
      this.areaPolygon.addLatLng(e.latlng);
    }

    // Save current location as last location
    this.lastPoint = e.latlng;
    if (this.points.indexOf(e.latlng) === -1) {
      this.points.push(e.latlng);
    }

    if (this.points.length === 3) {
      this.areaPolygon.addLatLng(e.latlng);
    }

    if (this.options.needPoints) {
      // создать тултип, если можно вычислить площадь полигона
      if (this.points.length >= 3) {
        // удалить предыдущий тултип
        if (this.tooltip) {
          this.layerPaint.removeLayer(this.tooltip);
        }
        this.createTooltip(e.latlng);
      }

      const clickable = this.lastCircle ? true : false;
      const icon = new DivIcon({ className: 'measure-line-div-icon' });

      this.lastCircle = new Marker(e.latlng, { icon/*, clickable */}).addTo(this.layerPaint);

      this.lastCircle.on('click', evt => {
        this.finishPath(evt);
        DomEvent.stop(evt);
      });
    }
  }

  private finishPath(e:LeafletMouseEvent) {
    if (this.points.length < 3) { return; }

    if (this.tooltip) {
      this.layerPaint.removeLayer(this.tooltip);
    }

    if (this.options.showArea) {
      this.createTooltip(e.latlng);

      const tooltip = document.getElementsByClassName('leaflet-measure-tooltip-total')[0];
      const clearButton = DomUtil.create('div', 'clear-measure-result');

      clearButton.addEventListener('click', evt => {
        this.finishPath(e as any);
        this.clearButtonClicked = true;
        this.layerPaint.clearLayers();
        evt.stopPropagation();
      });

      tooltip.appendChild(clearButton);
    }

    if (this.layerPaint && this.layerPaintPathTemp) {
      this.layerPaint.removeLayer(this.layerPaintPathTemp);
    }

    this.stopDraw();
  }

  private restartPath() {
    this.tooltip = undefined;
    this.lastCircle = undefined;
    this.lastPoint = undefined;
    this.layerPaintPath = undefined;
    this.layerPaintPathTemp = undefined;
    this.layerPaintPathTemp2 = undefined;
    this.areaPolygon.setLatLngs([]);
    this.points = [];
  }

  private createTooltip(position:LatLng) {
    if (this.tooltip) {
      this.layerPaint.removeLayer(this.tooltip);
    }

    // в метрах
    const mArea:number = Number(this.geodesicArea(this.areaPolygon.getLatLngs()));
    // в км
    const kmArea:number = Number(mArea / 1000000);

    let featureArea:string = mArea.toFixed(2);
    let measure = 'м';

    // если есть кв. км. то выводим в них
    if (kmArea >= 0.01) {
      featureArea = kmArea.toFixed(2);
      measure = 'км';
    }

    let hArea:number = Number(parseFloat(kmArea.toString()) * 100);
    // если прощадь в га маленькая выводить 4 знака после запятой
    if (hArea < 1) {
      hArea = Number(hArea.toFixed(4));
    } else {
      hArea = Number(hArea.toFixed(2));
    }

    const tooltipIcon:DivIcon = new DivIcon({
      className: 'leaflet-measure-tooltip leaflet-measure-tooltip-area',
      iconAnchor: new Point(75, 45),
      html: `<div class='leaflet-measure-tooltip-total'><span class='polyArea'>
<span class='measureVal'>${featureArea}  ${measure} <sup>2</sup> / ${hArea} га</span></span></div>`
    });

    this.tooltip = new Marker(position, {
      icon: tooltipIcon/*, clickable: false*/
    }).addTo(this.layerPaint);

    // Для того, чтобы понять какой ширины контент в подсказке
    // нужно нанести его на карту, посчитать,
    // потом поправить расположение подсказки.
    const toolContent:HTMLElement = document.getElementsByClassName('leaflet-measure-tooltip-area')[0] as HTMLElement;
    (tooltipIcon as any).options.iconAnchor.x = toolContent.offsetWidth / 2;
    this.tooltip.setIcon(tooltipIcon);
    // ставим zIndex = -1, чтобы можно было кликнуть по последней точке,
    // иначе клик будет по подсказке
    this.tooltip.setZIndexOffset(-1);
  }

  private geodesicArea(latLngs:any[]) {
    latLngs = latLngs[0];

    const pointsCount = latLngs.length;
    const d2r = Math.PI / 180;
    const  R = 6378137.0; // radius earth

    let area = 0.0;
    let p1, p2;

    if (pointsCount > 2) {
      for (let i = 0; i < pointsCount; i++) {
        p1 = latLngs[i];
        p2 = latLngs[(i + 1) % pointsCount];
        area += ((p2.lng - p1.lng) * d2r) *
            (2 + Math.sin(p1.lat * d2r) + Math.sin(p2.lat * d2r));
      }
      area = area * R * R / 2.0;
    }

    return Math.abs(area);
  }

  private stopDraw() {
    this.mapDiv.style.cursor = this.oldCursor;

    this.map.off('mousemove', this.mouseMove, this);
    this.map.off('click', this.mouseClick, this);
    this.map.off('dblclick', this.finishPath, this);

    if (this.doubleClickZoom) { this.map.doubleClickZoom.enable(); }

    this.restartPath();
    this.onDrawFinish();
  }

  private clearMap() {
    if (this.layerPaint) {
      this.layerPaint.clearLayers();
    }
  }
}
