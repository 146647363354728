import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IDictionary } from '../interfaces/IDictionary';

@Injectable()
export class DictionaryService {
  private _url = 'dictionaries/';
  private dictionaries:Map<number, IDictionary> = new Map<number, IDictionary>();

  constructor(private httpClient:HttpClient) {}

  getDictionaries(idDicts:number[]) {
    idDicts = idDicts.filter(id => !this.dictionaries.has(id));
    if (!idDicts.length) {
      return;
    }
    const params:HttpParams = new HttpParams().set('ids', idDicts.toString());
    this.httpClient
      .get<{ dictionaries:any[] }>(this._url, { params })
      .pipe(catchError(ex => observableThrowError(ex)))
      .subscribe(data => {
        data.dictionaries.map((dict:IDictionary) => {
          this.dictionaries.set(dict.id, dict);
        });
      });
  }

  getDictionary(id:number):IDictionary {
    return this.dictionaries.get(id);
  }

  getDictionaryVals(id:number):string[] {
    return this.getDictionary(id).entries.map(item => item.value);
  }
}
