import {
  AfterContentInit,
  Component,
  ElementRef, EventEmitter,
  HostBinding,
  Input, Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {IActivator, IContainer, IContentChild, IDomPanel, IMenu, IPluginInterface, ITool} from '../../interfaces';
import { Creator } from '../../services';
import { MenuComponent } from '../menu/menu.component';
import {Button} from '..';

@Component({
  selector: 'simple-win',
  templateUrl: 'simple-window.component.html',
  styleUrls: ['simple-window.component.less']
})
export class SimpleWindowComponent extends MenuComponent implements IDomPanel, IContainer, ITool, AfterContentInit {
  @Input() btnOpen = true; // toggle slide menu after btn click
  @Input() padding = 0;

  @Output() resizeStart = new EventEmitter<void>();

  @HostBinding('class.simple-window-open') opened = false;
  @ViewChild('content', { read: ViewContainerRef }) contentDiv:ViewContainerRef;
  activateMode:string = null;
  public groupName = '';

  protected btn:Button;
  private contentChildren = Array<IContentChild>();
  private domNode:HTMLElement;
  private menu:IMenu;
  private active:boolean;
  private activator:IActivator; // плагин, отвечающий за отключение связанных плагинов

  constructor(creator:Creator, el:ElementRef) {
    super(creator, el);
    this.domNode = el.nativeElement;
  }

  addInterface(name:string, pi:IPluginInterface):void {
    const menu:IMenu = pi as IMenu;
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;
        // TODO: Придумать более элегантную реализацию кнопок для 'включения' инструментов
        // создание кнопки в меню для активации
        this.menu.createBtn().then(btnRef => {
          const btn:Button = btnRef.instance;
          btn.className = this.buttonConfig.className;
          btn.title = this.buttonConfig.title;
          btn.position = this.buttonConfig.position;

          btn.onClickMe = () => {
            this.toggle();
          };

          btn.onDeactivate = () => {
            this.close();
          };

          this.btn = btn;
          this.menu.addBtn(btn);
        });
        break;
      case 'Activator':
        this.activator = pi as IActivator;
        this.activator.setConnect(this as ITool);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
      case 'Activator':
        this.activator = null;
        break;
    }
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
  }

  getContainer() {
    return this.contentDiv;
  }

  addChild(child:IContentChild) {
    this.contentChildren.push(child);
  }

  activateTool() {
    this.opened = true;
    this.active = true;
    this.contentChildren.forEach(child => {
      child.activate();
    });
  }

  deactivateTool():Promise<boolean> {
    this.domNode.style.width = '40%';
    this.opened = false;
    this.active = false;
    this.contentChildren.forEach(child => {
      child.deactivate();
    });
    return Promise.resolve(true);
  }

  isActive() {
    return this.active;
  }


  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.opened = true;
    this.contentChildren.forEach(child => {
      child.activate();
    });
    this.onShow.emit();
  }

  close() {
    this.opened = false;
    this.contentChildren.forEach(child => {
      child.deactivate();
    });
    this.onHide.emit();
  }

  getGroup() {
    return this.groupName;
  }
}
