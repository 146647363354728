import { Component } from '@angular/core';
import { Feature, PluginClass, Point } from 'shared/classes';
import { Button } from 'shared/components';
import {
  IContainer,
  IContentChild,
  IEdit,
  IFeature,
  IGeoJson,
  ILayer,
  IMenu,
  IPluginInterface,
  IPosition,
  ISearchResult,
  ISwitch,
  IVertexPosition
} from 'shared/interfaces';

export interface IVertex {
  x:number;
  y:number;
  checked?:boolean;
}

export interface IGeomFragment {
  parentIdx:number;
  coordinates:IVertex[];
}

@Component({
  selector: 'edit-coords',
  templateUrl: 'edit_coords.component.html',
  styleUrls: ['edit_coords.component.less']
})
export class EditCoordsComponent extends PluginClass implements IFeature, IContentChild {
  parentComponent:IContainer;
  toolName = 'Редактирование';
  keepHighlighted = false;
  tempObjectsList:IGeomFragment[] = [{ parentIdx: 0, coordinates: Array<IVertex>() }];
  showWarning = false;
  feature:Feature = null;
  currentVertexPosition:IVertexPosition = { objectIdx: null, vertexIdx: null };

  private active = false;
  private btn:Button;
  private layer:ILayer;
  private menu:IMenu;
  private truncateCoordsBy:number;

  // Связанные плагины
  private featurePlugin:IFeature;
  private editPlugin:IEdit;
  private switchModePlugin:ISwitch;
  private posPlugins:IPosition[] = [];
  private resultPlugins:ISearchResult[] = [];

  constructor() {
    super();
  }

  onLoad() {
    if (this.parentComponent) {
      const oldFunc:() => void = this.parentComponent.close;

      this.parentComponent.close = () => {
        oldFunc.call(this.parentComponent);
        if (this.btn.isActive()) {
          this.btn.setActive(false);
        }
        this.deactivate();
      };
    }
  }

  // При активации получить текущий объект от плагина редактирования,
  // создать временный список вершин
  activate() {
    this.active = true;
    const features = this.featurePlugin.getFeatures();

    // Включить подсветку
    this.editPlugin.highlightMode = true;

    if (features[0]) {
      this.feature = features[0];
      this.feature.truncateCoordinates(this.truncateCoordsBy);
      this.tempObjectsList = this.createTempList(this.feature);
    } else {
      this.feature = this.createNewFeature();
    }
  }

  deactivate() {
    if (this.active) {
      this.active = false;

      // Выключить подсветку
      this.editPlugin.highlightMode = false;

      // Обнулить текущий объект
      if (this.feature.geometry.coordinates.length) {
        // Снять подсветку с вершины
        this.showFeature(this.feature);

        // При скрытии окна с координатами меняем состояние переключателя вручную
        if (this.switchModePlugin.mode !== 'Add') {
          this.switchModePlugin.mode = 'CoordsHasFeature';
          this.switchModePlugin.switchMode('Edit');
        }
      }

      this.feature = null;

      this.resetTempObjectList();
      this.resetHighlight();
      this.showWarning = false;
      this.keepHighlighted = false;
    }
  }

  enable() {
    this.btn.btnDisabled = false;
  }

  disable() {
    this.btn.btnDisabled = true;
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Feature':
        this.featurePlugin = pi as IFeature;
        break;
      case 'Edit':
        this.editPlugin = pi as IEdit;
        break;
      case 'Position':
        this.posPlugins.push(pi as IPosition);
        break;
      case 'SwitchMode':
        this.switchModePlugin = pi as ISwitch;
        break;
      case 'ShowResult':
        this.resultPlugins.push(pi as ISearchResult);
        break;
      case 'Menu':
        this.menu = pi as IMenu;
        this.menu.createBtn(this.buttonConfig.menuLvl).then(btn => {
          this.btn = btn.instance;
          this.buttonConfig.onClickMe = () => {
            if (this.parentComponent) {
              this.parentComponent.open();
            }

            this.activate();
          };
          this.buttonConfig.onDeactivate = () => {
            if (this.parentComponent) {
              this.parentComponent.close();
            }
            this.deactivate();
          };

          this.btn.setOptions(this.buttonConfig);
          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Feature':
        this.featurePlugin = null;
        break;
      case 'Edit':
        this.editPlugin = null;
        break;
      case 'SwitchMode':
        this.switchModePlugin = null;
        break;
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  set vertexPosition(value:IVertexPosition) {
    this.currentVertexPosition = value;
  }

  setFeatures(features:Feature[]):void {
    if (this.active) {
      this.feature = features[0];
      this.feature.truncateCoordinates(this.truncateCoordsBy);
      this.tempObjectsList = this.createTempList(this.feature);
    }
  }

  getFeatures() {
    return [this.feature];
  }

  updateFeatures(features?:Feature[]) {
    // Очистить список объектов
    if (!features) {
      this.feature = this.createNewFeature();
      this.resetHighlight();
    }
  }

  deleteVertices() {
    if (!this.keepHighlighted) {
      // Фильтруем отмеченные вершины, создаем из них объект и отображаем на карте,
      // отмеченные вершины автоматически удалятся
      this.tempObjectsList.forEach(object => {
        object.coordinates = object.coordinates.filter((vertex:IVertex) => {
          return !vertex.checked;
        });
      });

      const validVertices = this.filterInvalidCoordinates(this.tempObjectsList);
      const geometry = this.createGeometry(validVertices);

      if (geometry) {
        this.feature.geometry = geometry;
        this.showFeature(this.feature);
        this.editPlugin.writeToHistory(this.feature);
        this.featurePlugin.updateFeatures([this.feature]);
      } else {
        this.editPlugin.clearEditedFeatures();
      }

      this.resetHighlight();
    }
  }

  /**
   * Обновление объекта при изменении значений координат.
   * @param {string} coord - Название координаты, 'x' или 'y'
   * @param {number} vertexPosition - Индекс вершины
   * @param {string} value - Значение, введенное в инпут
   * @param {input} HTMLElement - Элемент инпута для установки фокуса при вводе некорректного значения
   */
  updateFeature(coord:string, vertexPosition:IVertexPosition, value:string, input:HTMLElement):void {
    let filteredCoordinates, geometry;
    const { vertexIdx, objectIdx } = vertexPosition;

    if (!this.inputIsValid(coord, value)) {
      this.showWarning = true;
      this.keepHighlighted = true;

      // Сохранять фокус на инпуте с некорректным значением,
      // пока оно не будет исправлено. Timeout для корректной работы в FF,
      // см. http://stackoverflow.com/questions/26406718/focus-onblur-not-working-in-mozilla
      input.onblur = event => {
        setTimeout(() => {
          input.focus();
        });
      };
      return;
    }

    // Сбросить фокус, скрыть предупреждение
    input.onblur = () => {};
    this.keepHighlighted = false;
    this.showWarning = false;

    // Сохраняем позицию текущей вершины в массиве для подсветки строки в таблице
    this.currentVertexPosition = vertexPosition;

    // Обновляем значение координаты и создаем новый объект
    this.tempObjectsList[objectIdx]['coordinates'][vertexIdx][coord] = Number(value);

    filteredCoordinates = this.filterInvalidCoordinates(this.tempObjectsList);
    if (!this.hasAnyCoordinates(filteredCoordinates)) {
      return;
    }

    geometry = this.createGeometry(filteredCoordinates);

    // Наносим обновленный объект на карту
    this.feature.geometry = geometry;
    this.switchModePlugin.switchMode('CoordsHasFeature');
    this.resultPlugins.forEach(plugin => {
      plugin.editInfo(this.feature);
    });

    this.showFeature(this.feature);
    this.editPlugin.writeToHistory(this.feature);

    this.highlightVertex(vertexPosition);

    // Если это первая точка, центрировать карту на неё
    const coordinates = this.getSimpleCoordinatesArray(this.feature);
    if (coordinates.length === 1) {
      const point = new Point(coordinates[0][0], coordinates[0][1]);
      this.posPlugins.forEach(plugin => {
        plugin.goToPoint(point);
      });
    }

    // Если это последний инпут, добавить еще одну вершину
    if (coord === 'y' && vertexIdx === this.tempObjectsList[objectIdx].coordinates.length - 1) {
      this.addVertex(objectIdx);
      this.focusOnInput(vertexPosition);
    }
  }

  highlightVertex(position:IVertexPosition) {
    if (position.objectIdx === null || position.vertexIdx === null) {
      return;
    }

    const targetVertex = this.tempObjectsList[position.objectIdx].coordinates[position.vertexIdx];

    if (!this.keepHighlighted && this.vertexIsValid(targetVertex)) {
      this.currentVertexPosition = position;

      // TODO: сделать подсветку маркера на карте
      if (this.feature.type === 'point') {
        return;
      }

      const validVertices = this.filterInvalidCoordinates(this.tempObjectsList);
      if (!this.hasAnyCoordinates(validVertices)) {
        return;
      }

      this.feature.geometry = this.createGeometry(validVertices);
      this.showFeature(this.feature);
      this.editPlugin.highlightVertex(position);
    }
  }

  addVertex(objectIdx:number) {
    // Нельзя добавить вершину в маркер
    if ((this.feature.type === 'point' || this.feature.type === 'multipoint') && this.tempObjectsList[objectIdx].coordinates.length) {
      return;
    }

    const object = this.tempObjectsList[objectIdx];

    // Если нет последней вершины, значит это новый объект
    const lastVertex = object.coordinates[object.coordinates.length - 1];

    if (!lastVertex || this.vertexIsValid(lastVertex)) {
      object.coordinates.push({ x: null, y: null, checked: false });
      this.resetHighlight();
    }
  }

  customTrackBy(index:number, obj:any):any {
    return index;
  }

  // Возвращает true, если в массивах координат объектов есть хотя бы один элемент
  private hasAnyCoordinates(validCoordinates:IGeomFragment[]):boolean {
    let has = false;
    validCoordinates.forEach((object:IGeomFragment) => {
      if (object.coordinates.length) {
        has = true;
      }
    });
    return has;
  }

  private inputIsValid(coord:string, value:string):boolean {
    let matches;
    // Проверяем введенные координаты на корректность,
    // регулярки отсюда: http://stackoverflow.com/a/31408260
    if (coord === 'x') {
      // -90 до 90
      matches = value.match(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/);
    } else if (coord === 'y') {
      // -180 до 180
      matches = value.match(/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/);
    }

    if (!matches) {
      return false;
    }
    return true;
  }

  // Сфокусироваться на инпуте, следующим за последним
  // TODO: заставить работать на нажатие Tab
  private focusOnInput(vertexPosition:IVertexPosition) {
    setTimeout(() => {
      const input = document.getElementById(`vertexX-${vertexPosition.objectIdx}-${vertexPosition.vertexIdx + 1}`);
      if (input) {
        input.focus();
      }
    });
  }

  // Создает пустой объект с заданным типом геометрии и ссылкой на слой
  private createNewFeature():Feature {
    const feature = new Feature();
    this.layer = feature.layer = this.editPlugin.getCurrentLayer();
    if (!this.layer) {
      return;
    }
    feature.geometry = {
      type: this.layer.geomType,
      coordinates: []
    };
    this.resetTempObjectList();

    return feature;
  }

  private filterInvalidCoordinates(objects:IGeomFragment[]):IGeomFragment[] {
    const objectsCopy = JSON.parse(JSON.stringify(objects));

    objectsCopy.forEach((object:IGeomFragment) => {
      object.coordinates = object.coordinates.filter((vertex:IVertex) => {
        return this.vertexIsValid(vertex);
      });
    });

    return objectsCopy;
  }

  // Создает временный список объектов из геометрии
  private createTempList(feature:Feature):IGeomFragment[] {
    const tempObjectList:IGeomFragment[] = [];

    switch (feature.type) {
      case 'multipolygon':
        (feature.geometry.coordinates as any[]).forEach((poly, i) => {
          poly.forEach((part:any[]) => {
            const geomFragment:IGeomFragment = { parentIdx: i, coordinates: [] };
            part.forEach((vertex:number[]) => {
              geomFragment.coordinates.push(new Point(vertex[0], vertex[1]));
            });
            tempObjectList.push(geomFragment);
          });
        });
        break;
      case 'multilinestring':
      case 'polygon':
        (feature.geometry.coordinates as any[]).forEach(object => {
          const geomFragment:IGeomFragment = { parentIdx: 0, coordinates: [] };
          object.forEach((vertex:number[]) => {
            geomFragment.coordinates.push(new Point(vertex[0], vertex[1]));
          });
          tempObjectList.push(geomFragment);
        });
        break;
      case 'multipoint':
        (feature.geometry.coordinates as any[]).forEach((point, i) => {
          const geomFragment:IGeomFragment = { parentIdx: i, coordinates: [new Point(point[0], point[1])] };
          tempObjectList.push(geomFragment);
        });
        break;
      case 'linestring':
        const coordsList:IGeomFragment = { parentIdx: 0, coordinates: [] };
        (feature.geometry.coordinates as any[]).forEach(vertex => {
          coordsList.coordinates.push(new Point(vertex[0], vertex[1]));
        });
        tempObjectList.push(coordsList);
        break;
      case 'point':
        tempObjectList.push({
          parentIdx: 0,
          coordinates: [new Point((feature.geometry.coordinates as number[])[0], (feature.geometry.coordinates as number[])[1])]
        });
        break;
      default:
        console.error(`Неподдерживаемый тип геометрии ${feature.type}`);
        break;
    }

    return tempObjectList;
  }

  // Создает геометрию из временного списка объектов
  private createGeometry(objects:IGeomFragment[]):IGeoJson {
    if (!objects.length) {
      return;
    }

    const geometry:IGeoJson = { coordinates: [], type: this.feature.type };
    const coordinates:any[] = [];

    switch (this.feature.type) {
      case 'multipolygon':
        const copyObjects:IGeomFragment[] = JSON.parse(JSON.stringify(objects));

        copyObjects.forEach((object:IGeomFragment) => {
          if (!coordinates[object.parentIdx]) {
            coordinates[object.parentIdx] = [];
          }
          coordinates[object.parentIdx].push(object);
        });

        coordinates.forEach(poly => {
          poly.forEach((object:any, i:number, arr:any[]) => {
            arr[i] = this.convertPointsToNumArr(object.coordinates);
          });
        });

        geometry.coordinates = coordinates;
        break;
      case 'multilinestring':
      case 'polygon':
        objects.forEach(object => {
          coordinates.push(this.convertPointsToNumArr(object.coordinates));
        });
        geometry.coordinates = coordinates;
        break;
      case 'multipoint':
        objects.forEach(object => {
          if (object.coordinates.length) {
            coordinates.push(this.convertPointsToNumArr(object.coordinates)[0]);
          }
        });
        geometry.coordinates = coordinates;
        break;
      case 'point':
        geometry.coordinates = this.convertPointsToNumArr(objects[0].coordinates)[0];
        break;
      case 'linestring':
        geometry.coordinates = this.convertPointsToNumArr(objects[0].coordinates);
        break;
      default:
        break;
    }

    return geometry;
  }

  public showFeatureInfo(feature:Feature) {
  }

  // Отобразить на карте
  public showFeature(feature:Feature) {
    this.editPlugin.clearEditedFeatures();
    this.editPlugin.showEditableFeatures([feature]);
  }

  // Возвращает массив координат первого объекта
  private getSimpleCoordinatesArray(feature:Feature) {
    switch (feature.type) {
      case 'point':
        return [feature.geometry.coordinates];
      case 'linestring':
        return feature.geometry.coordinates;
      case 'polygon':
      case 'multilinestring':
        return feature.geometry.coordinates[0];
      case 'multipolygon':
        return (feature.geometry.coordinates as any[])[0][0];
      case 'multipoint':
        return [feature.geometry.coordinates[0]];
    }
  }

  private convertPointsToNumArr(points:IVertex[]):number[][] {
    return points.map(point => {
      return [point.x, point.y];
    });
  }

  private vertexIsValid(vertex:IVertex):boolean {
    return vertex.x === 0 || (!!vertex.x && vertex.y === 0) || !!vertex.y;
  }

  private resetHighlight() {
    this.currentVertexPosition = { objectIdx: null, vertexIdx: null };
  }

  private resetTempObjectList() {
    this.tempObjectsList = [{ parentIdx: 0, coordinates: Array<IVertex>() }];
  }
}
