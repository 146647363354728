import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import {AlertBuilderComponent} from 'shared/components';

@Injectable()
export class MessageService {
  constructor(private modalService:BsModalService) {}

  openAlertWindow(text:string, buttonsText:string[], activeButton:number):Observable<number> {
    return new Observable(obs => {
      const bsModalRef = this.modalService.show(AlertBuilderComponent, { class: 'alert-builder-dialog' });
      const component = bsModalRef.content as AlertBuilderComponent;
      component.text = text;
      component.buttons = buttonsText;
      component.activeButton = activeButton;
      component.submit.subscribe((index:number) => {
        obs.next(index);
        bsModalRef.hide();
      });
    });
  }
}
