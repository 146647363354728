import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILayer } from '../../interfaces';

@Component({
  selector: 'layer-tree',
  templateUrl: 'layer_tree_view.component.html',
  styleUrls: ['layer_tree_view.component.less']
})
export class LayerTreeViewComponent {
  @Input() layers:ILayer[];

  @Output() onLayerChecked = new EventEmitter();

  check(layer:ILayer) {
    this.onLayerChecked.emit(layer);
  }

  passEventThrough(evt:Event) {
    this.onLayerChecked.emit(evt);
  }

  toggleGroup(layer:any) {
    layer.collapse = !layer.collapse;
  }
}
