import { Component, ElementRef, ViewChild } from '@angular/core';
import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import { Feature, Utils } from 'shared/classes';
import { Image, PluginClass } from '../../';
import { ILightBox } from '../../interfaces';

@Component({
  selector: 'geo-light-box',
  templateUrl: 'light_box.component.html',
  styleUrls: ['light_box.component.less']
})
export class LightBoxComponent extends PluginClass implements ILightBox {
  @ViewChild('pswp') pswp:ElementRef;

  images:Image[] = [];

  constructor() {
    super();
  }

  openGallery(idx:number) {
    Utils.getImagesMeta(this.images).subscribe(images => {
      const gallery = new PhotoSwipe(
        this.pswp.nativeElement,
        PhotoSwipeUI_Default,
        images.map(image => ({
          src: image.url,
          w: image.width,
          h: image.height
        })),
        {
          index: idx
        }
      );
      gallery.init();
    });
  }

  showImages(images:Image[], image:Image) {
    const idx = images.findIndex(item => item === image);
    this.images = images;
    this.openGallery(idx);
  }

  showChart(feature:Feature) {}
}
