import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Feature } from '../../classes/Feature';
import { GeoserverLayer } from '../../classes/LeafletLayer/GeoserverLayer/GeoserverLayer.class';

@Injectable()
export class GeoserverService {
  constructor(private httpClient:HttpClient) {}

  searchPoint(layers:GeoserverLayer[], geoJSON:GeoJSON.DirectGeometryObject) {
    return this.searchArea(layers, geoJSON);
  }

  searchArea(layers:GeoserverLayer[], geoJSON:GeoJSON.DirectGeometryObject) {
    if (!layers.length) { return Promise.resolve([]); }
    const allRequest$:Observable<Feature[]>[] = [];
    layers.forEach(layer => {
      allRequest$.push(this._searchArea(layer, geoJSON));
    });
    const source$:any = forkJoin(allRequest$);
    return this._getResults(source$);
  }

  searchText(layers:GeoserverLayer[], text:string) {
    if (!layers.length) { return Promise.resolve([]); }
    const allRequest$:Observable<Feature[]>[] = [];
    layers.forEach(layer => {
      allRequest$.push(this._searchText(layer, text));
    });
    const source$:any = forkJoin(allRequest$);
    return this._getResults(source$);
  }

  _searchArea(layer:GeoserverLayer, geomObject:GeoJSON.DirectGeometryObject):Observable<any> {
    const body:string = layer.getGMLForArea(geomObject);

    return this.httpClient.post(layer.url, body, { responseType: 'text' }).pipe(
      map(res => {
        return layer.parseGML(res);
      })
    );
  }

  private _searchText(layer:GeoserverLayer, text:string):Observable<any> {
    const body:string = layer.getGMLForText(text);
    return this.httpClient.post(layer.url, body, { responseType: 'text' }).pipe(
      map(res => {
        let findFeatures:Feature[] = [];
        // check for errors
        const nodeExp:HTMLCollectionOf<Element> = new DOMParser()
          .parseFromString(res, 'text/xml')
          .getElementsByTagNameNS('http://www.opengis.net/ows', 'ExceptionReport');
        if (!nodeExp.length) {
          findFeatures = layer.parseGML(res);
        }
        return findFeatures;
      })
    );
  }

  private _getResults(source$:Observable<Feature[]>):Promise<Feature[] | any> {
    return source$
      .pipe(
        map((data:any) => {
          let features = [];
          data.forEach(bunch => {
            features = [...features, ...bunch];
          });
          return features;
        }),
        catchError(() => of([]))
      )
      .toPromise();
  }
}
