import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { PluginClass } from '../../classes';
import { IContainer, IContentChild, IMenu, IPluginInterface } from '../../interfaces';
import { Creator } from '../../services/creator.service';
import { Button } from '../button/button.component';

@Component({
  selector: 'menu-item-container',
  templateUrl: 'menu_item_container.component.html',
  styleUrls: ['menu_item_container.component.less']
})
export class MenuItemContainerComponent extends PluginClass implements IContainer {
  @HostBinding('class') classes:string;

  @ViewChild('container', { read: ViewContainerRef }) container:ViewContainerRef;
  @ViewChild('btn') menuBtn:Button;

  @Input() className:string;
  @Input() title:string;
  @Input() text:string;
  @Input() action:string;
  @Input() contentClass:string;
  @Input() simple = false; // отображать просто контент без рамок
  @Input() showTip = false; // отображать треугольник
  @Input() hideOnClickOutside = false;

  @Input() componentName:string;

  @Output() onShow = new EventEmitter();
  @Output() onHide = new EventEmitter();

  contentChildren = Array<IContentChild>();
  active = false;

  private menu:IMenu;

  constructor(private creator:Creator, private elementRef:ElementRef) {
    super();
  }

  onLoad() {
    this.menuBtn.onClickMe = () => {
      this.activate();
    };

    this.menuBtn.onDeactivate = () => {
      this.deactivate();
    };
  }

  getMenuBtn():Button {
    return this.menuBtn as Button;
  }

  activate() {
    this.active = true;
    this.contentChildren.forEach(child => {
      child.activate();
    });
  }

  deactivate() {
    this.active = false;
    this.contentChildren.forEach(child => {
      child.deactivate();
    });
    this.contentChildren.forEach(child => {
      child.deactivate();
    });
  }

  addInterface(name:string, pi:IPluginInterface):void {
    if (name === 'Menu') {
      const menu = (this.menu = pi as IMenu);

      this.menuBtn.onClickMe = () => {
        this.activate();
      };

      this.menuBtn.onDeactivate = () => {
        this.deactivate();
      };

      this.menuBtn.setOptions(this.buttonConfig);

      // добавляем кнопку в меню
      this.menu.addBtn(this.menuBtn);
    } else {
      console.error('Компонент ' + (this.constructor as any).name + ' не обрабатывает вход ' + name);
    }
  }

  removeInterface(name:string):void {
    if (name === 'Menu') {
      this.menu.removeBtn(this.menuBtn);
      this.menu = null;
    }
  }

  createComponent(component:any):Promise<any> {
    return this.creator.createComponent(component, this.container);
  }

  getContainer() {
    return this.container;
  }

  open() {
    this.menuBtn.setActive(true);
  }

  close() {
    this.menuBtn.setActive(false);
  }

  isOpen() {
    return this.active;
  }

  addChild(child:IContentChild) {
    this.contentChildren.push(child);
  }

  @HostListener('document:mousedown', ['$event.target'])
  private outSideClick(targetElement:HTMLElement):void {
    if (!targetElement || !this.active || !this.hideOnClickOutside) {
      return;
    }

    if (!this.elementRef.nativeElement.contains(targetElement)) {
      this.menuBtn.setActive(false);
    }
  }
}
