import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Bounds, PluginClass } from 'shared/classes';
import { Button } from 'shared/components';
import { IContainer, IContentChild, IMenu, IPluginInterface } from 'shared/interfaces';
import { MapService } from '../../services/services';
import { Bookmark } from './Bookmark.class';

@Component({
  selector: 'bookmarks',
  templateUrl: 'bookmarks.component.html',
  styleUrls: ['bookmarks.component.less']
})
export class BookmarksComponent extends PluginClass implements IContentChild, OnInit {
  @ViewChild('bookmarkForm') form:NgForm;

  bookmarkName = '';
  bookmarks:Bookmark[];
  parentComponent:IContainer;

  private btn:Button = null;
  private menu:IMenu;

  constructor(private mapService:MapService) {
    super();
  }

  ngOnInit() {
    this.retrieveStoredBookmarks();
  }

  activate() {
    if (this.parentComponent) {
      this.parentComponent.open();
    }
  }

  deactivate() {}

  enable() {}

  disable() {}

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;

        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.btn.setOptions(this.buttonConfig);
          this.btn.onClickMe = () => {
            if (this.parentComponent) {
              this.parentComponent.open();
            }
            this.activate();
          };

          this.btn.onDeactivate = () => {
            this.deactivate();
          };

          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  addBookmark(name:string):void {
    if (name) {
      this.bookmarkName = '';

      const newBounds = this.mapService.currentMapBound$.value;
      const bookmark = new Bookmark(name, newBounds);
      this.bookmarks.push(bookmark);
      this.form.reset();
      this.saveToLocalStorage();
    }
  }

  removeBookmark(idx:number):void {
    this.bookmarks.splice(idx, 1);
    this.saveToLocalStorage();
  }

  loadExtent(bounds:Bounds):void {
    this.mapService.changeMapBound$.next(bounds);
  }

  private saveToLocalStorage():void {
    localStorage.setItem('bookmarks', JSON.stringify(this.bookmarks));
  }

  private retrieveStoredBookmarks():void {
    const retrievedBookmarks = localStorage.getItem('bookmarks');
    this.bookmarks = retrievedBookmarks ? JSON.parse(retrievedBookmarks) : [];
  }
}
