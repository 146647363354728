import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {IDomPanel, IMenu, IPluginInterface, ISimpleMap} from '../../interfaces';
import { Creator } from '../../services/creator.service';
import { Button } from '../button/button.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'slide-panel-new',
  templateUrl: 'slide_panel_new.component.html',
  styleUrls: ['slide_panel_new.component.less']
})
export class SlidePanelNewComponent extends MenuComponent implements IDomPanel, AfterContentInit {
  @Input() btnOpen = true; // toggle slide menu after btn click
  @Input() id:string;
  @Input() minSize:number = 0;

  @Output() resizeStart = new EventEmitter<void>();

  @HostBinding('class.panel-open') opened = false;

  @ViewChild('buttons', { read: ViewContainerRef }) buttonsDiv:ViewContainerRef;
  @ViewChild('content', { read: ViewContainerRef }) contentDiv:ViewContainerRef;

  protected menu:IMenu;
  protected btn:Button;
  private map:ISimpleMap;

  constructor(creator:Creator, el:ElementRef) {
    super(creator, el);
  }

  onResizeStart() {
    this.resizeStart.emit();
  }

  onResizeEnd() {
    this.map.redraw();
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;
        // создание кнопки
        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.buttonConfig.onClickMe = () => {
            this.toggle();
          };
          this.buttonConfig.onDeactivate = () => {
            this.toggle();
          };
          this.btn.setOptions(this.buttonConfig);
          this.menu.addBtn(this.btn);
        });
        break;
      case 'Map':
        this.map = pi as ISimpleMap;
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
  }

  addBtn(btn:Button) {
    if (!btn) {
      return;
    }

    btn.btnClick = () => {
      if (this.curBtn === btn && this.btnOpen) {
        this.opened = false;
        return;
      }

      this.childClick(btn);

      if (this.btnOpen) {
        this.opened = true;
      }

      this.curBtn = btn;
      this.deactivateBtns();
      // текущая
      btn.setActive(true);
    };
    this.btns.push(btn);
  }

  getContainer() {
    return this.contentDiv;
  }

  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.opened = true;
    this.onShow.emit();
    if (this.map) {
      setTimeout(() => this.map.redraw(), 100);
    }
  }

  close() {
    this.opened = false;
    this.onHide.emit();
    if (this.map) {
      setTimeout(() => this.map.redraw(), 100);
    }
  }
}
