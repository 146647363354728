import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Creator } from '../../services';

@Component({
  selector: 'getmap-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['dialog.component.less']
})
export class DialogComponent implements OnInit {
  @Input() caption:string;
  @Input() windowHeightStyle:string;
  @Output() hide = new EventEmitter();

  private domNode:HTMLElement;
  private parentNode:HTMLElement;

  constructor(el:ElementRef, private creator:Creator) {
    this.domNode = el.nativeElement;
  }

  ngOnInit() {
    this.parentNode = this.domNode.parentElement;
  }
}
