import { StyleClass } from './StyleClass';

export class PointStyle extends StyleClass {
  constructor(options:any) {
    super();
    for (const option in options) {
      if (typeof this[option] !== 'undefined') {
        this[option] = options[option];
      }
    }
  }

  draw() {
    if (!this.canvas) { return; }
  }
}
