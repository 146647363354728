import {ISymbolizer} from './ISymbolizer';
import {Attribute, AttributeOptions} from './IDataLayer';

export class Value {
  value:string;
  title:string;
}

export function adjustPalette(palette:string[], count:number) {
  while (palette.length < count) {
    palette.push(palette.slice(-1)[0]);
  }
  return palette.slice(0, count);
}

export const FONTS = ['DejaVuSans'];

export type ColorizerType = 'text' | 'raster' | 'polygon' | 'marker' | 'linestring' | 'cluster';
export type DistributionMethod = 'auto' | 'quantile' | 'manual';
export type TypeGroup = 'string' | 'integer';
export type ColorizerStopMode = 'L' | 'D' | 'E';

export class Font {
  bold:boolean;
  bold_italic:boolean;
  italic:boolean;
  name:string;
}

export const PLACEMENT_CHOICES = [{ value: 'P', name: 'point' }, { value: 'L', name: 'line' }, { value: 'I', name: 'interior' }];

export class Symbolizer implements ISymbolizer {
  id:number;
  rule_id:number;
  resource_uri:string;
  type:ColorizerType = null;
  name = '';
  scale_min = 0;
  scale_max = 22;

  order = 0;
  attributes:StyleAttribute[] = [];
  dirty = false;

  // зачем геттер?
  get colors():any[] {
    return [];
  }

  constructor(data?:any) {
    this.setData(data);
  }

  setData(data:any) {
    if (!data) {
      return;
    }
    Object.assign(this, data);
  }

  getPropertyColor(property:string) {
    if (!this.hasOwnProperty(property)) {
      return;
    }

    let colors:string[] = [this[property]];
    if (!this.attributes) {
      this.attributes = [];
    }
    const attr:StyleAttribute = this.attributes.find(item => item.styleProperty === property);

    // раскраска по атрибуту
    if (attr) {
      colors = new StyleAttribute(attr).getColors();
    }

    return colors;
  }
}

export class AttributeValue {
  name:any = null;
  nameString = 'По умолчанию';
  color:string[];
  checked = true;
  file?:MarkerImage;

  constructor(data?:any) {
    this.color = ['#' + this.randomColor()];
    this.setData(data);
  }

  setData(data:any) {
    if (!data) {
      return;
    }
    Object.assign(this, data);
  }

  private randomColor():string {
    const color:string = Math.floor(Math.random() * 16777215).toString(16);
    if (!this.isHex(color)) {
      return this.randomColor();
    }
    return color + 'ff';
  }

  private isHex(color:string) {
    return /[0-9A-Fa-f]{6}/g.test(color);
  }
}

interface StyleAttributeOptions extends AttributeOptions {
  range:number[];
  rangesNum:number;
  method:DistributionMethod;
  minVal:number;
  maxVal:number;
  colors:string[];
  values?:AttributeValue[];
  typeGroup?:TypeGroup;
  defaultValue?:AttributeValue;
  default_mode?:ColorizerStopMode;
}

export class StyleAttribute extends Attribute implements StyleAttributeOptions {
  styleProperty:string;
  range:number[] = [];
  rangesNum = 5;
  method:DistributionMethod = 'auto';
  minVal = 1;
  maxVal = 10;
  colors:string[] = [];
  values:AttributeValue[] = [];
  typeGroup:TypeGroup;
  defaultValue:AttributeValue = new AttributeValue({ name: 'По умолчанию' }); // цвет для категории "другое"
  default_mode:ColorizerStopMode = 'D';
  description:string[] = []; // массив с описаниями диапазонов
  palette:string[] = null; // выбранный в библиотеке пресет (храним его для перегенерации шкалы)
  customPalette = false;

  private _intAr:string[] = ['integer', 'float', 'bigdecimal', 'double precision', 'serial', 'numeric', 'real'];
  private _stringAr:string[] = ['string', 'date', 'boolean', 'timestamp with time zone', 'text', 'character varying'];

  constructor(data?:any) {
    super();
    this.setData(data);
  }

  setData(data:any) {
    if (!data) {
      return;
    }
    Object.assign(this, data);
    this.values = this.values.map(item => new AttributeValue(item));
    this.defaultValue = new AttributeValue(data.defaultValue);
  }

  getType():TypeGroup {
    if (!this.typeGroup) {
      if (this._checkType(this.type, 'integer')) {
        this.typeGroup = 'integer';
      } else if (this._checkType(this.type, 'string')) {
        this.typeGroup = 'string';
      }
    }
    return this.typeGroup;
  }

  getColors():string[] {
    if (this.typeGroup === 'integer') {
      return this.colors;
    }
    // возвращаем массив цветов отмеченных значений
    if (this.values.length) {
      const valueColors = this.values.filter(item => item.checked).map(item => item.color[0]);
      const defaultColors = this.defaultValue.checked ? this.defaultValue.color : [];
      return [...valueColors, ...defaultColors];
    } else {
      return this.colors;
    }
  }

  getValues():AttributeValue[] {
    return this.typeGroup === 'string' ? this.values : [];
  }

  private _checkType(val:string, type:string) {
    const checkAr = type === 'integer' ? this._intAr : this._stringAr;
    return checkAr.findIndex(item => val.indexOf(item) !== -1) !== -1;
  }
}

export class MarkerImage {
  id:number;
  type = 'M';
  image:string;
  resource_uri:string;
  url:string;
  geometry?:string;
}

export class MarkerSymbolizer extends Symbolizer {
  type:ColorizerType = 'marker';
  name = 'Стиль точки';

  get colors() {
    if (this.heatmap && this.heatmap_colors.length) {
      return this.heatmap_colors;
    }
    let attr:StyleAttribute;
    if (this.attributes.length) {
      attr = this.attributes[0];
      if (attr.values.filter(item => !!item.file).length) {
        return [];
      }
    }

    return this.getPropertyColor('fill');
  }
  figure = 'el';
  fill = '#000000';
  fill_opacity = 1;
  size = 10;
  stroke = '#000000';
  stroke_width = 1;
  file:MarkerImage;
  fileId:number;
  offset_x = 0;
  offset_y = 0;
  heatmap = false;
  heatmap_colors:string[] = ['#0000ff', '#00ffff', '#90ee90', '#ffff00', '#ffa500', '#ff0000'];
  allow_overlap:boolean;
}

export class PolygonSymbolizer extends Symbolizer {
  type:ColorizerType = 'polygon';
  name = 'Стиль полигона';

  get colors() {
    return this.getPropertyColor('fill');
  }

  fill = '#00ff00';
  stroke = '#0000ff';
  stroke_width = 1;
}

export class LineSymbolizer extends Symbolizer {
  type:ColorizerType = 'linestring';
  name = 'Стиль линии';

  get colors() {
    return this.getPropertyColor('stroke');
  }

  stroke = '#ff0000';
  stroke_width = 1;
}

export class TextSymbolizer extends Symbolizer {
  type:ColorizerType = 'text';
  name = 'Стиль текста';

  get colors() {
    return this.getPropertyColor('fill');
  }

  bold = false;
  italic = false;
  font_name = 'DejaVuSans';
  overlay = '';
  fill = '#000000';
  size = 10;
  halo_fill = '#ffffff';
  halo_radius = 1;
  avoid_edges = true;
  allow_overlap = false;
  margin = 0;
  placement:'P' | 'L' | 'V' | 'I' = 'P';
}

export class RasterSymbolizer extends Symbolizer {
  type:ColorizerType = 'raster';
  name = 'Стиль изображения';
  opacity = 1;
  colorSchema:string = null;
  colorizers:RasterColorizer[] = [];

  setData(data:any) {
    if (!data) {
      return;
    }
    if (!data.attributes.length && data.colorizers.length) {
      data.attributes = [this.getStyleAttribute(data.colorizers[0])];
    }
    Object.assign(this, data);
  }

  private getStyleAttribute(colorizer:RasterColorizer):StyleAttribute {
    // условное название атрибута для одноканальных растров
    const attributeName = 'grey';
    return new StyleAttribute({
      name: attributeName,
      alias: attributeName,
      type: 'float',
      colors: colorizer.stops.map((stop:any) => stop.color),
      defaultValue: new AttributeValue({
        color: [colorizer.default_color],
        name: 'По умолчанию'
      }),
      method: 'auto',
      rangesNum: colorizer.stops.length,
      styleProperty: 'colorSchema',
      range: colorizer.stops.map((stop:any) => stop.value),
      values: [],
      default_mode: colorizer.default_mode
    });
  }
}

export interface RasterColorizer {
  default_color:string;
  default_mode:string;
  id:number;
  resource_uri:string;
  stops:RasterColorizerStop[];
}

export interface RasterColorizerStop {
  color:string;
  id?:number;
  mode:ColorizerStopMode;
  resource_uri?:string;
  value:number;
  raster_colorizer:string;
}

export class Style {
  id:number = null;
  name = '';
  resource_uri = '';
  rules:Rule[] = [];
  heatmap = false;
  heatmap_colors:string[] = [];
}

export class Rule {
  filter = '';
  id:number = null;
  max_scale_denominator:number = null;
  min_scale_denominator:number = null;
  name = '';
  resource_uri = '';
  rule_items:any[] = [];
  order:number;
}

export class ClusterSymbolizer extends Symbolizer {
  type:ColorizerType = 'cluster';
  name = 'КЛАСТЕР ТОЧКИ';

  // cluster
  c_fill = '#000000'; // Цвет заливка кластера
  c_size = 10; // Размер заливки кластера
  c_stroke = '#000000'; // Цвет контура кластера
  c_stroke_width = 1; // Размер контура кластера
  font_name = 'Sans'; // Шрифт
  halo_fill = '#FFFFFF'; // Цвет контура шрифта
  halo_radius = 1; // Размер контура шрифта
  t_fill = '#0000ff'; // Цвет заливки шрифта
  t_size = 10; // Размер шрифта

  // marker
  figure = 'el';
  fill = '#000000';
  fill_opacity = 1;
  size = 10;
  stroke = '#000000';
  stroke_width = 1;
  file:MarkerImage;
  fileId:number;
  offset_x = 0;
  offset_y = 0;

  get colors() {
    let attr:StyleAttribute;
    if (this.attributes.length) {
      attr = this.attributes[0];
      if (attr.values.filter(item => !!item.file).length) {
        return [];
      }
    }

    return this.getPropertyColor('fill');
  }
}
