import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Margins, ReportWidgetType} from '../widgets';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export type Orientation = 'portrait' | 'landscape';

export const DEFAULT_MARGINS:Margins = {
  left: 1, right: 1,
  top: 1, bottom: 1
};

export interface ScaleEvent {
  scale:number;
  scaleCommand:string;
}

@Component({
  selector: 'options-panel',
  templateUrl: './options-panel.component.html',
  styleUrls: ['./options-panel.component.less']
})
export class OptionsPanelComponent {
  @Output() addWidget = new EventEmitter<ReportWidgetType>();
  @Output() pageAdd = new EventEmitter<string>();
  @Output() pageDelete = new EventEmitter<number>();
  @Output() pageSelect = new EventEmitter<number>();
  @Output() orientationChange = new EventEmitter<Orientation>();
  @Output() scaleChange = new EventEmitter<ScaleEvent>();
  @Output() marginChange = new EventEmitter<Margins>();
  @Output() bordersChange = new EventEmitter<boolean>();
  @Output() print = new EventEmitter();
  @Output() close = new EventEmitter();

  isVisible:boolean = true;
  pages = [{value: 0, title: 1}];
  selectedPage = this.pages[0];

  orientation:Orientation = 'portrait';
  scales = [
    //{value: 'original', title: '100 %'},
    {value: 'fit-height', title: 'По высоте'},
    {value: 'fit-width', title: 'По ширине'},
  ];
  selectedScale = this.scales[0];
  currentScale:number;
  hideBorders:boolean = false;
  marginTypes = [
    {value: 'default', title: 'по умолчанию'},
    {value: 'custom', title: 'задать'}
  ];
  selectedMarginType = this.marginTypes[0];
  margins:Margins = Object.assign({}, DEFAULT_MARGINS);

  reset() {
    this.pages = [{value: 0, title: 1}];
    this.selectedPage = this.pages[0];
    this.selectedMarginType = this.marginTypes[0];
    this.selectedScale = this.scales[0];
    this.hideBorders = false;
    this.orientation = 'portrait';
  }

  get isDefaultMargin():boolean {
    return this.selectedMarginType.value === 'default';
  }

  onPageAdd() {
    this.pages.push({value: this.pages.length, title: this.pages.length + 1});
    this.pageAdd.emit(this.orientation);
    setTimeout(() => {
      this.selectedPage = this.pages[this.pages.length - 1];
      this.pageSelect.emit(this.pages.length - 1);
    }, 100);
  }

  onMarginSelect(evt:{title:string, value:string}) {
    if (evt.value === 'default') {
      this.margins = Object.assign({}, DEFAULT_MARGINS);
      this.onMarginChange();
    }
  }

  onMarginChange() {
    this.marginChange.emit(this.margins);
  }

  onBordersChange(evt:Event) {
    this.bordersChange.emit(!(evt.target as any).checked);
  }

  changeOrientation(orientation:Orientation) {
    this.orientationChange.emit(orientation);
    this.orientation = orientation;
  }

  onPageSelect(evt:{value:number}) {
    this.pageSelect.emit(evt.value);
  }

  deleteSelectedPage() {
    let selectedPageIndex = this.selectedPage.value;
    this.pages.splice(this.selectedPage.value, 1);
    this.pages.forEach((page, idx) => {
      page.value = idx;
      page.title = idx + 1;
    });
    this.pageDelete.emit(selectedPageIndex);
    if (selectedPageIndex > this.pages.length - 1) {
      selectedPageIndex--;
    }
    this.selectedPage = this.pages[selectedPageIndex];
    this.onPageSelect(this.selectedPage);
  }

  onScaleSelect(evt:{title:string, value:string}) {
    const outEvent:ScaleEvent = {
      scale: evt.value === 'original' ? 1 : 0,
      scaleCommand: evt.value === 'original' ? null : evt.value
    };
    this.scaleChange.emit(outEvent);
  }

  onScaleIncrement(step:number) {
    this.selectedScale = null;
    this.currentScale += step * 0.1;
    const outEvent:ScaleEvent = {
      scale: this.currentScale,
      scaleCommand: null
    };
    this.scaleChange.emit(outEvent);
  }

  get currentScaleString():string {
    return Math.round(this.currentScale * 100).toString() + ' %';
  }
}
