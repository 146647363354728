import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { WidgetsModule } from '@geoanalitika/widgets';
import { BaseModule } from './base.module';
import {
  ActivatorComponent,
  AddressSearchComponent,
  AttachmentFileUploader,
  BaseMapsComponent,
  CopyrightsComponent,
  CrowdsourceUploader,
  CrowdsourcingComponent,
  DropMenuButtonComponent,
  FeatureEditComponent,
  FeatureInfoComponent,
  FullExtentComponent,
  GeoanalitikaApi,
  LayerCatalogComponent,
  LayerColorComponent,
  LayerManagerComponent,
  LayerTreeViewComponent,
  LightBoxComponent,
  MapLegend,
  MenuItemContainerComponent,
  MouseCoordsComponent,
  PopupComponent,
  ResultListComponent,
  ScaleControlComponent,
  SelectBoxComponent,
  SearchResultsComponent,
  SimpleWindowComponent,
  SingleResultComponent,
  SingleResultPopup,
  Window, AlertBuilderComponent
} from './components';
import { ClickOutsideDirective } from './directives/directives';
import { Environment } from './environment';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { UrlInterceptor } from './interceptors/url.interceptor';
import {
  AuthService,
  ConnectionService,
  Creator,
  DictionaryService,
  GeocoderService,
  LayersFactory,
  LayersService,
  MapService,
  MessageService
} from './services';
import { LayersStore } from './stores/LayersStore';
import {EditableGridComponent} from './components/editable-grid/editable-grid.component';
import {TableModule} from 'primeng/table';
import {DialogComponent} from './components/dialog/dialog.component';
import {DndModule} from 'ng2-dnd';

const DECLARATION:Type<any>[] = [
  ActivatorComponent,
  ScaleControlComponent,
  SelectBoxComponent,
  CopyrightsComponent,
  FullExtentComponent,
  GeoanalitikaApi,
  CrowdsourcingComponent,
  PopupComponent,
  SingleResultPopup,
  CrowdsourceUploader,
  Window,
  SimpleWindowComponent,
  DialogComponent,
  LayerColorComponent,
  AddressSearchComponent,
  LayerManagerComponent,
  LayerCatalogComponent,
  EditableGridComponent,
  DropMenuButtonComponent,
  MapLegend,
  BaseMapsComponent,
  MouseCoordsComponent,
  MenuItemContainerComponent,
  LayerTreeViewComponent,
  SearchResultsComponent,
  SingleResultComponent,
  FeatureInfoComponent,
  AttachmentFileUploader,
  FeatureEditComponent,
  ResultListComponent,
  LightBoxComponent,
  AlertBuilderComponent
];

const ENTRY_COMPONENTS:Type<any>[] = [
  ActivatorComponent,
  ScaleControlComponent,
  SelectBoxComponent,
  CopyrightsComponent,
  FullExtentComponent,
  GeoanalitikaApi,
  CrowdsourcingComponent,
  PopupComponent,
  SingleResultPopup,
  CrowdsourceUploader,
  Window,
  SimpleWindowComponent,
  DialogComponent,
  LayerColorComponent,
  AddressSearchComponent,
  LayerManagerComponent,
  LayerCatalogComponent,
  DropMenuButtonComponent,
  MapLegend,
  BaseMapsComponent,
  MouseCoordsComponent,
  MenuItemContainerComponent,
  SearchResultsComponent,
  LightBoxComponent,
  EditableGridComponent,
  AlertBuilderComponent
];

@NgModule({
  imports: [BaseModule, WidgetsModule, TableModule, DndModule],
  declarations: [ClickOutsideDirective, DECLARATION],
  entryComponents: [ENTRY_COMPONENTS],
  exports: [BaseModule, WidgetsModule, ClickOutsideDirective, DECLARATION]
})
export class SharedModule {
  static forRoot():ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        ConnectionService,
        Creator,
        DictionaryService,
        LayersService,
        GeocoderService,
        MapService,
        MessageService,
        LayersFactory,
        AuthService,
        LayersStore,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
        { provide: 'environment', useValue: Environment }
      ]
    };
  }
}
