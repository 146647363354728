import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from 'shared/classes';
import { ILayer, TimeInterval } from 'shared/interfaces';

@Injectable()
export class TimelineService {
  constructor(private http:HttpClient) {}

  getTimeBounds(layer:ILayer):Observable<TimeInterval> {
    return this.http
      .get(`get_time_bounds/${layer.id}/`)
      .pipe(map((data:{ start:string; end:string }) => ({ start: new Date(data.start), end: new Date(data.end) })));
  }

  getTimeline(layer:ILayer, period:TimeInterval, base:string = 'day'):Observable<any> {
    let params:HttpParams = new HttpParams();

    if (period) {
      params = params.set('start', Utils.getISOString(period.start)).set('end', Utils.getISOString(period.end));
    }

    if (layer.filter) {
      params = params.set('filter', layer.filter);
    }

    return this.http.get(`get_timeline/${layer.id}/`, { params }).pipe(
      map((data:{ intervals:any[]; orig_data:any[] }) => {
        const intervals = data.intervals.map(interval =>
          interval.map(item => ({
            date: new Date(item.date),
            objects: item.objects,
            dataIndex: item.data_index
          }))
        );
        const originalData = data.orig_data.map((item, idx) => ({
          objects: item.objects,
          date: new Date(item.date),
          dataIndex: idx
        }));
        return { intervals, originalData };
      })
    );
  }
}
