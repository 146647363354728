import * as Gml from './GML.class';

import IGml = Gml.IGml;
import SimpleFilter = Gml.SimpleFilter;
import FilterOr = Gml.FilterOr;
import FilterAnd = Gml.FilterAnd;
import FilterIn = Gml.FilterIn;
import FilterLike = Gml.FilterLike;
import FilterNot = Gml.FilterNot;
import FilterIsNull = Gml.FilterIsNull;

export class FilterGmlGenerator {
  private brackets_res:Map<string, IGml> = new Map<string, IGml>();

  generate(filter:string):string {
    if (this.brackets_res.size > 0) {
      this.brackets_res.clear();
    }
    let gmlItem:IGml;
    gmlItem = this.generateGml(filter);
    return gmlItem ? gmlItem.createGml() : '';
  }

  private generateGml(filter:string):IGml {
    let gmlItem:IGml;
    const matches:string[] = this.parseBrackets(filter);
    if (matches.length) {
      matches.forEach(item => {
        // заменить выражение в скобках
        filter = filter.replace(`(${item})`, `bracket${this.brackets_res.size}`);
        // добавить результат скобок в словарь
        this.brackets_res.set(`bracket${this.brackets_res.size}`, this.generateGml(item));
      });
    }
    // filter = filter.toLocaleLowerCase();
    filter = filter.replace(/OR/g, 'or');
    filter = filter.replace(/AND/g, 'and');
    filter = filter.replace(/IN/g, 'in');
    filter = filter.replace(/NOT/g, 'not');
    filter = filter.replace(/LIKE/g, 'like');

    // AND > OR (поэтому сначала бьём строку по OR )
    const orChunks:string[] = filter.split(' or ');
    if (orChunks.length > 1) {
      gmlItem = this.generateOr(orChunks);
    } else {
      const andChunks:string[] = filter.split(' and ');
      if (andChunks.length > 1) {
        gmlItem = this.generateAnd(andChunks);
      }
    }

    // если логических операторов не было
    if (!gmlItem) {
      gmlItem = this.getSingleItem(filter);
    }

    return gmlItem;
  }

  private generateOr(orChunks:string[]):IGml {
    const or:FilterOr = new FilterOr();
    orChunks.forEach((orChunk:string) => {
      const andChunks:string[] = orChunk.split(' and ');
      if (andChunks.length > 1) {
        or.colums.push(this.generateAnd(andChunks));
      } else {
        const simpleFilter:IGml = this.getSingleItem(orChunk);
        if (simpleFilter) { or.colums.push(simpleFilter); }
      }
    });
    return or;
  }

  private generateAnd(andChunks:string[]):IGml {
    const and:FilterAnd = new FilterAnd();
    andChunks.forEach((andChunk:string) => {
      const simpleFilter:IGml = this.getSingleItem(andChunk);
      if (simpleFilter) { and.colums.push(simpleFilter); }
    });
    return and;
  }

  private getSingleItem(filter:string):IGml {
    filter = filter.trim();
    // если результат есть в словаре, то отдаём его
    if (this.brackets_res.has(`${filter}`)) {
      return this.brackets_res.get(`${filter}`);
    }

    filter = filter.trim().replace(/'|"/g, '');
    let regexp:string[];
    if (filter.match(/\sin\s?/gi)) {
      regexp = filter.match(/([A-Za-z_]+)\s?(?:in)\s?\((.*)\)/i);
      if (regexp !== null) {
        return new FilterIn(regexp[1].trim(), regexp[2].split(','));
      }
    }

    if (filter.match(/\slike\s?/gi)) {
      regexp = filter.match(/([A-Za-z_]+)\s?(?:like)\s?(.*)/i);
      if (regexp !== null) {
        return new FilterLike(regexp[1].trim(), regexp[2].trim());
      }
    }

    // column is null
    if (filter.toLocaleLowerCase().indexOf('is null') !== -1) {
      return new FilterIsNull(filter.split(' ')[0]);
    }

    if (filter.match(/\s?not\s?/gi)) {
      const filterNot:FilterNot = new FilterNot();
      filterNot.column = this.getSingleItem(filter.replace('not ', ''));
      return filterNot;
    }

    regexp = filter.match(/(=|>=|<=|<>|>|<)/gi);
    if (regexp) {
      const operator:string = regexp[0];
      const colAr:string[] = filter.split(operator);
      return new SimpleFilter(colAr[0].trim(), operator, colAr[1].trim());
    }

    return null;
  }

  private parseBrackets(text:string):string[] {
    const res:string[] = [];
    let text_in_brackets = '';
    let count = 0;
    let addTostring = false;
    text.split('').forEach((letter:string) => {
      switch (letter) {
        case '(':
          count++;
          break;
        case ')':
          count--;
          if (count === 0) {
            res.push(text_in_brackets);
            text_in_brackets = '';
            addTostring = false;
          }
          break;
        default:
          addTostring = count > 0;
          break;
      }

      if (addTostring) {
        text_in_brackets += letter;
      }
    });
    return res;
  }
}
