import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { FileUploaderWidget } from '@geoanalitika/widgets';

@Component({
  selector: 'attachment-file-uploader',
  templateUrl: 'file-uploader.widget.html',
  styleUrls: ['file-uploader.widget.less']
})
export class AttachmentFileUploader extends FileUploaderWidget {
  @ViewChild('dropZone') dropZone:HTMLElement;

  @Input() iconClass = 'file-upload-icon'; // класс иконки типа файлов
  @Input() text = 'Выберите файл'; // текст на свернутой дроп-зоне
  @Input() fileType:string; // для отображения в развернутой дроп-зоне
  @Input() dropZoneClass:string;

  @Output() onFileDrop = new EventEmitter();

  overMe = false; // индикатор, что файл над дроп-зоной

  reset() {
    this.over = false;
    this.overMe = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event:any) {
    this.preventAndStop(event);
    const files = (this.files = event.dataTransfer.files);
    const validFiles = this.filterUpload(files);
    this.updateFiles(validFiles);
    this.over = false;
    this.overMe = false;
    this.onFileDrop.emit('');
  }

  // Разворачивает дроп-зоны, если файл перетащили
  // в окно браузера
  @HostListener('body:dragover', ['$event'])
  private dragOverBody(event:Event) {
    this.over = true;
    this.preventAndStop(event);
  }

  @HostListener('body:dragleave', ['$event'])
  private dragLeaveBody(event:Event) {
    // Если файл уже не над браузером,
    // свернуть дроп-зоны
    if (!(event as any).x && !(event as any).y) {
      this.over = false;
    }
    this.preventAndStop(event);
  }

  // Подсвечивает дроп-зону, если
  // файл над ней
  @HostListener('dragover', ['$event'])
  private dragOverMe(event:Event) {
    this.overMe = true;
    this.preventAndStop(event);
  }

  @HostListener('dragleave', ['$event'])
  private dragLeaveMe(event:Event) {
    this.overMe = false;
    this.preventAndStop(event);
  }
}
