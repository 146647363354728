import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CartometricService {
  constructor(private http:HttpClient) {}

  getMinMax(sourceId:number, attrName:string):Observable<{ min:number; max:number }> {
    return this.http.get<{ min:number; max:number }>(`get_min_max/${sourceId}/${attrName}/`);
  }

  createLayer(data:any) {
    return this.http.post(`create_cartometric_layer/`, data);
  }

  getFeatureCount(sourceId:number):Observable<{ [key:number]:number }> {
    return this.http.get<{ [key:number]:number }>(`get_feature_count/?layers=${sourceId}`);
  }

  getQuantileIntervals(sourceId:number, attrName:string, rangesNum:number):Observable<number[]> {
    return this.http
      .get<{ intervals:number[] }>(
        `get_quantile_attr/?layer_id=${sourceId}&attr=${attrName}&count=${rangesNum}`
      )
      .pipe(map(data => data.intervals.map(item => +item.toFixed(3))));
  }
}
