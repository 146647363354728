import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'suitable' })
export class SuitablePipe implements PipeTransform {
  transform(array:any[], property:string, val:any):any {
    const suit:any[] = [];
    if (!array) {
      return;
    }
    array.forEach(item => {
      if (item[property] && item[property] === val) {
        suit.push(item);
      }
    });
    return suit;
  }
}
