import { StyleClass } from './StyleClass';

export class LineStyle extends StyleClass {
  private _color:string = null;
  private _width:any = null;

  constructor(options:any) {
    super();
    for (const option in options) {
      if (typeof this[option] !== 'undefined') {
        this[option] = options[option];
      }
    }
  }

  get color() {
    return this._color;
  }

  set color(value:any) {
    if (Array.isArray(value)) {
      const r = parseInt((value[0] * 255).toString(), 10);
      const g = parseInt((value[1] * 255).toString(), 10);
      const b = parseInt((value[2] * 255).toString(), 10);
      this._color = 'rgb(' + r + ',' + g + ',' + b + ')';
    } else {
      this._color = value;
    }
  }

  get width() {
    return this._width;
  }

  set width(val:any) {
    this._width = val;
  }

  draw() {
    if (!this.canvas || !this.color) { return; }
    const ctx = this.ctx;
    ctx.beginPath();
    ctx.moveTo(this.startX, this.iconSize);
    ctx.lineTo(this.iconSize, this.startY);
    // ширина от зума
    let width = this.width;
    if (Array.isArray(width)) {
      width = this.getWidthFromZoom(width);
    }
    //
    if (width) {
      ctx.lineWidth = width;
    }

    ctx.strokeStyle = this.color;
    ctx.stroke();
  }

  // ширина линнии в зависимости от масштаба карты  }
  private getWidthFromZoom(widthAr:any[]):number {
    const zoom = this.mapZoom;
    let width:number;

    widthAr.forEach(ar => {
      if (ar[0] >= zoom && !width) {
        width = Math.ceil(parseFloat(ar[1]));
      }
    });

    return width;
  }
}
