const GML_DICT:{ [key:string]:string } = {
  '=': 'PropertyIsEqualTo',
  '<>': 'PropertyIsNotEqualTo',
  '<': 'PropertyIsLessThan',
  '<=': 'PropertyIsLessThanOrEqualTo',
  '>': 'PropertyIsGreaterThan',
  '>=': 'PropertyIsGreaterThanOrEqualTo'
};

export interface IGml {
  createGml():string;
}

export class FilterOr implements IGml {
  colums:IGml[] = [];

  createGml():string {
    let gml = '<ogc:Or>';
    this.colums.forEach((col:IGml) => {
      gml += col.createGml();
    });
    gml += '</ogc:Or>';
    return gml;
  }
}

export class FilterAnd implements IGml {
  colums:IGml[] = [];

  createGml():string {
    let gml = '<ogc:And>';
    this.colums.forEach((col:IGml) => {
      gml += col.createGml();
    });
    gml += '</ogc:And>';
    return gml;
  }
}

export class FilterNot implements IGml {
  column:IGml;

  createGml():string {
    return `<ogc:Not>${this.column.createGml()}</ogc:Not>`;
  }
}

export class SimpleFilter implements IGml {
  columnName:string;
  operator:string;
  value:string;

  constructor(columnName:string, operator:string, value:string) {
    this.columnName = columnName;
    this.operator = operator;
    this.value = value;
  }

  createGml():string {
    return `<${GML_DICT[this.operator]}>
                  <PropertyName>${this.columnName}</PropertyName>
                  <Literal>${this.value}</Literal>
               </${GML_DICT[this.operator]}>`;
  }
}

export class FilterIn implements IGml {
  columnName:string;
  values:string[];

  constructor(columnName:string, values:string[]) {
    this.columnName = columnName;
    this.values = values;
  }

  createGml():string {
    let literals = '';
    this.values.forEach(item => {
      item = item.trim();
      literals += `<ogc:Literal>${item}</ogc:Literal>`;
    });

    return `<ogc:PropertyIsEqualTo>
                       <ogc:Function name="in">
                          <ogc:PropertyName>${this.columnName}</ogc:PropertyName>
                          ${literals}
                       </ogc:Function>
                       <ogc:Literal>true</ogc:Literal>
                     </ogc:PropertyIsEqualTo>`;
  }
}

export class FilterLike implements IGml {
  columnName:string;
  value:string;

  constructor(columnName:string, value:string) {
    this.columnName = columnName;
    this.value = value;
  }

  createGml():string {
    return ` <ogc:PropertyIsLike matchCase="false" wildCard="%" singleChar="*" escapeChar="!">
                        <ogc:PropertyName>${this.columnName}</ogc:PropertyName>
                        <ogc:Literal>${this.value}</ogc:Literal>
                    </ogc:PropertyIsLike>`;
  }
}

export class FilterIsNull implements IGml {
  columnName:string;

  constructor(columnName:string) {
    this.columnName = columnName;
  }

  createGml():string {
    return `<ogc:PropertyIsNull>
                <ogc:PropertyName>${this.columnName}</ogc:PropertyName>
              </ogc:PropertyIsNull>`;
  }
}
