import { Injectable } from '@angular/core';
import { Window } from 'shared/components';

@Injectable()
export class WindowFactory {
  private windows:Window[] = [];
  private startZindex = 1000;

  addWindow(window:Window) {
    const oldOpen:() => void = window.open;
    window.open = () => {
      oldOpen.call(window);
      // если не это окно вверху
      // то ставим его
      if (this.windows.indexOf(window) !== this.windows.length - 1) {
        this.setWindowToPosition(window, this.windows.length);
      }
    };
    this.windows.push(window);
  }

  removeWindow(window:Window) {
    const idx:number = this.windows.indexOf(window);
    if (idx < 0) {
      return;
    }
    this.windows.splice(idx, 1);
  }

  setWindowToPosition(window:Window, pos:number) {
    this.removeWindow(window);
    this.windows.splice(pos, 0, window);
    this.updateIndex();
  }

  private updateIndex() {
    this.windows.forEach((win:Window, idx:number) => {
      win.getDomnode().style.zIndex = (this.startZindex + idx).toString();
    });
  }
}
