import { Component } from '@angular/core';
import { PluginClass } from '../../classes/Plugin';
import { ILayer, IMenu, IPluginInterface } from '../../interfaces';
import { LayersStore } from '../../stores/LayersStore';
import { Button } from '../button/button.component';

@Component({
  selector: 'layers-manager',
  templateUrl: 'layer_manager.component.html',
  styleUrls: ['layer_manager.component.less']
})
export class LayerManagerComponent extends PluginClass {
  active = false;
  activeLayers:ILayer[] = [];

  protected btn:Button;
  protected menu:IMenu;
  protected currentLayer:ILayer = null;

  constructor(protected layersStore:LayersStore) {
    super();
    this.setLayers();
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;

        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.btn.className = 'layersManagerBtn';
          this.btn.title = 'Управление слоями';
          this.btn.text = 'Управление слоями';
          this.btn.activeMode = true;
          this.btn.onClickMe = () => {
            this.active = true;
          };
          this.btn.onDeactivate = () => {
            this.active = false;
          };
          // добавить кнопку массив меню
          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  protected setLayers() {
    this.layersStore.getActiveLayers().subscribe(data => {
      this.activeLayers = data;
    });
  }

  private setOrder() {
    let count:number = this.activeLayers.length + 1;
    this.activeLayers.forEach((layer:ILayer) => {
      layer.order = count;
      count--;
    });
  }
}
