import { Point } from './Point';

export class Bounds {
  xmin:Point;
  xmax:Point;
  ymin:Point;
  ymax:Point;

  constructor(xmin:Point, ymin:Point, xmax:Point, ymax:Point) {
    this.xmin = xmin;
    this.xmax = xmax;
    this.ymin = ymin;
    this.ymax = ymax;
  }
}

export class Extent {
  xmin:number;
  xmax:number;
  ymin:number;
  ymax:number;

  constructor(xmin:number, ymin:number, xmax:number, ymax:number) {
    this.xmin = xmin;
    this.xmax = xmax;
    this.ymin = ymin;
    this.ymax = ymax;
  }

  static get MIN_EXTENT():Extent {
    return new Extent(Number.MAX_VALUE, Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);
  }

  get isValid():boolean {
    return this.xmax - this.xmin > 0 && this.ymax - this.ymin > 0;
  }

  expand(x:number, y:number) {
    if (x < this.xmin) {
      this.xmin = x;
    }
    if (y < this.ymin) {
      this.ymin = y;
    }
    if (x > this.xmax) {
      this.xmax = x;
    }
    if (y > this.ymax) {
      this.ymax = y;
    }
  }
}
