import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'newline' })
export class NewlinePipe implements PipeTransform {
  transform(value:any):string {
    if (typeof value !== 'string') {
      return value;
    }
    return value ? value.replace(/\r?\n/g, '<br />') : value;
  }
}
