import { HttpParams } from '@angular/common/http';
import {Component, EventEmitter, Inject, Input, OnChanges, Output, ViewChild} from '@angular/core';
import { IAppSettings } from 'shared/environment';
import {Attribute, Document, Feature, Image, IToolFeature} from '../../../';
import { AttachmentFileUploader } from '../attachment-file-uploader/file-uploader.widget';
import { DocumentService } from './document.service';

@Component({
  selector: 'geo-single-result',
  templateUrl: 'single_result.component.html',
  styleUrls: ['single_result.component.less'],
  providers: [DocumentService]
})
export class SingleResultComponent implements OnChanges {
  @ViewChild('fl1') fl1:AttachmentFileUploader;
  @ViewChild('fl2') fl2:AttachmentFileUploader;

  @Input() showEmptyAttributes = false;
  @Input() editing = false;
  @Input() editable = true;
  @Input() toolsForFeature:IToolFeature[] = [];

  @Output() onUpdateFeature = new EventEmitter();
  @Output() onImageClick = new EventEmitter();
  @Output() gotoFeature = new EventEmitter<Feature>();

  dopToolsVisible = false;

  showAllImages = false;
  imageFormats:string[] = [];
  maxSize:number;
  maxDocumentSize:number;

  attrsOpen = true;
  docsOpen = false;
  imgsOpen = false;

  attributesToDisplay:Attribute[] = []; // массив выводимых атрибутов

  private projectSlug:string;
  private _feature:Feature;

  constructor(private documentService:DocumentService, @Inject('environment') settings:IAppSettings) {
    this.projectSlug = settings.PROJECT_SLUG;
    this.imageFormats = settings.IMAGE_FORMATS;
    this.maxSize = settings.FILE_SIZE;
    this.maxDocumentSize = settings.DOCUMENT_SIZE;
  }

  ngOnChanges() {
    if (this.feature) {
      const feature = this.feature;

      if ((feature.layer.type === 'geojson' || feature.layer.type === 'dynamic') && (!feature.layer.columns || !feature.layer.columns[0]) ) {
        this.attributesToDisplay = this._getFeatureColumns(feature);
      } else {
        this.attributesToDisplay = this.getListOfAttributesToDisplay(feature.layer.columns, feature.layer.pk);
      }

      if (!this.editing) {
        this.getAttachmentsForFeature(feature);
      }
    }
  }

  @Input() set feature(f:Feature) {
    this._feature = f;
    this.showAllImages = false;
  }
  get feature():Feature {
    return this._feature;
  }

  activate() {
    this.showAllImages = false;
  }

  resetUploaders() {
    this.fl1.reset();
    this.fl2.reset();
  }

  addFile(files:File[], type:string) {
    if (!Array.isArray(files)) {
      files = [files];
    }

    files.forEach(file => {
      this.saveFile({ type, file });
    });

    // Сигнал редактированию, что объект изменился
    this.updateAttributes({ feature: this.feature });
  }

  deleteFile(idx:number, type:string) {
    const feature = this.feature;
    let files:Image[] | Document[];

    switch (type) {
      case 'image':
        files = feature.images;
        break;
      case 'document':
        files = feature.documents;
        break;
    }

    const params:HttpParams = new HttpParams()
      .set('object_id', `${feature.id}`)
      .set('layer', `${feature.layer.id}`)
      .set('file', files[idx].title)
      .set('type', files[idx].type)
      .set('project_name', this.projectSlug)
      .set('id', `${files[idx].id}`);

    this.documentService.deleteFile(params).subscribe(
      () => {
        files.splice(idx, 1);
      },
      error => {
        console.error(error);
      }
    );
  }

  showLightBox(image:Image) {
    this.onImageClick.emit({ item: image, items: this.feature.images });
  }

  hideDopTools(event:MouseEvent, btn:HTMLElement) {
    if (event.target !== btn) {
      this.dopToolsVisible = false;
    }
  }

  activateOtherTool(tool:IToolFeature, feature:Feature) {
    tool.activateToolFeature(feature);
    this.dopToolsVisible = false;
  }

  private _getFeatureColumns(feature:Feature):Attribute[] {
    const attr:Attribute[] = [];
    for (const property in feature.properties) {
      const a:Attribute = new Attribute();
      a.name = a.alias = property;
      attr.push(a);
    }
    return attr;
  }

  private getListOfAttributesToDisplay(columns:Attribute[], pk:string) {
    return columns.filter(column => {
      return column.inAttr;
    });
  }

  private getAttachmentsForFeature(feature:Feature) {
    if (!feature.layer.pk) {
      return;
    }

    this.documentService.getAttachments(feature).subscribe(data => {
      feature.documents = [];
      feature.images = [];
      this.constructAttachments(feature, data.documents, data.images);
    });

    if (feature.layer.rating) {
      this.documentService.getRating(feature).subscribe(data => {
        feature.rating = data.rating;
      });
    }
  }

  private constructAttachments(feature:Feature, documents:any[], images:any[]) {
    documents.forEach(document => {
      const newDoc = new Document(document);
      feature.documents.push(newDoc);
    });

    images.forEach(image => {
      const newImg = new Image(image);
      feature.images.push(newImg);
    });
  }

  // Апдейтит объект в плагинах редактирования при изменении полей в атрибутах.
  private updateAttributes(data:{ feature:Feature; attr?:any; event?:any }) {
    this.onUpdateFeature.emit(data);
  }

  private saveFile(attachment:{ type:string; file:File }) {
    const data:any = {
      object_id: this.feature.id,
      layer: this.feature.layer.id,
      file: attachment.file,
      type: attachment.type,
      project_name: this.projectSlug
    };

    const formdata = new FormData();
    Object.keys(data).forEach(key => {
      formdata.append(key, data[key]);
    });

    this.documentService.saveFile(formdata).subscribe(
      response => {
        if (!response.url) {
          // Непонятная ошибка, почему на сервере не падает?
          console.error('Ошибка при сохранении файла.');
          return;
        }

        switch (attachment.type) {
          case 'image':
            const image = new Image(response);
            this.feature.images.push(image);
            break;
          case 'document':
            const document = new Document(response);
            this.feature.documents.push(document);
            break;
        }
      },
      error => {
        console.error(error);
      }
    );
  }
}
