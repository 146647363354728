import { Component, ElementRef, OnInit } from '@angular/core';
import { Feature, PluginClass, Utils } from 'shared/classes';
import { Button } from 'shared/components';
import { IActivator, ICursor, IDraw, IDrawFinish, IMenu, IPluginInterface, ITool } from 'shared/interfaces';
import { MapService } from '../../services/services';

@Component({
  selector: 'zoomIn',
  template: ''
})
export class ZoomIn extends PluginClass implements IDrawFinish, ITool, OnInit {
  activateMode:string = null;
  groupName:string; // наименование группы связанных компонентов

  private active = false;
  private btn:Button = null;
  private drawPlugins:IDraw[] = [];
  private cursorPlugins:ICursor[] = [];
  private menu:IMenu;
  private activator:IActivator; // плагин, отвечающий за отключение связанных плагинов

  constructor(private el:ElementRef, private mapService:MapService) {
    super();
  }

  ngOnInit() {
    Utils.removeElement(this.el.nativeElement as HTMLElement);
  }

  finishDraw(feature:Feature) {
    if (this.active) {
      this.mapService.changeMapBound$.next(feature.bounds);
      this.clearDrawFeatures();
      this.startDraw('rectangle');
    }
  }

  clearDrawFeatures() {
    this.drawPlugins.forEach(plugin => {
      plugin.clearDrawFeatures();
    });
  }

  stopDraw() {
    if (!this.active) {
      return;
    }
    this.active = false;
    this.clearDrawFeatures();
    this.drawPlugins.forEach(plugin => {
      plugin.stopDraw();
    });

    this.changeCursor('default');
  }

  updateDrawingFeature(feature:Feature) {}

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Draw':
        this.drawPlugins.push(pi as IDraw);
        break;
      case 'Cursor':
        this.cursorPlugins.push(pi as ICursor);
        break;
      case 'Menu':
        this.menu = pi as IMenu;
        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.btn.className = 'zoomingBtn';
          this.btn.title = 'Увеличить область';
          this.btn.activeMode = true;
          this.btn.onClickMe = () => {
            this.activate();
          };
          this.btn.onDeactivate = () => {
            this.stopDraw();
          };
          this.menu.addBtn(this.btn);
        });
        break;
      case 'Activator':
        this.activator = pi as IActivator;
        this.activator.setConnect(this as ITool);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Draw':
        this.drawPlugins = [];
        break;
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
      case 'Activator':
        this.activator = null;
        break;
    }
  }

  activateTool() {
    this.active = true;
    this.startDraw('rectangle');
  }

  deactivateTool():Promise<boolean> {
    this.btn.setActive(false);
    // возвращаем курсор
    this.changeCursor('default');
    return Promise.resolve(true);
  }

  isActive() {
    return this.active;
  }

  getGroup() {
    const group:string = this.groupName || '';
    return group;
  }

  private startDraw(geomType:string) {
    this.drawPlugins.forEach(plugin => {
      plugin.startDraw(geomType, this as IDrawFinish);
    });
    this.changeCursor(`url('/images/Cursor/icons_zoom.cur'), auto`);
  }

  private changeCursor(cursor:string) {
    // возвращаем курсор
    this.cursorPlugins.forEach(plugin => {
      plugin.setCursor(cursor);
    });
  }

  private activate() {
    if (this.activator) {
      this.activator.activateComponent(this as ITool);
    }
  }
}
