import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalWidget } from '@geoanalitika/widgets';
import { Attribute, PluginClass, WMSLayer } from 'shared/classes';
import { DataColumnFilter, DataFilter, DataFilterCondition, LogicFunction, Operation, Operations } from 'shared/classes/FilterGenerator';
import { Button } from 'shared/components';
import { IContainer, IContentChild, ILayer, IMenu, IPluginInterface, IToolLayer } from 'shared/interfaces';
import { LayersStore } from 'shared/stores/LayersStore';
import { FilterService } from './filter.service';

@Component({
  selector: 'geo-attr-filter',
  templateUrl: 'attr-filter.component.html',
  styleUrls: ['attr-filter.component.less'],
  providers: [FilterService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributeFilter extends PluginClass implements IToolLayer, IContentChild, OnInit {
  @ViewChild('askClearAll') askClearAll:ModalWidget;

  activateMode:string = null;
  toolName:string;
  groupName:string;
  className:string;

  active = false;

  parentComponent:IContainer;

  layers:WMSLayer[] = [];
  layersListVisible = false;

  form:FormGroup;

  operators:LogicFunction[] = [{ title: 'Всем условиям', sql: 'and' }, { title: 'Любому из условий', sql: 'or' }];

  showColumnsList = false;

  private btn:Button;
  private activateBtn:Button;
  private menu:IMenu;
  private activateMenu:IMenu;

  constructor(
    private layersStore:LayersStore,
    private builder:FormBuilder,
    private ref:ChangeDetectorRef,
    private filterService:FilterService
  ) {
    super();

    this.layersStore.getActiveLayers().subscribe((layers:ILayer[]) => {
      this.layers = layers.filter(item => {
        return this.checkLayer(item);
      }) as WMSLayer[];
    });
  }

  ngOnInit() {
    this.toolName = this.buttonConfig.text;
    this.className = this.buttonConfig.className;
  }

  addInterface(name:string, pi:IPluginInterface) {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;
        this.menu.createBtn().then(btnRef => {
          this.btn = btnRef.instance;
          this.btn.setOptions(this.buttonConfig);
          this.btn.position = this.buttonConfig.position;
          this.btn.onClickMe = () => {
            this.activate();
          };
          this.menu.addBtn(this.btn);
        });
        break;
      case 'ActivateMenu':
        this.activateMenu = pi as IMenu;
        // TODO: Придумать более элегантную реализацию кнопок для 'включения' инструментов
        // создание кнопки в меню для активации
        this.activateMenu.createBtn().then(btnRef => {
          this.activateBtn = btnRef.instance;
          this.activateBtn.className = this.buttonConfig.className + '-menu';
          this.activateBtn.title = this.buttonConfig.title;
          this.activateBtn.text = this.buttonConfig.text;
          this.activateBtn.onClickMe = () => {
            if (this.btn) {
              this.btn.visible = true;
            }
          };

          this.activateBtn.onDeactivate = () => {
            if (this.btn) {
              this.btn.visible = false;
              this.deactivate();
            }
          };
          this.activateMenu.addBtn(this.activateBtn);
          this.activateBtn.setActive(this.buttonConfig.visible);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
    }
  }

  removeInterface(name:string) {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
      case 'ActivateMenu':
        this.activateMenu.removeBtn(this.activateBtn);
        this.activateBtn = null;
        this.activateMenu = null;
        break;
    }
  }

  activate() {
    this.activateToolLayer(null);
  }

  deactivate() {
    this.deactivateTool();
  }

  enable() {}

  disable() {}

  isActive():boolean {
    return this.active;
  }

  getGroup():string {
    return '';
  }

  activateTool() {}

  deactivateTool():Promise<boolean> {
    this.active = false;
    if (this.parentComponent) {
      this.parentComponent.close();
    }
    return Promise.resolve(true);
  }

  // фильтр применим к однослойным wms сервисам, сервисам Геосервера и векторным временным сервисам
  checkLayer(layer:ILayer) {
    return (
      ((layer.simple && layer.type === 'wms') || layer.type === 'geoserver' || (layer.geomType !== 'raster' && layer.type === 'wms-t')) &&
      !!layer.id
    );
  }

  activateToolLayer(layer:WMSLayer) {
    if (!layer && this.layersStore.selectedLayer && this.layersStore.selectedLayer.type === 'wms') {
      layer = this.layersStore.selectedLayer as WMSLayer;
    }
    this.setForm(layer);

    if (this.parentComponent) {
      this.parentComponent.open();
    }

    if (this.activateBtn) {
      this.activateBtn.setActive(true);
    }

    this.active = true;
  }

  askClearAllFilters() {
    this.askClearAll.show();
  }

  clearLayerFilter():void {
    const layer = this.form.get('layer').value;
    layer.dataFilter = null;
    layer.filter = null;
    layer.refresh();
    this.layersStore.updateLayer(layer);
    this.setForm(layer);
  }

  addAttributeFilter(column:Attribute) {
    this.showColumnsList = false;

    this.filterGroups.push(
      this.builder.group({
        column: [column, Validators.required],
        operator: [this.operators[0], Validators.required],
        filters: this.builder.array([this.getFilterFG(column)])
      })
    );
  }

  getOperations(type:string):Operation[] {
    return Operations.operationsByType(type);
  }

  // фильтрация уже добавленных атрибутов
  get columns():Attribute[] {
    const layer = this.form.get('layer').value;
    if (!layer || !layer.columns) {
      return [];
    }
    return layer.columns.filter(column => !this.filterGroups.controls.find(control => control.value.column.name === column.name));
  }

  get filterGroups():FormArray {
    return this.form.get('filterGroups') as FormArray;
  }

  removeAttributeFilter(idx:number) {
    this.filterGroups.removeAt(idx);
  }

  removeFilter(idx:number, filterIdx:number) {
    (this.filterGroups.at(idx).get('filters') as FormArray).removeAt(filterIdx);
  }

  addFilter(idx:number) {
    const column = this.filterGroups.at(idx).get('column').value;

    (this.filterGroups.at(idx).get('filters') as FormArray).push(this.getFilterFG(column));
  }

  applyFilter() {
    const formModel = Object.assign({}, this.form.value);
    const layer:WMSLayer = formModel.layer;

    const filters = formModel.filterGroups.map(group => {
      const dataColumnFilter = new DataColumnFilter();
      dataColumnFilter.column = group.column;
      dataColumnFilter.operator = group.operator.sql;
      dataColumnFilter.conditions = group.filters.map(filter => {
        const condition = new DataFilterCondition();
        condition.operator = filter.operation.sql;

        if (filter.value) {
          // значение из справочника
          if (typeof filter.value === 'object') {
            condition.value = filter.value.key;
          } else {
            condition.value = filter.value;
          }
        } else {
          condition.values = [filter.range.min, filter.range.max];
        }

        return condition;
      });
      return dataColumnFilter;
    });

    layer.dataFilter = {
      operator: formModel.generalOperator.sql,
      filters
    } as DataFilter;

    this.filterService.getFilterSql(layer.dataFilter).subscribe(filter => {
      layer.filter = filter;
      layer.refresh();
      this.layersStore.updateLayer(layer);
    });
  }

  clearAllFilters() {
    for (const layer of this.layers) {
      layer.dataFilter = null;
      layer.filter = null;
      this.layersStore.updateLayer(layer);
    }
    this.setForm(this.form.get('layer').value);
  }

  filtersExist():boolean {
    return this.layers.some(layer => !!layer.dataFilter);
  }

  private getFilterFG(column:Attribute, filter?:DataFilterCondition):FormGroup {
    const operation = filter
      ? Operations.operations.find(item => item.sql === filter.operator)
      : Operations.operationsByType(column.type)[0];

    const filterFG = this.builder.group({
      column: [column, Validators.required],
      operation: [operation, Validators.required]
    });

    if (filter) {
      if (operation.sql === 'between' || operation.sql === 'not between') {
        filterFG.setControl(
          'range',
          this.builder.group({
            min: [filter.values[0], Validators.required],
            max: [filter.values[1], Validators.required]
          })
        );
      } else {
        let value = filter.value;
        if (column.dictionary) {
          value = column.dictionary.find(item => item.key === value);
        }
        filterFG.addControl('value', this.builder.control(value, Validators.required));
      }
    } else {
      filterFG.addControl('value', this.builder.control(null, Validators.required));
    }

    filterFG.get('operation').valueChanges.subscribe(value => {
      if (value.sql === 'between' || value.sql === 'not between') {
        if (!filterFG.get('range')) {
          filterFG.removeControl('value');
          filterFG.setControl(
            'range',
            this.builder.group({
              min: [null, Validators.required],
              max: [null, Validators.required]
            })
          );
        }
      } else {
        filterFG.removeControl('range');
        filterFG.addControl('value', this.builder.control(null, Validators.required));
      }
    });

    return filterFG;
  }

  private setForm(layer:ILayer) {
    let generalOperator = this.operators[0];
    let filterGroups = [];

    if (layer && layer.dataFilter) {
      generalOperator = this.operators.find(operator => operator.sql === layer.dataFilter.operator);

      filterGroups = layer.dataFilter.filters.map(colFilter =>
        this.builder.group({
          column: [colFilter.column, Validators.required],
          operator: [this.operators.find(operator => operator.sql === colFilter.operator), Validators.required],
          filters: this.builder.array(colFilter.conditions.map(filter => this.getFilterFG(colFilter.column, filter)))
        })
      );
    }

    this.form = this.builder.group({
      layer: [layer, Validators.required],
      generalOperator: [generalOperator, Validators.required],
      filterGroups: this.builder.array(filterGroups)
    });

    this.form.get('layer').valueChanges.subscribe(value => {
      this.setForm(value);
    });

    this.ref.markForCheck();
  }
}
