import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DistributionMethod } from '../classes';
import { IRangeItem } from '../interfaces';
import { gradColor, scale } from '../utils';

@Component({
  selector: 'geo-range-editor',
  templateUrl: 'range_editor.component.html',
  styleUrls: ['./range_editor.component.less']
})
export class RangeEditorComponent {
  @Input() editableRange:IRangeItem[] = [];

  @Input() maxRangesNum = 20;
  // true - отображать значения шкалы
  // false - отображать только min/max на концах шкалы (для тепловой карты)
  @Input() showValues = true;

  @Input() customPalette = false;
  @Input() colors:string[];
  @Input() method:DistributionMethod = 'auto';
  @Input() rangesNum = 5;

  @Output() rangeUpdate:EventEmitter<void> = new EventEmitter();
  @Output() paletteEdited:EventEmitter<void> = new EventEmitter();

  alphaChannel:'always' | 'disabled' = 'always';

  editedItem:IRangeItem;

  colorPickerChange(color:any, idx:number) {
    this.editableRange[idx].color = color.color;
    this.paletteEdited.emit();
    this.rangeUpdate.emit();
  }

  addItem(idx:number) {
    if (this.method !== 'manual' || this.rangesNum >= this.maxRangesNum) {
      return;
    }

    this.rangesNum++;

    const newIdx = idx + 1;

    const prevValue = this.editableRange[idx].value;
    const nextValue = this.editableRange[newIdx].value;

    this.editableRange.splice(newIdx, 0, {
      value: +(prevValue + (nextValue - prevValue) / 2).toFixed(2),
      color: null
    });

    if (!this.customPalette) {
      const colors = this.recalcColors();
      this.editableRange.forEach((item, i) => (item.color = colors[i]));
    } else {
      const prevColor = this.editableRange[idx].color;
      const nextColor = this.editableRange[newIdx + 1].color;

      if (!nextColor) {
        // у последнего интервала нет следующего цвета,
        // поэтому последний цвет в шкале становится цветом нового интервала,
        // предыдущий расcчитываем по формуле
        this.editableRange[newIdx].color = this.editableRange[idx].color;
        this.editableRange[idx].color = gradColor(
          [this.editableRange[idx - 1].color, this.editableRange[idx].color],
          0.5
        );
      } else {
        this.editableRange[newIdx].color = gradColor([prevColor, nextColor], 0.5);
      }
    }

    this.rangeUpdate.emit();
  }

  updateValue(idx:number) {
    const newValue = this.editableRange[idx].value;
    if (newValue == null) {
      if (this.editableRange.length > 3) {
        this.editableRange.splice(idx, 1);
        this.rangesNum--;

        if (!this.customPalette) {
          const colors = this.recalcColors();
          this.editableRange.forEach((item, i) => (item.color = colors[i]));
        }
      } else {
        // должно оставаться минимум 2 интервала в шкале
        return;
      }
    } else {
      // сортируем значения по возрастанию, чтобы предотвратить
      // появление невалидных интервалов
      const values = this.editableRange.map(item => item.value);
      values.sort((a, b) => a - b);
      this.editableRange.forEach((item, i) => (item.value = values[i]));
      const newItemIdx = this.editableRange.findIndex(item => item.value === newValue);
    }
    this.rangeUpdate.emit();
  }

  editDescription(rangeItem:IRangeItem, idx:number) {
    this.editedItem = rangeItem;
    setTimeout(() => {
      (document.getElementById('range-item-' + idx) as HTMLInputElement).focus();
    });
  }

  updateDescription() {
    this.editedItem = null;
  }

  private recalcColors():string[] {
    return scale(this.colors, this.rangesNum);
  }
}
