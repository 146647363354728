import { Circle, FeatureGroup, LatLng, LatLngBounds, LayerEvent, Marker, Polygon } from 'leaflet';
import { Bounds, Feature, Point } from '../../..//classes';

export class MapUtils {
  static createFeature(e:LayerEvent):Feature {
    const feature = new Feature();

    // Если это не точка, получить bounds
    if (!(e.layer instanceof Marker)) {
      const b:LatLngBounds = (e.layer as FeatureGroup).getBounds();
      // если ничего не нарисовано, выходим
      if (!Object.keys(b).length) {
        return;
      }

      feature.bounds = new Bounds(
        new Point(b.getNorthWest().lat, b.getNorthWest().lng),
        new Point(b.getSouthWest().lat, b.getSouthWest().lng),
        new Point(b.getSouthEast().lat, b.getSouthEast().lng),
        new Point(b.getNorthEast().lat, b.getNorthEast().lng)
      );
    }

    // TODO:Расширить typing, чтобы у ILayer можно было вызвать toGeoJson
    feature.geometry = (e.layer as any).toGeoJSON().geometry;

    // Удалить последнюю точку, т.к. она дублирует первую
    if (feature.geometry.type === 'Polygon') {
      (feature.geometry.coordinates[0] as any[]).pop();
    }

    // -- если это круг, то запомнить радиус
    if ((e.layer as Circle).getRadius) {
      feature.geometry.radius = (e.layer as Circle).getRadius();
    }

    return feature;
  }

  static createPolygonFromBounds(latLngBounds:LatLngBounds):Polygon {
    const latlngs:LatLng[] = [];

    latlngs.push(latLngBounds.getSouthWest()); // bottom left
    latlngs.push(latLngBounds.getSouthEast()); // bottom right
    latlngs.push(latLngBounds.getNorthEast()); // top right
    latlngs.push(latLngBounds.getNorthWest()); // top left

    return new Polygon(latlngs);
  }
}
