import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SharedModule } from 'shared/shared.module';
import {
  ChartListComponent,
  ChartPreviewComponent,
  ChartViewComponent,
  ColumnFormComponent,
  DashboardComponent,
  HistogramFormComponent,
  LineFormComponent,
  PieFormComponent,
  ScatterFormComponent
} from './components';
import { AttributeTypePipe, ExcludePipe } from './pipes';
import { ChartService } from './services';

@NgModule({
  imports: [BrowserModule, NgxChartsModule, HttpClientModule, SharedModule.forRoot()],
  declarations: [
    DashboardComponent,
    PieFormComponent,
    ChartListComponent,
    ColumnFormComponent,
    HistogramFormComponent,
    ScatterFormComponent,
    LineFormComponent,
    ChartViewComponent,
    ChartPreviewComponent,
    AttributeTypePipe,
    ExcludePipe
  ],
  entryComponents: [DashboardComponent],
  exports: [DashboardComponent, NgxChartsModule],
  providers: [ChartService]
})
export class DashboardModule {}
