import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {IDomPanel, IMenu, IPluginInterface, ISimpleMap} from '../../interfaces';
import { Creator } from '../../services/creator.service';
import { Button } from '../button/button.component';
import { MenuComponent } from '../menu/menu.component';
import {ResizableDirective, ResizeEvent} from 'angular-resizable-element';

@Component({
  selector: 'slide-panel',
  templateUrl: 'slide_panel.component.html',
  styleUrls: ['slide_panel.component.less'],
  providers: [ResizableDirective]
})
export class SlidePanelComponent extends MenuComponent implements IDomPanel, AfterContentInit {

  @Input() btnOpen = true; // toggle slide menu after btn click
  @Input() id:string;

  @Output() resizeStart = new EventEmitter<void>();
  @Input() resizeEdge:string;
  @Input() minWidth:number = 0;
  @Input() dockside:string = 'left';

  @HostBinding('style.left') left;
  @HostBinding('style.right') right;
  @HostBinding('style.bottom') bottom;

  @ViewChild('buttons', { read: ViewContainerRef }) buttonsDiv:ViewContainerRef;
  @ViewChild('content', { read: ViewContainerRef }) contentDiv:ViewContainerRef;

  protected menu:IMenu;
  protected btn:Button;
  private map:ISimpleMap;
  private visible:boolean = false;

  constructor(creator:Creator, el:ElementRef) {
    super(creator, el);
  }

  get hostResizeEdge():{bottom?:string, right?:string, top?:string, left?:string} {
    const ret = {};
    if (this.resizeEdge) {
      ret[this.resizeEdge] = true;
    }
    return ret;
  }

  onResizing(evt:ResizeEvent) {
    const width = evt.rectangle.right > this.minWidth ? evt.rectangle.right : this.minWidth;
    this.el.nativeElement.style.width = width + 'px';
  }

  setVisible(isVisible:boolean) {
    this.visible = isVisible;
    if (this.dockside === 'right') {
      this.right = isVisible ? '0' : -this.el.nativeElement.clientWidth + 'px';
    } else if (this.dockside === 'bottom') {
      this.bottom = isVisible ? '0' : 'auto';
    } else {
      this.left = isVisible ? '0' : -this.el.nativeElement.clientWidth + 'px';
    }
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;
        // создание кнопки
        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.buttonConfig.onClickMe = () => {
            this.toggle();
          };
          this.buttonConfig.onDeactivate = () => {
            this.toggle();
          };
          this.btn.setOptions(this.buttonConfig);
          this.menu.addBtn(this.btn);
        });
        break;
      case 'Map':
        this.map = pi as ISimpleMap;
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
  }

  addBtn(btn:Button) {
    if (!btn) {
      return;
    }

    btn.btnClick = () => {
      if (this.curBtn === btn && this.btnOpen) {
        this.setVisible(false);
        return;
      }

      this.childClick(btn);

      if (this.btnOpen) {
        this.setVisible(true);
      }

      this.curBtn = btn;
      this.deactivateBtns();
      // текущая
      btn.setActive(true);
    };
    this.btns.push(btn);
  }

  getContainer() {
    return this.contentDiv;
  }

  toggle() {
    if (this.visible) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.setVisible(true);
    this.onShow.emit();
    if (this.map) { this.map.redraw(); }
  }

  close() {
    this.setVisible(false);
    this.onHide.emit();
    if (this.map) { this.map.redraw(); }
  }
}
