import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AfterContentInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalWidget } from '@geoanalitika/widgets';
import { Observable, throwError as observableThrowError, forkJoin } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Feature, PluginClass, TMSLayer, Utils } from 'shared/classes';
import { Button, DropMenuButtonComponent } from 'shared/components';
import {
  IActivator,
  IContainer,
  IContentChild,
  ICurrent,
  IDraw,
  IDrawFinish,
  IEdit,
  IFeature,
  ILayer,
  ILayerList,
  IMenu,
  IPluginInterface,
  ISearchResult,
  ISwitch,
  ITool,
  IToolFeature,
  IToolLayer,
  IVertexPosition
} from 'shared/interfaces';
import { LayersStore } from 'shared/stores/LayersStore';
import {LineString, Polygon, Position} from 'geojson';
import {MessageService} from 'shared/services';

const cloneDeep = require('lodash/cloneDeep');

@Component({
  selector: 'edit-feature',
  templateUrl: 'edit_feature.component.html',
  styleUrls: ['edit_feature.component.less']
})
export class EditFeatureComponent extends PluginClass
  implements IToolLayer, ISearchResult, IEdit, IMenu, IFeature, IToolFeature, ISwitch, OnInit, AfterContentInit {

  constructor(private httpClient:HttpClient, private layersStore:LayersStore, private messageService:MessageService) {
    super();

    layersStore.getActiveLayers().subscribe(data => {
      this.activeLayers = data.filter(layer => layer.editable);
    });
  }

  // Кнопки в дроп-меню
  @ViewChild('editBtn') editBtn:Button;
  @ViewChild('splitBtn') splitBtn:Button;
  @ViewChild('newBtn') newBtn:Button;
  @ViewChild('delBtn') delBtn:Button;

  // Меню
  @ViewChild('editMenu') innerMenu:IMenu;
  @ViewChild('dropMenu') dropMenu:IMenu;

  // Модальные окна
  @ViewChild('delModal') delModal:ModalWidget;
  @ViewChild('saveModal') saveModal:ModalWidget;
  @ViewChild('notSavedModal') notSavedModal:ModalWidget;
  @ViewChild('deactModal') deactModal:ModalWidget;
  @ViewChild('cancelModal') cancelModal:ModalWidget;
  @Output() onShow = new EventEmitter();
  @Output() onHide = new EventEmitter();

  activateMode:string = null;
  toolName = 'Редактирование';
  className = 'editFeature';
  groupName:string;
  btns:Button[];
  parentComponent:IContainer;
  mode = 'None';
  currentEditMode:string;
  currentModeHandler:Map<string, (mode:string) => any>;

  exception:any;
  activeLayers:ILayer[] = [];
  currentLayer:ILayer = null;
  panelVisible = false;

  // Текущие параметры
  private clickedBtn:Button;
  private currentFeatures:Feature[] = [];
  private selectedFeature:Feature;
  private drawnFeature:Feature;
  private featureHistory:Feature[] = []; // история изменений объекта
  private originalFeatures:Feature[]; // начальное состояние объекта для реализации сброса изменений

  // Неизменные параметры
  private activator:IActivator; // плагин, отвечающий за отключение связанных плагинов
  private btn:Button = null;
  private menu:IMenu;
  private switcher:Map<string, Map<string, (obj:any) => any>>;

  // Состояние
  private active = false;
  private drawing = false;

  // Массивы плагинов
  private attrPlugins:ISearchResult[] = []; // боковая панель (SearchResults)
  private currentPlugins:ICurrent[] = []; // плагины, которым нужны изменения текущего объекта (EditCoords)
  private deactPlugins:ITool[] = []; // плагины, которые нужно выключить (Search)
  private drawPlugins:IDraw[] = []; // рисование (MapComponent)
  private editPlugins:IEdit[] = []; // редактирование (MapComponent)
  private featurePlugins:IFeature[] = []; // плагины, которым нужен редактируемый объект (EditCoords)
  private searchPlugins:ILayerList[] = []; // плагины поиска объектов (SearchEdit)
  private activePlugins:ITool[] = []; // плагины, которые нужно активировать (SearchEdit)
  private resultPlugins:ISearchResult[] = []; // плагины отображения результатов (EditResultPopup)

  ngOnInit() {
    this.switcher = new Map<string, Map<string, (mode:string) => any>>();
    this.createSwitcherMap();
  }

  ngAfterContentInit() {
    this.dropMenu.addBtn = (btn:Button) => {
      if (!btn) {
        return;
      }

      // Переопределяем клик по кнопке в дропменю
      btn.btnClick = () => {
        if (btn.btnDisabled) {
          return;
        }

        this.clickedBtn = btn;
        this.activateEdit();

        switch (btn) {
          case this.editBtn:
            this.currentEditMode = 'Edit';
            this.switchMode('Edit');
            break;
          case this.splitBtn:
            this.mode = 'None';
            this.currentEditMode = 'Split';
            this.switchMode('Split');
            this.splitStart();
            break;
          case this.newBtn:
            this.mode = 'None';
            this.currentEditMode = 'Add';
            this.switchMode('Add');
            break;
          case this.delBtn:
            this.currentEditMode = 'Del';
            this.switchMode('Del');
            break;
          default:
            this.currentEditMode = 'Coords';
            this.switchMode('Coords');
            break;
        }
      };

      this.dropMenu.btns.push(btn);
    };

    // Подменяем метод на кнопке дроп-меню. Timeout, т.к. этот метод
    // уже подменяется на ngAfterContentInit в компоненте DropMenuButton (WTF?!)
    setTimeout(() => {
      const btn:Button = (this.dropMenu as DropMenuButtonComponent).getMenuBtn();
      btn.btnClick = () => {
        if (this.hasChanges()) {
          this.deactModal.wait().then(data => {
            switch (data) {
              case 'ok':
                this.saveEdits().subscribe(
                  () => {
                    this.currentLayer.refresh();
                    btn.setActive(false);
                    this.proceedDeact();
                  },
                  error => {
                    this.deactModal.hide();
                    this.exception = Utils.getErrorMsg(error);
                  }
                );
                break;
              case 'notOk':
                btn.setActive(false);
                this.proceedDeact();
                break;
              default:
                break;
            }
          });
          return;
        }

        // Если дроп-меню скрыто, развернуть его
        if (!btn.isActive()) {
          btn.setActive(true);
          return;
        }

        btn.setActive(false);
        // Если дроп-меню открыто, свернуть его и деактивировать
        this.proceedDeact();
      };
    });
  }

  enable() {
  }

  disable() {
  }

  splitStart() {
    if (this.hasChanges()) {
      this.notSavedModal.doModal(
        () => {
          this.saveEdits().subscribe(
            data => this.splitProceed(),
            error => {
              this.notSavedModal.hide();
              this.exception = Utils.getErrorMsg(error);
            }
          );
        },
        () => this.splitProceed(),
        this
      );
    } else {
      this.splitProceed();
    }
  }

  splitProceed() {
    this.stopDraw();
    this.stopEdit();
    this.exception = null;
    this.notSavedModal.hide();
    this.enableBtn(this.clickedBtn);
    this.toggleTools(this.activePlugins, 'on');
    this.adjustSearchPlugins([this.currentLayer]);
  }

  // Показать панель редактирования
  activate() {
    if (!this.currentLayer) {
      this.currentLayer = this.layersStore.selectedLayer;
    }
    this.panelVisible = true;
    this.enableAll(true);
  }

  deactivate() {
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Edit':
        this.editPlugins.push(pi as IEdit);
        break;
      case 'Search':
        this.searchPlugins.push(pi as ILayerList);
        break;
      case 'Draw':
        this.drawPlugins.push(pi as IDraw);
        break;
      case 'ActivateTool':
        this.activePlugins.push(pi as ITool);
        break;
      case 'DeactivateTool':
        this.deactPlugins.push(pi as ITool);
        break;
      case 'Result':
        this.resultPlugins.push(pi as ISearchResult);
        break;
      case 'Attributes':
        this.attrPlugins.push(pi as ISearchResult);
        break;
      case 'Feature':
        this.featurePlugins.push(pi as IFeature);
        break;
      case 'Current':
        this.currentPlugins.push(pi as ICurrent);
        break;
      case 'Activator':
        this.activator = pi as IActivator;
        this.activator.setConnect(this as ITool);
        break;

      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Edit':
        this.editPlugins = [];
        break;
      case 'Search':
        this.searchPlugins = [];
        break;
      case 'Draw':
        this.drawPlugins = [];
        break;
      case 'ActivateTool':
        this.activePlugins = [];
        break;
      case 'DeactivateTool':
        this.deactPlugins = [];
        break;
      case 'Result':
        this.resultPlugins = [];
        break;
      case 'Attributes':
        this.attrPlugins = [];
        break;
      case 'Feature':
        this.featurePlugins = [];
        break;
      case 'Current':
        this.currentPlugins = [];
        break;
      case 'Activator':
        this.activator = null;
        break;
    }
  }

  vertexPosition(value:IVertexPosition) {
    this.currentPlugins.forEach(plugin => {
      plugin.vertexPosition = value;
    });
  }

  showFeatureInfo(feature:Feature) {

  }

  setFeatures(features:Feature[]):void {
    const nonEditableFeatures = features.filter(f => !f.editable);
    if (nonEditableFeatures[0] && this.active) {
      this.messageService.openAlertWindow(nonEditableFeatures[0].message, ['Закрыть'], 0).subscribe();
    }
    features = features.filter(f => f.editable);

    if (!features.length) {
      return;
    }

    if (this.active) {
      if (features.length === 1) {
        const feature = features[0];

        // Удаляем последние точки из частей полигонов, т.к. они дублируют первые
        if (feature.type === 'polygon') {
          (feature.geometry.coordinates as any[]).forEach(polyPart => {
            polyPart.pop();
          });
        } else if (feature.type === 'multipolygon') {
          (feature.geometry.coordinates as any[]).forEach(poly => {
            poly.forEach((part:any[]) => {
              part.pop();
            });
          });
        }

        this.mode = 'EditHasFeature';

        feature.invertCoordinates();

        if (!this.originalFeatures) {
          this.originalFeatures = this.cloneFeatures(features);
        }

        this.currentFeatures = features;
        this.selectedFeature = feature;
        this.showEditableFeatures(features);
        this.setFeaturesInPlugins(features);
        if (this.currentModeHandler) {
          const handler = this.currentModeHandler.get('EditHasFeature');
          if (handler) {
            handler.apply(this, feature);
          }
        }

        this.editInfo(feature);
      } else {
        this.resultPlugins.forEach(plugin => {
          plugin.setFeatures(features, true);
        });
      }
    }
  }

  updateFeatures(features:Feature[]) {
    this.currentFeatures = features;
    this.clearEditedFeatures();
    this.showEditableFeatures(features);
  }

  getFeatures():Feature[] {
    return this.currentFeatures;
  }

  showInfo(feature:Feature) {
  }

  editInfo(feature:Feature) {
    this.attrPlugins.forEach(plugin => {
      plugin.editInfo(feature);
    });
  }

  clearResults():void {
    this.resultPlugins.forEach(plugin => {
      plugin.clearResults();
    });
  }

  changeLayer(layer:any) {
    if (!layer) {
      this.deactivateTool();
      return;
    }

    if (this.mode === 'None') {
      this.updateLayer(layer);
      return;
    }

    if (!this.hasChanges()) {
      this.updateLayer(layer);
      this.stopEdit();

      if (this.drawing) {
        this.stopDraw();
        this.startDraw(this.currentLayer.geomType);
      }
    } else {
      this.notSavedModal.doModal(
        () => {
          this.saveEdits().subscribe(
            data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.notSavedModal.hide();
              this.switchToEditAndClearMap();
              this.updateLayer(layer);
            },
            error => {
              this.notSavedModal.hide();
              this.exception = Utils.getErrorMsg(error);
            }
          );
        },
        () => {
          this.notSavedModal.hide();
          this.switchToEditAndClearMap();
          this.updateLayer(layer);
        },
        this
      );
    }
  }

  updateEditableFeatures(geometry:any) {
    if (this.active) {
      if (this.mode === 'Add' && !this.hasChanges()) {
        this.mode = 'AddStartedDrawing';
      } else if (this.mode === 'EditHasFeature') {
        this.mode = 'EditHasChanges';
      }

      const lastFeature = this.featureHistory.slice(-1)[0];
      let feature:Feature = new Feature();

      // Если в истории уже есть объект, то копируем его
      if (lastFeature) {
        feature = Object.assign(feature, lastFeature);
      } else if (this.hasFeatures()) {
        Object.assign(feature.properties, this.currentFeatures[0].properties);
      }

      feature.geometry = geometry;
      feature.invertCoordinates();

      this.writeToHistory(feature);

      if (!this.drawing) {
        // Запоминаем изменения, чтобы они не сбрасывались при закрытии окна
        // редактирования координат
        this.currentFeatures = [feature];
        this.setFeaturesInPlugins([feature]);
      }
    }
  }

  clearEditedFeatures() {
    this.editPlugins.forEach(plugin => {
      plugin.clearEditedFeatures();
    });
  }

  showEditableFeatures(features:Feature[]) {
    if (features.length) {
      this.currentFeatures = features.map(feature => cloneDeep(feature));
      this.editPlugins.forEach(plugin => {
        plugin.showEditableFeatures(features);
      });
    }
  }

  writeToHistory(feature:Feature) {
    if (this.active) {
      this.featureHistory.push(cloneDeep(feature));
    }
  }

  updateAttributes(feature:Feature) {
    const lastFeature = this.featureHistory.slice(-1)[0];
    const newFeature = new Feature();

    if (lastFeature) {
      Object.assign(newFeature, lastFeature);
    } else {
      Object.assign(newFeature, this.currentFeatures[0]);
    }

    Object.assign(newFeature.properties, feature.properties);

    this.writeToHistory(newFeature);
  }

  startSnapping() {
    if (!this.currentLayer) {
      return;
    }

    this.editPlugins.forEach(plugin => {
      const layerName:string = this.currentLayer.layerName;
      plugin.startSnapping(layerName);
    });
  }

  removeEditedFeaturesByType(type:String):void {
    this.editPlugins.forEach(plugin => {
      plugin.removeEditedFeaturesByType(type);
    });
  }

  stopSnapping() {
    this.editPlugins.forEach(plugin => {
      plugin.stopSnapping();
    });
  }

  highlightVertex(position:IVertexPosition) {
    this.editPlugins.forEach(plugin => {
      plugin.highlightVertex(position);
    });
  }

  set highlightMode(value:boolean) {
    this.editPlugins.forEach(plugin => {
      plugin.highlightMode = value;
    });
  }

  getCurrentLayer() {
    return this.currentLayer;
  }

  hasFeatures() {
    return this.currentFeatures.length;
  }

  hasChanges() {
    return this.featureHistory.length;
  }

  stopDraw() {
    if (!this.drawing) {
      return;
    }
    this.drawing = false;
    this.clearDrawFeatures();
    this.clearHistory();
    this.clearEditedFeatures();
    this.currentFeatures = [];
    this.originalFeatures = null;
    this.stopDrawInPlugins();
    this.clearResults();
  }

  finishDraw(feature:Feature) {
    if (this.drawing) {
      this.stopDrawInPlugins();

      this.mode = 'AddFinishedDrawing';
      this.drawing = false;

      if (feature) {
        this.currentFeatures = [feature];
        feature.invertCoordinates();
        this.showEditableFeatures([feature]);
        this.clearDrawFeatures();

        if (this.currentModeHandler) {
          const handler = this.currentModeHandler.get('AddFinishedDrawing');
          if (handler) {
            handler.apply(this, [feature]);
          }
        }

        this.setFeaturesInPlugins([feature]);
      }
    }
  }

  updateDrawingFeature(feature:Feature) {
    this.updateEditableFeatures(feature.geometry);
  }

  addBtn(btn:Button) {
    this.dropMenu.addBtn(btn);
  }

  createBtn(level:number):Promise<any> {
    if (level === 0) {
      return this.innerMenu.createBtn(level);
    }
    return this.dropMenu.createBtn(level);
  }

  removeBtn(btn:Button) {
    this.dropMenu.removeBtn(btn);
  }

  deactivateBtns() {
    this.dropMenu.deactivateBtns();
  }

  createComponent(component:any):Promise<any> {
    return this.dropMenu.createComponent(component);
  }

  getContainer():ViewContainerRef {
    return this.dropMenu.getContainer();
  }

  childClick(btn:Button) {
    this.dropMenu.childClick(btn);
  }

  open() {
  }

  close() {
  }

  isOpen() {
    return this.active;
  }

  addChild(child:IContentChild):void {
  }

  checkLayer(layer:ILayer):boolean {
    return layer.editable;
  }

  getGroup() {
    return this.groupName;
  }

  activateTool() {
    this.active = true;
    this.toggleTools(this.deactPlugins, 'off');
  }

  activateToolLayer(layer:ILayer) {
    this.activate();
    this.changeLayer(layer);
  }

  // Cбросить все изменения,
  // не скрывая панели редактирования
  deactivateTool():Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.hasChanges()) {
        this.deactModal.wait().then(data => {
          switch (data) {
            case 'ok':
              this.saveEdits().subscribe(
                () => {
                  this.currentLayer.refresh();
                  this.deactModal.hide();
                  this.proceedDeact();
                  resolve(true);
                },
                error => {
                  this.deactModal.hide();
                  this.exception = Utils.getErrorMsg(error);
                  reject(false);
                }
              );
              break;
            case 'notOk':
              this.deactModal.hide();
              this.proceedDeact();
              resolve(true);
              break;
            case 'cancel':
              reject(false);
              break;
          }
        });
      } else {
        this.proceedDeact();
        resolve(true);
      }
    });
  }

  isActive() {
    return this.panelVisible;
  }

  activateToolFeature(feature:Feature) {
    if (!feature.editable) {
      const msg = feature.message ? feature.message : 'Редактирование не разрешено';
      this.messageService.openAlertWindow(feature.message, ['Закрыть'], 0).subscribe();
      return;
    }

    const activatePromise = this.activateEdit();

    if (activatePromise) {
      activatePromise.then(() => {
        this.currentLayer = this.activeLayers.find(item => item.id === feature.layer.id);

        this.setFeatures([feature]);
        this.hideAttributes();
        this.editInfo(feature);

        this.mode = 'EditHasFeature';

        // Активировать кнопку редактирования в дроп-меню
        // после обновления DOM
        setTimeout(() => {
          (this.dropMenu as DropMenuButtonComponent).getMenuBtn().setActive(true);
          this.enableBtn(this.editBtn);
        });
      });
    }
  }

  checkFeature(feature:Feature):boolean {
    return !!feature.layer.editable;
  }

  switchMode(mode:string):void {
    this.currentModeHandler = this.switcher.get(mode);
    const action = this.currentModeHandler.get(this.mode);
    if (action) {
      action.call(this, mode);
    }
  }

  createSwitcherMap():void {
    this.switcher.set('Add', new Map<string, (mode:string) => any>());
    this.switcher.set('Edit', new Map<string, (mode:string) => any>());
    this.switcher.set('Coords', new Map<string, (mode:string) => any>());
    this.switcher.set('Del', new Map<string, (mode:string) => any>());
    this.switcher.set('Save', new Map<string, (mode:string) => any>());
    this.switcher.set('None', new Map<string, (mode:string) => any>());
    this.switcher.set('Reset', new Map<string, (mode:string) => any>());
    this.switcher.set('EditHasFeature', new Map<string, (mode:string) => any>());
    this.switcher.set('CoordsHasFeature', new Map<string, (mode:string) => any>());
    this.switcher.set('Split', new Map<string, (obj:any) => any>());

    const Add = this.switcher.get('Add');
    const Edit = this.switcher.get('Edit');
    const Coords = this.switcher.get('Coords');
    const Del = this.switcher.get('Del');
    const Save = this.switcher.get('Save');
    const None = this.switcher.get('None');
    const Reset = this.switcher.get('Reset');
    const EditHasFeature = this.switcher.get('EditHasFeature');
    const CoordsHasFeature = this.switcher.get('CoordsHasFeature');
    const Split = this.switcher.get('Split');

    Add.set('None', (mode:string) => {
      this.enableBtn(this.clickedBtn);
      this.stopEdit();
      this.stopDraw();
      this.clearResults();
      this.hideAttributes();
      this.startDraw(this.currentLayer.geomType);
      this.mode = mode;
    });

    Add.set('AddStartedDrawing', (mode:string) => {
      this.notSavedModal.doModal(
        () => {
          this.saveEdits().subscribe(
            data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.notSavedModal.hide();
              this.enableMode(mode);
              this.stopDraw();
              this.startDraw(this.currentLayer.geomType);
            },
            error => {
              this.notSavedModal.hide();
              this.exception = Utils.getErrorMsg(error);
            }
          );
        },
        () => {
          this.enableMode(mode);
          this.stopDraw();
          this.startDraw(this.currentLayer.geomType);
        },
        this
      );
    });

    Add.set('AddFinishedDrawing', (mode:string) => {
      // Открыть окно редактирования атрибутов
      this.openAttributesEditor();
    });

    Add.set('EditHasChanges', (mode:string) => {
      this.notSavedModal.doModal(
        () => {
          this.saveEdits().subscribe(
            data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.notSavedModal.hide();
              this.enableMode(mode);
              this.stopEdit();
              this.startDraw(this.currentLayer.geomType);
            },
            error => {
              this.notSavedModal.hide();
              this.exception = Utils.getErrorMsg(error);
            }
          );
        },
        () => {
          this.enableMode(mode);
          this.stopEdit();
          this.startDraw(this.currentLayer.geomType);
        },
        this
      );
    });

    Add.set('EditHasFeature', (mode:string) => {
      this.exception = null;
      this.currentLayer.refresh();
      this.notSavedModal.hide();
      this.enableMode(mode);
      this.stopEdit();
      this.startDraw(this.currentLayer.geomType);
    });

    Edit.set('AddStartedDrawing', (mode:string) => {
      this.notSavedModal.doModal(
        () => {
          this.saveEdits().subscribe(
            data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.notSavedModal.hide();
              this.stopDraw();
              this.toggleTools(this.activePlugins, 'on');
              this.enableMode(mode);
            },
            error => {
              this.notSavedModal.hide();
              this.exception = Utils.getErrorMsg(error);
            }
          );
        },
        () => {
          this.stopDraw();
          this.toggleTools(this.activePlugins, 'on');
          this.enableMode(mode);
        },
        this
      );
    });

    Edit.set('AddFinishedDrawing', (mode:string) => {
      // Открыть окно редактирования атрибутов
      this.openAttributesEditor();
      /*this.notSavedModal.doModal(
        () => {
          this.saveEdits().subscribe(
            data => {
              this.exception = null;
              this.layer.refresh();
              this.notSavedModal.hide();
              this.stopEdit();
              this.toggleTools(this.activePlugins, 'on');
              this.enableMode(mode);
            },
            error => {
              this.notSavedModal.hide();
              this.exception = Utils.getErrorMsg(error);
            }
          );
        },
        () => {
          this.stopEdit();
          this.toggleTools(this.activePlugins, 'on');
          this.enableMode(mode);
        },
        this
      );*/
    });

    Edit.set('EditHasChanges', (mode:string) => {
      this.notSavedModal.doModal(
        () => {
          this.saveEdits().subscribe(
            data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.notSavedModal.hide();
              this.stopEdit();
              this.enableMode(mode);
            },
            error => {
              this.notSavedModal.hide();
              this.exception = Utils.getErrorMsg(error);
            }
          );
        },
        () => {
          this.stopEdit();
          this.enableMode(mode);
        },
        this
      );
    });
    Edit.set('Add', (mode:string) => {
      this.enableBtn(this.clickedBtn);
      this.stopDraw();
      this.toggleTools(this.activePlugins, 'on');
      this.adjustSearchPlugins([this.currentLayer]);
      this.mode = mode;
    });

    Edit.set('Coords', (mode:string) => {
      this.enableBtn(this.clickedBtn);
      this.toggleTools(this.activePlugins, 'on');
      this.adjustSearchPlugins([this.currentLayer]);
      this.mode = mode;
    });

    Edit.set('CoordsHasFeature', (mode:string) => {
      this.mode = 'EditHasFeature';
      this.enableBtn(this.editBtn);
    });

    Split.set('AddStartedDrawing', (mode:string) => {
      this.notSavedModal.doModal(
        () => {
          this.saveEdits().subscribe(
            data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.notSavedModal.hide();
              this.enableMode(mode);
              this.stopDraw();
              this.startDraw('linestring');
            },
            error => {
              this.notSavedModal.hide();
              this.exception = Utils.getErrorMsg(error);
            }
          );
        },
        () => {
          this.enableMode(mode);
          this.stopDraw();
          this.startDraw(this.currentLayer.geomType);
        },
        this
      );
    });

    Split.set('AddFinishedDrawing', (feature:any) => {
      if (!feature.geometry && feature.geometry.type !== 'LineString') {
        return;
      }
      this.enableAll(false);
      this.drawnFeature = feature;
      //activate save button
      this.featureHistory.push(this.selectedFeature);
    });

    Split.set('EditHasChanges', (mode:string) => {
      this.notSavedModal.doModal(
        () => {
          this.saveEdits().subscribe(
            data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.notSavedModal.hide();
              this.enableMode(mode);
              this.stopEdit();
              this.startDraw('linestring');
            },
            error => {
              this.notSavedModal.hide();
              this.exception = Utils.getErrorMsg(error);
            }
          );
        },
        () => {
          this.enableMode(mode);
          this.stopEdit();
          this.startDraw(this.currentLayer.geomType);
        },
        this
      );
    });

    Split.set('EditHasFeature', (feature:any) => {
      // Можно рисовать разделительную линию
      this.startDraw('linestring');
    });

    Coords.set('EditHasFeature', (mode:string) => {
      this.mode = 'CoordsHasFeature';
      this.enableBtn(this.editBtn);
    });

    Coords.set('Add', (mode:string) => {
      this.enableBtn(this.clickedBtn);
      this.stopDraw();
      this.toggleTools(this.activePlugins, 'off');
      this.mode = mode;
    });

    // Повторный клик на кнопку редактирования координат
    Coords.set('Coords', (mode:string) => {
      this.enableBtn(this.clickedBtn);
      this.mode = mode;
    });

    Coords.set('AddStartedDrawing', (mode:string) => {
      this.notSavedModal.doModal(
        () => {
          this.saveEdits().subscribe(
            data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.notSavedModal.hide();
              this.stopDraw();
              this.toggleTools(this.activePlugins, 'off');
              this.enableMode(mode);
            },
            error => {
              this.notSavedModal.hide();
              this.exception = Utils.getErrorMsg(error);
            }
          );
        },
        () => {
          this.stopDraw();
          this.toggleTools(this.activePlugins, 'off');
          this.enableMode(mode);
        },
        this
      );
    });

    Coords.set('AddFinishedDrawing', (mode:string) => {
      this.enableBtn(this.clickedBtn);
      this.mode = 'CoordsHasFeature';
    });

    Coords.set('EditHasChanges', (mode:string) => {
      this.enableBtn(this.clickedBtn);
      this.mode = 'CoordsHasFeature';
    });

    Coords.set('Edit', (mode:string) => {
      this.enableBtn(this.clickedBtn);
      this.toggleTools(this.activePlugins, 'off');
      this.mode = mode;
    });

    Coords.set('None', (mode:string) => {
      this.enableBtn(this.clickedBtn);
      this.mode = mode;
    });

    CoordsHasFeature.set('Coords', (mode:string) => {
      this.mode = 'CoordsHasFeature';
    });

    Del.set('AddStartedDrawing', (mode:string) => {
      this.delModal.doModal(
        () => {
          this.deleteFeature();
          this.stopDraw();
          this.startDraw(this.currentLayer.geomType);
          this.mode = 'Add';
        },
        null,
        this
      );
    });

    Del.set('AddFinishedDrawing', (mode:string) => {
      this.delModal.doModal(
        () => {
          this.deleteFeature();
          this.stopEdit();
          this.startDraw(this.currentLayer.geomType);
          this.mode = 'Add';
        },
        null,
        this
      );
    });

    Del.set('EditHasChanges', (mode:string) => {
      this.delModal.doModal(
        () => {
          this.deleteFeature();
          this.stopEdit();
          this.toggleTools(this.activePlugins, 'on');
          this.mode = 'Edit';
        },
        null,
        this
      );
    });

    Save.set('AddStartedDrawing', (mode:string) => {
      this.saveModal.doModal(
        () => {
          this.saveEdits()
            .pipe(catchError((ex:any) => this.saveError(ex)))
            .subscribe(data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.saveModal.hide();
              this.stopDraw();
              this.startDraw(this.currentLayer.geomType);
              this.mode = 'Add';
            });
        },
        null,
        this
      );
    });

    Save.set('AddFinishedDrawing', (mode:string) => {
      this.saveModal.doModal(
        () => {
          this.saveEdits()
            .pipe(catchError((ex:any) => this.saveError(ex)))
            .subscribe(data => {
              this.enableAll(true);
              this.exception = null;
              this.currentLayer.refresh();
              this.saveModal.hide();
              this.stopEdit();
              this.startDraw(this.currentLayer.geomType);
              this.mode = 'Add';
            });
        },
        null,
        this
      );
    });

    Save.set('EditHasChanges', (mode:string) => {
      this.saveModal.doModal(
        () => {
          this.saveEdits()
            .pipe(catchError((ex:any) => this.saveError(ex)))
            .subscribe(data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.saveModal.hide();
              this.stopEdit();
              this.mode = 'Edit';
              this.toggleTools(this.activePlugins, 'on');
            });
        },
        null,
        this
      );
    });

    Save.set('CoordsHasFeature', (mode:string) => {
      this.saveModal.doModal(
        () => {
          this.saveEdits()
            .pipe(catchError((ex:any) => this.saveError(ex)))
            .subscribe(data => {
              this.exception = null;
              this.currentLayer.refresh();
              this.saveModal.hide();
              this.stopEdit();
              this.mode = 'Coords';
              this.toggleTools(this.activePlugins, 'on');
            });
        },
        null,
        this
      );
    });

    Reset.set('AddStartedDrawing', (mode:string) => {
      this.cancelModal.doModal(
        () => {
          this.stopDraw();
          this.startDraw(this.currentLayer.geomType);
          this.clearHistory();
          this.cancelModal.hide();
          this.mode = 'Add';
        },
        null,
        this
      );
    });

    Reset.set('AddFinishedDrawing', (mode:string) => {
      this.cancelModal.doModal(
        () => {
          this.stopEdit();
          this.startDraw(this.currentLayer.geomType);
          this.clearHistory();
          this.cancelModal.hide();
          this.clearHistory();
          if (this.currentEditMode === 'Split') {
            this.removeEditedFeaturesByType('LineString');
            //goto mode feature to split selected
            this.mode = 'EditHasFeature';
            this.switchMode(this.currentEditMode);
            this.editBtn.btnDisabled = false;
            this.splitBtn.btnDisabled = false;
            this.newBtn.btnDisabled = false;
            this.delBtn.btnDisabled = false;
            this.enablePlugins(true);
          } else {
            this.clearEditedFeatures();
            this.hideAttributes();
            this.startDraw(this.currentLayer.geomType);
            this.mode = 'None';
            this.switchMode('Add');
          }
        },
        null,
        this
      );
    });

    Reset.set('EditHasChanges', (mode:string) => {
      this.cancelModal.doModal(
        () => {
          this.clearEditedFeatures();
          this.resetFeatures();
          this.clearHistory();
          this.cancelModal.hide();
          this.mode = 'EditHasFeature';
        },
        null,
        this
      );
    });

    Reset.set('EditHasFeature', (mode:string) => {
      this.cancelModal.doModal(
        () => {
          this.clearEditedFeatures();
          this.mode = 'Edit';

          if (this.originalFeatures) {
            this.resetFeatures();
            this.mode = 'EditHasFeature';
          } else {
            this.clearFeaturesInPlugins();
            this.toggleTools(this.activePlugins, 'on');
            this.currentFeatures = [];
          }

          this.clearHistory();
          this.cancelModal.hide();
        },
        null,
        this
      );
    });

    Reset.set('CoordsHasFeature', (mode:string) => {
      this.cancelModal.doModal(
        () => {
          this.clearEditedFeatures();
          this.currentFeatures = [];
          this.clearFeaturesInPlugins();
          this.mode = 'Coords';

          if (this.originalFeatures) {
            const features = this.resetFeatures();
            this.setFeaturesInPlugins(features);
            this.mode = 'CoordsHasFeature';
          }

          this.clearHistory();
          this.cancelModal.hide();
        },
        null,
        this
      );
    });

    // Повторяющиеся действия
    Save.set('EditHasFeature', Save.get('EditHasChanges'));

    Del.set('EditHasFeature', Del.get('EditHasChanges'));
    Del.set('CoordsHasFeature', Del.get('EditHasChanges'));

    Coords.set('EditHasFeature', Coords.get('EditHasChanges'));

    Edit.set('None', Edit.get('Coords'));
    //Edit.set('EditHasFeature', Edit.get('EditHasChanges'));
    Add.set('CoordsHasFeature', Add.get('EditHasChanges'));
    Add.set('Edit', Add.get('None'));
    Add.set('Coords', Add.get('None'));
  }

  private openAttributesEditor() {
    // Открыть окно редактирования атрибутов
    const feature = this.currentFeatures[0];
    this.resultPlugins.forEach(plugin => {
      feature.layer = this.currentLayer;
      feature.properties = {};
      this.currentLayer.columns.forEach(column => {
        if (column.name === this.currentLayer.pk) {
          return;
        }
        const value = column.type === 'boolean' ? false : null;
        feature.properties[column.name] = value;
      });
      plugin.editInfo(feature);
    });
  }

  private enableAll(isEnabled:boolean) {
    this.editBtn.btnDisabled = !isEnabled;
    this.splitBtn.btnDisabled = !isEnabled;
    this.newBtn.btnDisabled = !isEnabled;
    this.enablePlugins(isEnabled);
  }

  private enableBtn(btn:Button):void {
    this.dropMenu.childClick(btn);
    btn.setActive(true);
  }

  private enableMode(mode:string):void {
    this.mode = mode;
    this.enableBtn(this.clickedBtn);
    this.clearHistory();
    this.notSavedModal.hide();
  }

  private deleteFeature():void {
    const feature = this.currentFeatures[0];

    const pk = feature.properties[this.currentLayer.pk];
    if (!feature || pk === undefined) {
      this.delModal.hide();
      return;
    }

    const url = `geom/delete`;
    const params:HttpParams = new HttpParams().set('layer_id', this.currentLayer.id.toString()).set('pk', pk);

    this.httpClient.delete(url, {params}).subscribe(
      request => {
        this.currentLayer.refresh();
        this.delModal.hide();
      },
      error => {
        this.delModal.hide();
        this.exception = Utils.getErrorMsg(error);
      }
    );
  }

  private clearHistory():void {
    this.featureHistory = [];
  }

  private saveEdits():Observable<any> {
    const feature = this.featureHistory.slice(-1)[0];
    if (this.currentEditMode === 'Split') {
      return this.serverSplitFeature(feature, this.drawnFeature);
    } else {
      return this.saveFeature(feature);
    }
  }

  private saveFeature(feature:Feature):Observable<any> {
    // костыль для редактирования multipoint
    if (this.currentLayer.geomType === 'multipoint') {
      feature.geometry.type = 'MultiPoint';
      if (!Array.isArray(feature.geometry.coordinates[0])) {
        feature.geometry.coordinates = [feature.geometry.coordinates as number[]];
      }
    }

    // Для корректной конвертации в WKT меняем местами X и Y
    feature.invertCoordinates();

    // Обнулить пустые свойства (из инпутов возвращаются пустые строки, на которые ругается БД)
    Object.keys(feature.properties).forEach((key:string) => {
      if (feature.properties[key] === '' || feature.properties[key] == null) {
        feature.properties[key] = null;
      }
    });

    const pk = feature.properties[this.currentLayer.pk];
    const data:any = {
      layer_id: this.currentLayer.id,
      geom: Utils.geojsonToWKT(feature.geometry, feature.type),
      attributes: feature.properties
    };

    const url = `geom/create_or_update`;
    // pk может равняться 0, что считается валидным значением
    if (pk != null) {
      data['pk'] = pk;
    }

    // TODO: move all requests to service
    return this.httpClient.post(url, data, {observe: 'response'}).pipe(
      finalize(() => {
        feature.invertCoordinates();
      })
    );
  }

  private serverSplitFeature(poly:Feature, line:Feature):Observable<any> {
    const url = 'geom/split';
    const pkAttrName = Utils.getPkAttributeName(this.currentLayer);
    line.invertCoordinates();
    const data = {
      id: this.currentLayer.id,
      obj_id: poly.properties[pkAttrName],
      line: cloneDeep(line.geometry)
    };
    line.invertCoordinates();
    const ob = this.httpClient.post(url, data, {observe: 'response'});
    return ob;
  }

  private cloneFeatures(features:Feature[]) {
    return features.map(feature => cloneDeep(feature));
  }

  private resetFeatures() {
    const clone = this.cloneFeatures(this.originalFeatures);
    this.showEditableFeatures(clone);
    this.editInfo(clone[0]);
    return clone;
  }

  private clearDrawFeatures() {
    this.drawPlugins.forEach(plugin => {
      plugin.clearDrawFeatures();
    });
  }

  private stopDrawInPlugins() {
    this.drawPlugins.forEach(plugin => {
      plugin.stopDraw();
    });
  }

  private startDrawInPlugins(geometry:string) {
    this.drawPlugins.forEach(plugin => {
      plugin.startDraw(geometry, this as IDrawFinish);
    });
  }

  private startDraw(geometry:string) {
    this.drawing = true;
    this.startDrawInPlugins(geometry);
  }

  private hideAttributes():void {
    this.attrPlugins.forEach(plugin => {
      plugin.clearResults();
    });
  }

  private updateLayer(layer:any) {
    if (this.currentLayer) {
      (this.currentLayer as TMSLayer).isEdited = false;
    }
    this.currentLayer = layer;
    if (this.currentLayer) {
      (this.currentLayer as TMSLayer).isEdited = true;
    }
    this.adjustSearchPlugins(layer ? [layer] : []);
  }

  private switchToEditAndClearMap() {
    this.mode = 'Edit';
    this.toggleTools(this.activePlugins, 'on');
    this.enableBtn(this.editBtn);
    this.stopDraw();
    this.stopEdit();
  }

  private stopEdit() {
    this.clearEditedFeatures();
    this.clearHistory();
    this.currentFeatures = [];
    this.originalFeatures = null;
    this.clearFeaturesInPlugins();
    this.hideAttributes();
    this.clearResults();
  }

  private setFeaturesInPlugins(features:Feature[]) {
    this.featurePlugins.forEach(plugin => {
      plugin.setFeatures(features, true);
    });
  }

  private enablePlugins(isEnabled:boolean) {
    this.featurePlugins.forEach(plugin => {
      if (isEnabled) {
        ((plugin as any) as IContentChild).enable();
      } else {
        ((plugin as any) as IContentChild).disable();
      }
    });
  }

  private clearFeaturesInPlugins() {
    this.featurePlugins.forEach(plugin => {
      plugin.updateFeatures();
    });
  }

  private toggleTools(tools:ITool[], mode:string) {
    switch (mode) {
      case 'on':
        tools.forEach(tool => tool.activateTool());
        break;
      case 'off':
        tools.forEach(tool => tool.deactivateTool());
        break;
    }
  }

  private adjustSearchPlugins(list:ILayer[]) {
    this.layersStore.setEditLayers(list);
  }

  private proceedDeact() {
    this.adjustSearchPlugins([]);

    (this.dropMenu as DropMenuButtonComponent).getMenuBtn().setActive(false);
    this.innerMenu.deactivateBtns();

    this.clearFeaturesInPlugins();

    this.deactModal.hide();

    if (this.mode !== 'None') {
      this.hideAttributes();
      this.clearEditedFeatures();
      this.clearHistory();
      this.toggleTools(this.activePlugins, 'off');
      this.toggleTools(this.deactPlugins, 'on');

      if (this.drawing) {
        this.stopDraw();
      } else {
        this.currentFeatures = [];
        this.originalFeatures = null;
        this.clearResults();
      }
    }

    if (this.currentLayer) {
      (this.currentLayer as TMSLayer).isEdited = false;
    }
    this.currentLayer = null;

    this.mode = 'None';
    this.active = false;
  }

  private activateEdit() {
    if (this.activator && !this.active) {
      return this.activator.activateComponent(this as ITool);
    }
    return Promise.resolve();
  }

  private saveError(ex:HttpErrorResponse):Observable<Error> {
    this.saveModal.hide();
    this.exception = Utils.getErrorMsg(ex);
    return observableThrowError(ex);
  }
}
