import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { SearchOption, SearchType } from 'mobile/src/app/components/shared';
import { Feature, Image, PluginClass, Point } from '../..//classes';
import {
  IClearData,
  IContainer,
  IContentChild,
  IEdit,
  ILayer,
  ILightBox,
  IMapResult,
  IPluginInterface,
  IPopup,
  ISearchResult,
  IToolFeature
} from '../../interfaces';
import { MapService } from '../../services/map.service';
import {SingleResultComponent} from './single_result/single_result.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'search-result',
  templateUrl: 'search_results.component.html',
  styleUrls: ['search_results.component.less']
})
export class SearchResultsComponent extends PluginClass implements ISearchResult, IContentChild, OnInit {
  @ViewChild('scrollbar') scrollbar:ElementRef;

  parentComponent:IContainer;
  results:Feature[] = [];
  addresses:Feature[] = [];
  layersResult:ILayer[] = [];
  allResults = 0;
  curFeature:Feature;
  editing = false; // флаг редактирования объекта
  active = false;
  editable = true;
  showEmptyAttributes = false;
  params:SearchOption[] = [];
  showAddressIntersect = false;
  paramsMap:{ [K in SearchType]: SearchOption };

  // Массивы плагинов
  toolsForFeature:IToolFeature[] = [];

  protected lightBoxPlugins:ILightBox[] = [];

  private showResPlugins:ISearchResult[] = []; // плагины для отображения информации по объекту
  private mapResultsPlugs:IMapResult[] = [];
  private popupPlugins:IPopup[] = [];
  private featureToolPlugins:IToolFeature[] = [];
  private editFeaturePlugins:IEdit[] = [];
  private clearDataPlugins:IClearData[] = [];
  private hideEventHandle:Subscription;

  constructor(private mapService:MapService) {
    super();
  }

  ngOnInit() {
    this.paramsMap = this.params.reduce((acc:any, value:SearchOption) => ({ ...acc, ...{ [value.type]: value } }), {});
  }

  activate() {
    this.active = true;
    if (this.parentComponent) {
      this.parentComponent.open();
      if (!this.hideEventHandle) {
        this.hideEventHandle = this.parentComponent.onHide.subscribe(() => {
          for (const plug of this.mapResultsPlugs) {
            plug.clearResult();
          }
        });
      }
    }
  }

  deactivate() {
    if (this.scrollbar) {
      this.scrollbar.nativeElement.getElementsByClassName('scroll-container')[0].scrollTop = 0;
    }
    this.active = false;
    if (this.parentComponent) {
      this.parentComponent.close();
    }
  }

  enable() {}

  disable() {}

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'MapResults':
        this.mapResultsPlugs.push(pi as IMapResult);
        break;
      case 'Popup':
        this.popupPlugins.push(pi as IPopup);
        break;
      case 'FeatureTool':
        this.featureToolPlugins.push(pi as IToolFeature);
        break;
      case 'EditFeature':
        this.editFeaturePlugins.push(pi as IEdit);
        break;
      case 'ShowResult':
        this.showResPlugins.push(pi as ISearchResult);
        break;
      case 'ClearData':
        this.clearDataPlugins.push(pi as ISearchResult);
        break;
      case 'LightBox':
        this.lightBoxPlugins.push(pi as ILightBox);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'MapResults':
        this.mapResultsPlugs = [];
        break;
      case 'Popup':
        this.popupPlugins = [];
        break;
      case 'FeatureTool':
        this.featureToolPlugins = [];
        break;
      case 'EditFeature':
        this.editFeaturePlugins = [];
        break;
      case 'ShowResult':
        this.showResPlugins = [];
        break;
      case 'ClearData':
        this.clearDataPlugins = [];
        break;
      case 'LightBox':
        this.lightBoxPlugins = [];
        break;
    }
  }

  get backAvailable():boolean {
    return !!this.curFeature && this.results.length > 0;
  }

  setFeatures(features:Feature[] = [], showPlace = true, layers:ILayer[] = []) {
    this.curFeature = null;
    this.activate();
    this.editing = false;
if (this.paramsMap.layer.active) {
      this.layersResult = layers;
    }
    if (this.paramsMap.objects && this.paramsMap.objects.active) {
      this.results = features.filter(feature => feature.layer.name !== 'Адресный поиск');
    }
 if (this.paramsMap.address.active) {
      this.addresses = features.filter(feature => feature.layer.name === 'Адресный поиск');
    }
    this.allResults = this.layersResult.length + this.results.length + this.addresses.length;
  }

  getFeatures():Feature[] {
    return;
  }

  updateFeatures(features:Feature[]) {}

  activateOtherTool(tool:IToolFeature, feature:Feature) {
    tool.activateToolFeature(feature);
  }

  showInfo(feature:Feature) {
    this.results = [];
    this.prepareInfo(feature);
    this.editing = false;
  }

  editInfo(feature:Feature) {
    this.prepareInfo(feature);
    this.editing = true;
    this.results = [];
  }

  clearResults():void {
    this.onClose();
  }

  // закрытие окна
  onClose() {
    this.deactivate();
    this.clearMapFeatures();
    this.closePopups();
    this.results = [];

    // чистим результат во всех связанных сомпонентах
    this.clearDataPlugins.forEach(plug => {
      plug.clearResults();
    });

    // очищаем карту
    this.mapService.clearMap();
  }

  // шаг назад ко всем результатам
  back() {
    this.curFeature = null;
    this.clearMapFeatures();
    this.closePopups();
  }

  showLightBox(event:{ items:Image[]; item:Image | Feature }) {
    this.lightBoxPlugins.forEach(plug => {
      plug.showImages(event.items, event.item as Image);
    });
  }

  // Клик по названию слоя
  featureClicked(feature:Feature) {
    // Показать информацию об объекте
    this.prepareInfo(feature);
    this.editing = false;

    // Приблизиться к объекту и поставить маркер
    this.mapResultsPlugs.forEach(plug => {
      plug.showFeature([feature]).then(data => {
        plug.goToFeature(feature).then((point:Point) => {
          plug.setMarker(point);
        });
      });
    });

    this.closePopups();
  }

  showFeatureInfo(feature:Feature) {
    this.featureClicked(feature);
  }

  showFeature(feature:Feature) {
    this.showSingleResult(feature);

    this.mapResultsPlugs.forEach(plug => {
      plug.goToFeature(feature);
    });
  }

  // приблизится к объекту
  gotoFeature(feature:Feature) {
    this.mapResultsPlugs.forEach(plug => {
      plug.goToFeature(feature);
      plug.showFeature([feature]);
    });
  }

  // приблизится к слою
  goToLayer(layer:ILayer) {
    if (!layer.extent) {
      return;
    }
    this.mapService.changeMapBound$.next(layer.extent);
  }

  // Апдейтит объект в плагинах редактирования при изменении полей в атрибутах.
  updateAttributes(data:{ feature:Feature; attr?:any; event?:any }) {
    const { feature, attr, event } = data;
    // Для boolean устанавливаем значение атрибута вручную
    if (attr && attr.inputType === 'checkbox') {
      this.curFeature.properties[attr.name] = event.target.checked;
    }

    this.editFeaturePlugins.forEach(plugin => {
      plugin.updateAttributes(feature);
    });
  }

  backToList() {
    this.curFeature = null;
  }

  // удалить выделение на карте
  private clearMapFeatures() {
    this.mapResultsPlugs.forEach(plug => {
      plug.clearResult();
    });
  }

  private showSingleResult(feature:Feature) {
    // Приблизиться к объекту, поставить маркер, открыть попап
    this.showResPlugins.forEach(plugin => {
      plugin.setFeatures([feature], true);
    });
  }

  private closePopups() {
    this.popupPlugins.forEach(plugin => {
      plugin.closePopup();
    });
  }

  private prepareInfo(feature:Feature) {
    this.activate();
    this.curFeature = feature;

    // доп инструменты для фичи
    this.toolsForFeature = this.featureToolPlugins.filter(item => item.checkFeature(feature));
  }
}
