import { Pipe, PipeTransform } from '@angular/core';
import * as linkifyHtml from 'linkifyjs/html';

@Pipe({ name: 'linkify' })
export class LinkifyPipe implements PipeTransform {
  transform(value:string | number):string | number {
    if (!value || typeof value === 'number') {
      return value;
    }
    return linkifyHtml(value);
  }
}
