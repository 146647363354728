import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output, ViewChild
} from '@angular/core';
import {LazyLoadEvent, SortEvent} from 'primeng/api';
import {Attribute} from 'shared/classes';
import {JoinedTable} from '../../../shared/classes/JoinedTable';
import {ITableManager} from '../../classes/tableManager';
import {Table} from 'primeng/table';
import {Observable} from 'rxjs';
import {MessageService} from '../../services';

export interface QueryDataOptions {
  source:Component;
  page:number;
  pageSize:number;
  sortOrder:{attr:string, direction:string};
  joinedTable?:JoinedTable;
}

export  interface DataToSave {
  pk:any;
  rowItem:any;
}

interface RowCache {
  visible:boolean;
  source?:Component;
}

const INPUT_SELECTOR = 'input,select';

@Component({
  selector: 'editable-grid',
  templateUrl: 'editable-grid.component.html',
  styleUrls: ['editable-grid.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableGridComponent {
  @Input() tableId:number; //layerId ot joinedTable id
  @Input() tableType:string;
  @Input() paginator:boolean;
  @Input() editable:boolean;
  @Input() joinedTables:JoinedTable[] = [];
  @Input() tableManager:ITableManager;

  @Output() selectRows = new EventEmitter<any[]>();
  @Output() queryData = new EventEmitter<QueryDataOptions>();
  @Output() openJoinedTable = new EventEmitter<JoinedTable>();

  @ViewChild('table') table:Table;

  loading = false;

  sortOrder:{attr:string, direction:string} = null;
  totalRecords:number;
  page = 1;
  frozenCols:any[] = [];
  selectedRows:any[] = [];
  editingRow:{ rowData:any, input:HTMLInputElement, rowElement:HTMLElement };

  scrollHeight = 700;
  pageSize = 30;
  rowsPerPage:number[] = [10, 20, 30, 50, 100];

  private joinedTable:JoinedTable;
  private _tableItems:any[] = [];
  private _columns:Attribute[] = [];
  private _changedColumns:Object = {};
  private _sortColumnState = {column: '', count: 0};

  constructor(private _el:ElementRef, private changeDetectorRef:ChangeDetectorRef,
              private messageService:MessageService) {
  }

  @Input()
  set columns(columns:Attribute[]) {
    this._columns = columns;
    this.frozenCols = [
      {
        name: 'ng-idx',
        alias: '№',
        editable: false
      }
    ];
  }

  get columns():Attribute[] {
    return this._columns;
  }

  get visibleColumns():Attribute[] {
    return this.columns.filter(column => column.inAttr);
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement:HTMLElement) {
    let isClickedInsideTable = false;
    const bodyElements = (this.table.el.nativeElement as HTMLElement).querySelectorAll('.ui-table-scrollable-body');
    bodyElements.forEach(element => isClickedInsideTable = isClickedInsideTable || element.contains(targetElement));
    if (!isClickedInsideTable) {
      this.selectedRows = [];
    }
    if (this.editingRow) {
      const rowData = this.editingRow.rowData;
      const tr = this.editingRow.rowElement;
      const isClickedInside = tr.contains(targetElement);
      if (!isClickedInside) {
        if (rowData.__dirty) {
          this.saveData(rowData).subscribe(success => {
            if (success) {
              this.editingRow = null;
              this.changeDetectorRef.detectChanges();
              this.alignFrozenRows();
            }
          });
        } else if (rowData.__new) {
          this.purgeNewRow();
          this.editingRow = null;
          this.changeDetectorRef.detectChanges();
          this.alignFrozenRows();
        }
      }
    }
  }

  onRowUnselect(evt:any) {
  }

  onSort(evt:SortEvent) {
    //Сброс сортировки после третьего нажатия на колонку
    if (this._sortColumnState.column !== evt.field) {
      this._sortColumnState.column = evt.field;
      this._sortColumnState.count = 0;
    }
    this._sortColumnState.count++;
    if (this._sortColumnState.count > 2) {
      this._sortColumnState.count = 0;
      this.table.sortOrder = 0;
      this.table.sortField = '';
      this.table.reset();
    }
  }

  updateData() {
    this._changedColumns = {};
    this.editingRow = null;
    this.getTable();
  }

  setTableData(data:{ table:any[], meta:{ count:number } }, queryOptions:QueryDataOptions) {
    this.loading = false;

    this.joinedTable = queryOptions.joinedTable;
    this.totalRecords = data.meta.count;
    this._tableItems = data.table;
    // добавление индекса строки для отображения в первом столбце
    this._tableItems.forEach((item, idx) => {
      item['ng-idx'] = idx + 1 + (queryOptions.page - 1) * queryOptions.pageSize;
    });
    // HACK
    // при загрузке таблицы верстка пагинатора ломается из-за фиксированного первого столбца,
    // поэтому откладываем рендеринг пагинатора на произвольное время
    setTimeout(() => {
      // таймаут, т.к. ожидаем рендера DOM
      this.scrollHeight = this._el.nativeElement.closest('.resizable-content').offsetHeight - 115;
      this.changeDetectorRef.detectChanges();
    }, 500);
  }

  addNewRow() {
    const newRowData:any = {__new: true};
    for (const column of this._columns) {
      if (!column.is_pk) {
        if (column.type === 'string') {
          newRowData[column.name] = '';
        } else {
          newRowData[column.name] = null;
        }
      }
      if (column.name === 'data_fid') {
        newRowData[column.name] = this.joinedTable.value;
      }
    }
    this._tableItems.unshift(newRowData);
    setTimeout(() => {
      const firstCell = this._el.nativeElement.querySelector('.ui-table-unfrozen-view > .ui-table-scrollable-body > .ui-table-scrollable-body-table > .ui-table-tbody > tr > td');
      this.setCellEditing(firstCell, newRowData);
    }, 0);
  }

  purgeNewRow() {
    if (this._tableItems[0] && this._tableItems[0].__new) {
      this._tableItems.splice(0, 1);
    }
  }

  get tableItems():any[] {
    return this._tableItems;
  }

  //align frozen and unfrozen rows
  alignFrozenRows() {
    setTimeout(() => {
      const frozen = this._el.nativeElement.querySelectorAll('.ui-table-frozen-view > .ui-table-scrollable-body > .ui-table-scrollable-body-table > .ui-table-tbody > tr');
      const unfrozen = this._el.nativeElement.querySelectorAll('.ui-table-unfrozen-view > .ui-table-scrollable-body > .ui-table-scrollable-body-table > .ui-table-tbody > tr');
      for (let i = 0; i < frozen.length; i++) {
        (<any>frozen[i]).style.height = unfrozen[i].clientHeight + 'px';
      }
    }, 500);
  }

  getTable(event?:LazyLoadEvent) {
    this.loading = true;

    const page = (event ? event.first / event.rows : 0) + 1;
    const pageSize = event ? event.rows : this.pageSize;
    const sortOrder = (event && event.sortField) ?
      {attr: event.sortField, direction: event.sortOrder === 1 ? 'desc' : 'asc'} : undefined;
    this.sortOrder = sortOrder;
    this.queryData.emit({source: this as Component, page: page, pageSize: pageSize, sortOrder: sortOrder});
  }

  onJoinedTableClick(joinedTable:JoinedTable, rowData:any) {
    const keyValue = rowData[joinedTable.column];
    joinedTable.value = keyValue;
    this.openJoinedTable.emit(joinedTable);
  }

  getColumnAlias(column:Attribute):string {
    return column.alias ? column.alias : column.name;
  }

  getRowCell(rowData:any, column:Attribute):any {
    let ret:any;
    if (column.dictionary) {
      const entry = column.dictionary.find(item => item.key == rowData[column.name]);
      return entry ? entry.value : null;
    } else {
      ret = rowData[column.name];
    }
    return ret;
  }

  onRowDblClick(evt:MouseEvent, rowData:Object) {
    const cell = evt.currentTarget as HTMLTableCellElement;
    this.setCellEditing(cell, rowData);
  }

  setCellEditing(cell:HTMLElement, rowData:Object) {
    this.editingRow = {rowData: rowData, rowElement: cell.parentElement as HTMLElement, input: null};
    setTimeout(() => {
      const input = cell.querySelector(INPUT_SELECTOR) as HTMLInputElement;
      if (input) {
        input.focus();
        this.editingRow.input = input;
      }
    }, 500);
    this.alignFrozenRows();
  }

  isCellInEditMode(rowData:Object, column:Attribute):boolean {
    const ret = this.editingRow && this.editingRow.rowData === rowData && !column.is_pk
      && column.editable && column.name !== 'data_fid';
    return ret;
  }

  onInputTab(evt:KeyboardEvent, rowIndex:number, columnIndex:number) {
    const rowItem = this.tableItems[rowIndex];
    const visibleColumns = this.visibleColumns;
    let column = visibleColumns[columnIndex];
    const input = evt.target as HTMLInputElement;
    const htmlTable = input.parentElement.parentElement.parentElement as HTMLTableElement;
    if (rowItem[column.name] != this.getValue(input)) {
      this.tableItems[rowIndex].__dirty = true;
      this.setValue(rowItem, column, input);
      this._changedColumns[column.name] = true;
    }

    let columnIndexNext:number;
    for (let i = columnIndex + 1; i < visibleColumns.length; i++) {
      column = visibleColumns[i];
      if (!column.is_pk && column.editable) {
        columnIndexNext = i;
        break;
      }
    }

    if (!columnIndexNext) {
      if (rowItem.__dirty) {
        //сохранение строки
        this.saveData(rowItem).subscribe(success => {
          if (success) {
            //переход на след строку, если она есть
            this.editNextRow(rowIndex + 1, visibleColumns, htmlTable);
          }
        });
      } else if (rowItem.__new) {
        this.purgeNewRow();
      } else {
        this.editNextRow(rowIndex + 1, visibleColumns, htmlTable);
      }
    }
    this.alignFrozenRows();
  }

  editNextRow(rowIndexNext:number, columns:Attribute[], htmlTable:HTMLTableElement) {
    let columnIndexNext:number;
    if (rowIndexNext < this.tableItems.length) {
      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        if (!column.is_pk && column.editable) {
          columnIndexNext = i;
          this.editingRow = {
            rowData: this.tableItems[rowIndexNext],
            rowElement: htmlTable.rows[rowIndexNext],
            input: null
          };
          this.changeDetectorRef.detectChanges();
          break;
        }
      }
      setTimeout(() => {
        const input = htmlTable.rows[rowIndexNext].cells[columnIndexNext].querySelector(INPUT_SELECTOR) as HTMLInputElement;
        if (input) {
          input.focus();
          this.editingRow.input = input;
        }
      }, 100);
    } else {
      this.editingRow = null;
      this.changeDetectorRef.detectChanges();
    }
  }

  onLostFocus(evt:FocusEvent, rowData:Object, column:Attribute) {
    const input = evt.currentTarget as HTMLInputElement;
    if (rowData[column.name] != this.getValue(input)) {
      rowData['__dirty'] = true;
      this.setValue(rowData, column, input);
      this._changedColumns[column.name] = true;
    }
  }

  //method to override in calling code
  public saveData(rowData:any):Observable<boolean> {
    const pk:Object = {};
    const savingData:Object = {};
    for (const column of this._columns) {
      if (column.is_pk) {
        pk[column.name] = rowData[column.name];
      } else if (column.name === 'data_fid') {
        //adding reference column if record is new
        if (rowData.__new) {
          savingData[column.name] = rowData[column.name];
        }
      } else if (this._changedColumns[column.name]) {
        savingData[column.name] = rowData[column.name];
      }
    }
    return new Observable(subscriber => {
      if (rowData.__new) {
        this.tableManager.addData(this.tableId, this.tableType, savingData).subscribe(response => {
          rowData['__dirty'] = false;
          this._changedColumns = {};
          subscriber.next(true);
        }, err => {
          this.messageService.openAlertWindow('Строка не может быть сохранена!', ['Закрыть'], 0)
            .subscribe(idx => subscriber.next(false));
        });
      } else {
        this.tableManager.saveData(this.tableId, this.tableType, pk, savingData).subscribe(response => {
          rowData['__dirty'] = false;
          this._changedColumns = {};
          subscriber.next(true);
        }, err => {
          this.messageService.openAlertWindow('Строка не может быть сохранена!', ['Закрыть'], 0)
            .subscribe(idx => subscriber.next(false));
        });
      }
    });
  }

  private setValue(rowData:any, column:Attribute, input:HTMLInputElement) {
    let value:any;
    if (column.dictionary) {
      value = input.value;
    } else {
      switch (column.inputType) {
        case 'number':
          value = input.valueAsNumber;
          break;
        case 'checkbox':
          value = !!input.checked;
          break;
        case 'date':
          value = input.valueAsDate ? input.valueAsDate.toLocaleDateString('fr-CA') : null;
          break;
        default:
          value = input.value;
      }
    }
    rowData[column.name] = value;
  }

  private getValue(input:HTMLInputElement):any {
    switch (input.type) {
      case 'number':
        return input.value ? input.valueAsNumber : null;
      case 'checkbox':
        return !!input.checked;
      case 'date':
        return input.valueAsDate ? input.valueAsDate.toLocaleDateString('fr-CA') : null;
      default:
        return input.value;
    }
  }
}
