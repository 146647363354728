import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {Bounds, Feature, PluginClass} from 'shared/classes';
import { Button } from 'shared/components';
import {
  IContainer,
  IContentChild,
  ILayer, IMap,
  IMapOptions,
  IMenu,
  IPluginInterface, ISearch,
  ISimpleMap,
  IToolFeature
} from 'shared/interfaces';
import { LayersStore } from 'shared/stores/LayersStore';
import { Permalink } from './models';
import {filter, first} from 'rxjs/operators';

@Component({
  selector: 'permalink',
  templateUrl: 'permalink.component.html',
  styleUrls: ['permalink.component.less']
})
export class PermalinkComponent extends PluginClass implements IContentChild, IToolFeature, OnInit {
  parentComponent:IContainer;
  projectLink = '';
  iframeLink = '';

  activateMode:string = null;
  toolName = 'Ссылка на объект';
  className = '';
  groupName:string;

  private url = '';
  private activeLayers:ILayer[] = [];
  private btn:Button = null;
  private mapPlugin:IMapOptions;
  private searchPlugin:ISearch;
  private menu:IMenu;
  private iframeWidth = 560;
  private iframeHeight = 400;

  constructor(private httpClient:HttpClient, private layersStore:LayersStore) {
    super();

    layersStore.getActiveLayers().subscribe(data => {
      this.activeLayers = data;
    });
  }

  ngOnInit() {
  }

  clipboardCopy(textbox:HTMLInputElement) {
    textbox.select();
    textbox.setSelectionRange(0, 99999); //For mobile devices
    document.execCommand('copy');
  }

  activate() {
    if (this.parentComponent) {
      this.parentComponent.open();
    }
    if (!this.mapPlugin) {
      console.error('Отсутсвует соединение с картой');
      return;
    }

    this._createPermalink();
  }

  deactivate() {
  }

  enable() {
  }

  disable() {
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'SearchEngine':
        this.searchPlugin = pi as ISearch;
        break;
      case 'Map':
        this.mapPlugin = pi as IMapOptions;
        (pi as ISimpleMap).loaded.subscribe(() => this.onMapLoaded());
        break;
      case 'Menu':
        this.menu = pi as IMenu;
        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.buttonConfig.onClickMe = () => {
            this.activate();
          };
          this.buttonConfig.onDeactivate = () => {
            this.deactivate();
          };
          this.btn.setOptions(this.buttonConfig);
          this.btn.position = this.buttonConfig.position;
          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Map':
        this.mapPlugin = null;
        break;
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  getGroup():string {
    return null;
  }

  deactivateTool():Promise<boolean> {
    return new Promise<boolean>(resolve => resolve(true));
  }

  activateTool() {
  }

  isActive():boolean {
    return false;
  }

  activateToolFeature(feature:Feature) {
    if (this.parentComponent) {
      this.parentComponent.open();
    }
    let url = location.protocol + '//' + location.host + location.pathname;
    url += `?layer-id=${feature.layer.id}&obj-id=${encodeURIComponent(feature.getIdentConstarint())}`;
    url += '&layers=' + this.getVisibleLayerIds();

    this.projectLink = url;
    this.iframeLink = this.generateFramelink(url);
  }

  checkFeature(feature:Feature):boolean {
    return !!feature;
  }

  private getVisibleLayerIds():string {
    return this.activeLayers.reduce((accumulator:string, item:ILayer) => {
      return accumulator + (item.visible ? (',' + item.id) : '');
    }, '').substring(1);
  }

  private onMapLoaded() {
    if (this.activeLayers[0]) {
      this.applyLink();
    } else {
      const subs = this.layersStore.getActiveLayers()
        .pipe(
          filter(data => !!data[0]),
          first()
        )
        .subscribe(data => this.applyLink());
    }
  }

  private applyLink() {
    const urlParams = new URLSearchParams(window.location.search);
    const layerId = parseInt(urlParams.get('layer-id'), 10);
    const objId = urlParams.get('obj-id');
    const layersParam = urlParams.get('layers');
    if (layersParam) {
      const layers = layersParam.split(',');
      for (const layer of this.activeLayers) {
        layer.visible = layers.includes(layer.id.toString());
        if (layer.visible && !layer.isGroup) {
          layer.checked = true;
        }
      }
    }
    if (layerId && objId) {
      let pair = objId.split('and');
      pair = pair[0].split('=');
      const layer = this.activeLayers.find(lr => lr.id === layerId);
      this.searchPlugin.searchId(layer, pair[0], pair[1], true);
    }
  }

  private _createPermalink() {
    const permalink:Permalink = new Permalink();

    // экстент
    const b:Bounds = this.mapPlugin.getBounds();
    permalink.extent = `${b.ymin.x.toFixed(4)},${b.ymin.y.toFixed(4)},${b.ymax.x.toFixed(4)},${b.ymax.y.toFixed(4)}`;

    // список слоёв
    permalink.layers = this.activeLayers.map(layer => {
      return {
        id: layer.id,
        order: layer.order,
        brightness: layer.brightness,
        opacity: layer.opacity,
        contrast: layer.contrast
      };
    });

    // createPermalink
    this.httpClient.post<any>(this.url, permalink).subscribe(data => {
      this.projectLink = data.url + '&layers=' + this.getVisibleLayerIds();
      this.iframeLink = this.generateFramelink(data.url + '&layers=' + this.getVisibleLayerIds());
    });
  }

  private generateFramelink(url:string) {
    return `<iframe src='${url}&iframe=true' width='${this.iframeWidth}' height='${
      this.iframeHeight
      }' frameborder='0'></iframe>`;
  }
}
