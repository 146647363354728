import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataFilter } from 'shared';

@Injectable()
export class FilterService {
  constructor(private http:HttpClient) {}

  getFilterSql(filter:DataFilter) {
    return this.http.post<{ sql_filter:string }>('get_sql_filter/', { filter }).pipe(map(value => value.sql_filter));
  }
}
