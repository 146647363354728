import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';

@Injectable()
export class Creator {
  constructor(private cfr:ComponentFactoryResolver) {}

  createComponent(component:any, container:ViewContainerRef):Promise<ComponentRef<any>> {
    return new Promise((resolve, reject) => {
      const compFactory = this.cfr.resolveComponentFactory(component);
      if (!container) {
        reject(`Не указан контейнер для создания компонента ${component.name}!`);
      }
      const cmpRef = container.createComponent(compFactory);
      resolve(cmpRef);
    });
  }
}
