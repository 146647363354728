import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { PluginClass } from '../../classes/Plugin';
import { Utils } from '../../classes/Utils';
import { IContainer, IContentChild, IMenu, IPluginInterface } from '../../interfaces';
import { Creator } from '../../services/creator.service';
import { Button } from '../button/button.component';

@Component({
  selector: 'getmap-menu',
  template: `
    <div *ngIf="title" class="menu-title">
      <span>{{ title }}</span>
    </div>
    <div #btns></div>
    <ng-content></ng-content>
  `
})
export class MenuComponent extends PluginClass implements IMenu, AfterContentInit {
  @Input() id:string;
  @Input() singleMode = true; // в меню может быть активна только 1 кнопка статусом
  @Input() toogleMode = true; // разрешать деактивацию активных клавиш
  @Input() btnType:'button' | 'switcher' = 'button'; // тип кнопок в меню (button - обычные, switcher - переключатели)
  @Input() title:string = null;

  @ViewChild('btns', { read: ViewContainerRef }) buttonsDiv:ViewContainerRef;

  @Output() onChildClick = new EventEmitter();
  @Output() onShow = new EventEmitter();
  @Output() onHide = new EventEmitter();

  @ContentChildren(Button) existingBtns:QueryList<Button>;

  btns:Button[] = []; // массив добавленных клавиш
  parentComponent:IContainer;

  protected curBtn:Button;

  constructor(protected creator:Creator, protected el:ElementRef) {
    super();
  }

  ngAfterContentInit() {
    // добавляем кнопки из шаблона
    if (this.existingBtns) {
      this.existingBtns.forEach(btn => {
        this.addBtn(btn);
      });
    }
  }

  addInterface(name:string, pi:IPluginInterface) {
    console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
  }

  removeInterface(name:string) {}

  enable() {}

  disable() {}

  createBtn():Promise<Button> {
    return new Promise(resolve => {
      this.createComponent(Button)
        .then(btn => {
          btn.instance.type = this.btnType;
          resolve(btn);
        })
        .catch(ex => {
          console.error(`Ошибка при создании кнопки в MenuComponent: ${ex}`);
        });
    });
  }

  removeBtn(btn:Button) {
    this.btns.forEach((item:Button) => {
      if (item === btn) {
        Utils.removeElement(btn.domNode);
      }
    });
  }

  addBtn(btn:Button) {
    if (!btn) {
      return;
    }

    btn.btnClick = () => {
      if (btn.btnDisabled) {
        return;
      }

      this.childClick(btn);

      if (this.btnType !== 'switcher') {
        if (this.curBtn === btn && this.toogleMode && btn.activeMode && btn.isActive()) {
          btn.setActive(false);
          this.curBtn = null;
          return;
        }

        if (btn.activeMode) {
          this.curBtn = btn;
        }

        btn.setActive(true);
      } else {
        const active:boolean = !btn.isActive();
        btn.setActive(active);
      }
    };

    this.btns.push(btn);
  }

  // деактивировать все кнопки в меню
  deactivateBtns() {
    // деактивируем  все клавиши в меню
    this.btns.forEach((b:Button) => {
      b.setActive(false);
    });

    this.curBtn = null;
  }

  childClick(btn:Button) {
    if (!btn.btnDisabled) {
      this.onChildClick.emit('');
    }
    // если режим одной кнопки и нажимаем кнопку со статусом
    // отключаем остальные статусные кнопки
    if (btn.activeMode && !btn.isActive() && this.singleMode) {
      // получаем все кнопки, у которых activeMode = true
      const btns:Button[] = this.btns.filter((b:Button) => {
        return btn !== b && btn.activeMode;
      });

      // деактивируем их
      btns.forEach((b:Button) => {
        b.setActive(false);
      });
    }
  }

  open() {}

  close() {}

  isOpen() {
    return true;
  }

  addChild(child:IContentChild) {}

  createComponent(component:any):Promise<any> {
    return this.creator.createComponent(component, this.buttonsDiv);
  }

  getContainer() {
    return this.buttonsDiv;
  }

  activate() {}

  deactivate() {}
}
