import { Component, ElementRef, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Utils } from '../../classes/Utils';
import { IButtonConfig } from '../../interfaces';

@Component({
  selector: 'btn',
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.less']
})
export class Button implements IButtonConfig {
  @HostBinding('class') class = 'button';
  @HostBinding('class.btnDisabled')
  get disabled() {
    return this.btnDisabled;
  }

  @Input() className:string;
  @Input() title:string;
  @Input() text:string;
  @Input() iconText:string;
  @Input() action:string;
  @Input() activeMode = false;
  @Input() type = 'button';
  @Input() btnDisabled = false;

  @Output() onBtnDeactivate = new EventEmitter();
  @Output() onBtnActivate = new EventEmitter();

  domNode:HTMLElement;
  menuLvl:number;
  visible = true;

  active = false;
  private _position:number;

  constructor(el:ElementRef) {
    this.domNode = el.nativeElement as HTMLElement;
  }

  get position() {
    return this._position;
  }

  set position(pos:number) {
    this._position = pos;
    const parent:HTMLElement = this.domNode.parentElement;
    let newPos = pos;

    // если эта кнопка встречается, до новой позиции
    // увеличиваем позицию на 1
    for (let x = 0; x < pos; x++) {
      if (parent.children[x] === this.domNode) {
        newPos += 1;
      }
    }

    if (parent.children[newPos]) {
      parent.insertBefore(this.domNode, parent.children[newPos]);
    } else {
      parent.appendChild(this.domNode);
    }
  }

  btnClick() {
    if (!this.btnDisabled) {
      this.active = !this.active;
      this.setActive(this.active);
    }
  }

  onClickMe():void {
    this.onBtnActivate.emit('');
  }

  onDeactivate():void {
    this.onBtnDeactivate.emit('');
  }

  isActive():boolean {
    return this.active;
  }

  setActive(active:boolean) {
    // Игнорировать клик по выключенной кнопке
    if (this.btnDisabled) {
      return;
    }
    this.active = active;
    if (!active) {
      this.onDeactivate();
    } else {
      this.onClickMe();
    }
  }

  setOptions(otherButton:IButtonConfig):void {
    Utils.mixin(this, otherButton);
  }
}
