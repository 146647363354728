import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Creator } from '../../services';
import { Button } from '../button/button.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'drop_menu_btn',
  template: `
    <btn #btn [className]="className" [activeMode]="true" [title]="title" [btnDisabled]="btnDisabled" (click)="fireDeactivate()"></btn>
    <div class="drop-menu" [style.display]="btn.active ? 'block' : 'none'">
      <div #btns class="nav nav-btns tip-container"><div class="tip"></div></div>
      <ng-content></ng-content>
    </div>
  `
})
export class DropMenuButtonComponent extends MenuComponent implements AfterContentInit {
  @HostBinding('class') class = 'drop-menu-button';
  @ViewChild('btns', { read: ViewContainerRef }) buttonsDiv:ViewContainerRef;
  @ViewChild('btn') menuBtn:Button;

  @Input() className:string;
  @Input() title:string;
  @Input() id:string;
  @Input() btnDisabled:boolean;
  @Input() singleMode = true; // в меню может быть активна только 1 кнопка статусом
  @Input() hideOnClickOutside = false;

  @Output() onShowMenu = new EventEmitter();
  @Output() onBtnDeactivate = new EventEmitter();
  @Output() onChildClick = new EventEmitter();

  @Output() onShow = new EventEmitter();
  @Output() onHide = new EventEmitter();

  @ContentChildren(Button) existingBtns:QueryList<Button>;

  constructor(creator:Creator, el:ElementRef) {
    super(creator, el);
  }

  ngAfterContentInit() {
    const self = this;

    this.menuBtn.onDeactivate = () => {
      self.deactivateBtns();
    };

    super.ngAfterContentInit();
  }

  set position(pos:number) {
    const domNode = this.el.nativeElement;
    const parent:HTMLElement = domNode.parentElement;
    let newPos = pos;

    // если эта кнопка встречается, до новой позиции
    // увеличиваем позицию на 1
    for (let x = 0; x < pos; x++) {
      if (parent.children[x] === domNode) {
        newPos += 1;
      }
    }

    if (parent.children[newPos]) {
      parent.insertBefore(domNode, parent.children[newPos]);
    } else {
      parent.appendChild(domNode);
    }
  }

  getMenuBtn():Button {
    return this.menuBtn as Button;
  }

  fireDeactivate() {
    if (this.menuBtn.isActive()) {
      return;
    }
    this.onBtnDeactivate.emit('');
  }

  @HostListener('document:mousedown', ['$event.target'])
  outSideClick(targetElement:HTMLElement):void {
    if (!targetElement || !this.hideOnClickOutside) {
      return;
    }

    if (!this.el.nativeElement.contains(targetElement)) {
      this.menuBtn.setActive(false);
    }
  }
}
