import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[onClickOutside]'
})
export class ClickOutsideDirective {
  @Output() onClickOutside:EventEmitter<MouseEvent> = new EventEmitter();

  constructor(private _elementRef:ElementRef) {}

  @HostListener('document:click', ['$event', '$event.target'])
  onClick($event:MouseEvent, targetElement:HTMLElement) {
    const isClickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!isClickedInside) {
      this.onClickOutside.emit($event);
    }
  }
}
