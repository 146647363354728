import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, Observable, Observer, of } from 'rxjs';
import { ILayer } from '../interfaces';
import { Image as GeoImage } from './Attachment';
import { Bounds } from './Bounds';
import { Point } from './Point';
import {Attribute} from 'shared';

export class Browser {
  name:string;
  version:string;
}

export class Utils {
  // del element from dom
  static removeElement(dom:HTMLElement) {
    // if IE and Opera
    const b:Browser = Utils.getBrowzerInfo();
    if (b.name.toLocaleLowerCase() === 'msie') {
      if (dom.parentNode) {
        dom.parentNode.removeChild(dom);
      }
      return false;
    }
    dom.remove();
  }

  static getFuncName(func:any):string {
    func = func as () => any;
    // for IE11
    const b:Browser = Utils.getBrowzerInfo();
    if (b.name.toLocaleLowerCase() === 'msie' && b.version === '11.0') {
      return func.toString().match(/^function\s*([^\s(]+)/)[1];
    }
    return func.name;
  }

  // TODO: перейти на wicket?
  static geojsonToWKT(geojson:any, geomType?:string):string {
    // Добавляет в конец массива первую точку,
    // чтобы получить валидный WKT
    function closeRings(polyPart:any) {
      const firstVertex = polyPart[0];
      const lastVertex = polyPart[polyPart.length - 1];
      if (firstVertex !== lastVertex) {
        polyPart.push(`${firstVertex}`);
      }
    }

    // TODO: сделать нормальное преобразование к multi типам
    if (geojson.geometry) {
      geojson = geojson.geometry;
    }

    const type = geomType ? geomType.toLowerCase() : geojson.type.toLowerCase();

    const coordinates:string[] = [];

    switch (type) {
      case 'multipolygon':
        const polygons:any[] = [];
        geojson.coordinates.forEach((poly:any) => {
          const polyStrings:string[] = [];
          poly.forEach((part:any) => {
            const partCoords:string[] = [];
            part.forEach((vertex:any) => {
              partCoords.push(`${vertex[0]} ${vertex[1]}`);
            });
            closeRings(partCoords);
            polyStrings.push(`(${partCoords.join(', ')})`);
          });
          polygons.push(`(${polyStrings.join(', ')})`);
        });
        return `MULTIPOLYGON (${polygons.join(', ')})`;

      case 'multilinestring':
        const linestrings:string[] = [];
        geojson.coordinates.forEach((linestring:any) => {
          const linestringCoords:string[] = [];
          linestring.forEach((vertex:any) => {
            linestringCoords.push(`${vertex[0]} ${vertex[1]}`);
          });
          linestrings.push(`(${linestringCoords.join(', ')})`);
        });
        return `MULTILINESTRING (${linestrings.join(', ')})`;

      case 'multipoint':
        const pointStrings:string[] = [];
        geojson.coordinates.forEach((point:any) => {
          pointStrings.push(`(${point[0]} ${point[1]})`);
        });
        return `MULTIPOINT (${pointStrings.join(', ')})`;

      case 'polygon':
        const polylinesStrings:string[] = [];
        geojson.coordinates.forEach((part:any) => {
          const partCoords:string[] = [];
          part.forEach((vertex:any) => {
            partCoords.push(`${vertex[0]} ${vertex[1]}`);
          });
          closeRings(partCoords);
          polylinesStrings.push(`(${partCoords.join(', ')})`);
        });
        return `POLYGON(${polylinesStrings.join(', ')})`;

      case 'polyline':
      case 'linestring':
        geojson.coordinates.forEach((vertex:string[]) => {
          coordinates.push(`${vertex[0]} ${vertex[1]}`);
        });
        return `LINESTRING (${coordinates.join(', ')})`;

      case 'point':
        return `POINT(${geojson.coordinates[0]} ${geojson.coordinates[1]})`;
    }
  }

  static boundsToWkt(bounds:Bounds):string {
    const coordinates = Array<Point>();
    coordinates.push(bounds.xmin);
    coordinates.push(bounds.xmax);
    coordinates.push(bounds.ymin);
    coordinates.push(bounds.ymax);

    const wktCoords = Array<string>();
    coordinates.forEach((coords:Point) => {
      wktCoords.push(coords.x + ' ' + coords.y);
    });

    return `POLYGON((${wktCoords.join(',')}))`;
  }

  static getVisibleLayers(layers:ILayer[]):ILayer[] {
    let filterAr:ILayer[] = [];

    layers.forEach(layer => {
      if (layer.isGroup) {
        filterAr = filterAr.concat(this.getVisibleLayers(layer.subLayers));
      } else {
        if (layer.visible) {
          filterAr.push(layer);
        }
      }
    });

    return filterAr;
  }

  // Копирует свойства объекта
  static mixin(mixTo:any, mixFrom:any) {
    for (const key in mixFrom) {
      mixTo[key] = mixFrom[key];
    }
  }

  // Получение информации о браузере пользователя
  static getBrowzerInfo():Browser {
    const N:string = navigator.appName;
    const UA:string = navigator.userAgent;
    const temp:RegExpMatchArray = UA.match(/version\/([\.\d]+)/i);
    let browserVersion:RegExpMatchArray = UA.match(/(opera|chrome|safari|firefox|msie|rv)[/,:]?\s*(\.?\d+(\.\d+)*)/i);
    if (browserVersion && temp != null) {
      browserVersion[2] = temp[1];
    }
    // IE 11
    if (browserVersion[1] === 'rv') {
      browserVersion[1] = 'msie';
    }

    browserVersion = browserVersion ? [browserVersion[1], browserVersion[2]] : [N, navigator.appVersion, '-?'];

    const browserInfo = new Browser();
    browserInfo.name = browserVersion[0];
    browserInfo.version = browserVersion[1];
    return browserInfo;
  }

  static sortByKey(key:string, order:string) {
    // От большего к меньшему
    if (order === 'asc') {
      return (a:object, b:object) => {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      };
      // От меньшего к большему
    } else {
      return (a:object, b:object) => {
        if (a[key] < b[key]) {
          return 1;
        }
        if (a[key] > b[key]) {
          return -1;
        }
        return 0;
      };
    }
  }

  // Sort by two keys
  static sortByKeys(key1:string, key2?:string) {
    return (a:object, b:object) => {
      if (a[key1] > b[key1]) {
        return 1;
      }
      if (a[key1] < b[key1]) {
        return -1;
      }
      if (!key2) {
        return 0;
      }
      if (a[key2] > b[key2]) {
        return 1;
      }
      if (a[key2] < b[key2]) {
        return -1;
      }
      return 0;
    };
  }

  static hex8ToRgba(color:string) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    let opacity = parseInt(hex.substring(6, 8), 16);
    if (!opacity && opacity !== 0) {
      opacity = 255;
    }
    color = `rgba(${r},${g},${b},${(opacity / 255).toFixed(2)})`;
    return color;
  }

  static getErrorMsg(er:HttpErrorResponse):string {
    return er.error && er.error.message ? er.error.message : 'Ошибка при выполнении запроса';
  }

  static getDateWithTimezoneOffset(date:Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }

  static getISOString(date:Date):string {
    return this.getDateWithTimezoneOffset(date).toISOString();
  }

  static getDateString(date:Date):string {
    return this.getISOString(date).substring(0, 10);
  }

  static copyDate(date:Date):Date {
    return new Date(date.getTime());
  }

  static formatDate(date:Date, format:string):string {
    let ret = format;
    let mm = (date.getMonth() + 1).toString();
    if (mm.length < 2) {
      mm = '0' + mm;
    }
    let dd = date.getDate().toString();
    if (dd.length < 2) {
      dd = '0' + dd;
    }
    ret = ret.replace('yyyy', date.getFullYear().toString());
    ret = ret.replace('mm', mm);
    ret = ret.replace('dd', dd);
    return ret;
  }

  static searchDMSCoords(text:string):string[] {
    return text.match(/^(\-??\d{1,3}) *(\d{1,2}) *(\d{1,4})\ *([NSWE]|\W{4})?( *)(\-??\d{1,3}) *(\d{1,2}) *(\d{1,4})\ *([NSWE]|\W{4})?$/i);
  }

  static searchDecimalCoords(text:string):string[] {
    return text.match(/^(\-?\d{1,3}(\.\d+)?)( *)(\-?\d{1,3}(\.\d+)?)$/);
  }

  // замена спецсимволов на пробелы
  static normalizeCoords(text:string):string {
    // 58,272165;47,603249
    const coord = text.match(/^(\-?\d{1,3}(\,\d+)?)(\;\ *)(\-?\d{1,3}(\,\d+)?)$/);
    if (coord) {
      text = text.replace(/(,)+/g, '.');
    }
    return text.replace(/(\x27|\x22|°|′|″|″|,|;)+/g, ' ').trim();
  }

  static DMSCoordsToPoint(coords:any[]):Point {
    coords.splice(0, 1);

    // проверка: нужен ли минус для координат
    if (coords[3] && isNaN(parseFloat(coords[3]))) {
      const val = coords[3].trim();
      if (val === 'ю.ш.' || val === 'ю ш' || val === 's') {
        coords[0] *= -1;
      }
    }
    const last = coords[coords.length - 1];
    if (last && isNaN(parseFloat(last))) {
      const val = last.trim();
      if (val === 'з.д.' || val === 'з д' || val === 'w') {
        coords[4] *= -1;
      }
    }

    // удаляем лишнее из массива
    coords.splice(3, 1);
    coords.splice(7, 1);

    const signLat = Number(coords[0]) < 0 ? -1 : 1;
    const signLon = Number(coords[3]) < 0 ? -1 : 1;

    const lat = Number(coords[0]) + (signLat * Number(coords[1])) / 60 + (signLat * Number(coords[2])) / 3600;
    const lon = Number(coords[4]) + (signLat * Number(coords[5])) / 60 + (signLon * Number(coords[5])) / 3600;
    const point = new Point(Number(lat.toFixed(6)), Number(lon.toFixed(6)));
    return point;
  }

  // TODO: получать размеры изображенй с бэка
  static getImagesMeta(images:GeoImage[]) {
    if (!images.length) {
      return of([]);
    }
    return forkJoin(
      images.map(image => {
        return new Observable((observer:Observer<any>) => {
          const img = new Image();
          img.addEventListener('load', () => {
            image.width = img.naturalWidth;
            image.height = img.naturalHeight;
            observer.next(image);
            observer.complete();
          });
          img.src = image.url as string;
        });
      })
    );
  }

  static getPkAttributeName(layer:ILayer):string {
    const attr:Attribute = layer.columns.find(column => column.is_pk);
    return  attr ? attr.name : null;
  }

  static getPropertyDescriptor(o:any, propName:string) {
    if (o === null) {
      return null;
    } else {
      return o.hasOwnProperty(propName) ?
        Object.getOwnPropertyDescriptor(o, propName) :
        Utils.getPropertyDescriptor(Object.getPrototypeOf(o), propName);
    }
  }

  static hexColorToRGBA(hex:string, alfa:number):string {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/i.exec(hex);
    const ret = result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alfa})` : null;
    return ret;
  }

  static getRelativePosition(elt:HTMLElement, selector:string):{left:number, right:number, top:number, bottom:number} {
    const ret = {left: 0, top: 0, bottom: 0, right: 0};
    let currentElt = elt;
    let currentOffsetElt = elt;
    while (currentElt && !Utils.hasSelector(currentElt, selector)) {
      if (currentOffsetElt !== currentElt.offsetParent) {
        ret.left += currentOffsetElt.offsetLeft;
        ret.top += currentOffsetElt.offsetTop - currentOffsetElt.scrollTop;
        currentOffsetElt = currentElt.offsetParent as HTMLElement;
      }
      currentElt = currentElt.parentElement as HTMLElement;
    }
    if (currentElt) {
      ret.bottom = currentElt.scrollHeight - ret.top;
      ret.right = currentElt.scrollWidth - ret.left;
    }
    return ret;
  }

  static hasSelector(elt:HTMLElement, selector:string):boolean {
    if (selector.startsWith('.')) {
      const className = selector.replace('.', '');
      return elt.className.indexOf(className) >= 0;
    }
  }

  static getNumeralEnding(num:number):string {
    const lastDigit = +num
      .toString()
      .split('')
      .pop();

    if (lastDigit === 1) {
      return '';
    } else if (lastDigit >= 2 && lastDigit < 5) {
      return 'а';
    } else {
      return 'ов';
    }
  }

  // Создает из точки квадрат с заданным отступом от центра, в пикселях
  static convertPointToRectangle(pointXY:Point, searchDelta:number):Point[] {
    const topLeft = new Point(pointXY.x - searchDelta, pointXY.y - searchDelta);
    const bottomLeft = new Point(pointXY.x - searchDelta, pointXY.y + searchDelta);
    const topRight = new Point(pointXY.x + searchDelta, pointXY.y - searchDelta);
    const bottomRight = new Point(pointXY.x + searchDelta, pointXY.y + searchDelta);
    return [topLeft, topRight, bottomRight, bottomLeft];
  }
}
