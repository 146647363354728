import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const noop = () => {
};

@Component({
  selector: 'geo-input-clear',
  templateUrl: 'input-clear.component.html',
  styleUrls: ['input-clear.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputClearComponent),
    multi: true,
  }]
})
export class InputClearComponent implements ControlValueAccessor {
  @Input() placeholder:string;

  private onTouchedCallback:() => void = noop;
  private onChangeCallback:(_:any) => void = noop;
  private _value:string;

  constructor() {
  }

  onClearClick() {
    this.value = '';
  }

  onChange():void {
    this.onChangeCallback(this._value);
  }

  //get accessor
  get value():any {
    return this._value;
  }

  //set accessor including call the onchange callback
  set value(value:any) {
    if (value !== this.value) {
      this._value = value;
      this.onChangeCallback(value);
    }
  }

  //From ControlValueAccessor interface
  writeValue(value:any) {
    if (value !== this.value) {
      this._value = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn:any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn:any) {
    this.onTouchedCallback = fn;
  }
}
