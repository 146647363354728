import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {PluginClass, Utils, WMSTimeLayer} from 'shared/classes';
import {IContainer, IContentChild, ILayer, IPluginInterface, IToolLayer, TimeInterval} from 'shared/interfaces';
import {LayersStore} from 'shared/stores/LayersStore';
import {TimelineService} from '../timeline_service';

const INIT_MONTH_OFFSET = 2; // отступ от текущей даты при открытии таймлайна
const MONTH_STRINGS = ['ЯНВ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ИЮН', 'ИЮЛ', 'АВГ', 'СЕН', 'ОКТ', 'НОЯ', 'ДЕК'];

export interface ObjectsByDate {
  date:Date;
  objects:number;
  dataIndex?:number | null;
}

type Direction = 'r' | 'l';

@Component({
  selector: 'geo-timeline',
  templateUrl: 'timeline.component.html',
  styleUrls: ['timeline.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineComponent extends PluginClass implements OnDestroy, IPluginInterface, IToolLayer, IContentChild {
  @Input() base = 'day';

  @ViewChild('calendarContainer') calendarContainer:ElementRef;
  @ViewChild('scrollableContent') scrollableContent:ElementRef;

  activateMode:string = null;
  parentComponent:IContainer;
  expanded:boolean = true;
  layer:WMSTimeLayer;
  intervals:ObjectsByDate[][];
  months:ObjectsByDate[][][] = [];
  years:number[] = [];
  selectedMonth:ObjectsByDate[][];
  selectedYear:number;
  filterDay:ObjectsByDate;
  filterDecade:ObjectsByDate[];
  filterMonth:ObjectsByDate[][];
  prevInterval:ObjectsByDate;
  nextInterval:ObjectsByDate;
  currentScroll:number = 0;

  initLoading = false;
  private originalData:ObjectsByDate[] = [];
  private currentSqlFilter:string;
  private deactivate$:Subject<boolean> = new Subject<boolean>();

  constructor(private el:ElementRef, private timelineService:TimelineService, private layersStore:LayersStore,
              private changeDetector:ChangeDetectorRef) {
    super();
  }

  getMonth(item:any):string {
    return item ? MONTH_STRINGS[(item as ObjectsByDate[][])[0][0].date.getMonth()] : '';
  }

  getMonthTitle(item:ObjectsByDate[][]) {
    const months = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];
    const dt = item[0][0].date;
    return  months[dt.getMonth()] + ' ' + dt.getFullYear();
  }

  getDayElementId(item:ObjectsByDate):string {
    return 'day-' + item.date.getTime();
  }

  getIntervalString(interval:ObjectsByDate) {
    if (interval) {
      return Utils.formatDate(interval.date, 'dd.mm.yyyy');
    } else {
      return '';
    }
  }

  setMonthYear(interval:ObjectsByDate) {
    for (const month of this.months) {
      if (month[0][0].date.getMonth() === interval.date.getMonth()) {
        this.selectedMonth = month;
        this.selectedYear = interval.date.getFullYear();
      }
    }
  }

  onDayClick(day:ObjectsByDate) {
    if (day.objects > 0) {
      this.setFilterDay(day.date);
      this.filterDecade = null;
      this.filterMonth = null;
    }
  }

  onDecadeClick(decade:ObjectsByDate[]) {
    if (this.decadeHasData(decade)) {
      this.filterDay = null;
      this.filterDecade = decade;
      this.filterMonth = null;
      const endDate = new Date(decade[decade.length - 1].date.getTime());
      endDate.setDate(endDate.getDate() + 1);
      this.setDateFilter(decade[0].date, endDate);
    }
  }

  onMonthClick(month:ObjectsByDate[][]) {
    if (this.monthHasData(month)) {
      this.filterDay = null;
      this.filterDecade = null;
      this.filterMonth = month;
      const lastDecade = month[month.length - 1];

      const endDate = new Date(lastDecade[lastDecade.length - 1].date.getTime());
      endDate.setDate(endDate.getDate() + 1);
      this.setDateFilter(month[0][0].date, endDate);
    }
  }

  isElementVisible():boolean {
    return true;
  }

  isSelectedDay(day:ObjectsByDate):boolean {
    return this.filterDay && this.filterDay.date.getTime() === day.date.getTime();
  }

  isSelectedDecade(decade:ObjectsByDate[]):boolean {
    return this.filterDecade && this.filterDecade[0].date.getTime() === decade[0].date.getTime();
  }

  isSelectedMonth(month:ObjectsByDate[][]):boolean {
    return this.filterMonth && this.filterMonth[0][0].date.getTime() === month[0][0].date.getTime();
  }

  decadeHasData(decade:ObjectsByDate[]):boolean {
    for (const day of decade) {
      if (day.objects > 0) {
        return true;
      }
    }
    return false;
  }

  monthHasData(month:ObjectsByDate[][]) {
    for (const decade of month) {
      for (const day of decade) {
        if (day.objects > 0) {
          return true;
        }
      }
    }
    return false;
  }

  scrollPage(direction:string) {
    const container = this.calendarContainer.nativeElement as HTMLElement;
    const containerWidth = container.offsetWidth;
    const scrollableContent = this.scrollableContent.nativeElement as HTMLElement;
    const maxScrollWidth = scrollableContent.offsetWidth - containerWidth;
    if (direction !== 'l') {
      this.currentScroll += containerWidth;
      if (this.currentScroll > maxScrollWidth) {this.currentScroll = maxScrollWidth; }
    } else {
      this.currentScroll -= containerWidth;
      if (this.currentScroll < 0) {this.currentScroll = 0; }
    }
    scrollableContent.style.transform = `translateX(-${this.currentScroll}px)`;
  }

  canScroll(direction:string):boolean {
    if (!this.scrollableContent) {
      return false;
    }
    const container = this.calendarContainer.nativeElement as HTMLElement;
    const scrollableContent = this.scrollableContent.nativeElement as HTMLElement;
    const maxScrollWidth = scrollableContent.offsetWidth - container.offsetWidth;

    if (direction === 'l') {
      return this.currentScroll > 0;
    } else {
      return this.currentScroll < maxScrollWidth;
    }
  }

  gotoNextData(direction:string) {
    if (direction === 'r') {
      this.setFilterDay(this.nextInterval.date);
    } else {
      this.setFilterDay(this.prevInterval.date);
    }
    this.changeDetector.detectChanges();
    setTimeout(() => this.gotoInterval(this.filterDay), 0);
  }

  setFilterDay(date:Date) {
    this.filterDay = null;
    this.prevInterval = null;
    this.nextInterval = null;
    for (const intervalMonth of this.intervals) {
      for (const intervalDay of intervalMonth) {
        if (intervalDay.objects > 0) {
          if (this.filterDay && !this.nextInterval) {
            this.nextInterval = intervalDay;
          }
          if (intervalDay.date.getTime() === date.getTime()) {
            this.setMonthYear(intervalDay);
            this.filterDay = intervalDay;
            const endDate = new Date(intervalDay.date.getTime());
            endDate.setDate(endDate.getDate() + 1);
            this.setDateFilter(intervalDay.date, endDate);
          }
          if (!this.filterDay) {
            this.prevInterval = intervalDay;
          }
        }
      }
    }
  }

  onMonthChange() {
    //month center date
    const date = new Date(this.selectedMonth[0][0].date.getTime());
    date.setDate(15);
    this.selectedYear = date.getFullYear();
    for (const intervalMonth of this.intervals) {
      for (const intervalDay of intervalMonth) {
        if (intervalDay.date.getTime() === date.getTime()) {
          this.gotoInterval(intervalDay);
          break;
        }
      }
    }
  }

  onYearChange() {
    for (const month of this.months) {
      if (month[0][0].date.getFullYear() === this.selectedYear) {
        this.selectedMonth = month;
        this.gotoInterval(month[0][0], 'l');
        break;
      }
    }
  }

  toggle() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      setTimeout(() => {
        this.scrollableContent.nativeElement.style.transform = `translateX(-${this.currentScroll}px)`;
      }, 0);
    }
  }

  ngOnDestroy() {
    this.deactivate$.next();
    this.deactivate$.unsubscribe();
  }

  addInterface(name:string, pi:IPluginInterface) {}

  removeInterface(name:string) {}

  activateToolLayer(layer:WMSTimeLayer) {
    if (!(layer.visible && layer.type === 'wms-t')) {
      this.deactivateTool();
      return;
    }
    if (layer === this.layer) { return; }

    this.deactivate$.next();

    this.intervals = this.originalData = null;

    if (this.parentComponent) {
      this.parentComponent.open();
    }

    this.layer = layer;
    this.currentSqlFilter = layer.filter;

    this.loadTimeline();

    this.layersStore
      .getActiveLayers()
      .pipe(takeUntil(this.deactivate$))
      .subscribe((layers:ILayer[]) => {
        const curLayer = layers.find(item => item.id === this.layer.id);
        if (!curLayer || curLayer.notOnScale || !curLayer.visible) {
          this.deactivateTool();
        } else if (this.currentSqlFilter !== this.layer.filter) {
          this.currentSqlFilter = this.layer.filter;
          this.intervals = null;
          this.loadTimeline();
        }
      });
  }

  activateTool():void {}

  deactivateTool():Promise<boolean> {
    if (this.parentComponent) { this.parentComponent.close(); }
    this.intervals = null;
    this.filterDay = null;
    this.filterDecade = null;
    this.filterMonth = null;
    this.layer = null;
    this.changeDetector.detectChanges();
    this.deactivate$.next();
    return Promise.resolve(true);
  }

  checkLayer(layer:WMSTimeLayer):boolean {
    return layer.type === 'wms-t';
  }

  getGroup():string {
    return '';
  }

  isActive():boolean {
    return !!this.layer;
  }

  activate() {}

  deactivate() {}

  enable() {}

  disable() {}

  setDateFilter(begin:Date, end:Date) {
    this.layer.setDateFilter({ start: begin, end: end });
    this.layer.refresh();
  }

  private loadTimeline() {
    const period:TimeInterval = this.getOffsetPeriod(this.layer.timeBounds);
    this.initLoading = true;
    this.filterDay = null;
    this.prevInterval = null;
    this.nextInterval = null;

    this.getTimeBounds()
      .pipe(
        switchMap(() => this.timelineService.getTimeline(this.layer, period, this.base)),
        map((data:{ intervals:ObjectsByDate[][]; originalData:ObjectsByDate[] }) => {
          this.intervals = data.intervals;
          this.originalData = data.originalData;
          let year:number;
          let month:ObjectsByDate[][];
          let decade:ObjectsByDate[];
          for (const intervalMonth of data.intervals) {
            year = intervalMonth[0].date.getFullYear();
            if (this.years[this.years.length - 1] !== year) {
              this.years.push(year);
            }
            month = [];
            this.months.push(month);
            for (const intervalDay of intervalMonth) {
              let decadeIndex = Math.floor(intervalDay.date.getDate() / 10);
              if (decadeIndex > 2) { decadeIndex--; }
              if (!month[decadeIndex]) {
                decade = [];
                month[decadeIndex] = decade;
              }
              decade.push(intervalDay);
              if (!this.filterDay && intervalDay.objects && !this.layer.currentInterval) {
                this.setFilterDay(intervalDay.date);
              }
            }
          }

          this.selectedMonth = this.months[0];
          this.selectedYear = this.years[0];

          if (this.layer.currentInterval) {
            this.setFilterDay(this.layer.currentInterval.end);
          }

          this.initLoading = false;
          this.changeDetector.detectChanges();
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          if (this.filterDay) {
            this.gotoInterval(this.filterDay);
          }
        }, 0);
      });
  }

  gotoInterval(interval:ObjectsByDate, align?:string) {
    if (this.expanded) {
      const id = this.getDayElementId(interval);
      const container = this.scrollableContent.nativeElement as HTMLElement;
      const element = container.querySelector('#' + id);
      const offset = (element as any).offsetLeft;
      this.scroll(offset, align);
    }
  }

  private getTimeBounds():Observable<any> {
    return this.timelineService.getTimeBounds(this.layer).pipe(
      map((timeBounds:TimeInterval) => {
        this.layer.timeBounds = timeBounds;
      })
    );
  }

  private scroll(distance:number, align?:string) {
    const container = this.calendarContainer.nativeElement as HTMLElement;
    const containerWidth = container.offsetWidth;
    const scrollableContent = this.scrollableContent.nativeElement as HTMLElement;
    if (align !== 'l') {
      distance = distance - containerWidth / 2;
    }
    if (distance < 0) {
      distance = 0;
    }
    this.currentScroll = distance;
    scrollableContent.style.transform = `translateX(-${distance}px)`;
    this.changeDetector.detectChanges();
  }

  private getOffsetPeriod(period:TimeInterval):TimeInterval {
    let offsetPeriod = { start: null, end: null };

    switch (this.base) {
      case 'day':
        const start = Utils.copyDate(period.start);
        start.setMonth(start.getMonth() - INIT_MONTH_OFFSET);
        start.setDate(1);

        const end = Utils.copyDate(period.end);
        end.setMonth(end.getMonth() + INIT_MONTH_OFFSET + 1);
        end.setDate(0);

        offsetPeriod = { start, end };
        break;
      case 'decade':
        break;
      case 'month':
        break;
      case 'quarter':
        break;
      case 'year':
        break;
      default:
        console.error(`Invalid base period: ${this.base}. Choose from: 'day', 'decade', 'month', 'quarter', 'year'.`);
        break;
    }

    return offsetPeriod;
  }
}
