import { GeoJsonObject } from 'geojson';
import { FeatureGroup, GeoJSON, LatLng, LatLngBounds, Layer as leafletLayer, LayerEvent } from 'leaflet';
import { LayerType } from 'shared/interfaces';
import { Bounds, Feature, Point } from '../../classes';
import { WMSLayer } from './WMSLayer.class';

export class GeoJsonLayer extends WMSLayer {
  type:LayerType = 'geojson';

  addGeoJson(geojson:string | GeoJsonObject) {
    const self = this;
    if (typeof geojson === 'string') {
      geojson = JSON.parse(geojson as string);
    }

    const geoJson:GeoJSON = new GeoJSON(geojson as GeoJsonObject, {
      onEachFeature: (feature:any, layer:leafletLayer) => {
        (layer as any).on({
          click: (e:LayerEvent) => {
            const f:Feature = new Feature();
            f.properties = e.target.feature.properties || {};
            f.geometry = e.target.feature.geometry;
            f.layer = this;

            self.featureClicked(f);
          }
        });
      }
    });
    this._layerInstance.addLayer(geoJson);
    // fix при добавление объекта слоё был виден, хотя и был выключен
    this.visible = this.visible;
    this.createExtent();
  }

  set opacity(value:number) {
    if (!this._layerInstance) { return; }
    const newOpacity:number = (100 - value) / 100;
    this._layerInstance.setStyle(() => ({ opacity: newOpacity, fillOpacity: newOpacity }));
    this._opacity = value;
  }

  featureClicked(feature:Feature) {}

  createExtent() {
    const leafletBounds:LatLngBounds = this._layerInstance.getBounds();
    const NE:LatLng = leafletBounds.getNorthEast();
    const SW:LatLng = leafletBounds.getSouthWest();
    const southWest:Point = new Point(SW.lat, SW.lng),
      northEast:Point = new Point(NE.lat, NE.lng),
      southEast:Point = new Point(SW.lat, NE.lng),
      northWest:Point = new Point(NE.lat, SW.lng);
    this.extent = new Bounds(northWest, southWest, southEast, northEast);
  }

  protected createLayerInstance():Promise<any> {
    return new Promise((resolve, reject) => {
      this._layerInstance = this.buildLayerInstance();
      this._layerInstance.on('load', () => {
        this._layerInstance.off('load');
        resolve();
      });
    });
  }

  public buildLayerInstance():any {
    return new FeatureGroup([]);
  }

  protected createFilterStyle() {
    // TODO: Сделать управление цветом для geojson
  }
}
