import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAppSettings } from '../environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private appSettings:IAppSettings;

  constructor(@Inject('environment') settings:IAppSettings) {
    this.appSettings = settings;
  }

  intercept(req:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
    const api_key:string = this.appSettings.API_KEY;
    if (!!api_key === false) {
      return next.handle(req);
    }

    // если не надо добавлять заголовки авторизации
    if (req.params.has('noAuth')) {
      const noParams = req.params.delete('noAuth');
      const noAuth = req.clone({ params: noParams });
      return next.handle(noAuth);
    }

    const contentTypeSet = req.headers.set('Content-Type', 'application/json');
    const authHeaderSet = contentTypeSet.set('Authorization', `ApiKey ${api_key}`);

    // add headers to request before sending it
    const authReq = req.clone({
      headers: authHeaderSet
    });

    return next.handle(authReq);
  }
}
