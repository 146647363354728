import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, QueryList, ViewChildren} from '@angular/core';
import {IDraw, ILoader, IPluginInterface, IPosition, IRouteLeg, IRoutePoint, IRouteResult, ISimpleMap} from 'shared/interfaces';
import {Creator} from '../../services/services';
import {Extent, Feature, PluginClass, Point} from 'shared/classes';
import {GeocoderService} from 'shared/services';
import {debounceTime} from 'rxjs/operators';
import {DragDropData} from 'ng2-dnd';
import { Point as GeojsonPoint } from 'geojson';
import {SelectBoxComponent} from 'shared/components';
import {Observable} from 'rxjs';
import {DrawLayer} from '../../../../../shared/classes/LeafletLayer/DrawLayer.class';

@Component({
  selector: 'map_router-desktop',
  templateUrl: 'map_router.component.html',
  styleUrls: ['map_router.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GeocoderService]
})
export class MapRouterDesktopComponent extends PluginClass {
  @Input() style:CSSStyleDeclaration;
  @Input() minSearchTextLen:number = 3;

  @ViewChildren('stopSelectBox') stopSelectBoxes:QueryList<SelectBoxComponent>;

  isActive = false;
  showRouterOptions = false;
  vehicles:{value:string, name:string}[] = [{value: 'truck', name: 'грузовой автомобиль'}];
  selectedVehicle:{value:string, name:string} = this.vehicles[0];
  disableTolls:boolean = true;
  defaultSuggestions:IRoutePoint[] =  [];
  suggestionLocation:IRoutePoint = {
    name: 'Местоположение', lat: null, lng: null, icon: '/images/position.svg', className: 'map-router-location-item'
  };
  stops:IRoutePoint[] = [
    {name: '', lat: null, lng: null, suggestions: this.defaultSuggestions},
    {name: '', lat: null, lng: null, suggestions: this.defaultSuggestions}
  ];
  activeStop:IRoutePoint;
  result:IRouteResult;
  activeResultLegIndex:number = 0;

  private drawLayer:DrawLayer;
  private mapComponent:ISimpleMap;
  private positionComponent:IPosition;
  private loaders:ILoader[] = [];
  private startPointStyle = {
    type: 'icon',
    iconUrl: '/images/loc-red.png',
    iconSize: [32, 35],
    iconAnchor: [16, 35]
  };
  private finishPointStyle = {
    type: 'icon',
    iconUrl: '/images/loc-blue.png',
    iconSize: [32, 35],
    iconAnchor: [16, 35]
  };
  private middlePointStyle = {
    type: 'circle',
    radius: 8,
    stroke: true,
    color: '#FFF',
    weight: 1,
    fill: true,
    fillColor: '#C9C8C6',
    fillOpacity: 1,
    text: null,
    textClass: 'map_route_text'
  };
  private routeLineStyle = {
    stroke: true,
    color: '#1F79D2',
    weight: 4.5
  };
  private routeLineBackStyle = {
    stroke: true,
    color: '#FFFFFF',
    weight: 8
  };

  constructor(creator:Creator, el:ElementRef, private geocoder:GeocoderService, private changeDetector:ChangeDetectorRef) {
    super();
    this.geocoder.source = 'ga';
    setTimeout(() => {
      navigator.geolocation.getCurrentPosition(position => {
        this.suggestionLocation.lat = position.coords.latitude;
        this.suggestionLocation.lng = position.coords.longitude;
        this.defaultSuggestions.push(this.suggestionLocation);
      }, err => {
        console.error('API Geolocation error!\n\n' + err);
      });
    }, 0);
  }

  onAddPoint() {
    this.stops.splice(this.stops.length - 1, 0, {name: '', lat: null, lng: null, suggestions: this.defaultSuggestions});
    this.changeDetector.detectChanges();
  }

  onReset () {
    this.stops = [
      {name: '', lat: null, lng: null, suggestions: this.defaultSuggestions},
      {name: '', lat: null, lng: null, suggestions: this.defaultSuggestions}
    ];
    this.drawLayer.clearDrawFeatures();
    this.result = null;
    this.changeDetector.detectChanges();
  }

  filterSuggestions(suggestions:IRoutePoint[], stopExclude:IRoutePoint):IRoutePoint[] {
    return suggestions.filter(item => {
      let include = true;
      for (const stop of this.stops) {
        if (stop !== stopExclude) {
          include = include && stop.name !== item.name;
        }
      }
      return include;
    });
  }

  onFocusStop(stop:IRoutePoint, focused:boolean) {
    if (focused) {
      this.activeStop = stop;
    } else {
      setTimeout(() => this.activeStop = null, 500);
    }
  }

  onTollsChange() {
    this.updateRoute();
  }

  onDrop(dropEvent:DragDropData, destIndex:number) {
    const srcIndex = dropEvent.dragData.index as number;
    const stop = this.stops[srcIndex];
    this.stops.splice(srcIndex, 1);
    this.stops.splice(destIndex, 0, stop);
    this.updateRoute();
    this.changeDetector.detectChanges();
  }

  onSuggestSelect(suggest:IRoutePoint, stop:IRoutePoint) {
    stop.name = suggest ? suggest.name : '';
    stop.lat = suggest ? suggest.lat : null;
    stop.lng = suggest ? suggest.lng : null;

    if (!suggest) {
      const index = this.stops.indexOf(stop);
      if (index === 0 || index === this.stops.length - 1) {
        if (index === 0 && this.stops.length > 2) {
          //замещение первой точки второй
          this.copyStop(this.stops[1], stop);
          this.stops.splice(1, 1);
          this.stopSelectBoxes.first.selectedItem = stop.suggestions.find(item => item.name === stop.name);
        } else if (index === this.stops.length - 1 && this.stops.length > 2) {
          //замещение последней точки предпоследней
          this.copyStop(this.stops[this.stops.length - 2], stop);
          this.stops.splice(this.stops.length - 2, 1);
          this.stopSelectBoxes.last.selectedItem = stop.suggestions.find(item => item.name === stop.name);
        } else {
          stop.suggestions = this.defaultSuggestions;
        }
      } else {
        this.stops.splice(index, 1);
      }
    }

    this.updateRoute();
  }

  onStopTextChange(input:string, stop:IRoutePoint) {
    if (input.length < 3) {
      stop.suggestions = this.defaultSuggestions;
    } else {
      this.geocoder.searchAddress(input)
        .pipe(
          debounceTime(500)
        )
        .subscribe(features => {
          stop.suggestions = features.map(feature => {
            return {
              name: this.formatObjectName(feature.properties.name),
              lat: (feature.geometry as GeojsonPoint).coordinates[1],
              lng: (feature.geometry as GeojsonPoint).coordinates[0]
            };
          });
          if (this.defaultSuggestions[0]) {
            stop.suggestions.unshift(this.defaultSuggestions[0]);
          }
          this.changeDetector.detectChanges();
        }, error => {
          console.error(error);
        });
    }
  }

  onSwapClick() {
    const stop = this.stops[0];
    this.stops[0] = this.stops[1];
    this.stops[1] = stop;
    this.updateRoute();
  }

  getStopClass(index:number):string {
    let ret = '';
    if (index === 0) {
      ret = 'map-router_stop-first';
    } else if (index === this.stops.length - 1) {
      ret = 'map-router_stop-last';
    }
    const indexActive = this.stops.indexOf(this.activeStop);
    if (indexActive === index) {
      ret += ' map-router_stop-active';
    }
    return ret;
  }

  getStopText(index:number):string {
    if (index === 0) {
      return 'А';
    } else if (index === this.stops.length - 1) {
      return 'Б';
    } else {
      return index.toString();
    }
  }

  getPlaceholderText(index:number):string {
    if (index === 0) {
      return 'Откуда';
    } else {
      return 'Куда';
    }
  }

  getRouteTollsFlag(leg:IRouteLeg):boolean {
    let ret = false;
    for (const maneuver of leg.maneuvers) {
      ret = ret || !!maneuver.toll;
      if (ret) { break; }
    }
    return ret;
  }

  openClick() {
    this.isActive = true;
    this.drawLayer.visible = true;
    this.changeDetector.detectChanges();
  }

  onCloseClick () {
    this.isActive = false;
    this.drawLayer.visible = false;
    this.onReset();
  }

  onMapClick(evt:{latlng:{lat:number, lng:number}}) {
    if (this.activeStop) {
      const stop = this.activeStop;
      const index = this.stops.indexOf(stop);
      this.geocoder.searchReverse(new Point(evt.latlng.lng, evt.latlng.lat))
        .pipe(
          debounceTime(500)
        )
        .subscribe(features => {
          if (features.length > 0) {
            stop.suggestions = features.map((feature:Feature) => {
              return {
                name: feature.properties.name,
                lat: (feature.geometry as GeojsonPoint).coordinates[1],
                lng: (feature.geometry as GeojsonPoint).coordinates[0]
              };
            });
            if (this.defaultSuggestions[0]) {
              stop.suggestions.unshift(this.defaultSuggestions[0]);
            }
            const suggest = stop.suggestions[stop.suggestions.length - 1];
            this.stopSelectBoxes.toArray()[index].selectedItem = suggest;
            this.onSuggestSelect(suggest, stop);
            this.changeDetector.detectChanges();
            this.updateRoute();
          }
        }, error => {
          console.error(error);
        });
    }
  }

  formatTime(seconds:number):string {
    const hour = Math.floor(seconds / 3600);
    const min = Math.round((seconds - hour * 3600) / 60);
    return `${hour} ч ${min} мин`;
  }

  formatDistance(km:number):string {
    return `${Math.round(km)} километров`;
  }

  private formatObjectName(name:string):string {
    let ret = name;
    const pos = name.indexOf(',');
    if (pos > 0) {
      ret = '<b>' + name.substring(0, pos) + '</b>' + name.substring(pos);
    }
    return ret;
  }

  private updateRoute() {
    this.result = null;
    this.drawLayer.clearDrawFeatures();
    const validStops = this.stops.filter(item => item.lat && item.lng);
    if (validStops.length > 1) {
      this.updateMap();
      const extent = Extent.MIN_EXTENT;
      for (const stop of validStops) {
        extent.expand(stop.lng, stop.lat);
      }
      if (extent.isValid) {
        this.positionComponent.setExtent(extent);
      }
      this.showLoader();
      this.geocoder.buildRoute(validStops, !this.disableTolls).subscribe((result:IRouteResult) => {
        this.result = result;
        this.activeResultLegIndex = 0;
        this.hideLoader();
        this.updateMap();
        this.changeDetector.detectChanges();
      }, err => {
        this.hideLoader();
        console.error(err.error);
      });
    } else {
      this.updateMap();
      if (validStops[0]) {
        const point = new Point(validStops[0].lng, validStops[0].lat);
        this.positionComponent.goToPoint(point);
      }
      this.changeDetector.detectChanges();
    }
  }

  private updateMap() {
    this.drawLayer.clearDrawFeatures();
    if (this.result && this.result.legs[this.activeResultLegIndex]) {
      const leg = this.result.legs[this.activeResultLegIndex];
      this.drawLayer.addGeometry(leg.points, 'LineString', this.routeLineBackStyle);
      this.drawLayer.addGeometry(leg.points, 'LineString', this.routeLineStyle);
      this.positionComponent.setExtent(leg.extent);
    }
    for (let index = 0; index < this.stops.length; index++) {
      const stop = this.stops[index];
      if (stop.lat !== null && stop.lng !== null) {
        const point = new Point(stop.lng, stop.lat);
        if (index === 0) {
          this.drawLayer.addGeometry([point], 'Point', this.startPointStyle);
        } else if (index === this.stops.length - 1) {
          this.drawLayer.addGeometry([point], 'Point', this.finishPointStyle);
        } else {
          this.middlePointStyle.text = index.toString();
          this.drawLayer.addGeometry([point], 'Point', this.middlePointStyle);
        }
      }
    }
  }

  private showLoader() {
    this.loaders.forEach(plug => {
      plug.showLoader();
    });
  }

  private hideLoader() {
    this.loaders.forEach(plug => {
      plug.hideLoader();
    });
  }

  private copyStop(srcStop:IRoutePoint, destStop:IRoutePoint) {
    destStop.lng = srcStop.lng;
    destStop.lat = srcStop.lat;
    destStop.name = srcStop.name;
    destStop.suggestions = srcStop.suggestions;
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Map':
        this.mapComponent = pi as ISimpleMap;
        this.drawLayer = this.mapComponent.createDrawLayer();
        this.drawLayer.visible = false;
        break;
      case 'MapPosition':
        this.positionComponent = pi as IPosition;
        this.positionComponent.click.subscribe(point => this.onMapClick(point));
        break;
      case 'Loader':
        this.loaders.push(pi as ILoader);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
    }
  }
}
