import { DirectGeometryObject, Position } from 'geojson';
import { Attribute, Feature } from '../../../classes';
import { ILayer } from '../../../interfaces';
import { GeometryParser } from './GeometryParser.class';
import { FilterLike } from './GML.class';

export class SearchGmlGenerator {
  private _gmlTemplate:string = null;
  private _parser:DOMParser;
  private _geomParser:GeometryParser;
  private _gmlns = 'http://www.opengis.net/gml';

  constructor(private _layer:ILayer) {
    this._parser = new DOMParser();
    this._geomParser = new GeometryParser(this._gmlns);
  }

  getForArea(geomObject:DirectGeometryObject, geomField:string, filterGml?:string):string {
    let geomFilter = `<ogc:And><ogc:Intersects>
      <ogc:PropertyName>${geomField}</ogc:PropertyName>
      <gml:Polygon xmlns:gml="http://www.opengis.net/gml" srsName="EPSG:4326">
        <gml:exterior>
          <gml:LinearRing>
            ${this._getCoordsList(geomObject.coordinates as Position[][])}
          </gml:LinearRing>
        </gml:exterior>
      </gml:Polygon>
    </ogc:Intersects>`;

    if (filterGml) {
      geomFilter += `${filterGml}`;
    }

    geomFilter += '</ogc:And>';

    const gml:string = this._create().replace('{{filter}}', geomFilter);
    return gml;
  }

  getForText(text:string, filterGml?:string):string {
    let textFilter = '<ogc:And><ogc:Or>';
    this._layer.columns
      .filter(column => column.type === 'string')
      .forEach((col:Attribute) => {
        textFilter += new FilterLike(col.name, `%${text}%`).createGml();
      });

    textFilter += '</ogc:Or>';

    if (filterGml) {
      textFilter += `${filterGml}`;
    }

    textFilter += '</ogc:And>';

    const gml:string = this._create().replace('{{filter}}', textFilter);
    return gml;
  }

  parse(gml:string):Feature[] {
    const xmlDoc:Document = this._parser.parseFromString(gml, 'application/xml');
    const findFeatures:Element = xmlDoc.getElementsByTagNameNS(this._gmlns, 'featureMembers').item(0);
    const allFeatures:Feature[] = [];

    if (findFeatures) {
      [].slice.call(findFeatures.childNodes).forEach((f:Node) => {
        const feature:Feature = this._parseFeature(f);
        allFeatures.push(feature);
      });
    }

    return allFeatures;
  }

  private _create(sort:string = '', featuresNum:number = 500):string {
    if (this._gmlTemplate) {
      return this._gmlTemplate;
    }

    const gml = `<wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" service="WFS" version="1.1.1"
                    maxFeatures="${featuresNum}" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                    xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd">
                    <wfs:Query typeName="${this._layer.layerName}" srsName="EPSG:4326" sortby="${sort}">
                        ${this._getPropertiesList()}
                        <ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
                        {{filter}}
                        </ogc:Filter>
                    </wfs:Query>
                    </wfs:GetFeature>`;
    return gml;
  }

  private _getPropertiesList():string {
    let prop = '';
    this._layer.columns.forEach((col:Attribute) => {
      prop += `<wfs:PropertyName>${col.name}</wfs:PropertyName>`;
    });
    return prop;
  }

  private _getCoordsList(coords:Position[][]):string {
    let gmlGeom = '<gml:posList>';
    let allCoords:Position[] = [];

    coords.forEach((polyCoords:Position[]) => {
      polyCoords.forEach((pos:Position) => {
        allCoords = allCoords.concat(pos);
      });

      // добавляем первую точку в конец массива, чтобы контур был замкнут
      allCoords = allCoords.concat(polyCoords[0]);
    });

    gmlGeom += allCoords.join(' ');
    gmlGeom += '</gml:posList>';
    return gmlGeom;
  }

  private _parseAttributes(node:Node):any[] {
    const properties:any = {};

    [].slice.call(node.childNodes).forEach((child:Node) => {
      if (!child.childNodes.length || child.childNodes[0].nodeType !== 3) {
        return;
      }

      const propName = child.nodeName.indexOf(':') !== -1 ? child.nodeName.split(':')[1] : child.nodeName;
      properties[propName] = child.textContent;
    });
    return properties;
  }

  private _parseFeature(node:Node):Feature {
    const geomTypes:string[] = ['MultiSurface', 'Polygon', 'MultiLineString', 'LineString', 'MultiPoint', 'Point'];

    let nodeList:HTMLCollectionOf<Element>, geometry:any;

    for (const type of geomTypes) {
      nodeList = (node as Element).getElementsByTagNameNS(this._gmlns, type);
      if (nodeList.length > 0) {
        geometry = this._geomParser.geomParse(type, nodeList[0]);
        break;
      }
    }
    const feature = new Feature();
    feature.properties = this._parseAttributes(node);
    feature.geometry = geometry;
    feature.layer = this._layer;
    return feature;
  }
}
