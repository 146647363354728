import { Component, Renderer2 } from '@angular/core';
import { Feature, PluginClass } from 'shared/classes';
import {
  IPluginInterface,
  IToolFeature
} from 'shared/interfaces';

@Component({
  selector: 'print-feature',
  template: ''
})
export class PrintFeatureComponent extends PluginClass
  implements IToolFeature {

  constructor(private renderer:Renderer2) {
    super();
  }

  activateMode:string = null;
  toolName = 'Печать атрибутов';
  className = '';
  groupName:string;

  // Состояние
  private active = false;

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
  }

  getGroup():string {
    return null;
  }

  deactivateTool():Promise<boolean> {
    return new Promise<boolean>(resolve => resolve(true));
  }
  activateTool() {
  }
  isActive():boolean {
    return false;
  }

  activateToolFeature(feature:Feature) {
    const r = this.renderer;
    const container = r.createElement('div');
    const br = r.createElement('br') as HTMLElement;
    const url = r.createElement('p') as HTMLElement;
    url.innerText = window.location.href;
    url.style.textAlign = 'center';
    const header = r.createElement('h3') as HTMLElement;
    header.innerText = feature.layer.name;
    header.style.textAlign = 'center';
    r.appendChild(container, url);
    r.appendChild(container, br);
    r.appendChild(container, br);
    r.appendChild(container, header);
    const table = r.createElement('table') as HTMLTableElement;
    table.border = '1';
    table.style.border = 'black solid 2px';
    table.style.margin = '0 auto';
    table.cellPadding = '7px';
    table.cellSpacing = '0';
    for (const attr in feature.properties) {
      const column = feature.layer.columns.find(cln => cln.name === attr);
      const attrName = column && column.alias ? column.alias : attr;
      const row = r.createElement('tr') as HTMLTableRowElement;
      r.appendChild(table, row);
      let cell = r.createElement('td') as HTMLElement;
      cell.innerText = attrName;
      r.appendChild(row, cell);
      cell = r.createElement('td') as HTMLElement;
      cell.innerText = feature.properties[attr];
      r.appendChild(row, cell);
    }
    r.appendChild(container, table);
    const win = window.open('', 'Title');
    win.document.body.innerHTML = container.outerHTML;
    win.document.close();
    win.focus();
    win.print();
  }

  checkFeature(feature:Feature):boolean {
    return !!feature;
  }
}
