import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IUser } from '../../../../interfaces';
import { Chart } from '../../classes';

@Component({
  selector: 'chart-list',
  templateUrl: 'chart-list.component.html',
  styleUrls: ['chart-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartListComponent {
  @Input() charts:Chart[] = [];
  @Input() user:IUser;
  @Input() manage:number[] = [];

  @Output() onFormClick = new EventEmitter();
  @Output() onChartClick = new EventEmitter();

  showBtns = false;

  showForm(type:string) {
    this.onFormClick.emit(type);
  }

  showDetailed(chart:Chart) {
    this.onChartClick.emit(chart);
  }
}
