import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Feature } from '../../classes/Feature';
import { WMSLayer } from '../../classes/LeafletLayer/WMSLayer.class';
import { WMSTimeLayer } from '../../classes/LeafletLayer/WMSTimeLayer.class';
import { WMTSLayer } from '../../classes/LeafletLayer/WMTSLayer.class';
import { Point } from '../../classes/Point';
import {stringify} from 'querystring';
import {LayerWrapper} from '../../classes/LeafletLayer/NativeLayer.class';

@Injectable()
export class RasterService {
  constructor(private httpClient:HttpClient) {}

  searchPoint(layers:(WMTSLayer | WMSLayer | WMSTimeLayer)[], pointXY:Point) {
    if (!layers.length) {
      return Promise.resolve([]);
    }
    const allRequest$:Observable<Feature[]>[] = [];
    layers.forEach(layer => {
      allRequest$.push(this._searchPoint(layer, pointXY));
    });
    const source$:any = forkJoin(allRequest$);
    return this._getResults(source$);
  }

  private _searchPoint(layer:WMTSLayer | WMSLayer | WMSTimeLayer, clickPos:Point):Observable<any> {
    let getFeatureUrl:string;
    if (layer.infoUrl === undefined) {
      getFeatureUrl  = layer['layer'].infoUrl(clickPos);
    } else {
      getFeatureUrl = layer.infoUrl(clickPos);
    }
    return this.httpClient.get<any>(getFeatureUrl).pipe(
      map((data:any) => this._prepareFeatures(layer, data)),
      catchError(() => of([]))
    );
  }

  private _prepareFeatures(layer:WMTSLayer | WMSLayer | WMSTimeLayer | LayerWrapper, data:{ features:any[] }):Feature[] {
    if (layer instanceof LayerWrapper) {
      layer = layer.innerLayer as WMTSLayer | WMSLayer | WMSTimeLayer;
    }
    if (layer instanceof WMSTimeLayer && layer.popupChart) {
      const timeAttributeName = 'date';
      const currentDateFeature = data.features.find(
        item => new Date(item.properties[timeAttributeName]).getTime() === (layer as WMSTimeLayer).currentInterval.start.getTime()
      );
      const feature = new Feature();
      feature.properties = currentDateFeature.properties;
      feature.layer = layer;
      feature.geometry = currentDateFeature.geometry;
      const attribute = layer.columns[0];
      feature.chartData = [
        {
          name: attribute.alias,
          series: data.features.map(item => ({
            value: item.properties[attribute.name],
            name: new Date(item.properties[timeAttributeName])
          }))
        }
      ];
      return [feature];
    } else {
      const features:Feature[] = [];
      data.features.forEach((item:{ geometry; properties }) => {
        try {
          const f:Feature = new Feature();
          f.properties = item.properties;
          f.layer = layer;
          f.geometry = item.geometry;
          features.push(f);
        } catch (e) {
          console.error(e);
        }
      });
      return features;
    }
  }

  private _getResults(source$:Observable<Feature[]>) {
    return source$
      .pipe(
        map((data:any) => {
          let features = [];
          data.forEach(bunch => {
            features = [...features, ...bunch];
          });
          return features;
        }),
        catchError(() => of([]).toPromise())
      )
      .toPromise();
  }
}
