interface ILayerPermalink {
  id:number;
  order:number;
  brightness:number;
  opacity:number;
  contrast:number;
}

export class Permalink {
  extent = '';
  layers:ILayerPermalink[] = [];
}
