import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'units',
  templateUrl: 'units_input.html',
  styleUrls: ['units_input.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnitsInput),
      multi: true
    }
  ]
})
export class UnitsInput implements ControlValueAccessor {
  units:'m' | 'km' = 'm';

  writeValue(units:'m' | 'km') {
    this.units = units;
    this.propagateChange(units);
  }

  registerOnChange(fn:any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  private propagateChange = (_:any) => {};
}
