import { HttpErrorResponse } from '@angular/common/http';
import { Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { PluginClass } from 'shared/classes';
import { Button, DropMenuButtonComponent } from 'shared/components';
import { IContainer, IContentChild, ILayer, IMenu, IPluginInterface } from 'shared/interfaces';
import { LayersFactory } from 'shared/services';
import { LayersStore } from 'shared/stores/LayersStore';
import { Utils } from '../../../../../shared/classes/Utils';
import { CartometricService } from './cartometric.service';
import { Operation } from './classes';

export abstract class AbstractCartometricOperation extends PluginClass implements IContentChild, IPluginInterface {
  @ViewChild('form') form:NgForm;

  @Input() featuresLimit:number;

  settings:Operation;

  layers:ILayer[] = [];
  parentComponent:IContainer;

  error:any;

  active = true; // нужно чтобы стоял true, иначе color-picker не успевает получить нужные параметры
  processing = false;

  protected btn:Button = null;
  protected menu:IMenu;

  constructor(
    protected layersStore:LayersStore,
    protected layersFactory:LayersFactory,
    protected cartometricService:CartometricService
  ) {
    super();
    this.subscribeForLayers();
  }

  onLoad() {
    this.parentComponent.onHide.subscribe(() => {
      this.active = false;
      this.reset();
    });
  }

  activate() {
    this.active = true;
  }

  deactivate() {
    this.active = false;
  }

  enable() {}

  disable() {}

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;

        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.btn.setOptions(this.buttonConfig);

          this.btn.onClickMe = () => {
            if (this.parentComponent) { this.parentComponent.open(); }
            this.activate();
            (this.menu as DropMenuButtonComponent).getMenuBtn().setActive(false);
          };

          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  createLayer() {
    this.processing = true;
    this.cartometricService
      .createLayer(this.prepareData())
      .pipe(
        finalize(() => {
          this.processing = false;
        })
      )
      .subscribe(this.onSuccess.bind(this), this.handleError.bind(this));
  }

  protected subscribeForLayers() {
    this.layersStore.getActiveLayers().subscribe(data => {
      this.layers = data.filter(layer => layer.type === 'wms' && layer.simple);
    });
  }

  protected onSuccess(response:any) {
    const options:any = {
      type: 'wms',

      order: 10000,

      visible: true,
      editable: false,
      popupImage: false,
      searchable: true,

      map: this.settings.source.map,

      name: response.title,
      geomType: response.geom_type,

      mapnikId: response.map.id,
      id: response.layer_id,
      url: response.real_url,
      layerName: typeof response.layers === 'string' ? response.layers : undefined,
      simple: response.simple,

      table: response.table ? response.table : '',
      schema: response.schema ? response.schema : '',
      datasource_id: response.datasource_id ? response.datasource_id : null,
      mapnikDatasourceId: response.mapnik_datasource_id ? response.mapnik_datasource_id : null
    };

    const newLayer = this.layersFactory.getLayer(options);
    this.layersStore.setLayers([newLayer]);

    this.parentComponent.close();
  }

  protected handleError(error:HttpErrorResponse) {
    this.displayError(Utils.getErrorMsg(error));
  }

  protected displayError(error:string, time:number = 5000) {
    this.error = error;
    setTimeout(() => {
      this.error = null;
    }, time);
  }

  protected abstract reset():void;

  protected abstract prepareData():any;
}
