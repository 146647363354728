import {Component, ElementRef, ViewChild} from '@angular/core';
import {Button, LayerCatalogComponent as SharedLayerCatalog} from 'shared/components';
import { ScrollWidget } from '@geoanalitika/widgets';
import { LayersStore } from 'shared/stores/LayersStore';
import {ILayer, IManageableLayer, IMenu, IPluginInterface, IScene, ITool, IToolLayer} from 'shared/interfaces';
import {TMSLayer} from 'shared';
import {MapService} from '../../../../../shared/services';
import {DomUtils} from '../../../../../shared/classes/DomUtils';

@Component({
  selector: 'my-layers',
  templateUrl: 'layer_list_catalog.component.html',
  styleUrls: ['layer_list_catalog.component.less']
})
export class LayerListCatalogDesktopComponent extends SharedLayerCatalog {
  @ViewChild('dopTools') dopTools:ElementRef;
  @ViewChild('scroll') scroll:ScrollWidget;

  layerFilter = '';
  currentLayer:ILayer;
  currentBtnDom:HTMLElement = null;
  showDopTools:boolean = false;

  private scenePlugs:IScene[] = [];
  toolPlugins:IToolLayer[] = [];
  opacityComponent:IToolLayer; // компонент для изменения цветовых характеристик слоя

  layerTreeOptions = {
    childrenField: 'subLayers'
  };

  constructor(layersStore:LayersStore, private mapService:MapService) {
    super(layersStore);
  }

  onNodeClick(node, event) {
    this.currentLayer = node.data as ILayer;
    for (const tool of this.toolPlugins) {
      if (tool.activateMode === 'layer-select') {
        if (this.currentLayer) {
          (tool as IToolLayer).activateToolLayer(this.currentLayer);
        } else {
          (tool as IToolLayer).deactivateTool();
        }
      }
    }
    this.layersStore.setSelectedLayer(this.currentLayer);
  }

  isActive(node):boolean {
    return node.data === this.currentLayer;
  }

  protected onLayerstSet(layers:ILayer[]) {
    const count = this.getLayersCountRecursive(layers, undefined);
    this.setLayersOrderRecursive(layers, count);
  }

  private setLayersOrderRecursive(layers:ILayer[], order:number):number {
    for (const layer of layers) {
      if (layer.isGroup) {
        order = this.setLayersOrderRecursive(layer.subLayers, order);
      } else {
        layer.order = order;
        order--;
      }
    }
    return order;
  }

  private getLayersCountRecursive(layers:ILayer[], visible:Boolean):number {
    let ret:number = 0;
    for (const layer of layers) {
      if (layer.isGroup) {
        ret += this.getLayersCountRecursive(layer.subLayers, visible);
      } else {
        if (visible === undefined) {
          ret++;
        } else if (layer.visible === visible) {
          ret++;
        }
      }
    }
    return  ret;
  }

  private setLayersAttrsRecursive(layers:ILayer[], attrs:any) {
    for (const layer of layers) {
      if (layer.isGroup) {
        this.setLayersAttrsRecursive(layer.subLayers, attrs);
      } else {
        Object.assign(layer, attrs);
      }
    }
  }

  // Приблизиться к слою
  goToLayer(layer:ILayer) {
    this.mapService.changeMapBound$.next(layer.extent);
  }

  showTools(button:HTMLElement, targetElement:HTMLElement, layer:IManageableLayer) {
    // Скрыть меню на повторный клик
    if (this.showDopTools ) {
      this.hideDopTools();
    } else {

      this.currentLayer = layer;
      this.currentBtnDom = button;

      const pos = DomUtils.calculateOffsetFromSelector(button, '.scroll-container');
      // Расчет расположения меню инструментов относительно кнопки вызова
      const padding = button.clientHeight + 10; // magic number
      this.dopTools.nativeElement.style.top = (pos.y + padding) + 'px';
      setTimeout(() => {
        this.showDopTools = true;
      }, 100);
    }
  }

  getVisibilityStyle(isVisible:boolean):string {
    return isVisible ? 'visible' : 'hidden';
  }

  // Скрыть меню инструментов
  hideDopTools() {
    this.currentLayer = this.currentBtnDom = null;
    this.showDopTools = false;
  }

  activateOtherTool(tool:ITool, layer:ILayer) {
    (tool as IToolLayer).activateToolLayer(layer);
    this.currentLayer = null;
  }

  setLayerVisibility(layer:ILayer) {
    if (!layer.notOnScale) {
      layer.visible = !layer.visible;
      this.layersStore.updateLayer(layer);
    }
  }

  setGroupVisibility(group:ILayer) {
    const visibleCount = this.getLayersCountRecursive(group.subLayers, true);
    if (visibleCount > 0) {
      this.setLayersAttrsRecursive(group.subLayers, {visible: false});
    } else {
      this.setLayersAttrsRecursive(group.subLayers, {visible: true});
    }
  }

  // убрать слой из списка
  deleteClick(layer:ILayer) {
    if ((layer as TMSLayer).isEdited) {
      return;
    }

    layer.visible = false;
    layer.order = 0;
    this.layersStore.updateLayer(layer);
  }

  showColorWidget(layer:ILayer):void {
    if (!this.opacityComponent) {
      return;
    }
    this.opacityComponent.activateToolLayer(layer);
  }

  onFilterClick(layer:ILayer):void {
    const tool:IToolLayer = this.toolPlugins.find((item:IToolLayer) => item.className === 'attributeFilterButton');

    if (tool) {
      tool.activateToolLayer(layer);
    }
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;

        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.btn.setOptions(this.buttonConfig);

          if (this.active) {
            this.btn.setActive(true);
          }

          this.btn.onClickMe = () => {
            this.active = true;
          };
          this.btn.onDeactivate = () => {
            this.active = false;
          };

          this.menu.addBtn(this.btn);
        });
        break;
      case 'Scene':
        this.scenePlugs.push(pi as IScene);
        break;
      case 'Tool':
        this.toolPlugins.push(pi as IToolLayer);
        break;
      case 'ColorWidget':
        this.opacityComponent = pi as IToolLayer;
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
      case 'Scene':
        this.scenePlugs = [];
        break;
      case 'Tool':
        this.toolPlugins = [];
        break;
      case 'ColorWidget':
        this.opacityComponent = null;
        break;
    }
  }
}
