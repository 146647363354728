const DateFormats = ['date'];

export class Attribute {
  name = '';
  alias = '';
  inTooltip = false;
  inPopup = false;
  inAttr = true;
  aliasInPopup = false;
  required = false;
  is_pk = false;
  inputType = 'text';
  crowdsourceSettings:CrowdsourceSettings;
  dictionary_id:number = null;
  dictionary:any[];
  timeField = false;
  editable = true;

  private _type:string;

  set type(value) {
    this._type = value;
    this.inputType = this.getAttrType(value);
  }

  get type() {
    return this._type;
  }

  isNumber():boolean {
    return this.attrIsNumeric(this.type);
  }

  isDate():boolean {
    return DateFormats.includes(this.type);
  }

  private attrIsNumeric(type:string) {
    const numericPostgreSQLTypes = ['integer', 'smallint', 'bigint', 'decimal', 'real', 'double precision', 'float'];
    const isNumeric = numericPostgreSQLTypes.indexOf(type) !== -1;
    return isNumeric || type.startsWith('numeric');
  }

  private getAttrType(type:string) {
    if (type === 'date' || type === 'datetime') {
      return 'date';
    } else if (this.attrIsNumeric(type)) {
      return 'number';
    } else if (type === 'boolean') {
      return 'checkbox';
    } else {
      return 'text';
    }
  }
}

interface CrowdsourceSettings {
  defaultValue:any;
  order:number;
  required:boolean;
  hidden:boolean;
  annotation:string;
}
