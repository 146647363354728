import { Component, ElementRef, OnInit } from '@angular/core';
import { PluginClass } from '../../classes/Plugin';
import { Utils } from '../../classes/Utils';
import { IActivator, IPluginInterface, ITool } from '../../interfaces';

@Component({
  selector: 'activator',
  template: ''
})
export class ActivatorComponent extends PluginClass implements IActivator, OnInit {
  private groups:Map<string, ITool[]> = new Map<string, ITool[]>();

  constructor(private el:ElementRef) {
    super();
  }

  ngOnInit() {
    Utils.removeElement(this.el.nativeElement as HTMLElement);
  }

  addInterface(name:string, pi:IPluginInterface) {
    console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
  }

  removeInterface(name:string) {}

  activateComponent(tool:ITool):Promise<any> {
    const group:string = tool.getGroup();

    if (!this.groups.has(group)) {
      return Promise.reject('Invalid group');
    }

    // получам массив активных компонентов, кроме текущего
    const activePlugins = this.groups.get(group).filter((value:ITool) => value !== tool && value.isActive());

    if (activePlugins.length) {
      return new Promise(resolve => {
        // Запрашиваем деактивацию у текущего плагина
        (activePlugins[0] as ITool).deactivateTool().then(
          () => {
            tool.activateTool();
            resolve();
          },
          () => {
            tool.deactivateTool();
            resolve();
          }
        );
      });
    } else {
      tool.activateTool();
      return Promise.resolve();
    }
  }

  setConnect(tool:ITool) {
    const group:string = tool.getGroup();

    if (group.length === 0) {
      return;
    }

    if (this.groups.has(group)) {
      this.groups.get(group).push(tool);
    } else {
      this.groups.set(group, [tool]);
    }
  }

  delConnect(tool:ITool) {
    const group:string = tool.getGroup();
    if (this.groups.has(group)) {
      this.groups.delete(group);
    }
  }
}
