import { Component } from '@angular/core';
import { DragPath, LatLng, LatLngExpression, LayerEvent, LayerGroup, Map, Polygon, TileLayer } from 'leaflet';
import {combineLatest, Subject} from 'rxjs';
import 'shared/assets/lib/Path.Drag.js';
import { Bounds, PluginClass, Point } from 'shared/classes';
import { IContainer, ILayer, IPluginInterface, ISimpleMap } from 'shared/interfaces';
import { MapService } from '../../services/services';
import {DrawLayer} from '../../../../../shared/classes/LeafletLayer/DrawLayer.class';
import {EventEmitter} from 'events';

@Component({
  selector: 'minimap',
  templateUrl: 'minimap.component.html',
  styleUrls: ['minimap.component.less']
})
export class MinimapComponent extends PluginClass implements ISimpleMap {
  parentComponent:IContainer;
  loaded = new Subject<void>();

  private active = false;
  private mainMapPlugin:ISimpleMap;
  private baseLayer:ILayer;
  private baseMap = new LayerGroup<TileLayer>();
  private map:Map;
  private bounds:Bounds;
  private mapScale:number;
  private zoomOffset = 4;
  private pathGroup = new LayerGroup();
  private pathOptions:any[] = [
    {
      color: '#fff',
      weight: 4,
      dashArray: '1 5',
      fillOpacity: 0,
      dragging: true
    },
    {
      color: '#d0021b',
      weight: 2,
      dashArray: '1 5',
      fillOpacity: 0,
      dragging: true
    }
  ];

  constructor(private mapService:MapService) {
    super();
    combineLatest([mapService.currentMapBound$, mapService.currentMapScale$, mapService.currentBaseMap$]).subscribe(
      ([bounds, scale, baseLayer]) => {
        this.bounds = bounds;
        this.mapScale = scale;
        this.baseLayer = baseLayer;
        if (this.active) {
          this.setBounds(this.bounds);
          this.setBaseMap();
        }
      }
    );
  }

  onLoad() {
    this.createMap();
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'SimpleMap':
        this.mainMapPlugin = pi as ISimpleMap;
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'SimpleMap':
        this.mainMapPlugin = null;
        break;
    }
  }

  getCenter():Point {
    const point = this.map.getCenter();
    return new Point(point.lat, point.lng);
  }

  createMap() {
    this.map = new Map('minimap', {
      zoomControl: false,
      center: new LatLng(60.74, 69.91),
      zoom: 13,
      minZoom: 1,
      maxZoom: 22,
      inertia: false,
      fadeAnimation: false,
      zoomAnimation: false,
      attributionControl: false,
      doubleClickZoom: false,
      scrollWheelZoom: false,
      dragging: false
    });
    this.pathGroup.addTo(this.map);
    this.baseMap.addTo(this.map);
  }

  getBounds():Bounds {
    const b = this.map.getBounds();
    const bounds = new Bounds(
      new Point(b.getNorthWest().lat, b.getNorthWest().lng),
      new Point(b.getSouthWest().lat, b.getSouthWest().lng),
      new Point(b.getSouthEast().lat, b.getSouthEast().lng),
      new Point(b.getNorthEast().lat, b.getNorthEast().lng)
    );
    return bounds;
  }

  setBounds(bounds:Bounds) {
    this.setView();
    this.pathGroup.clearLayers();
    this.createFrame(bounds);
    this.bounds = bounds;
  }

  activate() {
    this.active = true;
    setTimeout(() => {
      this.map.invalidateSize();
      this.setBaseMap();
      this.setBounds(this.bounds);
    });
  }

  deactivate() {
    this.active = false;
    this.pathGroup.clearLayers();
  }

  createDrawLayer():DrawLayer {
    return null;
  }

  redraw() {
    this.map.invalidateSize();
  }

  refreshMap() {}

  getLayers() {
    return [this.baseLayer];
  }

  private setBaseMap() {
    this.baseMap.clearLayers();
    if (!this.baseLayer) {
      return;
    }
    if (this.baseLayer.subLayers.length) {
      this.baseLayer.subLayers.forEach(layer => {
        this.baseMap.addLayer(new TileLayer(layer.url));
      });
    } else {
      this.baseMap.addLayer(new TileLayer(this.baseLayer.url));
    }
  }

  private createFrame(bounds:Bounds) {
    const coords:LatLngExpression[] = [
      [bounds.xmax.x, bounds.xmax.y],
      [bounds.ymax.x, bounds.ymax.y],
      [bounds.xmin.x, bounds.xmin.y],
      [bounds.ymin.x, bounds.ymin.y]
    ];

    this.pathOptions.forEach(style => {
      const path:DragPath = new Polygon(coords, style) as any;
      path.addTo(this.map);
      path.dragging.enable();

      path.on('drag', (event:LayerEvent) => {
        this.pathGroup
          .getLayers()
          .filter(layer => layer !== path)
          .forEach(layer => {
            (layer as any).setLatLngs(event.target.getLatLngs());
          });
      });

      path.on('dragend', (event:LayerEvent) => {
        const latlngs = event.target.getLatLngs();
        const newBounds = new Bounds(
          new Point(latlngs[0][0].lat, latlngs[0][0].lng),
          new Point(latlngs[0][1].lat, latlngs[0][1].lng),
          new Point(latlngs[0][2].lat, latlngs[0][2].lng),
          new Point(latlngs[0][3].lat, latlngs[0][3].lng)
        );
        this.mapService.changeMapBound$.next(newBounds);
      });

      this.pathGroup.addLayer(path);
    });
  }

  private setView() {
    const center = this.mainMapPlugin.getCenter();
    const zoom = this.mapScale - this.zoomOffset < 2 ? 1 : this.mapScale - this.zoomOffset;
    this.map.setView(new LatLng(center.x, center.y), zoom, { animate: false });
  }
}
