import { Pipe, PipeTransform } from '@angular/core';
import { ILayer } from 'shared/interfaces';

const includes = require('lodash/includes');

@Pipe({ name: 'filterTree' })
export class FilterTreePipe implements PipeTransform {
  transform(value:ILayer[], options:{ filterBy:string; text:string }):ILayer[] {
    if (!options.text) {
      return value;
    }

    const layers:ILayer[] = [];
    this.filterLayers(value, layers, options);
    return layers;
  }

  private filterLayers(inputArray:ILayer[], outputArray:ILayer[], options:{ filterBy:string; text:string }) {
    inputArray.forEach(layer => {
      if (!layer.isGroup && includes(layer[options.filterBy].toLowerCase(), options.text.toLowerCase())) {
        outputArray.push(layer);
      }
      this.filterLayers(layer.subLayers, outputArray, options);
    });
  }
}
