import { Injectable } from '@angular/core';
import { ArcGISLayer } from 'shared/classes/LeafletLayer/ArcGISLayer.class';
import { GeoJsonLayer } from 'shared/classes/LeafletLayer/GeoJsonLayer.class';
import { GeoserverLayer } from 'shared/classes/LeafletLayer/GeoserverLayer/GeoserverLayer.class';
import { GroupLayer } from 'shared/classes/LeafletLayer/GroupLayer.class';
import { TMSLayer } from 'shared/classes/LeafletLayer/TMSLayer.class';
import { WMSLayer } from 'shared/classes/LeafletLayer/WMSLayer.class';
import { WMSTimeLayer } from 'shared/classes/LeafletLayer/WMSTimeLayer.class';
import { WMTSLayer } from 'shared/classes/LeafletLayer/WMTSLayer.class';
import { ILayer, ILayerOptions } from '../interfaces';
import {Utils} from 'shared/classes';
import {NativeLayer} from '../classes/LeafletLayer/NativeLayer.class';
import {MarkerClusterLayer} from '../classes/LeafletLayer/MarkerClusterLayer.class';
import {MarkerDynamicLayer} from '../classes/LeafletLayer/MarkerDynamicLayer.class';

@Injectable()
export class LayersFactory {
  getLayer(options:ILayerOptions):ILayer {
    switch (options.type.toLowerCase()) {
      case 'tms':
        return new TMSLayer(options);
      case 'wms':
        if (options.mapnikId) {
          return new NativeLayer(options);
        } else {
          return new WMSLayer(options);
        }
      case 'geojson':
        return new MarkerClusterLayer(options);
      case 'dynamic':
        return new MarkerDynamicLayer(options);
      case 'geoserver':
        return new GeoserverLayer(options);
      case 'wmts':
        return new WMTSLayer(options);
      case 'arcgis':
        return new ArcGISLayer(options);
      case 'wms-t':
        if (options.mapnikId) {
          options.layerName = options.mapnikId.toString();
        }
        return new WMSTimeLayer(options);
      default:
        return new GroupLayer(options);
    }
  }

  public constructLayerList(config:any, leafletMap:any, isBaseLayer:boolean = false):ILayer[] {
    const drainLayerGroups = (groups:any, layerArray:ILayer[]):void => {
      for (const group of groups) {
        const options:ILayerOptions = {};
        options.name = group.title;
        options.type = group.type ? group.type : '';
        options.opacity = group.opacity;
        options.visible = group.visible;
        options.brightness = group.brightness;
        options.contrast = group.contrast;
        options.order = group.order;
        options.subLayers = [];
        options.id = group.layer_id ? group.layer_id : null;
        options.url = group.real_url;
        options.tileSize = group.tile_size;
        options.layerName = typeof group.layers === 'string' ? group.layers : undefined;
        options.columns = group.columns;
        options.attribution = group.attribution || '';
        options.extent = group.extent || null;
        options.simple = group.simple;
        options.pk = group.pk;
        options.table = group.table ? group.table : '';
        options.schema = group.schema ? group.schema : '';
        options.datasource_id = group.datasource_id ? group.datasource_id : null;
        options.mapnikDatasourceId = group.mapnik_datasource_id ? group.mapnik_datasource_id : null;
        options.mapnikId = group.map ? group.map.id : undefined;
        options.map = leafletMap;
        options.isGroup = group.is_group;
        options.isBaseLayer = isBaseLayer;
        options.rating = group.rating;
        options.geomType = group.geom_type;
        options.editable = group.editable;
        options.popupImage = group.popup_image;
        options.searchable = group.searchable || false;
        // необходимо для отображения превьюшек базовых карт
        options.image = group.image || null;
        options.maxNativeZoom = group.maxNativeZoom;
        options.popupChart = group.popup_chart;

        if (group.crowdsource) {
          options.crowdsource = group.crowdsource;
          options.crowdsourceBtnText = group.crowdsource_btn_text;
        }

        if (group.geom_type === 'geometry') {
          options.geomType = 'polygon';
        }

        if (group.type === 'wms-t') {
          const bounds = { start: new Date(group.time_bounds.start), end: new Date(group.time_bounds.end) };
          options.timeBounds = bounds;
          options.currentInterval = { start: Utils.copyDate(bounds.end), end: Utils.copyDate(bounds.end) };
        }

        const newLayer:ILayer = this.getLayer(options);

        layerArray.push(newLayer);

        if (Array.isArray(group.sublayers) && group.sublayers.length) {
          drainLayerGroups(group.sublayers, newLayer.subLayers);
        }
      }
    };

    const layers:ILayer[] = [];
    drainLayerGroups(config, layers);

    return layers;
  }
}
