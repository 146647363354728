import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Attribute } from 'shared/classes/Attribute';
import { LayersFactory } from 'shared/services';
import { LayersStore } from 'shared/stores/LayersStore';
import { AbstractCartometricOperation } from '../abstract_cartometric_operation_form';
import { CartometricService } from '../cartometric.service';
import { BaseStyle, DistributionMethod, ThematicMap } from '../classes';
import { IRangeItem } from '../interfaces';
import { adjustPalette, scale } from '../utils';
import { isObject } from 'util';

interface Method {
  name:string;
  value:DistributionMethod;
}

@Component({
  selector: 'thematic-map',
  templateUrl: 'thematic_map.component.html',
  styleUrls: ['thematic_map.component.less']
})
export class ThematicMapComponent extends AbstractCartometricOperation implements OnInit {
  settings = new ThematicMap();
  methods:Method[] = [
    { name: 'Вручную', value: 'manual' },
    { name: 'Равные интервалы', value: 'auto' },
    { name: 'Квантили', value: 'quantile' }
  ];
  numericAttributes:Attribute[] = [];

  minVal:number;
  maxVal:number;

  colors:string[] = [];
  range:number[] = [];
  description:string[] = [];

  editableRange:IRangeItem[] = [];

  customPalette = false;
  defaultPalette = ['#fef6b5', '#eb4a40'];
  showPalettesList = false;

  maxRangesNum = 20;

  constructor(layersStore:LayersStore, layersFactory:LayersFactory, cartometricService:CartometricService) {
    super(layersStore, layersFactory, cartometricService);
  }

  ngOnInit() {
    this.settings.method = this.methods[1];
  }

  selectPalette(palette:string[]) {
    this.customPalette = false;
    const newPalette = scale(palette, this.settings.rangesNum);
    this.colors = newPalette;
    this.editableRange.forEach((item, idx) => {
      item.color = newPalette[idx] || null;
    });
    this.showPalettesList = false;
  }

  getMinMax() {
    if (!this.settings.source) {
      return;
    }

    this.cartometricService.getMinMax(this.settings.source.id, this.settings.attribute.name).subscribe(data => {
      if (data.min === data.max) {
        this.error = `Невозможно разбить диапазон на интервалы,
            так как минимальное значение в столбце равно максимальному.
            Попробуйте выбрать другой атрибут слоя.`;
        return;
      }

      this.error = null;

      this.minVal = data.min;
      this.maxVal = data.max;
      this.countRanges().subscribe(() => {
        this.colors = scale(this.defaultPalette, this.settings.rangesNum);
        this.generateRange();
      });
    });
  }

  resetAttribute() {
    this.settings.attribute = null;
    this.settings.rangesNum = 5;
    this.minVal = 1;
    this.maxVal = 10;
    this.settings.method = this.methods[1];

    if (this.settings.source) {
      this.numericAttributes = this.settings.source.columns
        .filter(attr => attr.inputType === 'number')
        .sort((a:Attribute, b:Attribute) => a.name.localeCompare(b.name));
    }
  }

  selectRangesNum() {
    if (!this.active) {
      return;
    }

    if (this.customPalette) {
      this.colors = adjustPalette(this.colors, this.settings.rangesNum);
    } else {
      this.colors = scale(this.colors, this.settings.rangesNum);
    }

    this.countRanges().subscribe(() => {
      this.generateRange();
    });
  }

  selectRangesMethod() {
    if (!this.active) {
      return;
    }

    this.description = [];

    this.countRanges().subscribe(() => {
      this.generateRange();
    });
  }

  onRangeUpdate() {
    this.settings.rangesNum = this.editableRange.length - 1;
    this.colors = [];
    this.range = [];
    this.description = [];

    this.editableRange.forEach(item => {
      if (item.color) {
        this.colors.push(item.color);
      }
      this.range.push(item.value);
      this.description.push(item.description);
    });
  }

  get paletteGradient():string {
    return this.editableRange.filter(item => !!item.color).map(item => item.color).join(', ');
  }

  protected reset() {
    this.form.resetForm({ rangesNum: 5 });
    this.settings = new ThematicMap();
  }

  protected prepareData() {
    return {
      type: this.settings.source.geomType,
      style: this.getStyle(),
      layerId: this.settings.source.id,
      filter: this.settings.source.filter,
      name: this.settings.name,
      mapStyle: 'thematic'
    };
  }

  private getStyle():BaseStyle {
    const colors = [];
    const range = [];
    const description = [];

    this.editableRange.forEach(item => {
      if (item.color) {
        colors.push(item.color);
      }
      range.push(item.value);
      description.push(item.description);
    });

    return new BaseStyle({
      colors,
      range,
      description,
      name: this.settings.attribute.name
    });
  }

  private countRanges():Observable<void> {
    if (!this.active) {
      return;
    }

    if (this.settings.method && this.settings.method.value === 'quantile') {
      return this.cartometricService
        .getQuantileIntervals(this.settings.source.id, this.settings.attribute.name, this.settings.rangesNum)
        .pipe(
          map(data => {
            this.range = [this.minVal, ...data];
          })
        );
    } else {
      return this.cartometricService.getMinMax(this.settings.source.id, this.settings.attribute.name).pipe(
        map(data => {
          this.minVal = data.min;
          this.maxVal = data.max;

          const step = +(this.maxVal / this.settings.rangesNum).toFixed(2);
          const ranges:number[] = [this.minVal];
          let i = 1;
          while (i < this.settings.rangesNum) {
            ranges.push(+(step * i).toFixed(3));
            i++;
          }
          this.range = [...ranges, this.maxVal];
        })
      );
    }
  }

  private generateRange() {
    this.editableRange = this.range.map((value:number, idx:number) => ({
      value,
      color: this.colors[idx] || null,
      description: this.description[idx] || null
    }));
  }
}
