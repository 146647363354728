import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { PluginClass } from 'shared/classes';
import { IDomPanel, IPluginInterface, ITool } from 'shared/interfaces';
import { LayersStore } from 'shared/stores/LayersStore';

@Component({
  selector: 'layersNumBtn',
  template: `
    <div class="layersNumBtn" [class.activeBtn]="active" (click)="btnClick()">{{ text }}</div>
  `,
  styleUrls: ['layers_num_btn.less']
})
export class LayersNumBtn extends PluginClass {
  layersCount = 0;
  active = false;
  domNode:HTMLElement;

  // TODO: вынести стили в css
  @HostBinding('style.width.px') width = 40;
  @HostBinding('style.display') display = 'block';
  @HostBinding('style.overflow.px') overflow = 'hidden';

  @Input() className:string;
  @Input() title:string;
  @Input() text:string;
  @Input() action:string;
  @Input() activeMode = false;

  private _slidePanel:IDomPanel;
  private _toolPlugins:ITool[] = [];

  constructor(el:ElementRef, layersStore:LayersStore) {
    super();
    this.domNode = el.nativeElement as HTMLElement;
    layersStore.getActiveLayers().subscribe(data => {
      this.layersCount = data.length;
    });
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'slidepanel':
        this._slidePanel = pi as IDomPanel;
        this._slidePanel.toggle();
        break;
      case 'Tool':
        this._toolPlugins.push(pi as ITool);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'slidepanel':
        this._slidePanel = null;
        break;
      case 'Tool':
        this._toolPlugins = [];
        break;
    }
  }

  btnClick() {
    this.active = !this.active;
    this._slidePanel.toggle();
    if (!this.active) {
      this._toolPlugins.forEach(plugin => {
        plugin.deactivateTool();
      });
    }
  }
}
