import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Feature } from '../../../';

@Injectable()
export class DocumentService {
  constructor(private httpClient:HttpClient) {}

  getAttachments(feature:Feature):Observable<any> {
    const pk = feature.properties[feature.layer.pk];
    const url = 'attachments/';
    const params:HttpParams = new HttpParams().set('pk', pk).set('layer_id', feature.layer.id.toString());

    return this.httpClient.get<any>(url, { params });
  }

  getRating(feature:Feature):Observable<any> {
    const pk = feature.properties[feature.layer.pk];
    const url = 'rating/';
    const params:HttpParams = new HttpParams().set('pk', pk).set('layer_id', feature.layer.id.toString());

    return this.httpClient.get<any>(url, { params });
  }

  setRating(layerId:Number, featureId:string, rating:Number):Observable<any> {
    const data = {
      layer_id: layerId, pk: featureId, rating
    };
    return this.httpClient.post('rating/', data);
  }

  saveFile(formdata:FormData):Observable<any> {
    return this.httpClient.post<any>('upload/', formdata);
  }

  deleteFile(params:HttpParams):Observable<any> {
    return this.httpClient.delete('delete/', { params });
  }
}
