import { FeatureGroup, GeoJSON, Icon, LatLng, Map, Marker } from 'leaflet';
import { Observable } from 'rxjs';
import { Feature } from '../../../classes/Feature';
import { Point } from '../../../classes/Point';
import { IMapResult, IPluginInterface, ISearch } from '../../../interfaces';

export class MapResult implements IPluginInterface, IMapResult {
  searchPlugin:ISearch;

  private _map:Map;
  private resultLayer:FeatureGroup;

  // для подсветки вспомогательных объектов
  // (например, области поиска на карте)
  private altStyle = {
    color: '#6289c4',
    fillColor: '#6289c4',
    weight: 2
  };
  // для подсветки объектов слоя
  private defaultStyle = {
    fillColor: '#C05353',
    color: '#C05353'
  };
  private defaultOutline = {
    fillOpacity: 0,
    color: '#FFFFFF',
    weight: 5
  };

  set map(map:Map) {
    this._map = map;
    this.createResultLayer();
  }

  get map():Map {
    return this._map;
  }

  showFeature(features:Feature[], altStyle = false):Promise<string> {
    this.clearResult();
    return new Promise(resolve => {
      features.forEach(feature => {
        if (!feature.geometry) {
          this.getGeometry(feature).subscribe(features => {
            feature.geometry = features[0].geometry;
            resolve('success');
            this.drawFeature(feature, altStyle);
          });
        } else {
          resolve('success');
          this.drawFeature(feature, altStyle);
        }
      });
    });
  }

  clearResult() {
    // TODO: проверить почему вызывается более 1 раза
    if (this.resultLayer) {
      this.resultLayer.clearLayers();
    }
  }

  goToFeature(feature:Feature[] | Feature):Promise<Point> {
    const fitFeature = (featureData:Feature | Feature[]) => {
      const geojson = new GeoJSON();
      let isPoint = false;

      if (Array.isArray(featureData)) {
        featureData.forEach(f => {
          geojson.addData(f.geometry);
        });
      } else {
        geojson.addData(featureData.geometry);
        const geomType = (featureData as Feature).geometry.type.toLowerCase();
        isPoint = geomType === 'point' || geomType === 'multipoint';
      }

      const bounds = geojson.getBounds();
      const pointZoom = this.map.getZoom() > 10 ? this.map.getZoom() : 10;
      this.map.fitBounds(bounds, { animate: false, maxZoom: isPoint ? pointZoom : null });

      const featureCenter = geojson.getBounds().getCenter();

      return new Point(featureCenter.lat, featureCenter.lng);
    };

    return new Promise(resolve => {
      if (Array.isArray(feature)) {
        const point = fitFeature(feature);
        resolve(point);
      } else {
        if (!(feature as Feature).geometry) {
          this.getGeometry(feature as Feature).subscribe(features => {
            (feature as Feature).geometry = features[0].geometry;
            const point = fitFeature(feature);
            resolve(point);
          });
        } else {
          const point = fitFeature(feature);
          resolve(point);
        }
      }
    });
  }

  getFeatureCenter(feature:Feature):Point {
    const object:GeoJSON = new GeoJSON(feature.geometry);
    const center:LatLng = object.getBounds().getCenter();
    return new Point(center.lng, center.lat);
  }

  setMarker(point:Point) {
    const location:Icon = new Icon({
      iconUrl: '/images/loc.png',
      iconSize: [25, 34], // size of the icon
      iconAnchor: [12, 34] // point of the icon which will correspond to marker's location
    });

    const m:Marker = new Marker([point.y, point.x], { icon: location });

    if (!this.resultLayer) {
      this.resultLayer = new FeatureGroup([]);
      this.map.addLayer(this.resultLayer);
    }

    this.resultLayer.addLayer(m);
  }

  goToResultsExtent() {
    this.map.fitBounds(this.resultLayer.getBounds());
  }

  getResultLayer():FeatureGroup {
    return this.resultLayer;
  }

  getGeometry(feature:Feature):Observable<any> {
    return this.searchPlugin.getFeatureGeometry(feature);
  }

  private createResultLayer():void {
    this.resultLayer = new FeatureGroup([]);
    this.map.addLayer(this.resultLayer);
  }

  private drawFeature(f:Feature, altStyle = false) {
    // если точка, то ставим маркер
    switch (f.type) {
      case 'point':
        this.setMarker(new Point((f.geometry.coordinates as number[])[0], (f.geometry.coordinates as number[])[1]));
        break;
      case 'multipoint':
        (f.geometry.coordinates as any[]).forEach((coords:number[]) => {
          this.setMarker(new Point(coords[0], coords[1]));
        });
        break;
      default:
        // для двойной обводки приходится cоздавать 2 объекта
        const whiteBorder:GeoJSON = new GeoJSON(f.geometry);
        (whiteBorder as FeatureGroup).setStyle(this.defaultOutline);

        const geojsonLayer = new GeoJSON(f.geometry);
        (geojsonLayer as FeatureGroup).setStyle(altStyle ? this.altStyle : this.defaultStyle);

        this.resultLayer.addLayer(whiteBorder);
        this.resultLayer.addLayer(geojsonLayer);
        break;
    }
  }
}
