import { Component } from '@angular/core';
import { LayerCatalogComponent as SharedLayerCatalog } from 'shared/components';
import { LayersStore } from 'shared/stores/LayersStore';
import {ILayer} from 'shared/interfaces';

@Component({
  selector: 'my-layers',
  templateUrl: 'layer_catalog.component.html',
  styleUrls: ['layer_catalog.component.less']
})
export class LayerCatalogDesktopComponent extends SharedLayerCatalog {
  _layerFilter = '';

  constructor(layersStore:LayersStore) {
    super(layersStore);
  }

  get layerFilter():string {
    return this._layerFilter;
  }
  set layerFilter(value:string) {
    this._layerFilter = value;
  }

  isItemInclude(layer:ILayer):boolean {
    let ret = !this.layerFilter;
    if (!ret) {
      const filter = this.layerFilter.toLocaleLowerCase();
      if (layer.isGroup) {
        //Есть ли среди потомков слои по фильтру?
        this.layersStore.walkLayersRecursive(layer.subLayers, (layer:ILayer) => {
          ret = ret || (!layer.isGroup && layer.name.toLocaleLowerCase().includes(filter));
          return !ret;
        });
      } else {
        //слой удовлетворяет фильтру?
        ret = layer.name.toLocaleLowerCase().includes(filter);
      }
    }
    return  ret;
  }
}
