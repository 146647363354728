import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'shared/shared.module';
import { MonthPipe } from './month.pipe';
import { TimelineComponent } from './timeline/timeline.component';
import { TimelineGoogleComponent } from './timelineGoogle/timeline-google.component';
import { TimelineService } from './timeline_service';
import { TimePeriodComponent } from './time_period/time_period.component';

@NgModule({
  declarations: [TimelineComponent, TimelineGoogleComponent, TimePeriodComponent, MonthPipe],
  imports: [FormsModule, BrowserModule, SharedModule, HttpClientModule],
  exports: [TimelineComponent, TimelineGoogleComponent],
  entryComponents: [TimelineComponent, TimelineGoogleComponent],
  providers: [TimelineService]
})
export class TimelineModule {}
