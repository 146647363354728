import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import { LayersStore } from 'shared/stores/LayersStore';
import {ICoordsTransform, ILayer, ILoader, IPluginInterface, IPosition, ISearch, ISearchResult, ITool} from 'shared/interfaces';
import {PluginClass, Feature, Point, Utils} from 'shared/classes';
import {Polygon} from 'geojson';
import {GeoanalitikaService} from 'shared/components/search_engine/geoanalitika_service';

@Component({
  selector: 'map-tooltip',
  template: '',
})
export class MapTooltipComponent extends PluginClass implements ITool, OnInit {
  groupName:string;

  activateMode:string = null;
  private active = false;
  private geoanalitikaLayers:ILayer[] = [];

  private identifyResPlugins:ISearchResult[] = [];
  private searchPlugin:ISearch;
  private transformPlugin:ICoordsTransform;
  private map:IPosition;

  constructor(
    private geoanalitikaService:GeoanalitikaService,
    layersStore:LayersStore
  ) {
    super();

    combineLatest(layersStore.getActiveLayers(), layersStore.getEditLayers())
      .pipe(map(([activeLayers, editLayers]) => (editLayers.length ? editLayers : activeLayers.filter(item => item.searchable))))
      .subscribe(layerList => {
        if (this.active) {
          this.setlayers(layerList);
        }
      });
  }

  setlayers(layerList:ILayer[]) {
    this.geoanalitikaLayers = layerList.filter(item => item.visible)
      .filter(
        layer =>
          !layer.isGroup &&
          layer.type !== 'geoserver' &&
          layer.type !== 'arcgis' &&
          layer.geomType !== 'raster' &&
          layer.type !== 'wmts' &&
          layer.geomType !== 'unknown'
      );
  }

  ngOnInit() {  }

  addInterface(name:string, pi:IPluginInterface) {
    switch (name) {
      case 'IdentifyResult':
        this.identifyResPlugins.push(pi as ISearchResult);
        break;
      case 'Search':
        this.searchPlugin = pi as ISearch;
        break;
      case 'Map':
        this.map = pi as IPosition;
        this.map.mouseMove.pipe(debounceTime(1000))
          .subscribe((point) => this.onMouseStop(point));
        break;
      case 'TransformCoords':
        this.transformPlugin = pi as ICoordsTransform;
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'IdentifyResult':
        this.identifyResPlugins = [];
        break;
    }
  }

  onMouseStop(pointXY:Point) {
    const layers = this.geoanalitikaLayers.filter( layer => {
      return layer.showTooltip && layer.columns && layer.columns.some(attr => attr.inTooltip);
    });
    if (layers[0]) {
      const pointLatLon = this.transformPlugin.getLatLngPoint(pointXY);
      this.searchPlugin.gaSearch(pointXY, layers).then(features => {
        this.clearResult();
        this.showResult(features, true, pointLatLon);
      });
    }
  }

  showResult(features:Feature[], showPlace:boolean, point:Point) {
    if (features.length > 0) {
      features.sort((a:Feature, b:Feature) => {
        return a.layer.order - b.layer.order;
      });
      this.identifyResPlugins.forEach((plug:ISearchResult) => {
        plug.setFeatures([features[0]], showPlace, null, null, point);
      });
    }
  }

  activateTool() {
    this.active = true;
  }

  deactivateTool():Promise<boolean> {
    this.active = false;

    // Закрыть попапы
    this.identifyResPlugins.forEach(plugin => {
      plugin.clearResults();
    });

    return new Promise(resolve => {
      resolve(true);
    });
  }

  isActive() {
    return this.active;
  }

  getGroup() {
    return this.groupName;
  }

  private clearResult() {
    this.identifyResPlugins.forEach((plug:ISearchResult) => {
      plug.clearResults();
    });
  }
}
