import {
  DirectGeometryObject,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
  Position
} from 'geojson';

const regExes:any = {
  trimSpace: /^\s*|\s*$/g,
  removeSpace: /\s*/g,
  splitSpace: /\s+/,
  trimComma: /\s*,\s*/g
};

export class GeometryParser {
  constructor(private _gmlns:string) {}

  geomParse(type:string, node:Node):DirectGeometryObject {
    let geom:DirectGeometryObject;
    switch (type) {
      case 'MultiSurface':
        geom = this._multipolygon(node);
        break;
      case 'MultiLineString':
        geom = this._multilinestring(node);
        break;
      case 'MultiPoint':
        geom = this._multipoint(node);
        break;
      case 'Polygon':
        geom = this._polygon(node);
        break;
      case 'LineString':
        geom = this._linestring(node);
        break;
      case 'Point':
        geom = this._point(node);
        break;
      default:
        throw new TypeError('Unsupported geometry type: ' + type);
    }
    return geom;
  }

  private _multipolygon(node:Node):MultiPolygon {
    const components:Position[][][] = this._getFragmentCoords(node, 'Polygon');
    let multiPol:MultiPolygon;
    if (components.length) {
      multiPol = {
        type: 'MultiPolygon',
        coordinates: components
      };
    }
    return multiPol;
  }

  private _polygon(node:Node):Polygon {
    const components:Position[][] = this._getFragmentCoords(node, 'LinearRing');
    let poly:Polygon;
    if (components.length) {
      poly = {
        type: 'Polygon',
        coordinates: components
      };
    }
    return poly;
  }

  private _multilinestring(node:Node):MultiLineString {
    const components:Position[][] = this._getFragmentCoords(node, 'LineString');
    let line:MultiLineString;
    if (components.length) {
      line = {
        type: 'MultiLineString',
        coordinates: components
      };
    }
    return line;
  }

  private _linestring(node:Node):LineString {
    let nodeList:HTMLCollectionOf<Element>, coordString:string;
    let coords:string[] = [];
    const points:Position[] = [];

    // look for <gml:posList>
    nodeList = (node as Element).getElementsByTagNameNS(this._gmlns, 'posList');
    if (nodeList.length > 0) {
      coordString = this._getChildValue(nodeList[0]);
      coordString = coordString.replace(regExes.trimSpace, '');
      coords = coordString.split(regExes.splitSpace);
      const dim:number = parseInt((nodeList[0].parentNode as Element).getAttribute('srsDimension'), 10);
      let j:number, x:number, y:number, z:number;
      for (let i = 0; i < coords.length / dim; ++i) {
        j = i * dim;
        x = Number(coords[j]);
        y = Number(coords[j + 1]);
        z = dim === 2 ? null : Number(coords[j + 2]);
        points.push([x, y]);
      }
    }

    // look for <gml:coordinates>
    if (coords.length === 0) {
      nodeList = (node as Element).getElementsByTagNameNS(this._gmlns, 'coordinates');
      if (nodeList.length > 0) {
        coordString = this._getChildValue(nodeList[0]);
        coordString = coordString.replace(regExes.trimSpace, '');
        coordString = coordString.replace(regExes.trimComma, ',');

        const pointList:string[] = coordString.split(regExes.splitSpace);

        pointList.forEach(item => {
          coords = item.split(',');
          if (coords.length === 2) {
            coords[2] = null;
          }
          points.push([Number(coords[0]), Number(coords[1]), Number(coords[2])]);
        });
      }
    }

    let line:LineString;
    if (points.length !== 0) {
      line = {
        type: 'LineString',
        coordinates: points
      };
    }
    return line;
  }

  private _multipoint(node:Node):MultiPoint {
    const nodeList:HTMLCollectionOf<Element> = (node as Element).getElementsByTagNameNS(this._gmlns, 'Point');

    const components:number[][] = [];
    if (nodeList.length > 0) {
      let point:Point;
      [].slice.apply(nodeList).forEach((nodeEl:Node) => {
        point = this._point(nodeEl);
        if (point) {
          components.push(point.coordinates);
        }
      });
    }

    let multi:MultiPoint;
    if (components.length) {
      multi = {
        type: 'MultiPoint',
        coordinates: components
      };
    }
    return multi;
  }

  private _point(node:Node):Point {
    let nodeList:HTMLCollectionOf<Element>, coordString:string;
    let coords:string[] = [];

    // <gml:pos>
    nodeList = (node as Element).getElementsByTagNameNS(this._gmlns, 'pos');
    if (nodeList.length > 0) {
      coordString = nodeList[0].firstChild.nodeValue;
      coordString = coordString.replace(regExes.trimSpace, '');
      coords = coordString.split(regExes.splitSpace);
    }

    // <gml:coordinates>
    if (coords.length === 0) {
      nodeList = (node as Element).getElementsByTagNameNS(this._gmlns, 'coordinates');
      if (nodeList.length > 0) {
        coordString = nodeList[0].firstChild.nodeValue;
        coordString = coordString.replace(regExes.removeSpace, '');
        coords = coordString.split(',');
      }
    }

    // <gml:coord>
    if (coords.length === 0) {
      nodeList = (node as Element).getElementsByTagNameNS(this._gmlns, 'coord');
      if (nodeList.length > 0) {
        const xList:HTMLCollectionOf<Element> = nodeList[0].getElementsByTagNameNS(this._gmlns, 'X');
        const yList:HTMLCollectionOf<Element> = nodeList[0].getElementsByTagNameNS(this._gmlns, 'Y');
        if (xList.length > 0 && yList.length > 0) {
          coords = [xList[0].firstChild.nodeValue, yList[0].firstChild.nodeValue];
        }
      }
    }

    let point:Point;
    if (coords.length) {
      point = {
        type: 'Point',
        coordinates: coords.map(c => {
          return Number(c);
        })
      };
    }
    return point;
  }

  private _getChildValue(node:Element, def?:string):string {
    let value:string = def || '';
    if (node) {
      for (let child:Node = node.firstChild; child; child = child.nextSibling) {
        switch (child.nodeType) {
          case 3: // text node
          case 4: // cdata section
            value += child.nodeValue;
        }
      }
    }
    return value;
  }

  private _getFragmentCoords(node:Node, tagName:string):any {
    const nodeList = (node as Element).getElementsByTagNameNS(this._gmlns, tagName);
    // coords type must be Position[][][] | Position[][] | Position[]
    const coords:any = [];
    if (nodeList.length > 0) {
      let object:DirectGeometryObject;
      [].slice.apply(nodeList).forEach((nodeEl:Node) => {
        switch (tagName.toLowerCase()) {
          case 'linestring':
          case 'linearring':
            object = this._linestring(nodeEl);
            break;
          case 'polygon':
            object = this._polygon(nodeEl);
            break;
          case 'point':
            object = this._point(nodeEl);
            break;
        }

        if (object) {
          coords.push(object.coordinates);
        }
      });
    }

    return coords;
  }
}
