import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {JoinedTable} from '../../../../../shared/classes/JoinedTable';
import {ITableManager} from '../../../../../shared/classes/tableManager';
import {ILayer} from 'shared';

@Injectable()
export class TabViewerService implements ITableManager {
  constructor(private http:HttpClient) {}

  getTable(requestOpt:{
    id:number;
    page:number;
    attributes:string;
    order?:{ attr:string; direction:string };
  }):Observable<{ table:any[]; meta:{ count:number } }> {
    return this.http.post<any>('/tab-viewer/table/', requestOpt).pipe(catchError(this.handleError));
  }

  printTable(requestOpt:{
      id:number;
      attributes:string;
      order?:{ attr:string; direction:string };
  }):Observable<{ table:any[]; meta:{ count:number } }> {
      const requestOptions:Object = {
          headers: new HttpHeaders({
              'Accept': 'text/html'
          }),
          responseType: 'text'
      };
      return this.http.post<any>('/tab-viewer/table_print/', requestOpt, requestOptions).pipe(catchError(this.handleError));
  }

  getJoinedTableData(table:ILayer, pageIndex:number, limit:number, joinedTable:JoinedTable)
      :Observable<{table:any[]; meta:{ count:number }}> {
    const postData = {
      id: table.id,
      attributes: '',
      page: pageIndex,
      limit: limit,
      type: table.geomType ? undefined : 'table',
      joined: Object.assign({}, joinedTable)
    };
    return this.http.post<any>('/tab-viewer/table/', postData).pipe(
      catchError(err => {
        console.error(err);
        throw new Error();
      })
    );
  }

  saveData(layerId:number, tableType:string, pk:Object, rowItem:any):Observable<any> {
    const postData:any = {
      id: layerId,
      pk: pk,
      edit_column: rowItem
    };

    if (tableType !== 'layer') {
      postData.type = tableType;
    }
    return this.http.post<any>('/tab-viewer/table/save_cell/', postData).pipe(catchError(this.handleError));
  }

  addData(layerId:number, tableType:string, rowItem:any):Observable<any> {
    const postData:any = {
      id: layerId,
      edit_column: rowItem
    };

    if (tableType !== 'layer') {
      postData.type = tableType;
    }
    return this.http.post<any>('/tab-viewer/insert/', postData);
  }

  deleteData(layerId:number, tableType:string, editColumn:any):Observable<any> {
    const postData:any = {
      id: layerId,
      type: tableType,
      edit_column: editColumn
    };
    return this.http.post<any>('/tab-viewer/delete/', postData).pipe(catchError(this.handleError));
  }

  private handleError(e:HttpErrorResponse):Observable<any> {
    console.error(e.message);
    return of([]);
  }
}
