import { TileLayer } from 'leaflet';
import { Observable, Observer } from 'rxjs';
import { ILayerOptions, LayerType } from 'shared/interfaces';
import { Feature } from '../Feature';
import { Point } from '../Point';
import { TMSLayer } from './TMSLayer.class';

export class WMSLayer extends TMSLayer {
  type:LayerType = 'wms';
  params:any; // доп. параметры для запроса GetMap

  constructor(options?:ILayerOptions) {
    super(options);
    this.tileSize = 256;
  }

  getFeatureInfo(screenClick:Point):Observable<Feature[]> {
    return Observable.create((observer:Observer<Feature[]>) => {
      const xhr:XMLHttpRequest = new XMLHttpRequest();
      xhr.open('GET', this.infoUrl(screenClick), true);

      xhr.onreadystatechange = () => {
        let findFeatures:Feature[] = [];
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            findFeatures = this.prepareFeatures(JSON.parse(xhr.response));
          }

          observer.next(findFeatures);
          observer.complete();
        }
      };
      xhr.send(null);
    });
  }

  infoUrl(screenClick:Point):string {
    const url:string = this.url.indexOf('?') !== -1 ? this.url : `${this.url}?`;

    const query_params:any = {
      SERVICE: 'WMS',
      VERSION: '1.3.0',
      REQUEST: 'GetFeatureInfo',
      FORMAT: 'image/png',
      TRANSPARENT: 'true',
      QUERY_LAYERS: this.layerName,
      LAYERS: this.layerName,
      INFO_FORMAT: 'application/json',
      FEATURE_COUNT: 50,
      CRS: 'EPSG:4326',
      width: this.map.getContainer().offsetWidth,
      height: this.map.getContainer().offsetHeight,
      i: screenClick.x,
      j: screenClick.y,
      STYLES: '',
      BBOX: this.map.getBounds().toBBoxString()
    };

    const params:string[] = [];

    for (const key in query_params) {
      params.push(`${key}=${query_params[key]}`);
    }

    return url + params.join('&');
  }

  get filter() {
    return this._filter;
  }

  set filter(filter:string) {
    if (!filter || !filter.length) {
      this._filter = null;
      delete this._layerInstance.wmsParams.sqlFilter;
      this._layerInstance.setUrl(this.url);
    } else {
      this._filter = filter;
      this._layerInstance.setParams({ sqlFilter: filter });
    }
  }

  protected createLayerInstance():Promise<any> {
    return new Promise(resolve => {

      this._layerInstance = this.buildLayerInstance();

      this._layerInstance.on('load', () => {
        this._layerInstance.off('load');
        resolve();
      });
    });
  }

  public refreshLayerInstance():Promise<any> {
    return new Promise(resolve => {

      this.visible = false;
      this._layerInstance = this.buildLayerInstance();
      this.visible = true;
      this.checked = this.checked;
      this.opacity = this.opacity;
      this.order = this.order;

      this._layerInstance.on('load', () => {
        this._layerInstance.off('load');
        resolve();
      });
    });
  }

  public buildLayerInstance():any {
    const initOptions = {
      layers: this.layerName,
      format: 'image/png',
      transparent: true,
      tileSize: this.tileSize ? this.tileSize : 256,
      maxZoom: 22,
      maxNativeZoom: this.maxNativeZoom
    };

    return new TileLayer.WMS(this.url, Object.assign(initOptions, this.params));
  }

  protected prepareFeatures(data:any):Feature[] {
    return [];
  }
}
