import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { SearchOption, SearchType } from 'mobile/src/app/components/shared';
import { Feature } from 'shared/classes/Feature';
import { ILayer } from 'shared/interfaces/ILayer';
import { LayersStore } from 'shared/stores/LayersStore';
import {Utils} from 'shared/classes';

@Component({
  selector: 'geo-result-list',
  templateUrl: 'result_list.component.html',
  styleUrls: ['result_list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultListComponent {
  @Input() results:Feature[] = [];
  @Input() layers:ILayer[] = [];
  @Input() addresses:Feature[] = [];
  @Input() paramsMap:{ [K in SearchType]: SearchOption };

  @Output() featureClicked = new EventEmitter();
  @Output() showFeature = new EventEmitter();
  @Output() showLayer = new EventEmitter();

  addressesClose = false;
  objectsClose = false;
  layersClose = false;

  private currentFeature:Feature;

  constructor(private _layersStore:LayersStore, private el:ElementRef) {}

  chooseResult(feature:Feature) {
    if (this.currentFeature === feature) {
      return;
    }
    this.currentFeature = feature;
    this.featureClicked.emit(feature);
  }

  goToFeature(evt:MouseEvent, feature:Feature) {
    evt.stopPropagation();
    this.showFeature.emit(feature);
  }

  goToLayer(layer:ILayer, event:MouseEvent) {
    if ((event.target as HTMLElement).classList.contains('geo-label')) {
      event.stopPropagation();
    }
    this.showLayer.emit(layer);
  }

  layerChecked(layer:ILayer) {
    this._layersStore.updateLayer(layer);
  }

  getKeys() {
    if (!this.paramsMap) {
      return [];
    }
    const ret = Object.keys(this.paramsMap).sort((a, b) => {
      const rating = {
        objects: 3,
        layer: 2,
        address: 1
      };
      if (rating[a] && rating[b]) {
        return rating[b] - rating[a];
      } else if (a === b) {
        return 0;
      } else {
        return a > b ? 1 : -1;
      }
    });
    return ret;
  }

  getNumeralEnding(n:number):string {
    return Utils.getNumeralEnding(n);
  }
}
