import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Attribute } from 'shared/classes';
import { ILayer } from 'shared/interfaces';
import { IError } from '../components';
import { ChartService } from '../services';

export abstract class AbstractChartFormClass implements OnInit {
  @Input() layers:ILayer[];
  @Input() projectSlug:string;

  @Output() onClose = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  form:FormGroup;

  error:IError = null;

  groupByChoices:string[] = ['Атрибут', 'Другой слой'];
  attributes:Attribute[] = [];

  methods = [
    { title: 'Количество', value: 'count' },
    { title: 'Среднее', value: 'avg' },
    { title: 'Сумма', value: 'sum' },
    { title: 'Минимальное', value: 'min' },
    { title: 'Максимальное', value: 'max' }
  ];

  joinedMethods:string[] = ['st_within', 'st_intersects'];
  joinedAttributes:Attribute[] = [];

  constructor(protected chartService:ChartService, protected builder:FormBuilder) {}

  ngOnInit() {
    this.setForm();
  }

  closeForm() {
    this.onClose.emit('');
  }

  addChart() {
    this.chartService.saveChart(this.getSettings()).subscribe(
      () => {
        this.error = null;
        this.onSubmit.emit();
      },
      error => {
        this.error = {
          status: error.status,
          statusText: error.statusText,
          body: 'Ошибка при создании диаграммы!'
        };
      }
    );
  }

  compareFn(item1, item2):boolean {
    return item1 && item2 ? item1.value === item2.value : false;
  }

  protected abstract setForm();

  protected abstract getChartSettings(formModel:any):any;

  protected getSettings() {
    const formModel = Object.assign({}, this.form.value);

    const generalSettings:any = {
      layer_id: formModel.resource.id,
      title: formModel.name,
      project_slug: this.projectSlug,
      data_filter: formModel.resource.filter ? formModel.resource.filter : undefined
    };

    const settings = Object.assign(generalSettings, this.getChartSettings(formModel));

    return settings;
  }
}
