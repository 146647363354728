import {
  Circle,
  DivIcon,
  DomEvent,
  DomUtil,
  LatLng,
  LatLngExpression,
  LayerGroup,
  LeafletMouseEvent,
  Map,
  Marker, MarkerOptions,
  Point,
  Polyline,
  Util
} from 'leaflet';
import { ILeafletDraw } from './ILeafletDraw';

export class LeafletLine implements ILeafletDraw {
  private layerPaint:any;
  private map:Map;
  private mapDiv:HTMLElement;
  private drawing = true;
  private oldCursor:string;
  private doubleClickZoom:boolean;
  private lastPoint:LatLngExpression;
  private tooltip:Marker;
  private distance:number;
  private lastCircle:any;
  private layerPaintPath:any;
  private layerPaintPathStroke:any;
  private layerPaintPathTemp:any;
  private points:any[];
  private clearButtonClicked = false;

  private options:any = {
    needPoints: true, // нужно ли ставить точку по клику
    showTooltips: true, // показывать измерения
    showAllTooltips: false, // показывать измерения по всем промежуткам или только в конце пути
    showDeltaDist: false, // показывать на сколько изменилось расстояние между пунктами

    defLineStyle: {
      color: '#6289c4',
      weight: 3
    },

    defDeshLineStyle: {
      color: '#6289c4',
      weight: 2.5,
      clickable: false,
      dashArray: '5, 5'
    },

    defLineStrokeStyle: {
      color: '#FFFFFF',
      weight: 6,
      clickable: false
    }
  };

  constructor(options:any) {
    this.map = options.map;
    Util.setOptions(this, options);
    options.layer ? (this.layerPaint = options.layer) : (this.layerPaint = new LayerGroup([]).addTo(this.map));
    this.mapDiv = this.map.getContainer();
  }

  start() {
    if (!this.map) { console.error('Карта для класса leaflet.line не задана'); }

    this.oldCursor = this.mapDiv.style.cursor;
    this.mapDiv.style.cursor = 'crosshair';

    this.doubleClickZoom = this.map.doubleClickZoom.enabled();
    this.map.doubleClickZoom.disable();

    this.map.on('mousemove', this.mouseMove, this);
    this.map.on('click', this.mouseClick, this);
    this.map.on('dblclick', this.finishPath, this);

    if (!this.points) { this.points = []; }
  }

  onDrawFinish() {}

  stop() {
    this.stopDraw();
    this.clearMap();
  }

  private stopDraw() {
    this.mapDiv.style.cursor = this.oldCursor;

    this.map.off('mousemove', this.mouseMove, this);
    this.map.off('click', this.mouseClick, this);
    this.map.off('dblclick', this.finishPath, this);

    if (this.doubleClickZoom) { this.map.doubleClickZoom.enable(); }

    this.restartPath();
    this.onDrawFinish();
  }

  private clearMap() {
    if (this.layerPaint) {
      this.layerPaint.clearLayers();
    }
  }

  // на движение курсора
  private mouseMove(e:LeafletMouseEvent) {
    if (!e.latlng || !this.lastPoint || this.drawing === false) {
      this.restartPath();
      return;
    }

    if (!this.layerPaintPathTemp) {
      const dashLineStyle = this.options.dashLineStyle ? this.options.dashLineStyle : this.options.defDeshLineStyle;
      this.layerPaintPathTemp = new Polyline([this.lastPoint, e.latlng], dashLineStyle).addTo(this.layerPaint);
    } else {
      const latlngs = this.layerPaintPathTemp.getLatLngs();
      latlngs.splice(0, 2, this.lastPoint, e.latlng);
      this.layerPaintPathTemp.setLatLngs(latlngs);
    }

    if (this.tooltip) {
      if (!this.distance) {
        this.distance = 0;
      }

      this.updateTooltipPosition(e.latlng);

      const distance = e.latlng.distanceTo(this.lastPoint);
      this.updateTooltipDistance(this.distance + distance, distance);
    }
  }

  // клик по карте
  private mouseClick(e:LeafletMouseEvent) {
    // Skip if no coordinates
    if (!e.latlng) {
      return;
    }

    if (!this.lastPoint) {
      if (this.clearButtonClicked) {
        this.clearButtonClicked = false;
        return;
      } else {
        this.clearMap();
      }
    }

    // If we have a tooltip, update the distance and create a new tooltip, leaving the old one
    // exactly where it is (i.e. where the user has clicked)
    let oldTooltip;
    if (this.options.showAllTooltips !== true) {
      oldTooltip = this.tooltip;
    }

    if (this.options.showTooltips === true) {
      this.createTooltip(e.latlng);
    }

    if (this.lastPoint && this.tooltip) {
      if (!this.distance) {
        this.distance = 0;
      }

      this.updateTooltipPosition(e.latlng);

      const distance = e.latlng.distanceTo(this.lastPoint);
      this.updateTooltipDistance(this.distance + distance, distance);

      this.distance += distance;

      if (this.options.showAllTooltips !== true) {
        this.layerPaint.removeLayer(oldTooltip);
      }
    }

    // If this is already the second click, add the location to the fix path (create one first if we don't have one)
    if (this.lastPoint && !this.layerPaintPath) {
      const styleLineOpt = this.options.lineStyle ? this.options.lineStyle : this.options.defLineStyle;
      const styleStrokeOpt = this.options.defLineStrokeStyle;

      if (styleLineOpt.clickable === undefined) {
        styleLineOpt.clickable = false;
        styleStrokeOpt.clickable = false;
      }

      this.layerPaintPathStroke = new Polyline([this.lastPoint], styleStrokeOpt).addTo(this.layerPaint);
      this.layerPaintPath = new Polyline([this.lastPoint], styleLineOpt).addTo(this.layerPaint);
    }

    if (this.layerPaintPath) {
      this.layerPaintPath.addLatLng(e.latlng);
      this.layerPaintPathStroke.addLatLng(e.latlng);
    }

    // Upate the end marker to the current location
    if (this.lastCircle) {
      // this._layerPaint.removeLayer(this._lastCircle);
    }

    if (this.options.needPoints) {
      const self = this;
      const isClickable = this.lastCircle ? true : false;
      const circleIcon = new DivIcon({ className: 'measure-line-div-icon' });

      this.lastCircle = new Marker(e.latlng, { icon: circleIcon/*, clickable: isClickable */}).addTo(this.layerPaint);

      this.lastCircle.on('click', (evt:LeafletMouseEvent) => {
        if ((this.lastCircle as Circle).getLatLng() === (self.lastCircle as Circle).getLatLng()) {
          self.finishPath();
          DomEvent.stop(evt.originalEvent);
        } else {
          self.mouseClick(evt);
        }
      });
    }

    // Save current location as last location
    this.lastPoint = e.latlng;
  }

  private finishPath() {
    if (this.tooltip) {
      const tooltip = document.getElementsByClassName('leaflet-measure-tooltip-total')[0];
      const clearButton = DomUtil.create('div', 'clear-measure-result');

      clearButton.addEventListener('click', e => {
        this.finishPath();
        this.clearButtonClicked = true;
        this.layerPaint.clearLayers();
        e.stopPropagation();
      });

      tooltip.appendChild(clearButton);
    }

    if (this.tooltip && this.options.showAllTooltips) {
      this.layerPaint.removeLayer(this.tooltip);
    }

    if (this.layerPaint && this.layerPaintPathTemp) {
      this.layerPaint.removeLayer(this.layerPaintPathTemp);
    }

    this.stopDraw();
  }

  private restartPath() {
    this.distance = 0;
    this.tooltip = null;
    this.lastCircle = null;
    this.lastPoint = null;
    this.layerPaintPath = null;
    this.layerPaintPathTemp = null;
  }

  // создание подсказки
  private createTooltip(position:LatLng) {
    const tooltipIcon = new DivIcon({
      className: 'leaflet-measure-tooltip',
      iconAnchor: new Point(50, 45),
      html: `<div class='leaflet-measure - tooltip - total'><span>0 м</span></div>`
    });
    this.tooltip = new Marker(position, {
      icon: tooltipIcon/*, clickable: false*/
    }).addTo(this.layerPaint);
  }

  private updateTooltipPosition(position:LatLng) {
    this.tooltip.setLatLng(position);
  }

  private updateTooltipDistance(total:number, difference:number) {
    let mer = 'm';
    let merDif:string = mer;

    if (total > 1000) {
      total = total / 1000;
      mer = 'км';
    } else {
      mer = 'м';
    }

    if (difference > 1000) {
      difference = difference / 1000;
      merDif = 'км';
    } else {
      merDif = 'м';
    }

    const totalRound = this.round(total),
      differenceRound = this.round(difference);

    let text = `<div class='leaflet-measure-tooltip-total'><span>${totalRound} ${mer}</span></div>`;

    if (this.options.showDeltaDist && differenceRound > 0 && totalRound !== differenceRound) {
      text += `<div class='leaflet-measure-tooltip-difference'>(${differenceRound} ${merDif})</div>`;
    }

    if ((this.tooltip as any)._icon) { (this.tooltip as any)._icon.innerHTML = text; }
  }

  private round(val:number):number {
    return parseFloat(val.toFixed(2));
  }

  private onKeyDown(e:KeyboardEvent) {
    if (e.keyCode === 27) {
      // If not in path exit measuring mode, else just finish path
      if (!this.lastPoint) {
        // this._toggleMeasure();
      } else {
        this.finishPath();
      }
    }
  }
}
