import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractChartFormClass } from '../../classes';
import { ChartService } from '../../services';

@Component({
  selector: 'geo-line-form',
  templateUrl: 'line-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineFormComponent extends AbstractChartFormClass {
  timeLevels = [
    { title: 'День', value: 'day' },
    { title: 'Месяц', value: 'month' },
    { title: 'Квартал', value: 'quarter' },
    { title: 'Год', value: 'year' }
  ];

  constructor(protected chartService:ChartService, protected builder:FormBuilder) {
    super(chartService, builder);
  }

  protected setForm() {
    this.form = this.builder.group({
      name: [null, [Validators.required, Validators.maxLength(255)]],
      resource: [null, [Validators.required]],
      colValue: [{ value: null, disabled: true }, [Validators.required]],
      method: [this.methods[0], [Validators.required]],
      timeAttribute: [{ value: null, disabled: true }, [Validators.required]],
      timeLevel: [this.timeLevels[0], [Validators.required]],
      colName: [{ value: null, disabled: true }]
    });

    this.form.get('resource').valueChanges.subscribe(value => {
      this.attributes = value.columns;
      this.form.get('timeAttribute').enable();
      this.form.get('colName').enable();
    });

    this.form.get('method').valueChanges.subscribe(value => {
      const colValueSelect = this.form.get('colValue');
      value.value === 'count' ? colValueSelect.disable() : colValueSelect.enable();
    });
  }

  protected getChartSettings(formModel:any) {
    const data:any = {
      attribute_field_name_joined: formModel.colValue ? formModel.colValue.name : undefined,
      function: formModel.method.value,
      time_attribute: formModel.timeAttribute.name,
      time_level: formModel.timeLevel.value,
      type: 'line'
    };

    if (formModel.colName) {
      data.attribute_field_name = formModel.colName.name;
    }

    return data;
  }
}
