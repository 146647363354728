import { Component, AfterViewInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef } from '@angular/core';

@Component({
  selector: 'geo-alert-builder',
  templateUrl: 'alert-builder.component.html',
  styleUrls: ['./alert-builder.component.less']
})
export class AlertBuilderComponent implements AfterViewInit {
  @Input() text:string;
  @Input() buttons:string[] = [];
  @Input() activeButton:number;

  @Output() submit = new EventEmitter<number>();

  @ViewChildren('button') elements:QueryList<ElementRef>;

  constructor() {}

  ngAfterViewInit() {
    let maxWidth = 0;
    this.elements.changes.subscribe((r) => {
      this.elements.forEach(elt => {
        const width = (elt.nativeElement as HTMLElement).offsetWidth;
        maxWidth = width > maxWidth ? width : maxWidth;
      });
      this.elements.forEach(elt => {
        (elt.nativeElement as HTMLElement).style.width = maxWidth + 'px';
      });
    });
  }

  onClick(evt:MouseEvent, index:number) {
    evt.stopPropagation();
    this.submit.emit(index);
  }
}
