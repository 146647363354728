import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Bounds } from '../classes/Bounds';
import { Point } from '../classes/Point';
import { ILayer } from '../interfaces';
import {Feature} from 'shared';

@Injectable()
export class MapService {
  projectExtent:string;

  currentMapCenter$:BehaviorSubject<Point> = new BehaviorSubject(new Point(0, 0));
  currentMapScale$:BehaviorSubject<number> = new BehaviorSubject(null);
  currentMapBound$:BehaviorSubject<Bounds> = new BehaviorSubject(null);
  currentBaseMap$:BehaviorSubject<ILayer> = new BehaviorSubject(null);
  currentCursorPosition$:BehaviorSubject<Point> = new BehaviorSubject(new Point(0, 0));

  mapClick$:Subject<Point> = new Subject();
  mapClickEvent$:Subject<any> = new Subject();
  mapRightClick$:Subject<MouseEvent> = new Subject();

  changeMapBound$:Subject<Bounds> = new Subject();
  onClearMap$:Subject<boolean> = new Subject();

  foundFeatures$:Subject<Feature[]> = new Subject();

  clearMap() {
    this.onClearMap$.next(true);
  }

  setCurrentBaseMap(layer:ILayer) {
    this.currentBaseMap$.next(layer);
  }
}
