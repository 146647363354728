import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Inject,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { PluginConnect, Utils } from 'shared/classes';
import { AppData, IAppSettings } from 'shared/environment';
import { IContainer, IContentChild, IPlugin } from 'shared/interfaces';
import { ConnectionService, WindowFactory } from './services/services';

import {polyfill} from 'mobile-drag-drop';
// optional import of scroll behaviour
import {scrollBehaviourDragImageTranslateOverride} from 'mobile-drag-drop/scroll-behaviour';

@Component({
  selector: 'my-app',
  templateUrl: 'app.component.html',
  styles: [''],
  providers: [WindowFactory]
})
export class AppComponent implements AfterViewInit {
  @ViewChild('appContainer', { read: ViewContainerRef }) appContainer:ViewContainerRef;
  @ViewChild('mainContent', { read: ViewContainerRef }) mainDiv:ViewContainerRef;
  @ViewChild('topContent', { read: ViewContainerRef }) topDiv:ViewContainerRef;
  @ViewChild('bottomContent', { read: ViewContainerRef }) bottomDiv:ViewContainerRef;
  @ViewChild('slidePanel', { read: ViewContainerRef }) slidePanel:ViewContainerRef;
  @ViewChild('navigMenu', { read: ViewContainerRef }) navigMenu:ViewContainerRef;
  @ViewChild('analyticsMenu', { read: ViewContainerRef }) analyticsMenu:ViewContainerRef;
  @ViewChild('editMenu', { read: ViewContainerRef }) editMenu:ViewContainerRef;
  @ViewChild('addressMenu', { read: ViewContainerRef }) addressMenu:ViewContainerRef;
  @ViewChild('layersMenu', { read: ViewContainerRef }) layersMenu:ViewContainerRef;

  turnNav = true;
  notSupportedPlugins:string[] = [];

  private plugins_connects = new Map<string, PluginConnect[]>();
  private appSettings:IAppSettings;

  constructor(
    private componentFactoryResolver:ComponentFactoryResolver,
    private connectionService:ConnectionService,
    private http:HttpClient,
    private viewContainerRef:ViewContainerRef,
    private windowFactory:WindowFactory,
    private el:ElementRef,
    @Inject('environment') settings:IAppSettings
  ) {
    this.viewContainerRef = viewContainerRef;
    this.appSettings = settings;
  }

  ngAfterViewInit() {
    polyfill({
      // use this to make use of the scroll behaviour
      dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
    });

    // Получаем входные параметры. так как Input не работает для компонентов 1 уровня
    const remoteUrl = this.el.nativeElement.getAttribute('remoteUrl');
    const key = this.el.nativeElement.getAttribute('apiKey');

    if (key) {
      this.appSettings.API_KEY = key;
    }

    if (remoteUrl) {
      this.appSettings.BASE_URL = remoteUrl;
    }

    // создание компонентов динамически
    this.createComponents().then(() => {
      this.connectionService.setConnects(this.plugins_connects);
    });
  }

    turnNavToggle() {
    this.turnNav = !this.turnNav;
  }

  private getComponents():Observable<AppData> {
    return this.http.get<AppData>(this.appSettings.PLUGINS_URL + location.search);
  }

  private createComponents():Promise<any> {
    return new Promise(resolve => {
      this.getComponents().subscribe(data => {
        const plugins:any[] = data.plugins;
        if (data.environment) {
          this.appSettings.PROJECT_SLUG = data.environment.project.slug;
        }
        let count = 0;
        plugins.forEach(object => {
          this.create(object.component, object.options).then(com => {
            count++;
            if (count === plugins.length) {
              resolve('allCreated');
            }
          });
        });
      });
    });
  }

  private create(componentName:string, options:any, container?:ViewContainerRef):Promise<any> {
    const self = this;
    let retPromise:Promise<any> = null;

    if (container) {
      retPromise = this.createInstance(componentName, options, container);
    } else {
      if (!options.domId) {
        console.error('Не указан элемент, где разместить компонент ' + componentName);
      } else if (!self[options.domId]) {
        console.error('В шаблоне нет элемента ' + options.domId + ', для размещения компонента ' + componentName);
      } else {
        container = self[options.domId] as ViewContainerRef;
        retPromise = this.createInstance(componentName, options, container);
      }
    }

    if (!retPromise) {
      console.log(`Creating ${componentName} error`);
    }
    return retPromise;
  }

  private createInstance(componentName:string, options:any, container?:ViewContainerRef):Promise<any> {
    const self = this;
    return new Promise(resolve => {
      try {
        const factories:Type<any>[] = Array.from(this.componentFactoryResolver['_factories'].keys());
        const factoryClass:Type<any> = factories.find((x:any) => Utils.getFuncName(x) === componentName);
        const compFactory:ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(factoryClass);

        if (!compFactory) {
          console.error(`No factory found for ${componentName}`);
          resolve(null);
          return;
        }
        let com:ComponentRef<any>;

        if (process.env.NODE_ENV !== 'production') {
          console.log(`creating component ${componentName}...`);
        }

        if (container) {
          com = container.createComponent(compFactory);
          // параметры, которые не являются св-вами компонента
          const notOpt = ['include', 'domOpt', 'domId', 'connects'];
          for (const opt in options) {
            if (!notOpt.includes(opt)) {
              com.instance[opt] = options[opt];
            }
          }
          // если заданы настройки контейнера компонента
          const domOpt:any = options['domOpt'];
          const domNode:any = com.instance.el;

          if (domNode && domOpt) {
            for (const opt in domOpt) {
              domNode.nativeElement[opt] = domOpt[opt];
            }
          }

          const componentId = options.componentId || componentName;
          // если это компонент Window, то добавляем его в сервис windowfactory
          if (componentId === 'Window') {
            this.windowFactory.addWindow(com.instance);
          } else {
            // добавляем компонент в массив компонентов
            self.connectionService.addPlugin(componentId, com.instance);

            // добавляем связи
            this.setConnects(componentId, options.connects);
          }
          //
          let onInitFunc:any;
          // Если нужно создать элементы внутри данного компонента
          if (options.include && options.include.length) {
            self.createChildren(com, options.include).then((parent:any) => {
              // после загрузки всех компонентов запускаем ngOnInit
              parent.instance.ngOnInit.call(parent.instance);
              resolve(parent);
            });
          } else {
            // Подменяем функцию ngOnInit
            // для определения, что компонент инициализирован
            onInitFunc = com.instance.ngOnInit;

            if (!onInitFunc) {
              console.error(`Component ${componentName} must extend PluginClass or implement OnInit interface!`);
              resolve(null);
            }

            com.instance.ngOnInit = () => {
              onInitFunc.call(com.instance);
              resolve(com);
            };
          }

          // Проверка на браузеры
          if (com.instance.checkBrowserSupport) {
            com.instance.checkBrowserSupport();
          }
        }
      } catch (e) {
        console.error(`Error creating component ${componentName}:` + e);
        resolve(null);
      }
    });
  }

  private createChildren(parent:ComponentRef<any>, children:any[]):Promise<any> {
    const self = this;
    const length = children.length;
    return new Promise(resolve => {
      children.forEach((child:any, idx:number) => {
        const options = child.options;
        self
          .create(child.component, options, (parent.instance as IContainer).getContainer())
          .then((childCom:ComponentRef<any>) => {
            try {
              // Сохраняем ссылки на детей в родительском компоненте
              (parent.instance as IContainer).addChild(childCom.instance as IContentChild);
              (childCom.instance as IContentChild).parentComponent = parent.instance as IContainer;

              // функция на загрузку компонента
              (childCom.instance as IPlugin).onLoad();
              //
              if (idx === length - 1) {
                resolve(parent);
              }
            } catch (e) {
              if (process.env.NODE_ENV !== 'production') {
                console.error(child.component, e);
              }
              resolve(parent);
            }
          });
      });
    });
  }

  private setConnects(componentName:string, connects:any[]) {
    const ar:PluginConnect[] = [];
    if (!connects) {
      connects = [];
    }
    connects.forEach(connect => {
      const name = connect.componentId || connect.component;
      ar.push(new PluginConnect(name, connect.connectName, connect.interface));
    });
    this.plugins_connects.set(componentName, ar);
  }
}
