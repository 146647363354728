import {
  Feature as GeoJsonFeature,
  Point as GeoJsonPoint,
  FeatureCollection,
  MultiPoint, DirectGeometryObject, Position
} from 'geojson';
import * as L from 'leaflet';
import {
  DivIcon,
  LatLng,
  LatLngBounds,
  Point as LeafletPoint,
  Marker, Icon, LeafletMouseEvent, FeatureGroup
} from 'leaflet';
import 'leaflet.markercluster';
import {ILayer, LayerType} from 'shared/interfaces';
import {Bounds, Feature, Point, TMSLayer} from '../../classes';
import {CircleCanvasIcon, MarkerOptions, SquareCanvasIcon, StarCanvasIcon, TriangleCanvasIcon} from './CanvasIcons';
import {DataLayer} from './symbolizers/IDataLayer';
import {EventEmitter} from '@angular/core';
import {config} from '../../../local_env_settings';
import {MarkerSymbolizer} from './symbolizers/SymbolizerImpl.class';

type SFeature = GeoJsonFeature<DirectGeometryObject, any>;
interface ClickEvent {
  features:Feature[];
  point:Point;
}


export class MarkerClusterLayer extends TMSLayer {
  type:LayerType = 'cluster';
  clusterLayers = new Map<number, L.MarkerClusterGroup>();
  featureLayers = new Map<number, FeatureGroup>();
  click = new EventEmitter<ClickEvent>();
  defaultSymbolizer = new MarkerSymbolizer();

  private _notOnScale:boolean = false;
  private dataLayers:DataLayer[] = [];
  private scaleStatus:string = ''; //'010'

  get data():DataLayer[] {
    return this.dataLayers;
  }

  setData(dataLayers:DataLayer[]) {
    if (this.clusterLayers.size !== dataLayers.length) {
      this.clusterLayers.forEach(layer => {
        this.map.removeLayer(layer);
      });
      this.featureLayers.forEach(layer => {
        this.map.removeLayer(layer);
      });
      this.clusterLayers.clear();
      this.featureLayers.clear();
      for (const lr of dataLayers) {
        if (!lr.symbolizers) {lr.symbolizers = []; }
        const newLayer = L.markerClusterGroup({
          spiderfyOnMaxZoom: false,
          showCoverageOnHover: false,
          zoomToBoundsOnClick: false,
          iconCreateFunction: (cluster) => this.clusterFunction.call(this, lr, cluster)
        });
        this.clusterLayers.set(lr.id, newLayer);
        newLayer.on('click', (evt:LeafletMouseEvent) => {
          const point = new Point(evt.containerPoint.x, evt.containerPoint.y);
          const newFeature = this.getSystemFeature((evt.layer as Marker).feature);
          const position:Position = (newFeature.geometry as GeoJsonPoint).coordinates;
          this.click.emit({features: [newFeature], point: new Point(position[0], position[1])});
        });

        newLayer.on('clusterclick', (evt:any) => {
          evt.originalEvent.preventDefault();
          const point = new Point(evt.containerPoint.x, evt.containerPoint.y);
          const jsonFeatures:SFeature[] = evt.layer.getAllChildMarkers().map(marker => marker.feature);
          const features = jsonFeatures.map(feat => {
            return this.getSystemFeature(feat);
          });
          const position:Position = (features[0].geometry as GeoJsonPoint).coordinates;
          this.click.emit({features: features, point: new Point(position[0], position[1])});
        });

        const featureLayer = new FeatureGroup();
        this.featureLayers.set(lr.id, featureLayer);
        featureLayer.on('click', (evt:any) => {
          const point = new Point(evt.containerPoint.x, evt.containerPoint.y);
          const newFeature = this.getSystemFeature((evt.layer as Marker).feature);
          const position:Position = (newFeature.geometry as GeoJsonPoint).coordinates;
          this.click.emit({features: [newFeature], point: new Point(position[0], position[1])});
        });
      }
      if (this.visible) {
        this.clusterLayers.forEach(layer => {
          layer.addTo(this.map);
        });
        this.featureLayers.forEach(layer => {
          layer.addTo(this.map);
        });
      }
    }
    this.dataLayers = dataLayers;
    this.refreshLayerData();
  }

  refreshLayerData() {
    this.clusterLayers.forEach(layer => {
      layer.getLayers().forEach(layerMarker => {
        layerMarker.clearAllEventListeners();
      });
      layer.clearLayers();
    });
    this.featureLayers.forEach(layer => {
      layer.getLayers().forEach(layerMarker => {
        layerMarker.clearAllEventListeners();
      });
      layer.clearLayers();
    });
    this.scaleStatus = '';
    let leafletBounds:L.LatLngBounds = null;
    for (const lr of this.dataLayers) {
      let targetLayer:FeatureGroup;
      let symbolizer = lr.symbolizers && lr.symbolizers[0];
      const zoom = this.map.getZoom();
      if (symbolizer && zoom > symbolizer.scale_min &&  zoom <= symbolizer.scale_max) {
        targetLayer = this.clusterLayers.get(lr.id);
        this.scaleStatus += '1';
      } else {
        targetLayer = this.featureLayers.get(lr.id);
        this.scaleStatus += '0';
      }
      let latlng:LatLng;
      const featureCollection = JSON.parse(lr.geojson) as FeatureCollection<DirectGeometryObject, any>;
      for (const feature of featureCollection.features) {
        latlng = null;
        if (feature.geometry) {
          if (feature.geometry.type === 'Point') {
            latlng = new LatLng((feature.geometry as GeoJsonPoint).coordinates[1], (feature.geometry as GeoJsonPoint).coordinates[0]);
          } else if (feature.geometry.type === 'MultiPoint') {
            latlng = new LatLng(feature.geometry.coordinates[0][1], feature.geometry.coordinates[0][0]);
          }
          if (latlng) {
            if (!this.extent) {
              if (leafletBounds) {
                leafletBounds.extend(latlng);
              } else {
                leafletBounds = new L.LatLngBounds(latlng, latlng);
              }
            }
            if (!symbolizer) {
              symbolizer = this.defaultSymbolizer;
            }
            const markerOptions = this.getMarkerOptions(symbolizer, feature.properties);
            const marker = L.marker(latlng, markerOptions);
            marker.feature = feature as GeoJsonFeature<GeoJsonPoint, any>;
            targetLayer.addLayer(marker);
          }
        }
      }
    }
    if (leafletBounds) {
      this.setExtent(leafletBounds);
    }
  }

  private getSystemFeature(feat:SFeature):Feature {
    const newFeature = new Feature();
    newFeature.properties = feat.properties;
    newFeature.geometry = feat.geometry;
    newFeature.layer = this;
    return newFeature;
  }

  private getMarkerOptions(symbolizer:any, properties:any):L.MarkerOptions {
    const newSymbolizer = Object.assign({}, symbolizer);

    if (newSymbolizer.attributes) {
      for (const attrSymbolizer of newSymbolizer.attributes) {
        if (attrSymbolizer.values) {
          for (const rangeValue of attrSymbolizer.values) {
            if (properties[attrSymbolizer.name] === rangeValue.name) {
              newSymbolizer[attrSymbolizer.styleProperty] = rangeValue.color;
              if (rangeValue.file) {
                newSymbolizer.file = rangeValue.file;
              }
            }
          }
        }
        if (attrSymbolizer.range) {
          for (let iRange = 0; iRange < attrSymbolizer.range.length - 1; iRange++) {
            if (properties[attrSymbolizer.name] >= attrSymbolizer.range[iRange] && properties[attrSymbolizer.name] < attrSymbolizer.range[iRange + 1]) {
              newSymbolizer[attrSymbolizer.styleProperty] = attrSymbolizer.colors[iRange];
            }
          }
        }
      }
    }
    const options:MarkerOptions = {
      fillColor: newSymbolizer.fill,
      strokeColor: newSymbolizer.stroke,
      strokeWidth: newSymbolizer.stroke_width,
      opacity: newSymbolizer.fill_opacity,
      iconSize: L.point(newSymbolizer.size, newSymbolizer.size)
    };

    let icon:DivIcon;
    if (newSymbolizer.file) {
      let iconUrl = newSymbolizer.file.image;
      if (config.SERVER_URL) {
        iconUrl = config.SERVER_URL + iconUrl;
      }
      const offset_x = newSymbolizer.size / 2 + newSymbolizer.offset_x;
      const offset_y = newSymbolizer.size / 2 + newSymbolizer.offset_y;
      const ratio = newSymbolizer.file.height / newSymbolizer.file.width;
      icon = new Icon({
        iconUrl: iconUrl,
        iconAnchor: [offset_x, offset_y],
        iconSize: [newSymbolizer.size, newSymbolizer.size * ratio]
      });
    } else {
      switch (newSymbolizer.figure) {
        case 'el':
          icon = new CircleCanvasIcon(options);
          break;
        case 'sq':
          icon = new SquareCanvasIcon(options);
          break;
        case 'tr':
          icon = new TriangleCanvasIcon(options);
          break;
        case 'st':
          icon = new StarCanvasIcon(options);
          break;
        default:
          newSymbolizer.figure = 'el';
          icon = new CircleCanvasIcon(options);
      }
    }
    return {icon: icon, opacity: newSymbolizer.fill_opacity};
  }

  private clusterFunction(layer:any, cluster:L.MarkerCluster):Icon|DivIcon {
    const count = cluster.getChildCount();
    const symbolizer = layer.symbolizers[0];
    let radius = symbolizer.c_size;
    radius *= 1 + Math.log10(count);
    let style = `border-radius:${radius}px;background-color:${symbolizer.c_fill};border:${symbolizer.c_stroke} solid ${symbolizer.c_stroke_width}px;
    color:${symbolizer.t_fill};font-size:${symbolizer.t_size}px;`;
    if (symbolizer.font_name === 'bold') {
      style += 'font-weight:bold;';
    } else if (symbolizer.font_name === 'italic') {
      style += 'font-style:italic;';
    }
    return new DivIcon({
      html: `<div style="${style}"><div>${count}</div></div>`,
      className: 'marker-cluster-circle',
      iconSize: new LeafletPoint(2 * radius, 2 * radius)
    });
  }

  protected applyVisible(visible:boolean) {
    if (visible) {
      if (this.clusterLayers) {
        this.clusterLayers.forEach(layer => {
          layer.addTo(this.map);
        });
      }
      if (this.featureLayers) {
        this.featureLayers.forEach(layer => {
          layer.addTo(this.map);
        });
      }
    } else {
      if (this.clusterLayers) {
        this.clusterLayers.forEach(layer => {
          this.map.removeLayer(layer);
        });
      }
      if (this.featureLayers) {
        this.featureLayers.forEach(layer => {
          this.map.removeLayer(layer);
        });
      }
    }
  }

  get notOnScale():boolean {
    return this._notOnScale;
  }

  set notOnScale(flag:boolean) {
    this._notOnScale = flag;
    if (!this.map) {
      return;
    }

    const zoom = this.map.getZoom();
    let scaleStatus = '';
    for (const lr of this.dataLayers) {
      const symbolizer = lr.symbolizers && lr.symbolizers[0];
      if (!symbolizer || zoom > symbolizer.scale_min && zoom <= symbolizer.scale_max) {
        scaleStatus += '1';
      } else {
        scaleStatus += '0';
      }
    }
    if (scaleStatus !== this.scaleStatus) {
      this.refreshLayerData();
    }
  }

  set opacity(value:number) {
    if (!this._layerInstance) {
      return;
    }
    const newOpacity:number = (100 - value) / 100;
    this._layerInstance.setStyle(() => ({opacity: newOpacity, fillOpacity: newOpacity}));
    this._opacity = value;
  }

  featureClicked(feature:Feature) {
  }

  setExtent(leafletBounds:L.LatLngBounds) {
    const NE:LatLng = leafletBounds.getNorthEast();
    const SW:LatLng = leafletBounds.getSouthWest();
    const southWest:Point = new Point(SW.lat, SW.lng),
      northEast:Point = new Point(NE.lat, NE.lng),
      southEast:Point = new Point(SW.lat, NE.lng),
      northWest:Point = new Point(NE.lat, SW.lng);
    this.extent = new Bounds(northWest, southWest, southEast, northEast);
  }

  protected createLayerInstance():Promise<any> {
    return new Promise((resolve, reject) => {
      this._layerInstance = this.buildLayerInstance();
    });
  }

  public buildLayerInstance():any {
    return null;
  }

  protected createFilterStyle() {
    // TODO: Сделать управление цветом для geojson
  }
}
