import {ChangeDetectorRef, Component} from '@angular/core';
import { MapLegend as Legend } from 'shared/components';
import { MapService } from 'shared/services/map.service';
import { LayersStore } from 'shared/stores/LayersStore';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'geo-print-legend',
  templateUrl: 'print_legend.component.html',
  styleUrls: ['print_legend.component.less']
})
export class PrintLegendComponent extends Legend {
  constructor(layersStore:LayersStore, mapService:MapService, sanitizer:DomSanitizer, changeDetector:ChangeDetectorRef) {
    super(layersStore, mapService, sanitizer, changeDetector);
  }
}
