import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Feature, Point } from 'shared/classes';
import { SingleResultPopup } from 'shared/components';
import { IMapResult, IPluginInterface, IPopup, ISearchResult } from 'shared/interfaces';

@Component({
  selector: 'edit-result-popup',
  templateUrl: 'edit_result_popup.component.html',
  styleUrls: ['edit_result_popup.component.less']
})
export class EditResultPopupComponent extends SingleResultPopup {
  selectedFeature:Feature;

  private editPlugins:ISearchResult[] = [];

  constructor(httpClient:HttpClient) {
    super(httpClient);
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'MapResults':
        this.mapResultsPlugs.push(pi as IMapResult);
        break;
      case 'Popup':
        this.popupPlugins.push(pi as IPopup);
        break;
      case 'ShowResult':
        this.layerInfoPlugins.push(pi as ISearchResult);
        break;
      case 'EditFeature':
        this.editPlugins.push(pi as ISearchResult);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'MapResults':
        this.mapResultsPlugs = [];
        break;
      case 'Popup':
        this.popupPlugins = [];
        break;
      case 'ShowResult':
        this.layerInfoPlugins = [];
        break;
      case 'EditFeature':
        this.editPlugins = [];
        break;
    }
  }

  // Поставить маркер, открыть попап
  setFeatures(features:Feature[]) {
    this.feature = features[0];
    this.features = features;

    this.mapResultsPlugs.forEach(plug => {
      plug.goToFeature(this.feature).then((point:Point) => {
        plug.setMarker(point);

        this.popupPlugins.forEach(plugin => {
          plugin.showPopup(point, true);
        });
      });
    });
  }

  showInfo(feature:Feature) {
    this.layerInfoPlugins.forEach(plugin => {
      plugin.showInfo(feature);
    });
  }

  editInfo(feature:Feature) {
    this.layerInfoPlugins.forEach(plugin => {
      plugin.editInfo(feature);
    });
  }

  clearResults() {
    super.clearResults();

    this.mapResultsPlugs.forEach(plugin => {
      plugin.clearResult();
    });
  }

  // Подсветить на карте, открыть попап, поставить маркер
  highlightFeature(feature:Feature) {
    this.selectedFeature = feature;

    this.mapResultsPlugs.forEach(plugin => {
      plugin.goToFeature(feature).then(point => {
        plugin.showFeature([feature]);

        this.popupPlugins.forEach(plug => {
          plug.showPopup(point, true);
        });

        plugin.setMarker(point);
      });
    });

    this.showInfo(feature);
  }

  startEdit(feature:Feature) {
    // Отобразить в панели редактирования атрибутов
    this.editInfo(feature);

    // Начать редактирование геометрии
    this.editGeometry(feature);

    // Скрыть попап, убрать подсвеченный объект с карты
    this.clearResults();
  }

  getFeatureTitle(feature:Feature):string {
    const titleAttribute = feature.layer.columns.find(column => column.inPopup);
    if (!titleAttribute) {
      return '—';
    }
    return feature.properties[titleAttribute.name] || '—';
  }

  private editGeometry(feature:Feature) {
    this.editPlugins.forEach(plugin => {
      plugin.setFeatures([feature], true);
    });
  }
}
