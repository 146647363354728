import { Environment } from '../environment';

export abstract class Attachment {
  url:string;
  title:string;
  id:number;

  constructor(options:any) {
    Object.assign(this, options);
  }
}

export class Document extends Attachment {
  type:'document' = 'document';
  iconType:'image' | 'document' | 'archive' | 'default';

  constructor(options:{ url:string; id:number; title:string }) {
    super(options);

    const nameArr = options.url.split('.');
    const format = nameArr[nameArr.length - 1];

    if (Environment.IMAGE_FORMATS.indexOf(format) !== -1) {
      this.iconType = 'image';
    } else if (Environment.DOCUMENT_FORMATS.indexOf(format) !== -1) {
      this.iconType = 'document';
    } else if (Environment.ARCHIVE_FORMATS.indexOf(format) !== -1) {
      this.iconType = 'archive';
    } else {
      this.iconType = 'default';
    }
  }
}

export class Image extends Attachment {
  type:'image' = 'image';
  file:File; // используется только для превьюшек в crowdsourcing
  height:number;
  width:number;
}
