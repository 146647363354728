import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import * as chroma from 'chroma-js';

@Component({
  selector: 'geo-palettes-list',
  templateUrl: 'palettes_list.component.html',
  styleUrls: ['./palettes_list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PalettesListComponent {
  @Input() palettesList:string[][] = [
    ['#ffc6c4', '#672044'],
    ['#fef6b5', '#eb4a40'],
    ['#d3f2a3', '#074050'],
    chroma.scale('Spectral').colors(7, 'hex'),
    ['#0000ff', '#29dbff', '#8affbe', '#daff61', '#ffe100', '#ff8400', '#ff0000'],
    ['#4b2991', '#ea4f88', '#edd9a3']
  ];

  @Output() paletteSelected = new EventEmitter();

  selectPalette(palette:string[]) {
    this.paletteSelected.emit(palette);
  }

  reversePalette(idx:number) {
    this.palettesList[idx].reverse();
  }
}
