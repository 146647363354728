import {AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[myResizable]'
})
export class ResizableDirective {
}

@Directive({
  selector: '[myResizeHandle]'
})
export class ResizeHandleDirective implements AfterViewInit {
  @Input() minSize:number = 0;

  @Output() onResizeStart = new EventEmitter();
  @Output() onResizeEnd = new EventEmitter();

  private startPoint:{x:number, y:number, w:number} = null;
  private parentElement:HTMLElement;
  private initialAttrs:any = {};

  constructor(elementRef:ElementRef) {
    this.parentElement = (elementRef.nativeElement as HTMLElement).parentElement as HTMLElement;
  }

  ngAfterViewInit() {
    const width = this.getSize().w;
    this.initialAttrs = {width: width};
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(evt:MouseEvent) {
    this.onResizeStart.emit();
    const width = this.getSize().w;
    this.startPoint = {x: evt.clientX, y: evt.clientY, w: width};
    const mouseMoveHandle = (evtMove:MouseEvent) => this.onMouseMove(evtMove);
    const mouseUpHandle = (evtUp:MouseEvent) => {
      this.startPoint = null;
      document.removeEventListener ('mouseup', mouseUpHandle, true);
      document.removeEventListener ('mousemove', mouseMoveHandle, true);
      this.onResizeEnd.emit();
    };
    document.addEventListener ('mouseup', mouseUpHandle, true);
    document.addEventListener ('mousemove', mouseMoveHandle, true);
  }

  onMouseMove(evt:MouseEvent) {
    if (this.startPoint) {
      let width = this.startPoint.w + evt.clientX - this.startPoint.x;
      if (width <  this.initialAttrs.width) {
        width = this.initialAttrs.width;
      }
      if (width > this.minSize) {
        this.parentElement.style.width = width + 'px';
      }
    }
  }

  private getSize():{w:number} {
    const style = window.getComputedStyle(this.parentElement);
    const width:number = parseInt(style.width.replace('px', ''), 10);
    return {w: width};
  }
}
