import {Component, EventEmitter, OnInit} from '@angular/core';
import {
  FeatureGroup,
  LatLng,
  LatLngBounds,
  LeafletMouseEvent,
  Map,
  Point as leafletPoint,
  Popup
} from 'leaflet';
import {Bounds, Extent} from '../../classes/Bounds';
import { DomSize } from '../../classes/DomSize';
import { PluginClass } from '../../classes/Plugin';
import { Point } from '../../classes/Point';
import { Utils } from '../../classes/Utils';
import { ICurrent, IEdit, ILayer, ILayerOptions, IMap, IMapOptions, IPluginInterface, IPopup, ISearch } from '../../interfaces';
import { LayersService } from '../../services/layers.service';
import { LayersFactory } from '../../services/layers_factory.service';
import { MapService } from '../../services/map.service';
import { LayersStore } from '../../stores/LayersStore';
import { DrawTool } from './classes/DrawTool';
import { EditTool } from './classes/EditTool';
import { MapResult } from './classes/MapResult';
import { MeasureTool } from './classes/MeasureTool';
import {LayerWrapper, NativeLayer, NativeLayerRenderer} from '../../classes/LeafletLayer/NativeLayer.class';
import {MarkerClusterLayer} from '../../classes/LeafletLayer/MarkerClusterLayer.class';
import {MarkerDynamicLayer} from '../../classes/LeafletLayer/MarkerDynamicLayer.class';
import {Observable, Subject, zip} from 'rxjs';
import {DrawLayer} from '../../classes/LeafletLayer/DrawLayer.class';

@Component({
  selector: 'geoanalitika-map',
  template: '<div id="geoanalitika-map"></div>'
})
export class LeafletMapComponent extends PluginClass implements IMap, IMapOptions, IPopup, OnInit {
  scales:any[];
  loaded = new Subject<void>();
  readonly mouseMove = new EventEmitter<Point>();

  protected firstBounds:Bounds; // первоначальные экстент
  protected layerList:ILayer[] = [];
  protected map:Map;
  protected popup:Popup;
  // Массивы плагинов
  protected searchPlugins:ISearch[] = [];
  protected editPlugins:IEdit[] = []; // компоненты для редактирования
  protected popupPlugins:IPopup[] = []; // компоненты popup

  // Измерение
  protected measureTool:MeasureTool = new MeasureTool();

  // Рисование
  protected drawTool:DrawTool = new DrawTool();

  // Редактирование
  protected editTool:EditTool = new EditTool();

  // Результаты
  protected mapResult:MapResult = new MapResult();

  // Cлои
  protected resultLayer:FeatureGroup; // слой для вывода результатов поиска

  // Текущий курсор
  protected cursor = 'default';

  protected extent:string;

  protected baseLayer:ILayer;

  constructor(
    protected layersFactory:LayersFactory,
    protected layersStore:LayersStore,
    private mapService:MapService,
    private layersService:LayersService
  ) {
    super();
  }

  ngOnInit() {
    this.createMap();

    this.mapService.changeMapBound$.subscribe(data => {
      this.setBounds(data);
    });

    this.mapService.currentMapScale$.subscribe(scale => {
      this.setScale(scale);
    });

    this.mapService.projectExtent = this.extent;

    setTimeout(() => this.map.invalidateSize(), 100);
  }

  /**
   * Проверка занята ли карта (рисование, редактирование и тд)
   */
  get busy():boolean {
    return this.drawTool.active || this.editTool.active || this.measureTool.active;
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'SearchEngine':
        this.searchPlugins.push(pi as ISearch);
        this.mapResult.searchPlugin = pi as ISearch;
        break;
      case 'SearchEditEngine':
        this.editTool.searchPlugin = pi as ISearch;
        break;
      case 'Edit':
        this.editTool.addEditPlugin(pi as IEdit);
        break;
      case 'Popup':
        this.popupPlugins.push(pi as IPopup);
        break;
      case 'Current':
        this.editTool.addCurrentPlugin(pi as ICurrent);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'SearchEngine':
        this.searchPlugins = [];
        this.mapResult.searchPlugin = null;
        break;
      case 'Popup':
        this.popupPlugins = [];
        break;
      case 'SearchEditEngine':
        this.editTool.searchPlugin = null;
        break;
    }
  }

  getOuterface(name:string) {
    switch (name) {
      case 'Draw':
        return this.drawTool as IPluginInterface;
      case 'Measure':
        return this.measureTool as IPluginInterface;
      case 'Edit':
        return this.editTool as IPluginInterface;
      case 'MapResults':
        return this.mapResult as IPluginInterface;
      case 'Feature':
        return this.editTool as IPluginInterface;
      default:
        return this as IPluginInterface;
    }
  }

  redraw() {
    this.map.invalidateSize();
    (this.map as any)._onResize();
  }

  createMap() {
    const map = new Map('geoanalitika-map', {
      zoomControl: false,
      minZoom: 2,
      maxZoom: 22,
      zoomSnap: 0.1,
      editable: true, // включает инструмент рисования и редактирования (L.Editable)
      attributionControl: false,
      doubleClickZoom: false,
      worldCopyJump: true,
      inertia: false,
      fadeAnimation: true,
      zoomAnimation: true,
      markerZoomAnimation: true,
      boxZoom: false
    });

    this.map = map;

    // если задан экстент
    if (this.extent) {
      const coords:string[] = this.extent.split(',');
      const southWest:LatLng = new LatLng(Number(coords[0]), Number(coords[1])),
        northEast:LatLng = new LatLng(Number(coords[2]), Number(coords[3])),
        bounds = new LatLngBounds(southWest, northEast);
      this.map.fitBounds(bounds);
    }

    // устанавливаем карту вспомогательным классам
    this.drawTool.map = map;
    this.editTool.map = map;
    this.measureTool.map = map;
    this.mapResult.map = map;
    this.setEvents();

    // TODO: вынести из карты
    const obs1 = this.layersService.getLayers();
    obs1.subscribe(data => this.setConfig(data));
    const obs2 = this.layersService.getBaseMaps();
    obs2.subscribe(data => this.setBaseMaps(data));
    zip(obs1, obs2).subscribe(() => {
      this.loaded.next();
    });

    this.createPopup();

    // Выставляем первоначальный экстент и зум
    this.mapService.currentMapScale$.next(this.map.getZoom());
    this.firstBounds = this.getBounds();
  }

  createPopup() {
    this.popup = new Popup({ autoClose: false, keepInView: false, offset: [0, -25], maxWidth: 600 });
  }

  get click():Observable<{latlng:{lat:number, lng:number}}> {
    return this.mapService.mapClickEvent$;
  }

  createDrawLayer():DrawLayer {
    return new DrawLayer(this.map);
  }

  getCenter():Point {
    const c = this.map.getCenter();
    return new Point(c.lat, c.lng);
  }

  goToPoint(point:Point, zoom?:number) {
    if (point) {
      const newZoom:number = zoom ? zoom : this.map.getZoom();
      this.map.setView([point.y, point.x], newZoom);
    }
  }

  setConfig(data:any) {
    this.layerList = this.constructLayerList(data.groups);
    NativeLayerRenderer.instance.refresh();
    this.setLayersOrder(this.layerList);
    //this.map.options.zoomSnap = 0.1;
    this.layersStore.setLayers(this.layerList);
  }

  setBaseMaps(data:any[]) {
    const baseMaps:ILayer[] = this.constructLayerList(data, true);

    this.layersStore.setBaseMaps(baseMaps);
    // определить текущюю подложку и выставить copyright
    const defaultBaseLayer:ILayer[] = baseMaps.filter((layer:ILayer) => layer.visible);

    if (defaultBaseLayer.length) {
      defaultBaseLayer[0].visible = true; // fix для группового слоя
    }

    this.baseLayer = defaultBaseLayer[0];
    this.mapService.setCurrentBaseMap(defaultBaseLayer[0]);
  }

  getDistance(point1:Point, point2:Point) {
    const distance = this.map.distance(
      this.map.containerPointToLatLng(point1 as leafletPoint),
      this.map.containerPointToLatLng(point2 as leafletPoint)
    );
    return distance;
  }

  getDomSize():DomSize {
    const size = this.map.getSize();
    return new DomSize(size.x, size.y);
  }

  /*------------------------
   ЭКСТЕНТ
   --------------------------*/

  getBounds():Bounds {
    const b = this.map.getBounds();
    const bounds = new Bounds(
      new Point(b.getNorthWest().lat, b.getNorthWest().lng),
      new Point(b.getSouthWest().lat, b.getSouthWest().lng),
      new Point(b.getSouthEast().lat, b.getSouthEast().lng),
      new Point(b.getNorthEast().lat, b.getNorthEast().lng)
    );
    return bounds;
  }

  setBounds(bounds:Bounds) {
    if (!bounds && !this.firstBounds) {
      return;
    }

    if (!bounds) {
      bounds = this.firstBounds;
    }

    const southWest = new LatLng(bounds.ymin.x, bounds.ymin.y);
    const northEast = new LatLng(bounds.ymax.x, bounds.ymax.y);
    const newBounds = new LatLngBounds(southWest, northEast);

    this.map.fitBounds(newBounds);
  }

  setExtent(extent:Extent) {
    const southWest = new LatLng(extent.ymin, extent.xmin);
    const northEast = new LatLng(extent.ymax, extent.xmax);
    const newBounds = new LatLngBounds(southWest, northEast);

    this.map.fitBounds(newBounds);
  }

  /*------------------------
   МАСШТАБ
   --------------------------*/
  getScale() {
    return this.map.getZoom();
  }

  setScales(scales:any[]) {
    this.scales = scales;
  }

  setScale(scale:any) {
    this.map.setZoom(scale);
  }

  getScreenPoint(geoCoords:Point):Point {
    const screenPoint:leafletPoint = this.map.latLngToContainerPoint(new LatLng(geoCoords.x, geoCoords.y));
    return new Point(screenPoint.x, screenPoint.y);
  }

  getLatLngPoint(pointXY:Point):Point {
    const latLngPoint:LatLng = this.map.containerPointToLatLng(pointXY as leafletPoint);
    return new Point(latLngPoint.lng, latLngPoint.lat);
  }

  setCursor(cur:string) {
    const mapBlock:HTMLElement = this.map.getContainer();
    mapBlock.style.cursor = cur;
  }

  getCursor() {
    return this.map.getContainer().style.cursor;
  }

  refreshMap() {
    this.layerList.forEach((layer:ILayer) => {
      if (!layer.visible) {
        return;
      }
      layer.refresh();
    });
  }

  getLayers():ILayer[] {
    return [this.baseLayer].concat(this.layerList);
  }

  createLayer(type:string, options:any = {}):ILayer {
    Utils.mixin(options, { type, map: this.map });
    const layer:ILayer = this.layersFactory.getLayer(options);
    this.map.addLayer(layer.getLayerInstance());
    // добавить слой и раздать всем
    this.layerList.push(layer);
    this.layersStore.setLayers([layer]);
    return layer;
  }

  showPopup(point:Point, showMarker:boolean, content:any) {
    if (!this.popup) {
      return;
    }

    setTimeout(() => {
      this.popup.options.offset = showMarker ? [0, -25] : [0, 5];

      this.popup
        .setLatLng([point.y, point.x])
        .setContent(content)
        .openOn(this.map);
      if (showMarker) {
        this.mapResult.setMarker(new Point(point.x, point.y));
      }
    });
  }

  closePopup() {
    if (!this.popup) {
      return;
    }
    this.popup.removeFrom(this.map);
  }

  protected constructLayerList(config:any, isBaseLayer:boolean = false):ILayer[] {
    const drainLayerGroups = (groups:any, layerArray:ILayer[]):void => {
      for (const group of groups) {
        const options:ILayerOptions = {};
        options.name = group.title;
        options.type = group.type ? group.type : '';
        options.opacity = group.opacity;
        options.visible = group.visible;
        options.brightness = group.brightness;
        options.contrast = group.contrast;
        options.backColor = group.backColor;
        options.order = group.order;
        options.subLayers = [];
        options.id = group.layer_id ? group.layer_id : null;
        options.url = group.real_url;
        options.tileSize = group.tile_size;
        options.layerName = typeof group.layers === 'string' ? group.layers : undefined;
        options.columns = group.columns;
        options.attribution = group.attribution || '';
        options.extent = group.extent || null;
        options.simple = group.simple;
        options.pk = group.pk;
        options.table = group.table ? group.table : '';
        options.schema = group.schema ? group.schema : '';
        options.datasource_id = group.datasource_id ? group.datasource_id : null;
        options.mapnikDatasourceId = group.mapnik_datasource_id ? group.mapnik_datasource_id : null;
        options.mapnikId = group.map ? group.map.id : undefined;
        options.map = this.map;
        options.isGroup = group.is_group;
        options.isBaseLayer = isBaseLayer;
        options.geomType = group.geom_type;
        options.editable = group.editable;
        options.popupImage = group.popup_image;
        options.searchable = group.searchable || false;
        // необходимо для отображения превьюшек базовых карт
        options.image = group.image || null;
        options.maxNativeZoom = group.maxNativeZoom;
        options.popupChart = group.popup_chart;
        options.serviceId = group.service_id;
        options.currentVersion = group.currentVersion;
        options.arcgisId = group.arcgis_id;
        options.inTooltipDefault = group.inTooltipDefault;
        options.rating = group.rating;

        if (group.crowdsource) {
          options.crowdsource = group.crowdsource;
          options.crowdsourceBtnText = group.crowdsource_btn_text;
        }

        if (group.geom_type === 'geometry') {
          options.geomType = 'polygon';
        }

        if (group.type === 'wms-t') {
          const bounds = { start: new Date(group.time_bounds.start), end: new Date(group.time_bounds.end) };
          options.timeBounds = bounds;
          options.currentInterval = { start: Utils.copyDate(bounds.end), end: Utils.copyDate(bounds.end) };
        }

        let newLayer:ILayer = this.layersFactory.getLayer(options);
        if (newLayer instanceof MarkerClusterLayer) {
          newLayer.click.subscribe(evt => {
            this.mapService.mapClickEvent$.next({latlng: {lat: evt.point.y, lng: evt.point.x}});
            this.mapService.foundFeatures$.next(evt.features);
          });
        }

        if (!(newLayer instanceof NativeLayer || newLayer.isBaseLayer || newLayer.isGroup)) {
          newLayer = new LayerWrapper(newLayer);
        }

        layerArray.push(newLayer);

        if (newLayer.type === 'cluster') {
          this.layersService.loadLayerData(newLayer as MarkerClusterLayer).subscribe();
        } else if (newLayer.type === 'dynamic') {
          (newLayer as MarkerDynamicLayer).dataFunctionContext = this.layersService;
          (newLayer as MarkerDynamicLayer).dataFunction = this.layersService.loadLayerData;
        }

        if (Array.isArray(group.sublayers) && group.sublayers.length) {
          drainLayerGroups(group.sublayers, newLayer.subLayers);
        }
      }
    };

    const layers:ILayer[] = [];
    drainLayerGroups(config, layers);

    return layers;
  }

  private setLayersOrder(layers:ILayer[]) {
    let count:number = layers.length + 1;
    layers.forEach((layer:ILayer) => {
      layer.order = count;
      count--;
    });
  }

  private setEvents() {
    this.map.on('click', (event:LeafletMouseEvent) => {
      // TODO: при пространственной выборке квадратом this.busy = false
      if (!this.busy) {
        this.mapService.clearMap();
        const pointXY = new Point(Number(event.containerPoint.x.toFixed(0)), Number(event.containerPoint.y.toFixed(0)));
        this.mapService.mapClick$.next(pointXY);
        this.mapService.mapClickEvent$.next(event);
      }
    });

    this.map.on('mousemove', (event:LeafletMouseEvent) => {
      const pointXY = new Point(event.containerPoint.x, event.containerPoint.y);
      this.mouseMove.next(pointXY);
    });
    this.map.on('mousemove', (event:LeafletMouseEvent) => {
        const pointXY = new Point(event.containerPoint.x, event.containerPoint.y);
        this.mouseMove.next(pointXY);
    });

    // клик правой кнопкой
    this.map.on('contextmenu', (event:LeafletMouseEvent) => {
      this.mapService.mapRightClick$.next(event.originalEvent);
    });

    // изменение масштаба карты
    this.map.on('zoomend', () => {
      this.mapService.currentMapScale$.next(this.map.getZoom());
    });


    this.map.on('mousemove', (event:LeafletMouseEvent) => {
      const point = new Point(Number(event.latlng.lat.toFixed(6)), Number(event.latlng.lng.toFixed(6)));
      this.mapService.currentCursorPosition$.next(point);
    });

    this.map.on('moveend', () => {
      const bounds:Bounds = this.getBounds();
      this.mapService.currentMapBound$.next(bounds);

      const center:LatLng = this.map.getCenter();
      this.mapService.currentMapCenter$.next(new Point(center.lat, center.lng));
      // установить кооридинаты курсора на центр карты при загрузке
      const curPos:Point = this.mapService.currentCursorPosition$.getValue();
      if (!curPos.x && !curPos.y) {
        this.mapService.currentCursorPosition$.next(new Point(center.lat, center.lng));
      }
    });
  }
}
