import { Component } from '@angular/core';
import { Attribute } from 'shared/classes';
import { LayersFactory } from 'shared/services';
import { LayersStore } from 'shared/stores/LayersStore';
import { AbstractCartometricOperation } from '../abstract_cartometric_operation_form';
import { CartometricService } from '../cartometric.service';
import { BufferZone } from '../classes';

@Component({
  selector: 'buffer-zone',
  templateUrl: 'buffer_zone.component.html',
  styleUrls: ['buffer_zone.component.less']
})
export class BufferZoneComponent extends AbstractCartometricOperation {
  settings = new BufferZone();
  processing = false;

  set width(value:number | Attribute | null) {
    if (!value) {
      this.settings.attribute = null;
      this.settings.width = null;
      return;
    }

    if (value instanceof Attribute) {
      this.settings.attribute = value;
      this.settings.width = null;
    } else {
      this.settings.width = value;
      this.settings.attribute = null;
    }
  }

  constructor(layersStore:LayersStore, layersFactory:LayersFactory, cartometricService:CartometricService) {
    super(layersStore, layersFactory, cartometricService);
  }

  protected prepareData() {
    return {
      layerId: this.settings.source.id,
      filter: this.settings.source.filter,
      name: this.settings.name,
      mapStyle: 'buffer',
      bufferWidth: this.settings.width,
      bufferWidthAttribute: this.settings.attribute ? this.settings.attribute.name : null,
      bufferUnits: this.settings.units
    };
  }

  protected reset() {
    this.form.resetForm({ units: 'm' });
    this.settings = new BufferZone();
  }
}
