import { IDrawLegend } from '../../interfaces/IDrawLegend';

export class StyleClass implements IDrawLegend {
  protected ctx:CanvasRenderingContext2D;

  private _startX = 0;
  private _startY = 0;
  private _order:number = null;
  private _iconSize = 20;
  private _mapZoom:number;
  private _canvas:HTMLCanvasElement;

  get startX() {
    return this._startX;
  }

  set startX(val:number) {
    this._startX = val;
  }

  get startY() {
    return this._startY;
  }

  set startY(val:number) {
    this._startY = val;
  }

  get iconSize() {
    return this._iconSize;
  }

  set iconSize(val:number) {
    this._iconSize = val;
  }

  get order() {
    return this._order;
  }
  set order(value:number) {
    this._order = value;
  }

  get mapZoom() {
    return this._mapZoom;
  }

  set mapZoom(val:number) {
    this._mapZoom = val;
  }

  get canvas():HTMLCanvasElement {
    return this._canvas;
  }

  set canvas(canvas:HTMLCanvasElement) {
    this._canvas = canvas;
    this.ctx = canvas.getContext('2d');
  }

  clear() {
    if (this.canvas) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }

  draw() {}
}
