import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { AbstractChartFormClass } from '../../classes';
import { ChartService } from '../../services';

@Component({
  selector: 'geo-pie-form',
  templateUrl: 'pie-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieFormComponent extends AbstractChartFormClass {
  methods = [{ title: 'Количество', value: 'count' }, { title: 'Сумма', value: 'sum' }];

  constructor(protected chartService:ChartService, protected builder:FormBuilder) {
    super(chartService, builder);
  }

  protected setForm() {
    this.form = this.builder.group({
      name: [null, [Validators.required, Validators.maxLength(255)]],
      resource: [null, [Validators.required]],
      method: [this.methods[0], [Validators.required]],
      colValue: [{ value: null, disabled: true }, [Validators.required]],
      aggregatePercent: [3],
      groupBy: [{ value: this.groupByChoices[0], disabled: true }],
      colName: [{ value: null, disabled: true }, [Validators.required]],
      joined: this.builder.group({
        joinedResource: [null, [Validators.required]],
        joinedMethod: [null, [Validators.required]],
        joinedAttribute: [null, [Validators.required]]
      })
    });

    this.form.get('joined').disable();

    this.form.get('resource').valueChanges.subscribe(value => {
      this.attributes = value.columns;
      this.form.get('method').enable();
      this.form.get('groupBy').enable();
      this.form.get('colName').enable();

      this.form.get('colName').reset();
    });

    this.form.get('method').valueChanges.subscribe(value => {
      const colValueSelect = this.form.get('colValue');
      value.value === 'count' ? colValueSelect.disable() : colValueSelect.enable();
    });

    this.form
      .get('joined.joinedResource')
      .valueChanges.pipe(filter(value => !!value))
      .subscribe(value => {
        this.joinedAttributes = value.columns;
      });

    this.form.get('groupBy').valueChanges.subscribe(value => {
      const joinedFormGroup = this.form.get('joined');
      const colNameFormGroup = this.form.get('colName');
      if (value === 'Атрибут') {
        joinedFormGroup.reset();
        joinedFormGroup.disable();
        colNameFormGroup.enable();
      } else {
        colNameFormGroup.reset();
        colNameFormGroup.disable();
        joinedFormGroup.enable();
      }
    });
  }

  protected getChartSettings(formModel:any) {
    const data:any = {
      attribute_field_name_joined: formModel.colValue ? formModel.colValue.name : undefined,
      function: formModel.method.value,
      type: 'pie'
    };

    if (formModel.colName) {
      data.attribute_field_name = formModel.colName.name;
    }

    if (formModel.joined) {
      Object.assign(data, {
        resource_joined: formModel.joined.joinedResource.mapnikDatasourceId,
        joined_attribute: formModel.joined.joinedAttribute.name,
        filter_function: formModel.joined.joinedMethod
      });
    }

    if (formModel.aggregatePercent) {
      data.aggregate_part = formModel.aggregatePercent / 100;
    }

    return data;
  }
}
