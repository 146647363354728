import { Component, OnInit, ViewChild } from '@angular/core';
import { SliderWidget } from '@geoanalitika/widgets';
import { PluginClass } from '../..//classes';
import { IContainer, ILayer, IPluginInterface, IToolLayer } from '../../interfaces';

@Component({
  selector: 'layerColor',
  templateUrl: 'layer_color.component.html',
  styleUrls: ['layer_color.component.less']
})
export class LayerColorComponent extends PluginClass implements OnInit, IToolLayer {
  @ViewChild('brightnessSlider', { read: SliderWidget }) brightnessSlider:SliderWidget;
  @ViewChild('contrastSlider', { read: SliderWidget }) contrastSlider:SliderWidget;
  @ViewChild('opacitySlider', { read: SliderWidget }) opacitySlider:SliderWidget;

  activateMode:string = null;
  currentLayer:ILayer;
  parentComponent:IContainer;

  private active:boolean;

  addInterface(name:string, pi:IPluginInterface) {
    console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
  }

  removeInterface(name:string) {}

  checkLayer(layer:ILayer) {
    return true;
  }

  activateToolLayer(layer:ILayer) {
    if (!this.isSupported) {
      return;
    }
    this.currentLayer = layer;
    // уведомляем слайдеры о смене значения
    if (this.brightnessSlider) {
      this.brightnessSlider.setValue(layer.brightness);
      this.contrastSlider.setValue(layer.contrast);
      this.opacitySlider.setValue(layer.opacity);
    }

    if (this.parentComponent) {
      this.parentComponent.open();
    }
  }

  getGroup() {
    return '';
  }

  deactivateTool():Promise<boolean> {
    return Promise.resolve(true);
  }

  activateTool() {}

  isActive() {
    return this.active;
  }

  destroy() {
    if (this.parentComponent) {
      this.parentComponent.destroy();
    }
  }

  valueChanged(newVal:number, property:string):void {
    this.currentLayer[property] = newVal;
  }

  /**
   * Вернуть параметры контрасности, яркости и прозрачности
   */
  refresh(layer:ILayer) {
    this.currentLayer.brightness = 0;
    this.currentLayer.contrast = 0;
    this.currentLayer.opacity = 0;

    this.brightnessSlider.setValue(0);
    this.contrastSlider.setValue(0);
    this.opacitySlider.setValue(0);
  }
}
