import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  Button,
  LeafletMapComponent,
  Loader,
  MenuComponent,
  SearchEngineComponent,
  SlidePanelComponent,
  SlidePanelNewComponent,
  ZoomControlComponent,
  VerticalSpinnerComponent,
  InputClearComponent,
  TabsViewComponent,
  RatingViewComponent
} from './components';
import { CollapseDirective } from './directives/directives';
import {
  ChangeEmptyPipe,
  DictValuePipe,
  FilterTreePipe,
  KeysPipe,
  LinkifyPipe,
  NewlinePipe,
  SuitablePipe,
  TransformCoordsPipe,
  TruncatePipe
} from './pipes';
import {ResizableModule} from 'angular-resizable-element';
import {ResizableDirective, ResizeHandleDirective} from './directives/Resizable';
import {GeoanalitikaService} from './components/search_engine/geoanalitika_service';

const DECLARATION_PIPES:Type<any>[] = [
  TransformCoordsPipe,
  KeysPipe,
  SuitablePipe,
  TruncatePipe,
  LinkifyPipe,
  DictValuePipe,
  ChangeEmptyPipe,
  FilterTreePipe,
  NewlinePipe
];

@NgModule({
  imports: [CommonModule, FormsModule, ResizableModule],
  declarations: [
    Button,
    MenuComponent,
    SlidePanelComponent,
    SlidePanelNewComponent,
    VerticalSpinnerComponent,
    RatingViewComponent,
    TabsViewComponent,
    InputClearComponent,
    LeafletMapComponent,
    SearchEngineComponent,
    ZoomControlComponent,
    CollapseDirective,
    ResizableDirective, ResizeHandleDirective,
    Loader,
    DECLARATION_PIPES
  ],
  entryComponents: [
    Button,
    MenuComponent,
    SlidePanelComponent,
    SlidePanelNewComponent,
    LeafletMapComponent,
    SearchEngineComponent,
    ZoomControlComponent,
    VerticalSpinnerComponent,
    RatingViewComponent,
    TabsViewComponent,
    InputClearComponent,
    Loader
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseDirective,
    Button,
    MenuComponent,
    SlidePanelComponent,
    SlidePanelNewComponent,
    VerticalSpinnerComponent,
    RatingViewComponent,
    TabsViewComponent,
    InputClearComponent,
    LeafletMapComponent,
    SearchEngineComponent,
    ZoomControlComponent,
    CollapseDirective,
    ResizableDirective, ResizeHandleDirective,
    Loader,
    DECLARATION_PIPES
  ],
  providers: [GeoanalitikaService]
})
export class BaseModule {}
