import {Component, Input} from '@angular/core';
import {Feature} from 'shared/classes';
import {DocumentService} from '../search_results/single_result/document.service';

@Component({
  selector: 'geo-rating-view',
  templateUrl: 'rating-view.component.html',
  styleUrls: ['rating-view.component.less']
})
export class RatingViewComponent {

  static userRatings = {};

  @Input() feature:Feature = null;

  hoverIndex:Number = -1;

  rates = [1, 2, 3, 4, 5];

  constructor(private docService:DocumentService) {}

  get rating():Number {
    let rating = this.feature.rating;
    if (this.feature.properties && this.feature.properties.rating >= 0) {
      rating = this.feature.properties.rating;
    }
    return rating;
  }

  get text():string {
    return this.rating > 0 ? this.rating.toFixed(1) : 'Нет оценок';
  }

  get myRating():string {
    return RatingViewComponent.userRatings[this.feature.layer.id + '_' + this.feature.id];
  }


  isHalfOf(current, value):Boolean {
    return current > value && current - 1 < value;
  }

  isAlreadyRate():Boolean {
    return !!this.myRating;
  }

  onClick(value:Number) {
    if (!this.isAlreadyRate()) {
      this.docService.setRating(this.feature.layer.id, this.feature.id, value).subscribe((response) => {
        this.feature.rating = response.rating;
        RatingViewComponent.userRatings[this.feature.layer.id + '_' + this.feature.id] = value;
      });
    }
  }

  onMouseOver(value:Number) {
    if (!this.isAlreadyRate()) {
      this.hoverIndex = value;
    }
  }

  onMouseLeave(value:Number) {
    this.hoverIndex = -1;
  }
}
