import { Component, ElementRef } from '@angular/core';
import { PluginClass, Point } from '../../classes';
import { IDistance, IDomSize, IPluginInterface } from '../../interfaces';
import { MapService } from '../../services/map.service';

@Component({
  selector: 'scale-control',
  templateUrl: 'scale_control.component.html',
  styleUrls: ['scale_control.component.less']
})
export class ScaleControlComponent extends PluginClass {
  currentScale:number = null;

  private distancePlugin:IDistance;
  private domSizePlugin:IDomSize;
  private scaleDiv:HTMLElement;
  private scaleText:Element;

  constructor(private el:ElementRef, mapService:MapService) {
    super();

    mapService.currentMapBound$.subscribe(() => {
      if (this.distancePlugin && this.domSizePlugin) {
        this.update();
      }
    });
  }

  addInterface(name:string, pi:IPluginInterface):void {
    if (name === 'ScaleControlDistance') {
      this.distancePlugin = pi as IDistance;
      if (this.domSizePlugin) {
        this.update();
      }
    } else if (name === 'ScaleControlSize') {
      this.domSizePlugin = pi as IDomSize;
      if (this.distancePlugin) {
        this.update();
      }
    } else {
      console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
    }
  }

  removeInterface(name:string):void {
    if (name === 'ScaleControlDistance') {
      this.distancePlugin = null;
    } else if (name === 'ScaleControlSize') {
      this.domSizePlugin = null;
    }
  }

  private update() {
    const size = this.domSizePlugin.getDomSize();
    const y = size.height / 2;
    const maxMeters = this.distancePlugin.getDistance(new Point(0, y), new Point(100, y));
    this.updateMetric(maxMeters);
  }

  private updateMetric(maxMeters:number) {
    const meters = this.getRoundNum(maxMeters),
      label = meters < 1000 ? meters + ' м' : meters / 1000 + ' км';

    if (!this.scaleDiv) {
      this.scaleDiv = this.el.nativeElement.children[0];
    }

    if (!this.scaleText) {
      this.scaleText = this.scaleDiv.children[0];
    }

    this.updateScale(this.scaleDiv, this.scaleText, label, meters / maxMeters);
  }

  private updateScale(scaleDiv:HTMLElement, scaleText:Element, text:string, ratio:number) {
    scaleDiv.style.width = Math.round(100 * ratio) + 'px';
    scaleText.innerHTML = text;
  }

  private getRoundNum(num:number) {
    const pow10 = Math.pow(10, (Math.floor(num) + '').length - 1);
    let d = num / pow10;

    d = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1;

    return pow10 * d;
  }
}
