const esri = require('node_modules/esri-leaflet/dist/esri-leaflet.js');
//const L = require('node_modules/leaflet/dist/leaflet.js');

import * as L from 'leaflet';

export const EsriDynamicTiledLayer = L.TileLayer.extend({

  options: L.Util.extend({}, esri.DynamicMapLayer.prototype.options),

  _requests: [],

  /**
   * @constructor
   * @extends {L.TileLayer}
   * @param  {String} url
   * @param  {Object} options
   */
  initialize: function(url, options) {
    (L.TileLayer as any).prototype.initialize.call(this, url, options);
    esri.DynamicMapLayer.prototype.initialize.call(this, url, options);
  },

  /**
   * @param  {L.Map} map
   */
  onAdd: function(map) {
    if (map.options.crs && map.options.crs.code) {
      const sr = map.options.crs.code.split(':')[1];
      this.options.bboxSR = sr;
      this.options.imageSR = sr;
    }

    map.on('zoomstart zoomend', this._onZoomChange, this);
    return L.TileLayer.prototype.onAdd.call(this, map);
  },

  /**
   * @param  {L.Map} map
   */
  onRemove: function(map) {
    map.off('zoomstart zoomend', this._onZoomChange, this);
    L.TileLayer.prototype.onRemove.call(this, map);
  },

  /**
   * @param {Array.<Number>|Array.<String>} layers
   * @return {L.esri.Layers.TiledDynamicMapLayer} self
   */
  setLayers: function(layers) {
    this._reset();
    return esri.Layers.DynamicMapLayer.prototype.setLayers.call(this, layers);
  },

  /**
   * @param {Array.<Object>} layerDefs
   * @return {L.esri.Layers.TiledDynamicMapLayer} self
   */
  setLayerDefs: function(layerDefs) {
    this._reset();
    return esri.Layers.DynamicMapLayer.prototype.setLayerDefs.call(this, layerDefs);
  },

  /**
   * @param {Object} timeOptions
   * @return {L.esri.Layers.TiledDynamicMapLayer} self
   */
  setTimeOptions: function(timeOptions) {
    this._reset();
    return esri.Layers.DynamicMapLayer.prototype.setTimeOptions.call(this, timeOptions);
  },

  /**
   * Set/unset zooming flag to avoid unneeded requests
   * @param  {Object} e
   */
  _onZoomChange: function(e) {
    this._zooming = (e.type === 'zoomstart');
  },

  /**
   * @param  {L.LatLngBounds} bounds
   * @param  {L.Point}        size
   * @return {Object}
   */
  _buildExportParams: function(bounds, size) {
    const ne = this._map.options.crs.project(bounds._northEast);
    const sw = this._map.options.crs.project(bounds._southWest);

    //ensure that we don't ask ArcGIS Server for a taller image than we have actual map displaying
    const top = this._map.latLngToLayerPoint(bounds._northEast);
    const bottom = this._map.latLngToLayerPoint(bounds._southWest);

    if (top.y > 0 || bottom.y < size.y) {
      size.y = bottom.y - top.y;
    }

    const params:any = {
      bbox: [sw.x, sw.y, ne.x, ne.y].join(','),
      size: this.options.tileSize + ',' + this.options.tileSize,
      dpi: 96,
      format: this.options.format,
      transparent: this.options.transparent,
      bboxSR: this.options.bboxSR,
      imageSR: this.options.imageSR
    };

    if (this.options.layers) {
      params.layers = 'show:' + this.options.layers.join(',');
    }

    if (this.options.layerDefs) {
      params.layerDefs = JSON.stringify(this.options.layerDefs);
    }

    if (this.options.timeOptions) {
      params.timeOptions = JSON.stringify(this.options.timeOptions);
    }

    if (this.options.from && this.options.to) {
      params.time = this.options.from.valueOf() + ',' + this.options.to.valueOf();
    }

    if (this.service.options.token) {
      params.token = this.service.options.token;
    }

    if (params.bboxSR == 3857) {
      params.bboxSR = 102100;
    }
    if (params.imageSR == 3857) {
      params.imageSR = 102100;
    }

    return params;
  },

  /**
   * @param  {Object}  tile
   * @param  {L.Point} tilePoint
   */
  _loadTile: function(tile, tilePoint) {
    tile._layer = this;
    tile.onload = this._tileOnLoad;
    tile.onerror = this._tileOnError;

    this._adjustTilePoint(tilePoint);
    this.getTileUrl(tilePoint, function(err, url) {
      tile.src = url;
    });

    this.fire('tileloadstart', {
      tile: tile
    });
  },

  /**
   * Async request tile url
   * @param  {L.Point}  tilePoint
   * @param  {Function} callback
   */
  getTileUrl: function(tilePoint) { // (Point, Number) -> String

    const map = this._map,
      tileSize = this.options.tileSize,

      nwPoint = tilePoint.multiplyBy(tileSize),
      sePoint = nwPoint.add([tileSize, tileSize]);

    const bounds = new L.LatLngBounds(map.unproject(nwPoint, tilePoint.z),
      map.unproject(sePoint, tilePoint.z));
    const size = new L.Point(this.options.tileSize, this.options.tileSize);

    const params = this._buildExportParams(bounds, size);

    if (this.options.f === 'json') {
      return this.options.url + 'export' + L.Util.getParamString(params);
    } else {
      params.f = 'image';
      return this.options.url + 'export' + L.Util.getParamString(params); //, bounds);
    }
  },

  /**
   * Export call, json or image straight awy
   * @param  {Object}          params
   * @param  {L.LatLngBounds}  bounds
   * @param  {Function}        callback
   */
  _requestExport: function(params, bounds, callback) {
    if (this.options.f === 'json') {
      this._requests.push(this.service.get('export', params, function(error, response) {
        callback(null, response.href, bounds);
      }, this));
    } else {
      params.f = 'image';
      callback(null, this.options.url + 'export' + L.Util.getParamString(params), bounds);
    }
  },

  /**
   * Bounds or params changed
   */
  _update: function() {
    if (this._map && this._map._animatingZoom) {
      return;
    }
    (L.TileLayer as any).prototype._update.call(this);
  }

});
