import { Pipe, PipeTransform } from '@angular/core';
import { Attribute } from 'shared';

@Pipe({ name: 'attrType' })
export class AttributeTypePipe implements PipeTransform {
  transform(array:Attribute[], type:string):any {
    switch (type) {
      case 'sum':
      case 'numeric':
      case 'avg':
      case 'min':
      case 'max':
        return array.filter(item => item.isNumber());
      case 'count':
        return array;
      case 'date':
        return array.filter(item => item.isDate());
      default:
        return array;
    }
  }
}
