import {AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {GroupLayer, WMSLayer} from 'shared/classes';
import {GridLayer, LatLng, LatLngBounds, LatLngBoundsExpression, LatLngExpression, Map} from 'leaflet';
import { MapWidget } from '../widgets';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ILayer} from 'shared';
import {LayerWrapper, NativeLayer} from '../../../../../../shared/classes/LeafletLayer/NativeLayer.class';
import {MarkerClusterLayer} from '../../../../../../shared/classes/LeafletLayer/MarkerClusterLayer.class';

const cloneDeep = require('lodash/cloneDeep');

@Component({
  selector: 'map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MapViewComponent implements AfterViewInit {
  @Input() widget:MapWidget;
  @ViewChild('mapDom') mapDom:ElementRef;

  private map:Map;

  constructor(private sanitizer:DomSanitizer) {
  }

  ngAfterViewInit() {
    this.createMap();
  }

  get titleHtml():SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.widget.title);
  }

  resize() {
    this.map.invalidateSize();
  }

  private createMap() {
    let center:LatLngExpression = [55, 37];
    let leafletBounds:LatLngBoundsExpression;
    const bounds = this.widget.bounds;
    if (bounds) {
      const southWest = new LatLng(bounds.ymin.x, bounds.ymin.y);
      const northEast = new LatLng(bounds.ymax.x, bounds.ymax.y);
      leafletBounds = new LatLngBounds(southWest, northEast);
      center = leafletBounds.getCenter();
    }

    const map:Map = new Map(this.mapDom.nativeElement, {
      worldCopyJump: true,
      zoomControl: false,
      inertia: true,
      zoom: 3,
      center: center,
      doubleClickZoom: false
    });

    this.map = map;

    let firstLayer:GridLayer;
    let clusterLayer:MarkerClusterLayer;
    const layersList = this.retrieveLayers(this.widget.layers);
    layersList.forEach((layer, idx) => {
      if (layer.visible) {
        const leafletLayers = [];
        if (layer instanceof NativeLayer) {
          leafletLayers.push(layer.buildLayerInstanceBase());
        } else if (layer instanceof MarkerClusterLayer) {
          const options = cloneDeep(layer.originalOptions);
          options.map = this.map;
          options.visible = layer.visible;
          clusterLayer = new MarkerClusterLayer(options);
          clusterLayer.setData(layer.data);
        } else if (layer instanceof LayerWrapper && layer.innerLayer instanceof MarkerClusterLayer ) {
          const options = cloneDeep(layer.innerLayer.originalOptions);
          options.map = this.map;
          options.visible = layer.innerLayer.visible;
          clusterLayer = new MarkerClusterLayer(options);
          clusterLayer.setData(layer.innerLayer.data);
        } else {
          leafletLayers.push(layer.buildLayerInstance());
        }
        if (layer.mapnikId && leafletLayers[0]) {
          leafletLayers[0].options.layers = layer.mapnikId.toString();
          leafletLayers[0].wmsParams.layers = layer.mapnikId.toString();
        }
        for (const leafletLayer of leafletLayers) {
          this.map.addLayer(leafletLayer);
        }
        if (idx === 0) {
          firstLayer = leafletLayers[0];
        }
      }
    });
    if (firstLayer) {
      let eventOn:boolean = false;
      const onLoad = () => {
        this.fitLayersBound.apply(this, this.fitLayersBound());
      };
      firstLayer.on('load', onLoad);
      eventOn = true;
      this.mapDom.nativeElement.addEventListener('mousedown', () => {
        if (eventOn) { firstLayer.off('load', onLoad); }
        eventOn = false;
      });
      this.mapDom.nativeElement.addEventListener('wheel', () => {
        if (eventOn) { firstLayer.off('load', onLoad); }
        eventOn = false;
      });
    }
  }

  zoom(step:number) {
    const zoom = this.map.getZoom();
    this.map.setZoom(zoom + step);
  }

  fitLayersBound() {
    const bounds = this.widget.bounds;
    if (bounds) {
      const southWest = new LatLng(bounds.ymin.x, bounds.ymin.y);
      const northEast = new LatLng(bounds.ymax.x, bounds.ymax.y);
      const leafletBounds = new LatLngBounds(southWest, northEast);
      this.map.fitBounds(leafletBounds);
    }
  }


  retrieveLayers(items:any[]):ILayer[] {
    let layers = [];
    for (const item of items) {
      if (item instanceof GroupLayer) {
          layers = layers.concat(this.retrieveLayers(item.subLayers));
      } else {
        layers.push(item);
      }
    }
    return layers;
  }
}
