import { Component } from '@angular/core';
import { ILayer } from 'shared/interfaces';
import { LayersFactory } from 'shared/services';
import { LayersStore } from 'shared/stores/LayersStore';
import { AbstractCartometricOperation } from '../abstract_cartometric_operation_form';
import { CartometricService } from '../cartometric.service';
import { Heatmap } from '../classes';

@Component({
  selector: 'heat-map',
  templateUrl: 'heat_map.component.html',
  styleUrls: ['heat_map.component.less']
})
export class GeoHeatMapComponent extends AbstractCartometricOperation {
  settings = new Heatmap();

  constructor(layersStore:LayersStore, layersFactory:LayersFactory, cartometricService:CartometricService) {
    super(layersStore, layersFactory, cartometricService);
  }

  subscribeForLayers() {
    this.layersStore.getActiveLayers().subscribe(data => {
      this.layers = data.filter(layer => layer.type === 'wms' && layer.simple && this.geomIsPoint(layer));
    });
  }

  protected reset() {
    this.form.resetForm({ units: 'm' });
    this.settings = new Heatmap();
  }

  protected prepareData() {
    return {
      layerId: this.settings.source.id,
      filter: this.settings.source.filter,
      name: this.settings.name,
      mapStyle: 'heatmap',
      size: this.settings.size
    };
  }

  private geomIsPoint(layer:ILayer) {
    return layer.geomType === 'point' || layer.geomType === 'multipoint';
  }
}
