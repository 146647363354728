import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Feature } from 'shared/classes';
import { SingleResultPopup } from 'shared/components';
@Component({
  selector: 'single_popup',
  templateUrl: 'single_result_popup.component.html',
  styleUrls: ['single_result_popup.component.less']
})
export class DesktopSingleResultPopup extends SingleResultPopup {
  constructor(httpClient:HttpClient) {
    super(httpClient);
  }

  maximizeChart(feature:Feature) {
    this.lightBoxPlugins.forEach(plugin => {
      plugin.showChart(feature);
    });
  }
}
