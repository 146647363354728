import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import { Feature } from '../classes/Feature';
import { ILayer } from '../interfaces/ILayer';
import {IRoutePoint, IRouteResult} from 'shared/interfaces';
import {Extent, Point} from 'shared/classes';
import {DirectGeometryObject, FeatureCollection} from 'geojson';
import {IAppSettings} from '../environment';

const geocoders_sources:{ [key:string]:string } = {
  osm: 'https://nominatim.openstreetmap.org/search?q={searchText}&format=json&polygon=1&addressdetails=1&dedupe=1',
  mapzen: 'https://search.mapzen.com/v1/autocomplete?api_key=search-4CBy3TP&text={searchText}',
  ga: 'https://geocoder.geoanalitika.com/api?q={searchText}'
};
const reverse_urls:{ [key:string]:string } = {
  osm: 'https://nominatim.openstreetmap.org/reverse?lat={lat}&lon={lng}&format=json&polygon=1&addressdetails=1&dedupe=1',
  mapzen: 'https://search.mapzen.com/v1/autocomplete?api_key=search-4CBy3TP&text={searchText}',
  ga: 'https://geocoder.geoanalitika.com/reverse?lat={lat}&lon={lng}'
};

@Injectable()
export class GeocoderService {
  source:string = null;

  constructor(private httpClient:HttpClient, @Inject('environment') private settings:IAppSettings) {}

  searchAddress(searchText:string, bbox?:string):Observable<Feature[]> {
    if (!geocoders_sources[this.source]) {
      return of([] as Array<Feature>);
    }

    let url = geocoders_sources[this.source].replace('{searchText}', encodeURIComponent(searchText));

    if (this.source === 'osm' && bbox) {
      url += `&viewbox=${bbox}&bounded=1`;
    }

    return this.httpClient
      .get<any>(url)
      .pipe(
        map(data => {
          let features:Array<Feature>;
          switch (this.source) {
            case 'osm':
              features = this._prepareAddressDataNominatim(data);
              break;
            case 'mapzen':
              features = this._prepareAddressDataMapZen(data);
              break;
            case 'ga':
              features = this._prepareAddressDataGeoanalitika(data);
              break;
            default:
              features = [];
          }
          return features;
        }),
        catchError(() => of([]))
      );
  }

  searchReverse(point:Point):Observable<Feature[]> {
    if (!geocoders_sources[this.source]) {
      return of([]);
    }

    const url = reverse_urls[this.source].replace('{lat}', point.y.toString()).replace('{lng}', point.x.toString());

    return this.httpClient
      .get<any>(url)
      .pipe(
        map(data => {
          switch (this.source) {
            case 'osm':
              return this._prepareAddressDataNominatim([data]);
            case 'mapzen':
              return this._prepareAddressDataMapZen(data);
            case 'ga':
              return this._prepareAddressDataGeoanalitika(data);
            default:
              return [];
          }
        }),
        catchError(() => of([]))
      );
  }

  buildRoute(stops:IRoutePoint[], allowTolls:boolean):Observable<IRouteResult> {
    const first = stops[0];
    const last = stops[stops.length - 1];
    const locations = stops.map(item => ({lat: item.lat, lon: item.lng}));
    const userTolls = allowTolls ? '1' : '0';
    const url = `/projects/router/?locations=${JSON.stringify(locations)}&options={"costing_options":{"auto":{"use_tolls":${userTolls}}}}`;
    return this.httpClient.get(url).pipe(
      map((response:any) => {
        const ret = response.trip as IRouteResult;
        for (let i = 0; i < response.trip.legs.length; i++) {
          const sShape = response.trip.legs[i].shape as string;
          ret.legs[i].points = this.decodePolyline(sShape, 6);
          const bounds = Extent.MIN_EXTENT;
          for (const point of ret.legs[i].points) {
            bounds.expand(point.x, point.y);
          }
          ret.legs[i].extent = bounds;
          delete response.trip.legs[i].shape;
        }
        return ret;
      })
    );
  }

  private decodePolyline(str:string, precision?:number):Point[] {
    let index = 0,
      lat = 0,
      lng = 0,
      shift = 0,
      result = 0,
      byte = null,
      latitude_change,
      longitude_change;

    const coordinates:Point[] = [],
      factor = Math.pow(10, Number.isInteger(precision) ? precision : 5);

    while (index < str.length) {
      // Reset shift, result, and byte
      byte = null;
      shift = 0;
      result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

      shift = result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

      lat += latitude_change;
      lng += longitude_change;

      coordinates.push(new Point(lng / factor, lat / factor));
    }

    return coordinates;
  }

  private _prepareAddressDataGeoanalitika(data:FeatureCollection<DirectGeometryObject, any>):Feature[] {
    const features = data.features.map(item => {
      const f = new Feature();
      f.geometry = item.geometry;
      f.properties = item.properties;
      f.layer = {
        name: 'Адресный поиск',
        popupImage: false,
        columns: [
          {
            alias: 'Адрес',
            crowdsourceSettings: null,
            inAttr: true,
            inPopup: true,
            name: 'name',
            type: 'string'
          }
        ]
      } as ILayer;
      f.properties.nameOld = f.properties.name;
      f.properties.name = '';
      this.addNamePart(f.properties, 'nameOld');
      this.addOsmValue(f.properties);
      this.addNamePart(f.properties, 'state');
      this.addNamePart(f.properties, 'city');
      this.addNamePart(f.properties, 'street');
      this.addNamePart(f.properties, 'housenumber');
      return f;
    });
    return features;
  }

  private addOsmValue(obj:any) {
    let value_ru = '';
    switch (obj.osm_value) {
      case 'river':
        value_ru = 'река';
        break;
    }
    if (!value_ru) {
      switch (obj.osm_key) {
        case 'waterway':
          value_ru = 'река';
          break;
      }
    }
    if (value_ru) {
      if (obj.name) {
        obj.name += ', ';
      }
      obj.name += value_ru;
    }
  }

  private addNamePart(obj:any, attr:string) {
    if (obj[attr]) {
      if (obj.name) { obj.name += ', '; }
      obj.name += obj[attr];
    }
  }

  private _prepareAddressDataMapZen(data:any):Feature[] {
    const features = data.features.map((item:any) => {
      const f:Feature = new Feature();
      f.geometry = item.geometry;
      f.properties = {
        name: item.properties.name
      };

      f.layer = {
        name: 'Адресный поиск',
        popupImage: false,
        columns: [
          {
            alias: 'Адрес',
            crowdsourceSettings: null,
            inAttr: true,
            inPopup: true,
            name: 'name',
            type: 'string'
          }
        ]
      } as ILayer;

      return f;
    });
    return features;
  }

  private _prepareAddressDataNominatim(data:any[]):Feature[] {
    const features = data.map((item:any) => {
      const f:Feature = new Feature();
      f.geometry = {
        type: 'Point',
        coordinates: [parseFloat(item.lon), parseFloat(item.lat)]
      };

      f.properties = {
        name: item.display_name
      };

      // TODO: сделать более универсальным чем тип any
      f.layer = {
        name: 'Адресный поиск',
        popupImage: false,
        columns: [
          {
            alias: 'Адрес',
            crowdsourceSettings: null,
            inAttr: true,
            inPopup: true,
            name: 'name',
            type: 'string'
          }
        ]
      } as any;

      return f;
    });
    return features;
  }
}
