import { Component, Inject, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Attribute, PluginClass } from 'shared/classes';
import { IAppSettings } from 'shared/environment';
import { IContainer, ILayer, IMapResult, IPluginInterface } from 'shared/interfaces';
import { LayersStore } from 'shared/stores/LayersStore';
import { IUser } from '../../../../interfaces/IUser';
import { AuthService } from '../../../../services/auth.service';
import { Chart } from '../../classes';
import { ChartService } from '../../services';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html'
})
export class DashboardComponent extends PluginClass implements OnInit {
  parentComponent:IContainer;
  currentForm:string;
  chart:Chart;
  chartListVisible = false;
  charts:Chart[] = [];
  layers:ILayer[] = []; // список источников
  manage:number[] = []; // слои с разрешением на удаление/добавление диаграмм
  user:IUser = null;
  projectSlug:string;

  private resultPlugins:IMapResult[] = [];

  constructor(
    private chartService:ChartService,
    layersStore:LayersStore,
    authService:AuthService,
    @Inject('environment') settings:IAppSettings
  ) {
    super();

    this.projectSlug = settings.PROJECT_SLUG;

    layersStore.getActiveLayers().subscribe(data => {
      this.layers = data.filter(layer => layer.mapnikDatasourceId && this.manage.includes(layer.id) );
    });

    // получение инфы о пользователе
    authService.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  ngOnInit() {
    this.getChartList(this.projectSlug);
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'MapResults':
        this.resultPlugins.push(pi as IMapResult);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'MapResults':
        this.resultPlugins = null;
        break;
      default:
        break;
    }
  }

  activate() {}

  deactivate() {}

  onFormClick(type:string) {
    this.currentForm = type;
  }

  onFormClose() {
    this.currentForm = '';
  }

  onChartClick(chart:Chart) {
    this.chart = chart;
  }

  onChartSave() {
    this.currentForm = '';
    this.getChartList(this.projectSlug);
  }

  onChartDelete() {
    this.chart = null;
    this.getChartList(this.projectSlug);
  }

  private getChartList(projectSlug:string) {
    this.chartService
      .getChartList(projectSlug)
      .pipe(catchError(() => of({ objects: [] })))
      .subscribe(data => {
        this.charts = this.createChartList(data.objects);
      });
  }

  private createChartList(objects:any[]):Chart[] {
    const charts:Chart[] = [];
    objects.forEach(object => {
      const chart = new Chart(object.type);
      chart.colName = new Attribute();
      chart.colName.name = object.attribute_field_name;
      chart.colValue[0] = new Attribute();
      chart.colValue[0].name = object.attribute_field_name_joined;
      chart.methods = [object.function];
      chart.resource = object.rsrc.id;
      chart.resource_uri = object.resource_uri;
      chart.name = object.title;
      chart.joinedResource.resource = object.resource_joined;
      chart.joinedResource.method = object.filter_function;
      chart.joinedResource.attribute = new Attribute();
      chart.joinedResource.attribute.name = object.joined_attribute;
      chart.status = object.status;
      chart.layer_id = object.layer_id;
      chart.error = object.error;
      charts.push(chart);
    });

    return charts;
  }
}
