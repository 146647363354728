import {Attribute} from './Attribute';
import {Component} from '@angular/core';

export class JoinedTable {

    constructor(data:any) {
        Object.assign(this, data);
        this.attributes = data.attributes.map(attr => Object.assign(new Attribute(), attr));
    }

    id:number;
    column:string;
    title:string = 'table';
    value:any;
    attributes:Attribute[];
 }
