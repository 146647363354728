import { LayerType } from 'shared/interfaces';
import { TMSLayer } from './TMSLayer.class';
import {EsriDynamicTiledLayer} from './EsriDynamicTiledLayer.class';

const esri = require('node_modules/esri-leaflet/dist/esri-leaflet.js');

const EsriDynamicTiledLayerWrapper = EsriDynamicTiledLayer as any;

// TODO: убрать (this._layerInstance as any)
export class ArcGISLayer extends TMSLayer {
  type:LayerType = 'arcgis';

  mapService:any;
  layerIds:number[];

  constructor(options?:any) {
    super(options);

    if (this.url && !this.url.endsWith('/')) {
      this.url = this.url + '/';
    }
  }

  set order(value:number) {
    this._order = value;
    if ((this._layerInstance as any)._currentImage) {
      (this._layerInstance as any)._currentImage.setZIndex(value);
    }
  }

  getLegendUrl() {
    return this.currentVersion < 10.71 ?
      `${this.url}${this.name}/MapServer/legend?f=pjson` :
      `${this.url}${this.arcgisId}/legend?f=json`;
  }

  protected createLayerInstance():Promise<any> {
    return new Promise((resolve, reject) => {
      const mapServiceUrl = this.currentVersion < 10.71 ?
        `${this.url}${this.name}/MapServer` :
        `${this.url}${this.arcgisId}/MapServer`;
      this._layerInstance = this.buildLayerInstance();

      this.mapService = esri.mapService({
        url: mapServiceUrl
      });

      this._layerInstance.on('load', () => {
        this.createFilterStyle();
        this.order = this._order;
        resolve();
      });
    });
  }

  public buildLayerInstance():any {
    const mapServiceUrl = this.currentVersion < 10.71 ?
      `${this.url}/${this.name}/MapServer` :
      `${this.url}`;
    const options:any = {
      url: mapServiceUrl,
      f: 'image',
      format: 'png32',
      pane: 'tilePane'
    };
    if (this.currentVersion >= 10.71) {
      options.tileSize = this.tileSize;
      options.layers = [this.arcgisId.toString()];
    }
    if (options.tileSize) {
      return new EsriDynamicTiledLayerWrapper(options);
    } else {
      return new esri.DynamicMapLayer(options);
    }
  }

  protected createFilterStyle() {
    if (!(this._layerInstance as any)._currentImage) {
      return;
    }
    const layerDiv = (this._layerInstance as any)._currentImage._image;
    if (!layerDiv) {
      return;
    }
    const filterText:string = 'brightness(' + (this.brightness + 100) + '%) contrast(' + (this.contrast + 100) + '%)';
    (layerDiv.style as any)['-webkit-filter'] = filterText;
    (layerDiv.style as any)['-moz-filter'] = filterText;
    (layerDiv.style as any)['-ms-filter'] = filterText;
    (layerDiv.style as any)['-o-filter'] = filterText;
    layerDiv.style['filter'] = filterText;
    // TODO: Сделать поддержку IE и Safari
  }
}
