import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Feature } from 'shared/classes';
import { LightBoxComponent } from 'shared/components';

export const fadeAnimation = trigger('fadeAnimation', [
  state('true', style({ display: 'block', opacity: 1 })),
  state('false', style({ display: 'none', opacity: 0 })),
  transition('0 => 1', animate('0.5s')),
  transition('1 => 0', animate('0.5s'))
]);

@Component({
  selector: 'geo-light-box',
  templateUrl: 'light_box.component.html',
  styleUrls: ['light_box.component.less'],
  animations: [fadeAnimation]
})
export class DesktopLightBoxComponent extends LightBoxComponent {
  currentFeature:Feature;
  fade = false;

  constructor() {
    super();
  }

  showChart(feature:Feature) {
    this.fadeIn();
    this.currentFeature = feature;
  }

  close() {
    this.currentFeature = null;
    this.images = [];
  }

  protected fadeIn() {
    this.fade = false;

    setTimeout(() => {
      this.fade = true;
    });
  }
}
