import { Attribute } from 'shared/classes';
import { ILayer } from 'shared/interfaces';

export abstract class Operation {
  name:string;
  source:ILayer;
}

export class BufferZone extends Operation {
  units:'m' | 'km' = 'm';
  join = false;
  attribute:Attribute;
  width:number;
}

export class Heatmap extends Operation {
  units:'m' | 'km' = 'm';
  attribute:Attribute;
  colors:string[] = [];
  size = 32;
  opacity = 0.3;
}

export class OverlayOp extends Operation {
  target:ILayer;
  operation:'xor' | 'src-in' | 'src-out';
}

export class Cluster extends Operation {
  attribute:Attribute;
  p = 0.05;
  z = 1.65;
}

export class ThematicMap extends Operation {
  attribute:Attribute;
  method:{ name:string; value:string };
  rangesNum = 5;
}

export class BaseStyle {
  colors:string[];
  name:string;
  rangesNum:number;
  method:string;

  // Defaults
  typeGroup = 'integer';
  styleProperty = 'fill';
  defaultValue = {
    color: ['#1870dcff'],
    name: 'По умолчанию'
  };
  type:'integer' | 'string' = 'integer';
  values:any[] = [];

  range:number[] = [];
  description:string[] = [];

  constructor(options:any) {
    Object.assign(this, options);
  }
}

export type DistributionMethod = 'auto' | 'quantile' | 'manual';
