import { Component, HostBinding } from '@angular/core';
import { AddressSearchComponent as ParentComponent } from 'shared/components';

@Component({
  selector: 'address-search',
  templateUrl: 'address_search.component.html',
  styleUrls: ['address_search.component.less']
})
export class AddressSearch extends ParentComponent {
  @HostBinding('class') classes = 'address-search';

  showTooltip = false;

  constructor() {
    super();
  }

  search() {
    this.showTooltip = false;
    super.search();
  }
}
