import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {Attribute, Feature, Image, PluginClass, Point, Utils} from 'shared/classes';
import { IAppSettings } from '../../environment';
import {IActivator, IBaseEdit, IButtonConfig, IDraw, IDrawFinish, IEdit, ILayer, IMenu, IPluginInterface, ITool} from '../../interfaces';
import { DictionaryService } from '../../services/dictionary.service';
import { LayersStore } from '../../stores/LayersStore';
import {Button} from '..';

@Component({
  selector: 'crowdsourcing',
  templateUrl: 'crowdsourcing.component.html',
  styleUrls: ['crowdsourcing.component.less']
})
export class CrowdsourcingComponent extends PluginClass implements IDrawFinish, IDraw, IBaseEdit, ITool, OnInit {
  @Input() pictureFormats:string[] = ['png', 'jpeg', 'gif', 'jpg'];
  @Input() maxSize = 10485760; // = 10 Mb

  activateMode:string = null;
  groupName:string;
  layer:ILayer;
  feature:Feature;
  step = 'init';
  attributes:Attribute[];
  message:string;

  private buttonMenu:IMenu;
  private btn:Button;
  private drawPlugin:IDraw;
  private editPlugin:IEdit;
  private drawing = false;
  private activator:IActivator;
  private active = false;
  private projectSlug:string;

  constructor(
    private httpClient:HttpClient,
    public dictService:DictionaryService,
    public layersStore:LayersStore,
    @Inject('environment') settings:IAppSettings
  ) {
    super();

    this.projectSlug = settings.PROJECT_SLUG;

    layersStore
      .onOptionsLoaded()
      .pipe(
        switchMap(() => layersStore.getActiveLayers()),
        map(layers => {
          return layers.filter(layer => layer.crowdsource)[0];
        })
        // .take(1)  еслм не будет нужно следить за отображением на масштабе
      )
      .subscribe(layer => {
        try {
          this.layer = layer;
          this.btn.title = (this.layer as any).crowdsourceBtnText;
          this.btn.text = (this.layer as any).crowdsourceBtnText || 'Создать объект';
          this.btn.visible = true;

          if (!!this.layer === false) {
            if (this.active) {
              this.deactivate();
            }
            return;
          }

          if (!this.layer.columns || this.attributes) {
            return;
          }
          this.attributes = this.layer.columns
            .filter((column:Attribute) => !column.is_pk && column.crowdsourceSettings && !column.crowdsourceSettings.hidden)
            .sort((c1:Attribute, c2:Attribute) => c1.crowdsourceSettings.order - c2.crowdsourceSettings.order);
        } catch (e) {
          console.error(e.message);
        }
      });
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        this.buttonMenu = pi as IMenu;
        this.addButton();
        break;
      case 'Draw':
        this.drawPlugin = pi as IDraw;
        break;
      case 'Edit':
        this.editPlugin = pi as IEdit;
        break;
      case 'Activator':
        this.activator = pi as IActivator;
        this.activator.setConnect(this as ITool);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Draw':
        this.drawPlugin = null;
        break;
      case 'Edit':
        this.editPlugin = null;
        break;
      case 'Activator':
        this.activator = null;
        break;
    }
  }

  finishDraw(feature:Feature) {
    this.stopDraw();
    this.clearDrawFeatures();

    feature.invertCoordinates();
    this.showEditableFeatures([feature]);

    this.feature = feature;

    // подставляем значения по умолчанию из настроек краудсорсинга
    this.layer.columns.forEach((column:Attribute) => {
      this.feature.properties[column.name] = column.crowdsourceSettings ? column.crowdsourceSettings.defaultValue : null;
    });

    this.step = 'form';
    this.btn.visible = false;
  }

  stopDraw() {
    this.drawing = false;
    if (this.drawPlugin) {
      this.drawPlugin.stopDraw();
    }
  }

  addFeature() {}

  addGeometry(points:Point[], type:string, style?:any) {}

  showEditableFeatures(features:Feature[]) {
    this.editPlugin.showEditableFeatures(features);
  }

  clearEditedFeatures() {
    this.editPlugin.clearEditedFeatures();
  }

  removeEditedFeaturesByType(type:String) {
    throw new Error('Not imlpemented');
  }

  updateEditableFeatures(geometry:GeoJSON.DirectGeometryObject) {
    if (!this.drawing && this.active) {
      this.feature.geometry = geometry;
    }
  }

  highlightVertex() {}

  startSnapping() {}

  stopSnapping() {}

  clearDrawFeatures() {
    this.drawPlugin.clearDrawFeatures();
  }

  startDraw(geometry:string) {
    this.drawing = true;
    this.drawPlugin.startDraw(geometry, this as IDrawFinish);
  }

  updateDrawingFeature(feature:Feature) {}

  getGroup() {
    return this.groupName;
  }

  deactivateTool():Promise<boolean> {
    this.deactivate();
    return Promise.resolve(true);
  }

  activateTool():void {
    this.active = true;
    this.message = null;
    this.step = 'draw';
    this.btn.visible = false;
    this.startDraw(this.layer.geomType);
  }

  isActive() {
    return this.active;
  }

  getSelectedDictItem(attr:Attribute):string {
    try {
      const key:string = this.feature.properties[attr.name];
      const value:string = this.dictService.getDictionary(attr.dictionary_id).entries.find(item => item.key === key).value;
      return value;
    } catch (e) {
      return '';
    }
  }

  selectFormDict(editAttr:Attribute, selectedVal:string) {
    this.feature.properties[editAttr.name] = this.dictService
      .getDictionary(editAttr.dictionary_id)
      .entries.find(item => item.value === selectedVal).key;
  }

  activate() {
    if (this.activator) {
      return this.activator.activateComponent(this as ITool);
    }
  }

  deactivate() {
    this.active = false;
    this.message = null;
    this.stopDraw();
    this.clearDrawFeatures();
    this.clearEditedFeatures();
    this.feature = null;
    this.step = 'init';
    this.btn.visible = true;
  }

  addImage(files:File[]) {
    files.forEach(file => {
      const image = new Image({ title: file.name, file });
      this.readUrl(image);
      this.feature.images.push(image);
    });
  }

  removeImage(idx:number) {
    this.feature.images.splice(idx, 1);
  }

  saveObject() {
    if (typeof this.feature.geometry !== 'string') {
      this.feature.invertCoordinates();
      (this.feature as any).geometry = Utils.geojsonToWKT(this.feature.geometry);
    }

    // Обнулить пустые свойства (из инпутов возвращаются пустые строки, на которые ругается БД)
    Object.keys(this.feature.properties).forEach((key:string) => {
      if (this.feature.properties[key] === '' || this.feature.properties[key] == null) {
        this.feature.properties[key] = null;
      }
    });

    return this.httpClient
      .post<any>('geom/create_or_update', {
        layer_id: this.layer.id,
        geom: this.feature.geometry,
        attributes: this.feature.properties
      })
      .pipe(
        map(result => result.pk),
        switchMap(pk => (this.feature.images.length ? this.saveImages(pk) : of(pk)))
      )
      .subscribe(
        () => {
          this.onSuccess();
        },
        error => {
          console.error(error);
          this.message = 'Произошла ошибка про отправке данных.';
        }
      );
  }

  private onSuccess() {
    this.layer.refresh();
    this.deactivate();
    this.message = 'Ваши данные отправлены, они станут доступны после проверки модератором.';
  }

  private readUrl(image:Image) {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      image.url = fileReader.result as string;
    };
    fileReader.readAsDataURL(image.file);
  }

  private saveImages(pk:number):Observable<any> {
    const data:any = {
      object_id: pk,
      layer: this.layer.id,
      type: 'image',
      project_name: this.projectSlug
    };

    this.feature.images.forEach((image:Image, i:number) => {
      data[`file${i}`] = image.file;
    });

    const formdata = new FormData();
    Object.keys(data).forEach(key => {
      formdata.append(key, data[key]);
    });

    return this.httpClient.post('upload_list/', formdata);
  }

  private addButton() {
    this.buttonMenu.createBtn().then(btnRef => {
      this.btn = btnRef.instance;
      const buttonConfig = {
        title: '',
        text: '',
        className: 'create-btn',
        type: 'nostate',
        position: 1,
        visible: false
      };
      this.btn.setOptions(buttonConfig as IButtonConfig);
      this.btn.onClickMe = () => {
        this.activate();
      };
      this.buttonMenu.addBtn(this.btn);
    });
  }
}
