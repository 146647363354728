import { Pipe, PipeTransform } from '@angular/core';
import * as proj4 from 'proj4';

@Pipe({ name: 'transformCoords' })
export class TransformCoordsPipe implements PipeTransform {
  transform(input:any, system:string):any {
    if (system === 'degminsec') {
      const deg = Math.floor(input);
      const min = Math.floor((input - deg) * 60);
      const sec = Math.floor((input - deg - min / 60) * 3600);
      return `${Math.abs(deg)} ° ${min} ${sec}`;
    } else if (system === 'metersLat') {
      return proj4.default('EPSG:4326', 'EPSG:3857')
        .forward([0, input])[1]
        .toFixed(0);
    } else if (system === 'metersLng') {
      return proj4.default('EPSG:4326', 'EPSG:3857')
        .forward([input, 0])[0]
        .toFixed(0);
    }
  }
}
