import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList, ViewChild,
  ViewChildren
} from '@angular/core';
import {IPluginInterface, ISimpleMap, ITool} from 'shared/interfaces';
import {PluginClass} from 'shared/classes';
import {
  GridsterComponent,
  GridsterConfig,
  GridsterItemComponent,
  GridsterItemComponentInterface
} from 'angular-gridster2';
import {
  AbstractWidget,
  ChartOptions,
  ChartsWidget,
  LegendWidget,
  MapWidget, Margins, Page,
  ReportWidgetType,
  StatWidget,
  TextWidget
} from './widgets';
import {MapViewComponent} from './map-view/map-view.component';
import {ChartService} from '../../../../../shared/modules/dashboard/services';
import {IAppSettings} from '../../../../../shared/environment';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {OptionsPanelComponent, Orientation, ScaleEvent} from './options-panel/options-panel.component';
import {DragDropData} from 'ng2-dnd';

@Component({
  selector: 'report',
  templateUrl: 'report.component.html',
  styleUrls: ['report.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportComponent extends PluginClass implements OnInit, OnDestroy {
  @Input() editingWidget:AbstractWidget;

  @ViewChild('options') optionsPanel:OptionsPanelComponent;
  @ViewChildren(GridsterComponent) gridsters:QueryList<GridsterComponent>;
  @ViewChildren(GridsterItemComponent) gridItems:QueryList<GridsterItemComponent>;
  @ViewChildren(MapViewComponent) maps:QueryList<MapViewComponent>;

  parentComponent:ITool;

  //padding:string = '1cm 1cm 1cm 1cm';
  padding:string = '0';
  orientation:'portrait'|'landscape' = 'portrait';
  pages:Page[] = [{widgets:[]}];
  currentPageIndex:number = 0;
  scaleCommand:string;
  scale:number = 1;
  hasBorders:boolean = true;
  chartOptions = new ChartOptions();
  chartsList:any[];
  showChartOptionsDialog:boolean = false;
  currentChart:{widget:ChartsWidget, options:any} = {
    widget: null,
    options: null
  };
  safeHtmlString:SafeHtml;

  private map:ISimpleMap;

  private _gridsterConfig:GridsterConfig = {
    minRows: 4, maxRows: 4, minCols: 2, maxCols: 2,
    draggable: {
      enabled: true,
      ignoreContent: true
    },
    enableEmptyCellDrop: true,
    resizable: {
      enabled: true,
      handles: { s: true, e: true, n: true, w: true, se: true, ne: true, sw: true, nw: true }
    },
    pushItems: true,
    disableWarnings: true,
    itemResizeCallback: (widget:StatWidget) => {
      if (widget.type === 'map') {
        this.maps.find(mapView => mapView.widget === widget).resize();
      }
    }
  };

  constructor(
    private elementRef:ElementRef,
    private chartService:ChartService,
    private sanitizer:DomSanitizer,
    private changeDetector:ChangeDetectorRef,
    @Inject('environment') private appSettings:IAppSettings
  ) {
    super();
    if (this.isFirefox) {
      /* Firefox 72.0.2 for Ubuntu
      PAGE_SIZE.portrait.width = 1178;
      PAGE_SIZE.portrait.height = 1735;
      PAGE_SIZE.landscape.width = 1735;
      PAGE_SIZE.landscape.height = 1105;
       */
      /*Firefox 73.0 Windows*/
      PAGE_SIZE.portrait.width = 816;
      PAGE_SIZE.portrait.height = 1056;
      PAGE_SIZE.landscape.width = 1056;
      PAGE_SIZE.landscape.height = 790;
    }
  }

  get isFirefox():boolean {
    return typeof window['InstallTrigger'] !== 'undefined';
  }

  get gridsterConfig():GridsterConfig {
    return Object.assign( this._gridsterConfig, GRID_CELLS_BY_PAGE[this.orientation]);
  }

  onMarginChange(margins:Margins) {
    this.padding = `${margins.top}cm ${margins.right}cm ${margins.bottom}cm ${margins.left}cm`;
    setTimeout(() => {
      this.gridsters.forEach((comp => comp.resize()));
    }, 100);
  }

  onBordersChange(hasBorders:boolean) {
    this.hasBorders = hasBorders;
  }

  onOrientationChange(orient:Orientation) {
    const orientationOld = this.orientation;
    this.orientation = orient;
    this.resizeWidgets(orientationOld, orient);
    setTimeout(() => {
      this.gridsterConfig.api.optionsChanged();
      this.gridsters.forEach((gridster) => {
        gridster.resize();
      });
      this.refreshScalableLayout();
      this.onLayoutChanged();
    }, 100);
  }

  resizeWidgets(orientationOld, orientationNew) {
    const pageCellsOld = GRID_CELLS_BY_PAGE[orientationOld];
    const pageCellsNew = GRID_CELLS_BY_PAGE[orientationNew];
    let ratio;
    for (const page of this.pages) {
      for (const widget of page.widgets) {
        //preserve proportion of widget size
        ratio = widget.cols / pageCellsOld.minCols;
        widget.cols = Math.round(ratio * pageCellsNew.minCols);
        ratio = widget.rows / pageCellsOld.minRows;
        widget.rows = Math.round(ratio * pageCellsNew.minRows);
        //widget position
        ratio = widget.x / pageCellsOld.minCols;
        widget.x = Math.round(ratio * pageCellsNew.minCols);
        ratio = widget.y / pageCellsOld.minRows;
        widget.y = Math.round(ratio * pageCellsNew.minRows);
      }
    }
  }

  onLayoutChanged() {
    setTimeout(() => {
      this.maps.forEach(mapView => {
        mapView.resize();
      });
    }, 500);
  }

  getWidgetTitle(widget:AbstractWidget):string {
    let ret = '';
    switch (widget.type) {
      case 'text':
        ret = 'Текст';
        break;
      case 'legend':
        ret = 'Легенда';
        break;
      case 'map':
        ret = 'Карта';
        break;
      case 'chart':
        ret = 'Диаграмма';
        break;
    }
    return ret;
  }

  showConfigButton(widget:AbstractWidget):boolean {
    return widget.type === 'text' || widget.type === 'map';
  }

  addWidget(type:ReportWidgetType) {
    const options:GridsterConfig = Object.assign({ type }, DEFAULT_WIDGET_SIZE[this.orientation][type]);
    options.minItemRows = MIN_ITEM_ROWS_BY_TYPE[this.orientation][type];

    if (type === 'map') {
      options.layers = this.map.getLayers();
      options.bounds = this.map.getBounds();
    }

    const widget = new WIDGET_TYPES[options.type](options);
    if (type === 'chart') {
      this.showChartOptionsDialog = true;
      this.currentChart.widget = widget;
    }

    const pageIndex = this.currentPageIndex;
    if (this.gridsters.toArray()[pageIndex].getNextPossiblePosition(widget)) {
      this.pages[pageIndex].widgets.push(widget);
      widget.pageIdx = pageIndex;
      widget.widgetIdx = this.pages[pageIndex].widgets.indexOf(widget);

      if (type === 'text') {
        this.configWidget(widget);
      } else if (widget.type === 'legend') {
        setTimeout(() => this.fitWidgetHeight(widget), 100);
      }
      this.changeDetector.detectChanges();
    } else {
      alert('На выбранной странице нет места');
    }
  }

  addPage() {
    this.pages.push({
      widgets: []
    });
    this.fixZoom();
  }

  onPageDelete(pageIndex:number) {
    this.pages.splice(pageIndex, 1);
    this.changeDetector.detectChanges();
    setTimeout(() => this.fixZoom(), 100);
  }

  onPageSelect(pageIndex:number) {
    this.currentPageIndex = pageIndex;
    const rootElement = this.elementRef.nativeElement as HTMLElement;
    const scrollable = rootElement.querySelector('.paper-scrolable');
    const scrollPosition = (PAGE_SIZE[this.orientation].height + 15) * this.scale * pageIndex;
    scrollable.scrollTop = scrollPosition;
  }

  removeWidget(pageIndex, widgetIndex) {
    this.pages[pageIndex].widgets.splice(widgetIndex, 1);
  }

  configWidget(widget:AbstractWidget) {
    this.editingWidget = widget;
  }

  safeHtml(html:string):SafeHtml {
    this.safeHtmlString = html ? this.sanitizer.bypassSecurityTrustHtml(html) : null;
    return this.safeHtmlString;
  }

  print() {
    const setPrintOrientation = orientation => {
      const css = '@page { visibility: hidden; size: A4 ' + orientation + '; }',
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');

      style.type = 'text/css';
      style.media = 'print';
      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
      window.print();
    };
    setTimeout(() => {
      setPrintOrientation(this.orientation);
    });
    window.onafterprint = () => {
      const style = document.getElementsByTagName('style');
      for (let i = 0; i < style.length; i++) {
        if (style[i].media === 'print') {
          style[i].remove();
        }
      }
    };
  }

  close() {
    (this.parentComponent as any).toggle();
  }

  selectChart(chartOptions:any) {
    this.currentChart.options = chartOptions;
  }

  onWidgetDropSuccess(dropEvent:DragDropData, destPageIndex:number) {
    const srcPageIndex = dropEvent.dragData.pageIndex;
    const srcWidgetIndex = dropEvent.dragData.widgetIndex;
    const widget = this.pages[srcPageIndex].widgets[srcWidgetIndex];
    if (this.gridsters.toArray()[destPageIndex].getNextPossiblePosition(widget)) {
      this.pages[destPageIndex].widgets.push(widget);
      this.removeWidget(srcPageIndex, srcWidgetIndex);
      this.changeDetector.detectChanges();
    } else {
      alert('На странице нет места');
    }
    /*else {
      this.pages = this.pages.splice(destPageIndex, 0, { widgets: [] });
      widget.x = widget.y = 0;
      destPageIndex++;
    }*/
  }

  onWidgetConfigAccept() {
    const widget = this.editingWidget;
    if (widget.type === 'text' && widget.isNew) {
      setTimeout(() => this.fitWidgetHeight(widget), 100);
    }
    widget.isNew = false;
    this.editingWidget = null;
  }

  onWidgetConfigCancel() {
    if (this.editingWidget.isNew) {
      const widgetIndex = this.pages[this.currentPageIndex].widgets.indexOf(this.editingWidget);
      this.removeWidget(this.currentPageIndex, widgetIndex);
    }
    this.editingWidget = null;
  }

  acceptChartOption() {
    this.currentChart.widget.setOptions(this.currentChart.options);
    this.showChartOptionsDialog = false;
  }

  onScaleChange(evt:ScaleEvent) {
    this.scaleCommand = evt.scaleCommand;
    if (this.scaleCommand !== 'fit-height' && this.scaleCommand !== 'fit-width') {
      this.scale = evt.scale;
    }
    this.refreshScalableLayout();
  }

  refreshScalableLayout() {
    let nScale:number;
    if (this.scaleCommand === 'fit-height') {
      const root = this.elementRef.nativeElement as HTMLElement;
      const container = root.querySelector('.paper-scrolable');
      nScale = container.clientHeight / (PAGE_SIZE[this.orientation].height + 8);
      this.scale = nScale;
    } else if (this.scaleCommand === 'fit-width') {
      const root = this.elementRef.nativeElement as HTMLElement;
      const container = root.querySelector('.paper-scrolable');
      nScale = container.clientWidth / (PAGE_SIZE[this.orientation].width + 8);
      this.scale = nScale;
    }
    this.optionsPanel.currentScale = this.scale;
    setTimeout(() => this.fixZoom(), 500);
    this.changeDetector.detectChanges();
  }

  fixZoom() {
    const elementRoot = this.elementRef.nativeElement as HTMLElement;
    const container = elementRoot.querySelector('.paper-scrolable');
    const floatView = elementRoot.querySelector('.float-window') as HTMLElement;
    const elementScalable = elementRoot.querySelector('.paper-scalable') as HTMLElement;
    if (this.scale === 1) {
      floatView.style.height = 'fit-content';
      floatView.style.width = 'fit-content';
      elementScalable.style.top = '';
      elementScalable.style.left = '';
    } else {
      const height = (PAGE_SIZE[this.orientation].height + 15) * this.pages.length;
      const width = PAGE_SIZE[this.orientation].width;
      //real paper height
      const scaledHeight = this.scale * height;
      const scaledWidth = this.scale * width;
      floatView.style.height = scaledHeight + 'px';
      floatView.style.width = scaledWidth + 'px';
      elementScalable.style.top = -(height - scaledHeight) / 2 + 'px';
      elementScalable.style.left = (width - scaledWidth > 0) ? -(width - scaledWidth) * this.scale / 2 + 'px' : '0';
    }
  }

  fitWidgetHeight(widget:AbstractWidget) {
    //find element
    const gridsterItemComponent = this.gridItems.find((itemComp) => {
      return (itemComp.item === widget);
    });
    //get element height
    const gridsterItemElement = gridsterItemComponent.el as HTMLElement;
    const textDiv = gridsterItemElement.querySelector<HTMLElement>('.element');
    const divHeight = textDiv.offsetHeight;
    //get grid row height
    const rowHeight = gridsterItemComponent.height / gridsterItemComponent.item.rows;
    //component rows count required
    const rowsCount = Math.ceil((divHeight + 4) / rowHeight);
    //update component layout
    gridsterItemComponent.item.rows = rowsCount;
    gridsterItemComponent.$item.rows = rowsCount;
    gridsterItemComponent.gridster.calculateLayout();
  }

  ngOnInit() {
    this.chartService.getChartList(this.appSettings.PROJECT_SLUG).subscribe(response => {
      this.chartsList = response.objects;
    });
  }

  ngOnDestroy() {
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Map':
        this.map = pi as ISimpleMap;
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Map':
        this.map = null;
        break;
    }
  }

  activate() {
    setTimeout(() => {
      this.optionsPanel.reset();
      this.pages = [{widgets: []}];
      this.orientation = 'portrait';
      this.currentPageIndex = 0;
      this.padding = '1cm 1cm 1cm 1cm';
      this.padding = '0';
      this.hasBorders = true;
      this.scaleCommand = 'fit-height';
      this.changeDetector.detectChanges();
      this.refreshScalableLayout();
    }, 0);

    const navBar = document.querySelector('.navigation-tools') as HTMLElement;
    navBar.style.display = 'none';
  }

  deactivate() {
    const navBar = document.querySelector('.navigation-tools') as HTMLElement;
    navBar.style.display = '';
  }
}

const WIDGET_TYPES = {
  map: MapWidget,
  legend: LegendWidget,
  chart: ChartsWidget,
  text: TextWidget
};

const MIN_ITEM_ROWS_BY_TYPE = {
  portrait: {
    legend: 1,
    map: 1,
    charts: 1,
    text: 1
  },
  landscape: {
    legend: 1,
    map: 1,
    chart: 1,
    text: 1
  }
};

const GRID_CELLS_BY_PAGE = {
  portrait: {
    minRows: 16,
    maxRows:16,
    minCols:4,
    maxCols:4
  },
  landscape: {
    minRows: 8,
    maxRows: 8,
    minCols:16,
    maxCols:16
  }
};

const DEFAULT_WIDGET_SIZE = {
  portrait: {
    legend: { cols: 4, rows: 1 },
    map: { cols: 4, rows: 4 },
    chart: { cols: 1, rows: 1 },
    text: { cols: 4, rows: 1 }
  },
  landscape: {
    legend: { cols: 16, rows: 1 },
    map: { cols: 16, rows: 2 },
    chart: { cols: 1, rows: 1 },
    text: { cols: 16, rows: 1 }
  }
};

const PAGE_SIZE = {
  portrait: {
    width: 794,  height: 1123
  },
  landscape: {
    width: 1124,  height: 755
  }
};

