import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Utils } from '../..//classes';
import { IContainer, IContentChild } from '../../interfaces';
import { Creator } from '../../services';

@Component({
  selector: 'getmap-win',
  templateUrl: 'window.component.html',
  styleUrls: ['window.component.less']
})
export class Window implements IContainer, OnInit {
  @Input() caption:string;
  @Input() padding = 0;

  @Output() onHide = new EventEmitter();
  @Output() onShow = new EventEmitter();
  @Output() onShown = new EventEmitter();

  @ViewChild('here', { read: ViewContainerRef }) winContent:ViewContainerRef;

  visible = false;

  private isMoving = false;
  private startX = 0;
  private startY = 0;

  private x = 0;
  private y = 0;

  private domNode:HTMLElement;
  private parentNode:HTMLElement;

  constructor(el:ElementRef, private creator:Creator) {
    this.domNode = el.nativeElement;
  }

  ngOnInit() {
    this.parentNode = this.domNode.parentElement;
    if (this.parentNode) {
      this.startX = this.x = this.domNode.getBoundingClientRect().left - this.parentNode.getBoundingClientRect().left;
      this.startY = this.y = this.domNode.getBoundingClientRect().top - this.parentNode.getBoundingClientRect().top;
    }

    // Событие на полную загрузку окна
    // TODO: где это используется?
    setTimeout(() => {
      this.onShown.emit();
    });
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  open() {
    this.visible = true;
    this.onShow.emit({});
  }

  close() {
    this.visible = false;
    this.onHide.emit({});
  }

  isOpen() {
    return this.visible;
  }

  addChild(child:IContentChild):void {}

  getVisible() {
    return this.visible;
  }

  getDomnode():HTMLElement {
    return this.domNode;
  }

  onMouseDown($event:MouseEvent) {
    this.isMoving = true;
    this.startX = $event.pageX - this.x;
    this.startY = $event.pageY - this.y;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp($event:MouseEvent) {
    this.isMoving = false;
  }

  createComponent(component:any):Promise<any> {
    return this.creator.createComponent(component, this.winContent);
  }

  getContainer() {
    return this.winContent;
  }

  destroy() {
    Utils.removeElement(this.domNode);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove($event:MouseEvent) {
    if (this.isMoving) {
      // предотвращает выделение текста курсором
      $event.preventDefault();
      const newY = $event.pageY - this.startY;
      const newX = $event.pageX - this.startX;

      if (newX > 0 && newX + this.domNode.offsetWidth < this.parentNode.offsetWidth) {
        this.domNode.style.left = newX + 'px';
        this.x = newX;
      } else if (newX < 0) {
        this.domNode.style.left = '0px';
        this.x = 0;
      } else if (newX + this.domNode.offsetWidth > this.parentNode.offsetWidth) {
        this.domNode.style.left = this.parentNode.offsetWidth - this.domNode.offsetWidth + 'px';
        this.x = this.parentNode.offsetWidth - this.domNode.offsetWidth;
      }

      if (newY > 0 && newY + this.domNode.offsetHeight < this.parentNode.offsetHeight) {
        this.domNode.style.top = newY + 'px';
        this.y = newY;
      } else if (newY < 0) {
        this.domNode.style.top = '0px';
        this.y = 0;
      } else if (newY + this.domNode.offsetHeight > this.parentNode.offsetHeight) {
        this.domNode.style.top = this.parentNode.offsetHeight - this.domNode.offsetHeight + 'px';
        this.y = this.parentNode.offsetHeight - this.domNode.offsetHeight;
      }
    }
  }
}
