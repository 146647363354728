import { Component } from '@angular/core';
import { Utils } from 'shared/classes';
import { PluginClass } from '../../classes/Plugin';
import { Point } from '../../classes/Point';
import {
  IClearData,
  IMapResult,
  IPluginInterface,
  IPosition,
  ISearch,
  ISearchHistory,
  ISearchResult,
  ITextSearch,
  ITool
} from '../../interfaces';

@Component({
  selector: 'address-search',
  template: ''
})
export class AddressSearchComponent extends PluginClass implements ITextSearch {
  searchQuery:string;

  private deactTools:ITool[] = [];
  private posPlugins:IPosition[] = [];
  private searchPlugins:ISearch[] = [];
  private resultPlugins:ISearchResult[] = [];
  private mapResultPlugins:IMapResult[] = [];
  private clearResultsPlugins:IClearData[] = [];
  private historyPlugins:ISearchHistory[] = [];

  constructor() {
    super();
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'POS':
        this.posPlugins.push(pi as IPosition);
        break;
      case 'SearchEngine':
        this.searchPlugins.push(pi as ISearch);
        break;
      case 'ShowResult':
        this.resultPlugins.push(pi as ISearchResult);
        break;
      case 'MapResults':
        this.mapResultPlugins.push(pi as IMapResult);
        break;
      case 'DeactivateTool':
        this.deactTools.push(pi as ITool);
        break;
      case 'ClearData':
        this.clearResultsPlugins.push(pi as IClearData);
        break;
      case 'History':
        this.historyPlugins.push(pi as ISearchHistory);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'POS':
        this.posPlugins = [];
        break;
      case 'SearchEngine':
        this.searchPlugins = [];
        break;
      case 'ShowResult':
        this.resultPlugins = [];
        break;
      case 'MapResults':
        this.mapResultPlugins = [];
        break;
      case 'DeactivateTool':
        this.deactTools = [];
        break;
      case 'ClearData':
        this.clearResultsPlugins = [];
        break;
      case 'History':
        this.historyPlugins = [];
        break;
    }
  }

  search() {
    const query = this.searchQuery.trim();

    if (!query) {
      return;
    }

    // дожидаемся подтверждения деактивации от всех связанных плагинов,
    // в случае успеха запускаем поиск
    const promiseArr:Promise<boolean>[] = [];

    this.deactTools.forEach(tool => {
      promiseArr.push(tool.deactivateTool());
    });

    Promise.all(promiseArr)
      .then(() => {
        this.clearResults();

        this.historyPlugins.forEach(plug => {
          plug.saveToHistory(query);
        });

        const normalizedCoords = Utils.normalizeCoords(query);
        const dms = Utils.searchDMSCoords(normalizedCoords);
        const dd = Utils.searchDecimalCoords(normalizedCoords);

        if (dd || dms) {
          const point = dd ? new Point(Number(dd[4]), Number(dd[1])) : Utils.DMSCoordsToPoint(dms);
          this.goToPoint(point);
        } else {
          this.searchPlugins.forEach(plugin => {
            plugin.searchText(query);
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  clearSearch() {
    this.clearResults();
    this.searchQuery = '';
  }

  clearResults() {
    this.clearResultsPlugins.forEach(plug => {
      plug.clearResults();
    });
    this.mapResultPlugins.forEach(plug => {
      plug.clearResult();
    });
  }

  private goToPoint(point:Point) {
    this.posPlugins.forEach(plug => {
      plug.goToPoint(point);
    });
    this.mapResultPlugins.forEach(plug => {
      plug.setMarker(point);
    });
  }
}
