import {Component} from '@angular/core';
import { Feature, Image } from 'shared/classes';
import { SearchResultsComponent } from 'shared/components';
import { MapService } from 'shared/services';

@Component({
  selector: 'search-result',
  templateUrl: 'search_results.component.html',
  styleUrls: ['search_results.component.less']
})
export class DesktopSearchResultsComponent extends SearchResultsComponent {
  constructor(mapService:MapService) {
    super(mapService);
  }

  showLightBox(event:{ items:Image[]; item:Image | Feature }) {
    if (event.item instanceof Feature) {
      this.lightBoxPlugins.forEach(plug => {
        plug.showChart(event.item as Feature);
      });
    } else {
      this.lightBoxPlugins.forEach(plug => {
        plug.showImages(event.items, event.item as Image);
      });
    }
  }

  styleVisible(visible:boolean) {
    return visible ? 'visible' : 'hidden';
  }
}
