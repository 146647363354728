import {AfterContentInit, Component, ContentChild, ContentChildren, ElementRef, QueryList, ViewChild} from '@angular/core';

interface Tab {
  name:string;
  title:string;
  active:boolean;
}

@Component({
  selector: 'tabs-view',
  templateUrl: 'tabs_view.component.html',
  styleUrls: ['tabs_view.component.less']
})
export class TabsViewComponent implements AfterContentInit {
  @ViewChild('content') content:ElementRef;

  tabs:Tab[] = [];

  private contentElement:HTMLElement;

  ngAfterContentInit() {
    this.contentElement = this.content.nativeElement as HTMLElement;
    this.contentElement.childNodes.forEach((child:HTMLElement, idx:number) => {
      const tab:Tab = {
        name: child.getAttribute('name'),
        title: child.getAttribute('title'),
        active: idx === 0
      };
      this.tabs.push(tab);
      child.style.display = tab.active ? '' : 'none';
    });
  }

  onButtonClick(tabClicked:Tab) {
    let idx = 0;
    for (const tab of this.tabs) {
      tab.active = tab === tabClicked;
      (this.contentElement.childNodes.item(idx) as HTMLElement).style.display = tab.active ? '' : 'none';
      idx++;
    }
  }
}
