import { Component, ComponentRef, ElementRef, OnInit } from '@angular/core';
import { Feature, PluginClass, Utils } from 'shared/classes';
import { Button, DropMenuButtonComponent as DropMenuButton } from 'shared/components';
import { IActivator, IDraw, IDrawFinish, IMenu, IPluginInterface, ISearch, ISearchResult, ITool } from 'shared/interfaces';
import { MapService } from '../../services/services';

@Component({
  selector: 'area-search',
  template: ''
})
export class AreaSearchComponent extends PluginClass implements IDrawFinish, ITool, OnInit {
  activateMode:string = null;
  groupName:string;

  private buttons = [
    {
      className: 'rectangleSearch',
      title: 'Прямоугольник',
      action: 'rectangle'
    },
    {
      className: 'polySearch',
      title: 'Полигон',
      action: 'polygon'
    },
    {
      className: 'delSearch',
      title: 'Очистить',
      action: 'clear'
    }
  ];

  private activator:IActivator; // плагин, отвечающий за отключение связанных плагинов
  private drawPlugins = Array<IDraw>();
  private searchPlugins = Array<ISearch>();
  private attrPlugins = Array<ISearchResult>();
  private active = false;
  private menu:IMenu;
  private btns = Array<Button>();
  private menuBtn:Button; // главная кнопка в dropmenu
  private action:string;

  constructor(private el:ElementRef, mapService:MapService) {
    super();
    mapService.onClearMap$.subscribe(() => this.clearDrawFeatures());
  }

  ngOnInit() {
    Utils.removeElement(this.el.nativeElement as HTMLElement);
  }

  activateTool():void {
    this.active = true;
    if (this.action !== 'clear') {
      this.stopDraw();
      this.startDraw(this.action);
    } else {
      this.clearDrawFeatures();
    }
  }

  deactivateTool():Promise<boolean> {
    this.deactivate();
    if (this.menuBtn) {
      this.menuBtn.setActive(false);
    }
    return Promise.resolve(true);
  }

  isActive() {
    return this.active;
  }

  getGroup():string {
    return this.groupName;
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Draw':
        this.drawPlugins.push(pi as IDraw);
        break;
      case 'SearchEngine':
        this.searchPlugins.push(pi as ISearch);
        break;
      case 'ShowResult':
        this.attrPlugins.push(pi as ISearchResult);
        break;
      case 'Menu':
        const menu = pi as IMenu;
        menu.createComponent(DropMenuButton).then((m:ComponentRef<DropMenuButton>) => {
          this.menu = m.instance;

          // клик по одной из статусныx кнопок
          const oldFunc:(btn:Button) => void = this.menu.childClick;
          this.menu.childClick = (btn:Button) => {
            oldFunc.call(this.menu, btn);
            if (!this.active) {
              this.activate();
            }
          };

          m.instance.className = 'areaSearchBtn';
          m.instance.title = 'Пространственная выборка';
          m.instance.hideOnClickOutside = true;
          m.instance.position = 5;

          const oldInit = m.instance.ngAfterContentInit;
          m.instance.ngAfterContentInit = () => {
            oldInit.call(m.instance);
            const btn = (this.menuBtn = (m.instance as DropMenuButton).getMenuBtn());
            btn.onDeactivate = () => {};
            menu.addBtn(btn);
            // override: компонент не должен деактивироваться при скрытии дроп-меню (issue #2110)
            this.createBtns();
          };
        });
        break;
      case 'Activator':
        this.activator = pi as IActivator;
        this.activator.setConnect(this as ITool);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Draw':
        this.drawPlugins = [];
        break;
      case 'Menu':
        this.btns.forEach((btn:Button) => {
          this.menu.removeBtn(btn);
        });
        this.btns = [];
        this.menu = null;
        break;
    }
  }

  stopDraw() {
    if (!this.active) {
      return;
    }
    this.drawPlugins.forEach(plugin => {
      plugin.stopDraw();
    });
  }

  finishDraw(feature:Feature) {
    if (!this.active || this.action === 'clear') {
      return;
    }

    this.searchPlugins.forEach(plugin => {
      plugin.searchArea(feature);
    });

    this.clearDrawFeatures();
    this.addFeature(feature);
  }

  updateDrawingFeature(feature:Feature) {}

  private startDraw(geomType:string) {
    this.drawPlugins.forEach(plugin => {
      plugin.startDraw(geomType, this as IDrawFinish);
    });
  }

  private addFeature(feature:Feature) {
    this.drawPlugins.forEach(plugin => {
      plugin.addFeature(feature);
    });
  }

  private clearDrawFeatures() {
    this.drawPlugins.forEach(plugin => {
      plugin.clearDrawFeatures();
    });
  }

  // создание клавиш в выпадающем меню
  private createBtns() {
    const menu = this.menu;
    this.buttons.forEach(opt => {
      menu.createBtn().then(btn => {
        btn = btn.instance;
        btn.title = opt.title;
        btn.className = opt.className;

        // Клик на кнопку в дроп-меню
        btn.onClickMe = () => {
          // Запоминаем текущее действие, чтобы вызвать его после
          // подтверждения деактивации
          this.action = opt.action;

          if (this.active) {
            this.attrPlugins.forEach(plugin => {
              plugin.clearResults();
            });

            this.clearDrawFeatures();
          }

          this.activate().then(() => {
            this.menuBtn.setActive(false);
          });
        };

        btn.onDeactivate = () => {
          if (this.active) {
            this.clearDrawFeatures();
            this.stopDraw();
          }
        };

        // добавить кнопку в массив меню
        menu.addBtn(btn);

        this.btns.push(btn);
      });
    });
  }

  private activate():Promise<any> {
    if (this.activator) {
      return this.activator.activateComponent(this as ITool);
    }
  }

  private deactivate() {
    if (!this.active) {
      return;
    }

    if (this.active) {
      this.attrPlugins.forEach(plugin => {
        plugin.clearResults();
      });
    }

    this.stopDraw();
    this.clearDrawFeatures();
    this.active = false;
    this.menu.deactivateBtns();
  }
}
