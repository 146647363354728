import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractWidget, MapWidget, TextWidget} from '../widgets';
import {QuillEditorComponent} from 'ngx-quill';
import * as Quill from 'quill';

@Component({
  selector: 'text-widget-form',
  templateUrl: './text-form.component.html',
  styleUrls: ['./text-form.component.less']
})
export class TextFormComponent implements OnInit {
  @Output() accept = new EventEmitter();
  @Output() close = new EventEmitter();

  @ViewChild('quill') quillEditor:QuillEditorComponent;

  private _widget:AbstractWidget;
  text:string;

  editorOptions = {
    placeholder: 'Введите текст...'
  };
  customOptions = [{
    import: 'attributors/style/size',
    whitelist: [false, '14px', '16px', '18px', '20px']
  }, {
    import: 'formats/font',
    whitelist: ['sans-serif', 'times-new-roman', 'roboto', 'lato', 'oswald', 'montserrat', 'raleway', 'lora', 'nunito', 'prompt']
  }];

  @Input()
  set widget(value:AbstractWidget) {
    this._widget = value;
    if (value.type === 'text') {
      this.text = (value as TextWidget).text;
    } else if (value.type === 'map') {
      this.text = (value as MapWidget).title;
    }
  }
  get widget():AbstractWidget {
    return this._widget;
  }

  constructor() {
  }

  ngOnInit() {
    const icons = Quill.import('ui/icons');
    icons['bold'] = '<div class="mask-icon bold"></div>';
    icons['italic'] = '<div class="mask-icon italic"></div>';
    icons['underline'] = '<div class="mask-icon underline">';
    icons['color'] = '<div class="mask-icon color"></div>';
    icons['background'] = '<div class="svg-icon background"></div>';
    icons['align'][''] = '<div class="mask-icon left"></div>';
    icons['align']['center'] = '<div class="mask-icon center"></div>';
    icons['align']['right'] = '<div class="mask-icon right"></div>';
    icons['align']['justify'] = '<div class="mask-icon justify"></div>';
    icons['list']['ordered'] = '<div class="mask-icon list-ordered"></div>';
    icons['list']['bullet'] = '<div class="mask-icon list-bullet"></div>';
    icons['indent']['+1'] = '<div class="mask-icon indent-in"></div>';
    icons['indent']['-1'] = '<div class="mask-icon indent-out"></div>';
    icons['script']['super'] = '<div class="mask-icon superscript"></div>';
    icons['script']['sub'] = '<div class="mask-icon subscript"></div>';
  }

  onSubmit() {
    //prevent overriding color and backcolor
    let text = this.text;
    if (text) {
      text = text.replace('<p>', '').replace('</p>', '');
      const rgx = new RegExp('color:\W*([a-z0-9, ()#]+);');
      for (let matches = rgx.exec(text); matches; matches = rgx.exec(text)) {
        text = text.replace(matches[0], 'color:' + matches[1] + ' !important;');
      }
    }
    if (this._widget.type === 'text') {
      (this._widget as TextWidget).text = text;
    } else if (this._widget.type === 'map') {
      (this._widget as MapWidget).title = text;
    }
    this.accept.next();
  }

  onCancel() {
    this.close.next();
  }
}
