import * as L from 'leaflet';

import {DivIconOptions, Point} from 'leaflet';
import {Utils} from '../Utils';


export class CircleCanvasIcon extends L.DivIcon {

  constructor(options:MarkerOptions) {
    super(options);
  }

  createIcon() {
    const markerOptions = this.options as MarkerOptions;
    const canvas = document.createElement('canvas');
    const canvasWidth = (markerOptions.iconSize as Point).x;
    const canvasHeight = (markerOptions.iconSize as Point).y;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');

    ctx.strokeStyle = Utils.hexColorToRGBA(markerOptions.strokeColor, markerOptions.opacity);
    ctx.fillStyle = Utils.hexColorToRGBA(markerOptions.fillColor, markerOptions.opacity);
    ctx.lineWidth = markerOptions.strokeWidth;
    ctx.ellipse(canvasWidth / 2, canvasHeight / 2, canvasWidth / 2 - 2, canvasWidth / 2 - 2, 0, 0, 2 * Math.PI);

    ctx.fill();
    ctx.stroke();
    return canvas;
  }
}

export class SquareCanvasIcon extends L.DivIcon {

  constructor(options:MarkerOptions) {
    super(options);
  }

  createIcon() {
    const markerOptions = this.options as MarkerOptions;
    const canvas = document.createElement('canvas');
    const canvasWidth = (markerOptions.iconSize as Point).x;
    const canvasHeight = (markerOptions.iconSize as Point).y;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');

    const w = markerOptions.strokeWidth;
    ctx.strokeStyle = Utils.hexColorToRGBA(markerOptions.strokeColor, markerOptions.opacity);
    ctx.fillStyle = Utils.hexColorToRGBA(markerOptions.fillColor, markerOptions.opacity);
    ctx.lineWidth = w;
    ctx.rect(w / 2, w / 2, canvasWidth - w, canvasHeight - w);

    ctx.fill();
    ctx.stroke();
    return canvas;
  }
}

export class TriangleCanvasIcon extends L.DivIcon {

  constructor(options:MarkerOptions) {
    super(options);
  }

  createIcon() {
    const markerOptions = this.options as MarkerOptions;
    const canvas = document.createElement('canvas');
    const canvasWidth = (markerOptions.iconSize as Point).x;
    const canvasHeight = (markerOptions.iconSize as Point).y;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');

    const w = markerOptions.strokeWidth;
    ctx.strokeStyle = Utils.hexColorToRGBA(markerOptions.strokeColor, markerOptions.opacity);
    ctx.fillStyle = Utils.hexColorToRGBA(markerOptions.fillColor, markerOptions.opacity);
    ctx.lineWidth = w;

    ctx.beginPath();
    ctx.moveTo(0 + w / 2, canvasHeight - w);
    ctx.lineTo((canvasWidth - w) / 2, 0 + w / 2);
    ctx.lineTo(canvasWidth - w, canvasHeight - w);
    ctx.lineTo(0 + w / 2, canvasHeight - w);

    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    return canvas;
  }
}

export class StarCanvasIcon extends L.DivIcon {

  constructor(options:MarkerOptions) {
    super(options);
  }

  createIcon() {
    const markerOptions = this.options as MarkerOptions;
    const canvas = document.createElement('canvas');
    const canvasWidth = (markerOptions.iconSize as Point).x;
    const canvasHeight = (markerOptions.iconSize as Point).y;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');

    const w = markerOptions.strokeWidth;
    ctx.strokeStyle = Utils.hexColorToRGBA(markerOptions.strokeColor, markerOptions.opacity);
    ctx.fillStyle = Utils.hexColorToRGBA(markerOptions.fillColor, markerOptions.opacity);
    ctx.lineWidth = w;
    const n = 6;
    const inset = 0.5;
    const r = canvasWidth / 2;

    ctx.beginPath();
    ctx.translate(canvasWidth / 2, canvasHeight / 2);
    ctx.moveTo(0, 0 - r);
    for (let i = 0; i < n; i++) {
      ctx.rotate(Math.PI / n);
      ctx.lineTo(0, 0 - (r * inset));
      ctx.rotate(Math.PI / n);
      ctx.lineTo(0, 0 - r);
    }
    ctx.closePath();

    ctx.fill();
    ctx.stroke();
    return canvas;
  }
}

export interface MarkerOptions extends DivIconOptions {
  fillColor:string;
  strokeColor:string;
  strokeWidth:number;
  opacity:number;
}
