import { LayerType } from 'shared/interfaces';
import { TMSLayer } from './TMSLayer.class';

export class GroupLayer extends TMSLayer {
  type:LayerType = 'group';

  protected createLayerInstance():Promise<any> {
    return new Promise((resolve, reject) => {
      this._layerInstance = null;
      resolve();
    });
  }

  public buildLayerInstance():any {
    return null;
  }

  get opacity() {
    return this._opacity;
  }

  set opacity(value:number) {
    this._opacity = value;
  }

  set checked(value) {
    this._checked = value;
  }

  get visible() {
    return this._visible;
  }

  set visible(value) {
    // если хоть 1 из предков выключен, выключаем слой
    if (value) {
      value = this.parentsVisible;
    }

    this._visible = value;
    this.toggleChildVisible(this, value);
  }

  get order() {
    return this._order;
  }

  set order(value:number) {
    this._order = value;
  }
}
