import {LayerType} from 'shared/interfaces';
import {MarkerClusterLayer} from './MarkerClusterLayer.class';
import {Observable, timer} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';
import {Attribute} from 'shared/classes';


export class MarkerDynamicLayer extends MarkerClusterLayer {
  type:LayerType = 'dynamic';

  interval:number = 5000;
  loading = false;
  dataFunction:(layer:MarkerClusterLayer) => Observable<void>;
  dataFunctionContext:any;

  constructor(options?:any) {
    super(options);
    this.checked = true;
    this.defaultSymbolizer.file = {
      id: 0,
      image: '/media/cartoservice/patterns/M/car.svg',
      url: '',
      resource_uri: '',
      type: 'M'
    };
    this.defaultSymbolizer.size = 40;
    this.defaultSymbolizer.offset_x = 20;
    this.defaultSymbolizer.offset_y = 20;

    this.columns = [];

    let attr = new Attribute(); attr.name = 'name'; attr.alias = 'Название ТС'; attr.inPopup = true; attr.aliasInPopup = true; this.columns.push(attr);
    attr = new Attribute(); attr.name = 'terminal_type'; attr.alias = 'Тип терминала'; this.columns.push(attr);
    attr = new Attribute(); attr.name = 'address'; attr.alias = 'Адрес'; this.columns.push(attr);
    attr = new Attribute(); attr.name = 'currentFuel'; attr.alias = 'Уровень топлива (л)'; attr.inPopup = true; attr.aliasInPopup = true; this.columns.push(attr);
    attr = new Attribute(); attr.name = 'currentIgn'; attr.alias = 'Зажигание'; this.columns.push(attr);
    attr = new Attribute(); attr.name = 'currentSpeed'; attr.alias = 'Скорость (км/ч)'; attr.inPopup = true; attr.aliasInPopup = true; this.columns.push(attr);
    //attr = new Attribute(); attr.name = 'lastDataDate'; attr.alias = 'Время обновления'; this.columns.push(attr);
    attr = new Attribute(); attr.name = 'lastGPSDir'; attr.alias = 'Направление'; this.columns.push(attr);
    attr = new Attribute(); attr.name = 'speedExceed'; attr.alias = 'Превышение скорости'; this.columns.push(attr);

    timer(this.interval, this.interval)
      .pipe(
        filter(() => {
          return !!this.dataFunction && !this.loading;
        }),
        tap(() => this.loading = true),
        switchMap(() => this.dataFunction.call(this.dataFunctionContext, this))
      )
      .subscribe(() => this.loading = false);
  }
}
