export class PluginConnect {
  connectName:string;
  component:string;
  interface:string;

  constructor(component:string, name:string, inter:string) {
    this.connectName = name;
    this.component = component;
    this.interface = inter;
  }
}
