import { Component, ElementRef, OnInit } from '@angular/core';
import { Button } from '../';
import { PluginClass, Utils } from '../../classes';
import { IMenu, IPluginInterface } from '../../interfaces';
import { MapService } from '../../services';

@Component({
  selector: 'fullextent',
  template: ''
})
export class FullExtentComponent extends PluginClass implements OnInit {
  private btn:Button = null;
  private menu:IMenu;

  constructor(private el:ElementRef, private mapService:MapService) {
    super();
  }

  ngOnInit() {
    Utils.removeElement(this.el.nativeElement as HTMLElement);
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;
        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.btn.className = 'fullExtentBtn';
          this.btn.title = 'Первоначальный вид';
          this.btn.setActive(false);
          this.btn.onClickMe = () => {
            this.mapService.changeMapBound$.next(null);
          };
          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }
}
