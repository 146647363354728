import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { AbstractChartFormClass } from '../../classes';
import { ChartService } from '../../services';

@Component({
  selector: 'geo-column-form',
  templateUrl: 'column-form.component.html',
  styleUrls: ['column-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnFormComponent extends AbstractChartFormClass {
  constructor(protected chartService:ChartService, protected builder:FormBuilder) {
    super(chartService, builder);
  }

  excludeCount(selectedMethod:any) {
    if (!this.form || !this.form.value.colValues || (selectedMethod && selectedMethod.value === 'count')) {
      return [];
    }
    const countSelected = this.form.value.colValues.find(
      colValue => colValue.method && colValue.method.value === 'count'
    );
    if (countSelected) {
      return [this.methods.find(method => method.value === 'count')];
    }
    return [];
  }

  excludeAttrs(selectedAttr:any) {
    if (!this.form || !this.form.value.colValues) {
      return [];
    }
    return this.form.value.colValues
      .filter(colValue => colValue.colValue !== selectedAttr)
      .map(colValue => colValue.colValue);
  }

  get colValues():FormArray {
    return this.form.get('colValues') as FormArray;
  }

  addColValue() {
    this.colValues.push(this.getColValueGroup());
  }

  removeColValue(idx:number) {
    this.colValues.removeAt(idx);
  }

  protected setForm() {
    this.form = this.builder.group({
      name: [null, [Validators.required, Validators.maxLength(255)]],
      resource: [null, [Validators.required]],
      colValues: this.builder.array([this.getColValueGroup()]),
      groupBy: [{ value: this.groupByChoices[0], disabled: true }],
      colName: [{ value: null, disabled: true }],
      joined: this.builder.group({
        joinedResource: [{ value: null, disabled: true }, [Validators.required]],
        joinedMethod: [{ value: null, disabled: true }, [Validators.required]],
        joinedAttribute: [{ value: null, disabled: true }, [Validators.required]]
      })
    });

    this.form.get('resource').valueChanges.subscribe(value => {
      this.attributes = value.columns;
      this.form.setControl('colValues', this.builder.array([this.getColValueGroup()]));
      this.form.get('colName').enable();
      this.form.get('groupBy').enable();
    });

    this.form
      .get('joined.joinedResource')
      .valueChanges.pipe(filter(value => !!value))
      .subscribe(value => {
        this.joinedAttributes = value.columns;
      });

    this.form.get('groupBy').valueChanges.subscribe(value => {
      const joinedFormGroup = this.form.get('joined');
      const colNameFormGroup = this.form.get('colName');
      if (value === 'Атрибут') {
        joinedFormGroup.disable();
        colNameFormGroup.enable();
      } else {
        colNameFormGroup.disable();
        joinedFormGroup.enable();
      }
    });
  }

  protected getChartSettings(formModel:any) {
    const data:any = {
      attribute_field_name_joined: formModel.colValues.map(colValue =>
        colValue.colValue ? colValue.colValue.name : null
      ),
      function: formModel.colValues.map(colValue => colValue.method.value),
      type: 'column'
    };

    if (formModel.colName) {
      data.attribute_field_name = formModel.colName.name;
    }

    if (formModel.joined) {
      Object.assign(data, {
        resource_joined: formModel.joined.joinedResource.mapnikDatasourceId,
        joined_attribute: formModel.joined.joinedAttribute.name,
        filter_function: formModel.joined.joinedMethod
      });
    }

    return data;
  }

  private getColValueGroup():FormGroup {
    const colValueGroup = this.builder.group({
      method: [{ value: null, disabled: this.form ? !this.form.get('resource').value : true }, [Validators.required]],
      colValue: [{ value: null, disabled: true }, [Validators.required]]
    });
    colValueGroup.get('method').valueChanges.subscribe(value => {
      const colValueSelect = colValueGroup.get('colValue');
      value.value === 'count' ? colValueSelect.disable() : colValueSelect.enable();
    });
    return colValueGroup;
  }
}
