import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Feature, Image } from 'shared/classes';
import { SingleResultComponent } from 'shared/components';
import { DocumentService } from 'shared/components/search_results/single_result/document.service';
import { IAppSettings } from 'shared/environment';

@Component({
  selector: 'geo-desktop-single-result',
  templateUrl: 'single_result.component.html',
  styleUrls: ['single_result.component.less'],
  providers: [DocumentService]
})
export class DesktopSingleResultComponent extends SingleResultComponent {
  @Output() lightBox = new EventEmitter();

  constructor(documentService:DocumentService, @Inject('environment') settings:IAppSettings) {
    super(documentService, settings);
  }

  showLightBox(item:Image | Feature) {
    this.lightBox.emit({ items: this.feature.images, item });
  }

  get visibleImages():Image[] {
    return this.showAllImages ? this.feature.images : this.feature.images.slice(0, 2);
  }
}
