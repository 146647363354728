import { Component } from '@angular/core';
import { BaseMapsComponent as ParentComponent } from 'shared/components';
import { LayersStore } from 'shared/stores/LayersStore';
import { MapService } from '../../services/services';

@Component({
  selector: 'base-maps-desktop',
  templateUrl: 'base_maps.component.html',
  styleUrls: ['base_maps.component.less']
})
export class BaseMapsDesktopComponent extends ParentComponent {
  constructor(mapService:MapService, layersStore:LayersStore) {
    super(mapService, layersStore);
  }
}
