import { Component } from '@angular/core';
import { PluginClass, Point } from '../../classes';
import { IPluginInterface } from '../../interfaces';
import { MapService } from '../../services/map.service';

@Component({
  selector: 'mouse-coords',
  templateUrl: 'mouse_coords.component.html',
  styleUrls: ['mouse_coords.component.less']
})
export class MouseCoordsComponent extends PluginClass {
  active = false;
  system = 'decimaldeg';
  point:Point = { x: 0, y: 0 };

  constructor(mapService:MapService) {
    super();

    mapService.currentCursorPosition$.subscribe(point => {
      this.point = point;
    });
  }

  addInterface(name:string, pi:IPluginInterface):void {
    console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
  }

  removeInterface(name:string):void {}

  changeCoordSystem(value:string) {
    this.system = value;
    this.active = false;
  }

  toggleCoords() {
    this.active = !this.active;
  }
}
