import { HttpClient, HttpParams } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, share } from 'rxjs/operators';
import { Attribute } from '../classes/Attribute';
import { ArcGISLayer } from '../classes/LeafletLayer/ArcGISLayer.class';
import {ILayer, ILayerLegend, ILayerOptions, ILegend, ILegendStyle} from '../interfaces';
import { DictionaryService } from '../services/dictionary.service';
import {JoinedTable} from '../classes/JoinedTable';
import {IAppSettings} from '../environment';
import {GeoJsonLayer} from 'shared';
import {MarkerClusterLayer} from '../classes/LeafletLayer/MarkerClusterLayer.class';
import {DataLayer} from '../classes/LeafletLayer/symbolizers/IDataLayer';
import {
  ClusterSymbolizer,
  LineSymbolizer,
  MarkerSymbolizer, PolygonSymbolizer,
  RasterSymbolizer,
  Rule,
  TextSymbolizer
} from '../classes/LeafletLayer/symbolizers/SymbolizerImpl.class';
import {ISymbolizer} from '../classes/LeafletLayer/symbolizers/ISymbolizer';

interface ILayerLegendComp extends ILayerLegend {
  legendDesc:boolean;
}

@Injectable()
export class LayersService {
  private _appSettings:IAppSettings;
  private _layersUrl = 'layers.json';
  private _baseMapsUrl = 'basemaps.json';
  private _legendUrl = 'legend/';

  constructor(private httpClient:HttpClient, private dictService:DictionaryService,
              @Inject('environment') settings:IAppSettings
  ) {
    this._appSettings = settings;
  }

  getLayers():Observable<any> {
    const obsLayers = this.httpClient.get(this._layersUrl + location.search);
    let urlDynamic = `/projects/${this._appSettings.PROJECT_SLUG}/dynamic/`;
    if (this._appSettings.HOST) {
      urlDynamic = this._appSettings.HOST + urlDynamic;
    }
    const obsDynamic = this.httpClient.get(urlDynamic);
    return forkJoin(obsLayers, obsDynamic).pipe(
      map((results:any[]) => {
        let groups = results[0].groups as any[];
        const dynamics = results[1] as any[];
        dynamics.forEach(layer => {
          layer.real_url = `/projects/${this._appSettings.PROJECT_SLUG}/dynamic/${layer.id}/omnicomm/get_layers/`;
          layer.type = 'dynamic';
        });

        this.patchLayers(groups);
        groups = groups.concat(results[1]);
        return {groups: groups};
      })
    );
  }

  getBaseMaps():Observable<any> {
    return this.httpClient.get(this._baseMapsUrl);
  }

  getTables():Observable<ILayer[]> {
    let url = '/tab-viewer/api/v1/table/?project__slug=' + this._appSettings.PROJECT_SLUG;
    if (this._appSettings.HOST) {
      url = this._appSettings.HOST + url;
    }
    return this.httpClient.get(url).pipe(
      map((response:any) => {
        return response.objects.map(item => {
          return this.tableToLayer(item);
        });
      })
    );
  }

  private tableToLayer(table:any):ILayer {
    table.layerName = table.name;
    table.name = table.title;
    table.columns = table.attributes;
    table.columns.forEach(column => {
      column.inAttr = true;
      column.editable = !column.is_pk;
    });
    delete table.attributes;
    table.joinedTables = table.joined;
    return table;
  }

  getExtent(layers:ILayer[]):Observable<ILayer[]> {
    const noExtent:number[] = layers
      .filter((layer:ILayer) => !layer.extent && layer.id)
      .map((layer:ILayer) => layer.id);

    if (!noExtent.length) { return of(layers); }
    const params:HttpParams = new HttpParams().set('layers', noExtent.toString());

    return this.httpClient.get('get_extent/', { params }).pipe(
      debounceTime(500),
      map((data:{ id:number; extent:string }[]) => {
        data.forEach(item => {
          layers.find((l:ILayer) => l.id === item.id).createExtent(item.extent);
        });
        return layers;
      }),
      share()
    );
  }

  getExtentWithFilter(layer:ILayer):Observable<ILayer> {
    return this.httpClient.get(`get_extent/?layers=${layer.id}&sql_filter=${layer.filter}`).pipe(
        debounceTime(500),
        map((data:{id:number; extent:string}[]) => {
          layer.createExtent(data[0].extent);
          return layer;
        }),
        share()
    );
  }

  getAttributes(layers:ILayer[]):Observable<ILayer[]> {
    const noAttr:number[] = layers
      .filter((layer:ILayer) => !layer.columns && layer.id)
      .map((layer:ILayer) => layer.id);

    if (!noAttr.length) { return of(layers); }
    const params:HttpParams = new HttpParams().set('layers', noAttr.toString());

    return this.httpClient.get('get_attributes/', { params }).pipe(
      debounceTime(500),
      map((data:{ status:string; layers:{ id:number; attributes:any[]; joined?:any }[] }) => {
        data.layers.forEach(item => {
          const layer = layers.find((l:ILayer) => l.id === item.id);
          layer.columns = item.attributes.map((attr:any) => Object.assign(new Attribute(), attr));
          if (item.joined) {
            layer.joinedTables = item.joined.map((join:any) => new JoinedTable(join));
            layer.joinedTables.forEach(tbl => {
              tbl.attributes.forEach(attr => {
                attr.editable = !attr.is_pk;
              });
            });
          }
          const pk = layer.columns.find(column => column.is_pk);
          layer.pk = pk ? pk.name : null;
        });
        return layers;
      }),
      share()
    );
  }

  getLegend(allLayers:ILayer[], includeId:number[] = []):Observable<ILayer[]> {
    const layers:ILayer[] = allLayers.filter(
      layer =>
        !layer.legend &&
        layer.id &&
        includeId.indexOf(layer.id) !== -1 &&
        layer.type !== 'geoserver' &&
        layer.type !== 'arcgis'
    );
    const arcgisLayers = allLayers.filter(
      layer => layer.type === 'arcgis' && includeId.indexOf(layer.id) !== -1
    ) as ArcGISLayer[];
    return forkJoin([this._getGeoanalitikaLegend(layers), this._getArcgisLegend(arcgisLayers)]).pipe(
      map(data => allLayers),
      share()
    );
  }

  getDictionaries(allLayers:ILayer[]) {
    let ids:number[] = [];
    allLayers.forEach(layer => {
      if (!layer.columns) { return; }
      ids = [
        ...ids,
        ...layer.columns
          .filter(column => column.dictionary_id && ids.indexOf(column.dictionary_id) === -1)
          .map(col => col.dictionary_id)
      ];
    });
    this.dictService.getDictionaries(ids);
  }

  loadLayerData(layer:MarkerClusterLayer):Observable<void> {
    return new Observable<void>(obs => {
      this.httpClient.get(layer.url).subscribe((data:any) => {
        for (const layerOpt of data.map.layers) {
          if (layerOpt.type) {
            this.prepareLayerStyle(layerOpt.type, layerOpt);
          }
        }
        layer.setData(data.map.layers);
        obs.next();
      }, err => {
        obs.next();
      });
    });
  }

  private prepareLayerStyle(serviceType:string, layer:DataLayer) {
    layer.symbolizers = [];
    layer.style.rules.forEach((rule:Rule) => {
      rule.rule_items.forEach((symbol:ISymbolizer) => {
        const symbolizer:ISymbolizer = this.getSymbolizerInstance(symbol.type);
        if (!symbolizer) {
          return;
        }
        if (symbolizer instanceof MarkerSymbolizer) {
          symbolizer.heatmap_colors = layer.style.heatmap_colors;
        }
        symbolizer.setData((symbol as any).content_object);
        symbolizer.rule_id = rule.id;
        layer.symbolizers.push(symbolizer);
      });
    });
  }

  private getSymbolizerInstance(layerType:string):ISymbolizer {
    let ret:ISymbolizer = null;
    switch (layerType) {
      case 'point':
      case 'multipoint':
      case 'marker':
      case 'marker symbolizer':
        ret = new MarkerSymbolizer();
        break;
      case 'polygon':
      case 'multipolygon':
      case 'polygon symbolizer':
      case 'polygon symbolyzer':
        ret = new PolygonSymbolizer();
        break;
      case 'linestring':
      case 'multilinestring':
      case 'line symbolyzer':
      case 'line symbolizer':
        ret = new LineSymbolizer();
        break;
      case 'text':
      case 'text symbolizer':
        ret = new TextSymbolizer();
        break;
      case 'raster':
      case 'raster symbolizer':
        return new RasterSymbolizer();
        break;
      case 'cluster':
        ret = new ClusterSymbolizer();
        break;
    }
    return ret;
  }

  private _getArcgisLegend(layers:ArcGISLayer[]):Observable<ILayer[]> {
    const allObservables$:Observable<any>[] = [];
    layers.forEach(layer => {
      if (layer.legend) { return; }
      const url = layer.getLegendUrl();
      // add noAuth params
      const params:HttpParams = new HttpParams().set('noAuth', '');
      const request$:Observable<any> = this.httpClient.get(url, { params }).pipe(
        map((data:any) => {
          layer.legend = data.layers.map(item => {
            const subLegend:ILayerLegendComp = {} as ILayerLegendComp;
            subLegend.name = item.layerName;
            subLegend.legendDesc = true;
            subLegend.legend = [];

            const legend:ILegend = {} as ILegend;
            legend.items = item.legend.map(legendItem => {
              const styleCss:CSSStyleDeclaration = {} as CSSStyleDeclaration;
              styleCss.backgroundImage = `url(data:image/png;base64,${legendItem.imageData})`;
              styleCss.backgroundPosition = 'center center';
              styleCss.backgroundRepeat = 'no-repeat';
              styleCss.backgroundColor = 'transparent';
              styleCss.backgroundSize = 'contain';
              styleCss.border = 'none';
              return { value: legendItem.label, style: styleCss };
            });
            legend.default_mode = 'D';

            if (!subLegend.legendDesc && legend.items.length > 1) {
              subLegend.legendDesc = true;
            }

            subLegend.legend.push(legend);
            return subLegend;
          });
        }),
        catchError(e => of(layer))
      );

      allObservables$.push(request$);
    });

    if (!allObservables$.length) {
      return of([]);
    }
    return forkJoin(allObservables$);
  }

  private _getGeoanalitikaLegend(layers:ILayer[]):Observable<ILayer[]> {
    if (!layers.length) {
      return of([]);
    }
    const url = `${this._legendUrl}?layers=${layers.map(layer => layer.id).join(',')}`;
    return this.httpClient.get(url).pipe(
      map(data => {
        layers.forEach(layer => {
          if (layer.legend) {
            return;
          }
          const legendJson:any[] = data[layer.id];
          if (legendJson) {
            layer.legend = [];
            legendJson.forEach(item => {
              const subLegend:ILayerLegendComp = {} as ILayerLegendComp;
              subLegend.name = item.name;
              subLegend.legend = [];
              subLegend.legendDesc = false; // будет ли описание легенды, выводится только в случае раскраски по атрибуту
              item.legend.forEach((legend:any) => {
                const l:ILegend = {} as ILegend;
                l.attr = legend.attr;
                l.attr_type = legend.attr_type;
                l.default_mode = legend.default_mode || 'D';
                l.type = legend.type;
                l.items = this._getLegendStyles(legend);
                l.scale_min = legend.scale_min;
                l.scale_max = legend.scale_max;
                // проверяем будет ли описание для легенды
                if (!subLegend.legendDesc && legend.items.length > 1 && !legend.heatmap) {
                  subLegend.legendDesc = true;
                }
                subLegend.legend.push(l);

                // устанавливаем для слоя видимые уровни
                let i:number = legend.scale_min;
                while (i <= legend.scale_max) {
                  if (layer.visible_scales.indexOf(i) === -1) {
                    layer.visible_scales.push(i);
                  }
                  i++;
                }
              });

              layer.legend.push(subLegend);
            });
          }
        });
        return layers;
      }),
      catchError(e => of(layers))
    );
  }

  private _getLegendStyles(legendOpt:any):ILegendStyle[] {
    const legend:ILegendStyle[] = [];

    legendOpt.items.forEach((style:any) => {
      const styleCss:CSSStyleDeclaration = {} as CSSStyleDeclaration;

      if (style.fill) {
        styleCss.background = `${this._getColor(style.fill)}`;
      }

      if (style.stroke) {
        styleCss.border = `2px solid ${this._getColor(style.stroke)}`;
      }

      if ((legendOpt.type === 'marker' || legendOpt.type === 'cluster') && !style.iconUrl) {
        styleCss.borderRadius = '20px';
      }

      if (style.iconUrl) {
        styleCss['background-image'] = `url(${style.iconUrl})`;
        styleCss['background-position'] = 'center center';
        styleCss['background-repeat'] = 'no-repeat';
        styleCss['background-color'] = 'transparent';
        styleCss['background-size'] = 'contain';
        styleCss.border = 'none';
        styleCss.opacity = style.fillOpacity;
      }

      legend.push({
        style: styleCss,
        value: style.value,
        description: style.description
      });
    });

    return legend;
  }

  private _getColor(color:string):string {
    const isHex8:RegExpMatchArray = color.match(/^#(.*){6,8}/i);
    // перевод hex8 в rgba
    if (isHex8) {
      const hex:string = color.replace('#', '');
      const r:number = parseInt(hex.substring(0, 2), 16);
      const g:number = parseInt(hex.substring(2, 4), 16);
      const b:number = parseInt(hex.substring(4, 6), 16);
      let opacity:number = parseInt(hex.substring(6, 8), 16);
      if (!opacity && opacity !== 0) { opacity = 255; }
      color = 'rgba(' + r + ',' + g + ',' + b + ',' + (opacity / 255).toFixed(2) + ')';
    }

    return color;
  }

  private patchLayers(groups:any[]) {
    for (const group of groups) {
      if (group.type === 'geojson') {
        const arr = group.real_url.split('/');
        const host = arr[0] + '//' + arr[2];
        group.real_url = `${host}/cartoservice/api/v1/geojson/${group.service_id}/`;
      } else if (group.sublayers) {
        this.patchLayers(group.sublayers as any[]);
      }
    }
  }
}
