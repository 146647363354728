import { Component } from '@angular/core';
import { PluginClass } from '../../classes/Plugin';
import { IContainer, ILayer, IMenu, IPluginInterface } from '../../interfaces';
import { LayersStore } from '../../stores/LayersStore';
import { Button } from '../button/button.component';

@Component({
  selector: 'my-layers',
  templateUrl: 'layer_catalog.component.html',
  styleUrls: ['layer_catalog.component.less']
})
export class LayerCatalogComponent extends PluginClass {
  parentComponent:IContainer;
  layerList = Array<ILayer>();
  active = false;

  protected btn:Button;
  protected menu:IMenu;

  constructor(protected layersStore:LayersStore) {
    super();

    layersStore.layersChanged.subscribe(data => {
      this.layerList = data;
      this.onLayerstSet(data);
    });
  }

  protected onLayerstSet(layers:ILayer[]) {
  }

  addInterface(name:string, pi:IPluginInterface) {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;
        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;
          this.btn.setOptions(this.buttonConfig);
          if (this.active) {
            this.btn.setActive(true);
          }
          this.btn.onClickMe = () => {
            if (this.parentComponent) {
              this.parentComponent.open();
            }
            this.active = true;
          };
          this.btn.onDeactivate = () => {
            this.active = false;
          };
          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string) {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  onLayerChecked(layer:ILayer) {
    this.layersStore.updateLayers();
  }

  onGroupChecked(layer:ILayer) {
    this.onGroupCheckedRecursive(layer);
    this.layersStore.updateLayers();
  }

  private onGroupCheckedRecursive(layer:ILayer) {
    for (const subLayer of layer.subLayers) {
      subLayer.checked = layer.checked;
      if (subLayer.isGroup) {
        this.onGroupChecked(subLayer);
      }
    }
  }
}
