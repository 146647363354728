import { StyleClass } from './StyleClass';

export class PolygonStyle extends StyleClass {
  private _color:string = null;

  constructor(options:any) {
    super();
    for (const option in options) {
      if (typeof this[option] !== 'undefined') {
        this[option] = options[option];
      }
    }
  }

  get color() {
    return this._color;
  }

  set color(value:any) {
    if (Array.isArray(value)) {
      const r = parseInt((value[0] * 255).toString(), 10);
      const g = parseInt((value[1] * 255).toString(), 10);
      const b = parseInt((value[2] * 255).toString(), 10);
      this._color = `rgb(${r},${g},${b})`;
    } else {
      this._color = value;
    }
  }

  draw() {
    if (!this.canvas || !this.color) { return; }
    const ctx = this.ctx;
    ctx.fillStyle = this.color;
    ctx.beginPath();
    ctx.moveTo(this.startX, this.startY);
    ctx.lineTo(this.startX, this.startY + this.iconSize);
    ctx.lineTo(this.startX + this.iconSize, this.startX + this.iconSize);
    ctx.lineTo(this.startX + this.iconSize, this.startY);
    ctx.closePath();
    ctx.fill();
  }
}
