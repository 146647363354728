import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'changeempty' })
export class ChangeEmptyPipe implements PipeTransform {
  transform(value:string | number, changeTo:string = '—'):string | number {
    if (!value && value !== 0) {
      return changeTo;
    }
    return value;
  }
}
