import { LayerGroup, Map } from 'leaflet';
/*--- классы и интерфейсы для рисования на Leaflet---*/
import { ILeafletDraw, LeafletLine, LeafletPolygon } from '../../../classes/leafletDraw/leafletDraw';
import { IMeasuring } from '../../../interfaces';

export class MeasureTool implements IMeasuring {
  private _map:Map;
  private measuring:boolean;
  private measureTools:any = {};

  set map(map:Map) {
    this._map = map;
    this.addMeasureControl();

    for (const shape in this.measureTools) {
      this.measureTools[shape].onDrawFinish = () => (this.measuring = false);
    }
  }

  get map():Map {
    return this._map;
  }

  get active() {
    return this.measuring;
  }

  // начать измеренеие
  startMeasure(shape:string) {
    this.measuring = true;
    if (this.measureTools.hasOwnProperty(shape)) {
      (this.measureTools[shape] as ILeafletDraw).start();
    }
  }

  // закончить измерение
  stopMeasure() {
    this.measuring = false;
    for (const geom in this.measureTools) {
      this.measureTools[geom].stop();
    }
  }

  // инструменты лля измерения
  addMeasureControl() {
    const measureLayer = new LayerGroup([]);
    this.map.addLayer(measureLayer);
    this.measureTools = {
      line: new LeafletLine({ layer: measureLayer, map: this.map }),
      area: new LeafletPolygon({ layer: measureLayer, map: this.map })
    };
  }
}
