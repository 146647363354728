import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const noop = () => {
};

const defaultDisplayFunction = (item:any) => item ? item.toString() : '';

export const VERTICAL_SPINNER_CONTROL_VALUE_ACCESSOR:any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VerticalSpinnerComponent),
  multi: true
};

@Component({
  selector: 'vertical-spinner',
  templateUrl: 'vertical-spinner.component.html',
  styleUrls: ['vertical-spinner.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [VERTICAL_SPINNER_CONTROL_VALUE_ACCESSOR]
})
export class VerticalSpinnerComponent implements ControlValueAccessor {
  @Input() items:any[] = [];
  @Input() displayFunction:(item:any) => string = defaultDisplayFunction;

  currentIndex = 0;

  private onTouchedCallback:() => void = noop;
  private onChangeCallback:(_:any) => void = noop;

  constructor(private changeDetector:ChangeDetectorRef) {
  }

  get displayValue():string {
    return this.displayFunction(this.value);
  }

  //get accessor
  get value():any {
    return this.items[this.currentIndex];
  }

  //set accessor including call the onchange callback
  set value(value:any) {
    if (value !== this.value) {
      this.currentIndex = this.items.indexOf(value);
      this.changeDetector.detectChanges();
      this.onChangeCallback(value);
    }
  }

  onUpClick() {
    this.currentIndex++;
    this.onChangeCallback(this.value);
  }

  onDownClick() {
    this.currentIndex--;
    this.onChangeCallback(this.value);
  }

  //From ControlValueAccessor interface
  writeValue(value:number) {
    if (value !== this.value) {
      this.currentIndex = this.items.indexOf(value);
      this.changeDetector.detectChanges();
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn:any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn:any) {
    this.onTouchedCallback = fn;
  }
}
