import { Component, ElementRef, OnInit } from '@angular/core';
import { Subject, zip } from 'rxjs';
import { Bounds, Feature, GeoJsonLayer, PluginClass, Point, Utils } from '../..//classes';
import { IGeoanalitikaApi, ILayer, IMap, IMapResult, IPluginInterface, ISearch, ISearchResult } from '../../interfaces';
import { MapService } from '../../services/map.service';
import { LayersStore } from '../../stores/LayersStore';

@Component({
  selector: 'gis-api',
  template: ''
})
export class GeoanalitikaApi extends PluginClass implements IGeoanalitikaApi, OnInit {
  private allConnect$:Subject<boolean> = new Subject<boolean>();

  private _map:IMap;
  private _mapresult:IMapResult;
  private _searchEngine:ISearch;
  private _showResult:ISearchResult;

  private _allLayers:ILayer[] = [];

  constructor(private el:ElementRef, private mapService:MapService, private layersStore:LayersStore) {
    super();
    // слои загруженны и все компоненты соединенны
    zip(layersStore.layersChanged, this.allConnect$, (allLayers:ILayer[], connected:boolean) => {
      this._allLayers = allLayers;
      return connected;
    }).subscribe(data => {
      this.onReady();
    });

    mapService.mapRightClick$.subscribe(event => {
      this.onRightClick(event);
    });

    mapService.mapClickEvent$.subscribe(event => {
      this.onMapClick(event);
    });
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Map':
        this._map = pi as IMap;
        break;
      case 'MapResults':
        this._mapresult = pi as IMapResult;
        break;
      case 'SearchEngine':
        this._searchEngine = pi as ISearch;
        break;
      case 'ShowResult':
        this._showResult = pi as ISearchResult;
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Map':
        this._map = null;
        break;
      case 'MapResult':
        this._mapresult = null;
        break;
      case 'SearchEngine':
        this._searchEngine = null;
        break;
      case 'ShowResult':
        this._showResult = null;
        break;
    }
  }

  ngOnInit() {
    const geoanalitika:any = this;
    geoanalitika.Point = Point;
    geoanalitika.Bounds = Bounds;

    (window as any).geoanalitika = geoanalitika;

    Utils.removeElement(this.el.nativeElement as HTMLElement);
  }

  afterConnect() {
    this.allConnect$.next(true);
  }

  clearMap() {
    this._mapresult.clearResult();
  }

  refreshMap() {
    this._map.refreshMap();
  }

  onMapClick(e:any) {}

  onRightClick(e:MouseEvent) {}

  getLayers():ILayer[] {
    return this._allLayers;
  }

  getVisibleLayers():ILayer[] {
    return Utils.getVisibleLayers(this.getLayers());
  }

  updateLayer(layer:ILayer) {
    this.layersStore.updateLayer(layer);
  }

  /**
   * Навигация по карте
   */
  setCenter(center:Point, zoom?:number) {
    this._map.goToPoint(center, zoom);
  }

  setExtent(bounds:Bounds) {
    this.mapService.currentMapBound$.next(bounds);
  }

  setZoom(zoom:number) {
    this.mapService.currentMapScale$.next(zoom);
  }

  /**
   * Поиск
   */
  searchInAttr(text:string):Promise<Feature[]> {
    return this._searchEngine.searchText(text);
  }

  searchInArea(geoJson:object):Promise<Feature[]> {
    const wkt:string = Utils.geojsonToWKT(geoJson);
    return this._searchEngine.searchInWkt(wkt);
  }

  showFeature(feature:Feature) {
    this._mapresult.goToFeature(feature);
    this._mapresult.showFeature([feature]);
  }

  /**
   * Создание слоя и объектов
   */
  createGeoJsonLayer(options:any):GeoJsonLayer {
    const self = this;
    const l:GeoJsonLayer = this._map.createLayer('geojson', options) as GeoJsonLayer;
    l.featureClicked = (feature:Feature) => {
      self._searchEngine.addToResult([feature]);
    };
    return l;
  }

  init() {
    console.error('Повторная инициализация проекта');
  }

  onReady() {
    if ((window as any).onGeoanalitikaLoaded) {
      (window as any).onGeoanalitikaLoaded();
    }
  }
}
