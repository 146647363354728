import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Polygon } from 'geojson';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayersStore } from 'shared/stores/LayersStore';
import { Feature } from '../../classes/Feature';
import { ArcGISLayer } from '../../classes/LeafletLayer/ArcGISLayer.class';
import { GeoserverLayer } from '../../classes/LeafletLayer/GeoserverLayer/GeoserverLayer.class';
import { WMSLayer } from '../../classes/LeafletLayer/WMSLayer.class';
import { WMSTimeLayer } from '../../classes/LeafletLayer/WMSTimeLayer.class';
import { WMTSLayer } from '../../classes/LeafletLayer/WMTSLayer.class';
import { PluginClass } from '../../classes/Plugin';
import { Point } from '../../classes/Point';
import { Utils } from '../../classes/Utils';
import { ICoordsTransform, ILayer, ILoader, IPluginInterface, ISearch, ISearchResult, ITool } from '../../interfaces';
import { MapService } from '../../services/map.service';
import { ArcgisService } from './arcgis_service';
import { GeoanalitikaService } from './geoanalitika_service';
import { GeocoderService } from '../../services/geocoder_service';
import { GeoserverService } from './geoserver_service';
import { RasterService } from './raster_service';
import {Layer} from 'leaflet';

export interface SearchOption {
  type:SearchType;
  active:boolean;
  inExtent?:boolean;
}

export type SearchType = 'address' | 'layer' | 'objects';

@Component({
  selector: 'search-engine',
  template: '',
  providers: [ArcgisService, GeoserverService, RasterService, GeoanalitikaService, GeocoderService]
})
export class SearchEngineComponent extends PluginClass implements ISearch, ITool, OnInit {
  groupName:string;
  params:SearchOption[] = [];

  private paramsMap:{ [K in SearchType]: SearchOption };

  activateMode:string = null;
  private active = false;
  private allLayers:ILayer[] = [];
  private visibleLayers:ILayer[] = [];

  private geoanalitikaLayers:ILayer[] = [];
  private arcgisLayers:ArcGISLayer[] = [];
  private geoserverLayers:GeoserverLayer[] = [];
  private rasterLayers:(WMSLayer | WMTSLayer | WMSTimeLayer)[] = [];

  private identifyResPlugins:ISearchResult[] = [];
  private searchResPlugins:ISearchResult[] = [];
  private loaderPlugins:ILoader[] = [];
  private transformPlugin:ICoordsTransform;

  private pointSearchMargin = 3; // отступ от точки клика по карте
  private url:string; // url для поиска
  private geocoderSource:string = null; // тип источника для геокодера

  // TODO:убрать dopFeatures
  private dopFeatures:Feature[] = [];

  private busy = false;

  constructor(
    private el:ElementRef,
    private httpClient:HttpClient,
    private arcgisService:ArcgisService,
    private geoserverService:GeoserverService,
    private rasterService:RasterService,
    private geoanalitikaService:GeoanalitikaService,
    private geocoderService:GeocoderService,
    layersStore:LayersStore,
    private mapService:MapService
  ) {
    super();

    combineLatest(layersStore.getActiveLayers(), layersStore.getEditLayers())
      .pipe(map(([activeLayers, editLayers]) => (editLayers.length ? editLayers : activeLayers.filter(item => item.searchable))))
      .subscribe(layerList => {
        if (!this.active) {
          return;
        }
        this.visibleLayers = layerList.filter(item => item.visible);
        this.geoserverLayers = this.visibleLayers.filter(item => item.type === 'geoserver') as GeoserverLayer[];
        this.arcgisLayers = this.visibleLayers.filter(item => item.type === 'arcgis') as ArcGISLayer[];
        this.rasterLayers = this.visibleLayers.filter(
          layer => layer.geomType === 'raster' && (layer.type === 'wms' || layer.type === 'wmts' || layer.type === 'wms-t')
        ) as (WMSLayer | WMTSLayer | WMSTimeLayer)[];
        this.geoanalitikaLayers = this.visibleLayers.filter(
          layer =>
            !layer.isGroup &&
            layer.type !== 'geoserver' &&
            layer.type !== 'arcgis' &&
            layer.geomType !== 'raster' &&
            layer.type !== 'wmts' &&
            layer.geomType !== 'unknown'
        );
      });

    // список всех слоёв
    layersStore.layersChanged.subscribe(data => {
      this.allLayers = data;
    });

    // клик по карте
    this.mapService.mapClick$.subscribe(data => {
      this.searchPoint(data);
    });

    // Объекты найдены на карте, не отмечать маркером
    this.mapService.foundFeatures$.subscribe(features => {
      this.showResult(features, false);
    });

    this.geoanalitikaService.url = this.url;
  }

  ngOnInit() {
    Utils.removeElement(this.el.nativeElement);
    if (this.url) {
      this.geoanalitikaService.url = this.url;
    }
    this.geocoderService.source = this.geocoderSource;
    this.paramsMap = this.params.reduce((acc:any, value:SearchOption) => ({ ...acc, ...{ [value.type]: value } }), {});
  }

  addInterface(name:string, pi:IPluginInterface) {
    switch (name) {
      case 'IdentifyResult':
        this.identifyResPlugins.push(pi as ISearchResult);
        break;
      case 'AllResult':
        this.searchResPlugins.push(pi as ISearchResult);
        break;
      case 'Loader':
        this.loaderPlugins.push(pi as ILoader);
        break;
      case 'TransformCoords':
        this.transformPlugin = pi as ICoordsTransform;
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'IdentifyResult':
        this.identifyResPlugins = [];
        break;
      case 'AllResult':
        this.searchResPlugins = [];
        break;
      case 'Loader':
        this.loaderPlugins = [];
        break;
    }
  }

  searchId(layer:ILayer, attrName:string, attrValue:any, fitExtent:boolean) {
    this.geoanalitikaService.searchId(layer, attrName, attrValue).then(features => {
      this.identifyResPlugins.forEach((plug:ISearchResult) => {
        let options:any = null;
        if (fitExtent) {
          options = { fitExtent:true };
        }
        plug.setFeatures(features, false, null, options);
      });
    });
  }

  addToResult(features:Feature[]) {
    this.dopFeatures = [...this.dopFeatures, ...features];
  }

  showResult(features:Feature[], showPlace:boolean) {
    this.dopFeatures = [];
    this.identifyResPlugins.forEach((plug:ISearchResult) => {
      plug.setFeatures(features, showPlace, null, null, null);
    });
  }

  gaSearch(pointXY:Point, layers:ILayer[]):Promise<Feature[]> {
    const pointLatLng = this.transformPlugin.getLatLngPoint(pointXY);
    const geojson = this.convertPointToSearchPolygon(pointXY);
    const wkt = Utils.geojsonToWKT(geojson);
    return this.geoanalitikaService.searchPoint(layers, wkt,
      this.getLayerFilters(layers));
  }

  searchPoint(pointXY:Point) {
    if (!this.active || this.busy) {
      return;
    }
    this.clearResult();

    if (!this.visibleLayers.length) {
      return;
    }

    // предотвращает появление дубликатов контура при повторных кликах
    // TODO: реализовать отмену запросов при повторном клике для обычного поиска
    if (this.componentId === 'SearchEdit') {
      this.busy = true;
    }

    this.showLoader();
    const clickLatLng = this.transformPlugin.getLatLngPoint(pointXY);
    const geojson = this.convertPointToSearchPolygon(pointXY);
    const wkt = Utils.geojsonToWKT(geojson);

    Promise.all([
      this.arcgisService.searchPoint(this.arcgisLayers, pointXY, this.pointSearchMargin),
      this.geoserverService.searchPoint(this.geoserverLayers, geojson),
      this.rasterService.searchPoint(this.rasterLayers, pointXY),
      this.geoanalitikaService.searchPoint(this.geoanalitikaLayers, wkt, this.getLayerFilters(this.visibleLayers))
    ]).then((data:Feature[][]) => {
      const features = [...this.dopFeatures, ...data.reduce((acc, value) => [...acc, ...value], [])];
      this.hideLoader();
      this.identifyResPlugins.forEach((plug:ISearchResult) => {
        plug.setFeatures(features, true, null, geojson, clickLatLng);
      });
      // очищаем доп. объекты
      this.dopFeatures = [];
      this.busy = false;
    });
  }

  searchArea(feature:Feature) {
    if (!this.active) {
      return;
    }
    this.clearResult();
    if (!this.visibleLayers.length) {
      return;
    }
    this.showLoader();

    const geoJSON = feature.geometry;
    const wkt = Utils.geojsonToWKT(geoJSON);

    Promise.all([
      this.geoanalitikaService.searchArea(this.geoanalitikaLayers, wkt, this.getLayerFilters(this.visibleLayers)),
      this.arcgisService.searchArea(this.arcgisLayers, geoJSON),
      this.geoserverService.searchArea(this.geoserverLayers, geoJSON)
    ]).then((data:Feature[][]) => {
      const features = [...this.dopFeatures, ...data.reduce((acc, value) => [...acc, ...value], [])];
      this.hideLoader();
      this.searchResPlugins.forEach((plug:ISearchResult) => {
        plug.setFeatures(features, true);
      });
      // очищаем доп. объекты
      this.dopFeatures = [];
    });
  }

  searchText(searchText:string, showResult:boolean = true):Promise<Feature[]> {
    this.showLoader();

    const searchQueries:Promise<Feature[]>[] = [];

    // поиск по геокодеру
    if (this.paramsMap.address.active) {
      let bbox:string = null;
      if (this.paramsMap.address.inExtent && this.mapService.projectExtent) {
        const bboxArr = this.mapService.projectExtent.split(',');
        bbox = [bboxArr[1], bboxArr[0], bboxArr[3], bboxArr[2]].join(',');
      }
      searchQueries.push(this.geocoderService.searchAddress(searchText, bbox).toPromise() as Promise<Feature[]>);
    }

    // поиск в атрибутах объектов слоев Геоаналитики
    if (this.paramsMap.objects && this.paramsMap.objects.active) {
      searchQueries.push(this.geoanalitikaService.searchText(this.geoanalitikaLayers, searchText, this.getLayerFilters(this.visibleLayers)));
      searchQueries.push(this.geoserverService.searchText(this.geoserverLayers as GeoserverLayer[], searchText));
      searchQueries.push(this.arcgisService.searchText(this.arcgisLayers as ArcGISLayer[], searchText));
    }

    return new Promise(resolve => {
      Promise.all(searchQueries).then((data:Feature[][]) => {
        const features = data.reduce((acc, value) => [...acc, ...value], []);

        if (showResult) {
          let layers = [];
          if (this.paramsMap.layer && this.paramsMap.layer.active) {
            layers = this.searchInLayers(searchText);
          }
          this.searchResPlugins.forEach(plugin => {
            plugin.setFeatures(features, true, layers);
          });
        }
        this.hideLoader();
        resolve(features);
      });
    });
  }

  getFeatureGeometry(feature:Feature):Observable<Feature[]> {
    return new Observable(obs => {
      const pkColumn = feature.layer.columns.find(cln => cln.is_pk);
      const pkValue = feature.properties[pkColumn.name];
      this.geoanalitikaService.searchId(feature.layer, pkColumn.name, pkValue).then(features => {
        obs.next(features);
      });
    });
  }

  activateTool() {
    this.active = true;
  }

  deactivateTool():Promise<boolean> {
    this.active = false;

    // Закрыть попапы
    this.identifyResPlugins.forEach(plugin => {
      plugin.clearResults();
    });

    return new Promise(resolve => {
      resolve(true);
    });
  }

  isActive() {
    return this.active;
  }

  getGroup() {
    return this.groupName;
  }

  searchInWkt(wkt:string):Promise<Feature[]> {
    return this.geoanalitikaService.searchArea(this.geoanalitikaLayers, wkt, this.getLayerFilters(this.visibleLayers));
  }

  private searchInLayers(text:string):ILayer[] {
    const suitableLayers:ILayer[] = [];
    const findFunc:(layer:ILayer) => void = (layer:ILayer) => {
      if (layer.isGroup) {
        layer.subLayers.forEach(item => findFunc(item));
        return;
      }
      if (!layer.name.toLowerCase().includes(text.toLowerCase())) {
        return;
      }
      suitableLayers.push(layer);
    };

    this.allLayers.forEach(item => findFunc(item));
    return suitableLayers;
  }

  private showLoader() {
    this.loaderPlugins.forEach(plug => {
      plug.showLoader();
    });
  }

  private hideLoader() {
    this.loaderPlugins.forEach(plug => {
      plug.hideLoader();
    });
  }

  private clearResult() {
    this.identifyResPlugins.forEach((plug:ISearchResult) => {
      plug.clearResults();
    });
    this.searchResPlugins.forEach((plug:ISearchResult) => {
      plug.clearResults();
    });
  }

  private getLayerFilters(layers:ILayer[]):any {
    if (!layers.length) {
      return;
    }
    const filters = {};
    layers
      .filter(layer => layer.filter || (layer as WMSTimeLayer).timeFilter)
      .forEach(layer => {
        if (layer.filter && (layer as WMSTimeLayer).timeFilter) {
          filters[layer.id] = `${layer.filter.replace(/[\[\]]+/g, '')} AND ${(layer as WMSTimeLayer).timeFilter}`;
        } else if (layer.filter) {
          filters[layer.id] = layer.filter.replace(/[\[\]]+/g, '');
        } else {
          filters[layer.id] = (layer as WMSTimeLayer).timeFilter;
        }
      });
    return filters;
  }

  // Создает из точки квадрат с заданным отступом от центра, в пикселях
  private convertPointToSearchPolygon(pointXY:Point):any {
    return {
      coordinates: [
        Utils.convertPointToRectangle(pointXY, this.pointSearchMargin)
          .map(coord => this.transformPlugin.getLatLngPoint(coord))
          .map(point => [point.x, point.y])
      ],
      type: 'Polygon'
    };
  }
}
