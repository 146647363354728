import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import { SelectWidget } from '@geoanalitika/widgets';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Attribute, DataFilter, Feature, PluginClass } from 'shared/classes';
import { IContainer, ILayer, IMapResult, IPluginInterface, IUser } from 'shared/interfaces';
import { AuthService } from 'shared/services/auth.service';
import { LayersStore } from 'shared/stores/LayersStore';
import { TabViewerService } from './tab_viewer.service';
import {JoinedTable} from '../../../../../shared/classes/JoinedTable';
import {EditableGridComponent, QueryDataOptions} from '../../../../../shared/components';

interface DataTable {
  joinedTable:JoinedTable;
  layer:ILayer;
}

@Component({
  selector: 'tab-viewer',
  templateUrl: 'tab_viewer.component.html',
  styleUrls: ['tab_viewer.component.less'],
  providers: [TabViewerService]
})
export class TabViewerComponent extends PluginClass implements OnDestroy {
  @ViewChild('select') select:SelectWidget<ILayer>;
  @ViewChild('grid') grid:EditableGridComponent;

  parentComponent:IContainer;

  layers:ILayer[] = [];
  tables:ILayer[] = [];
  columns:Attribute[] = [];
  tableStack:DataTable[] = [];

  user:IUser = null;
  download = false;

  private isActive:boolean = false;
  private resultPlugins:IMapResult[] = [];
  private currentDataFilter:DataFilter;
  private deactivate$:Subject<boolean> = new Subject<boolean>();
  private inited:boolean = false;

  constructor(public tabviewerService:TabViewerService, private _layersStore:LayersStore, private _el:ElementRef, _auth:AuthService) {
    super();

    this._layersStore
      .onOptionsLoaded()
      .pipe(switchMap(() => _layersStore.getActiveLayers()))
      .subscribe(data => {
        this.layers = data.filter(layer => layer.schema && layer.geomType !== 'raster');
        this.tables = this._layersStore.tables;
      });
    /*this._layersStore.getSelectedLayer().subscribe(layer => {
      if (this.isActive) {
        this.selectLayer(layer);
        this.select.selectItem(layer);
      }
    });*/

    _auth.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.deactivate$.next();
    this.deactivate$.unsubscribe();
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'MapResults':
        this.resultPlugins.push(pi as IMapResult);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'MapResults':
        this.resultPlugins = null;
        break;
    }
  }

  activate() {
    if (!this.inited) {
      this.inited = true;
      this.parentComponent.onHide.subscribe(() => {
        this.deactivate();
      });
    }
    this.isActive = true;
    this._layersStore
      .getActiveLayers()
      .pipe(takeUntil(this.deactivate$))
      .subscribe((layers:ILayer[]) => {
        if (this.currentLayer !== this._layersStore.selectedLayer && this._layersStore.selectedLayer) {
          this.selectLayer(this._layersStore.selectedLayer);
          this.select.selectItem(this._layersStore.selectedLayer);
        } else if (this.currentLayer) {
          // если выбранный слой отключили, скрываем таблицу
          if (!layers.find(item => item.id === this.currentLayer.id)) {
            (this.parentComponent as any).toggle();
            // если перенастроили фильтр, перезагружаем данные
          } else if (this.currentDataFilter !== this.currentLayer.dataFilter) {
            this.currentDataFilter = this.currentLayer.dataFilter;
            this.grid.updateData();
          }
        }
      });
  }

  deactivate() {
    this.isActive = false;
    this.clearFeatures();
    this.tableStack = [];
    this.select.reset();
    this.deactivate$.next();
  }

  get layersList():ILayer[] {
    return this.layers.concat(this.tables);
  }

  get currentLayer():ILayer {
    const table:DataTable = this.tableStack[this.tableStack.length - 1];
    return table ? table.layer : null;
  }

  get baseLayer():ILayer {
    const table:DataTable = this.tableStack[0];
    return table.layer;
  }

  get joinedTables():JoinedTable[] {
    const table:DataTable = this.tableStack[this.tableStack.length - 1];
    return (table && table.layer) ? table.layer.joinedTables : [];
  }

  get isJoinedTableActive():boolean {
    return this.tableStack.length > 1;
  }

  get currentDataTable():DataTable {
    const table:DataTable = this.tableStack[this.tableStack.length - 1];
    return table;
  }

  get tableId():number {
    let ret:number;
    if (this.currentDataTable) {
      if (this.currentDataTable.layer) {
        ret = this.currentDataTable.layer.id;
      } else if (this.currentDataTable.joinedTable) {
        ret = this.currentDataTable.joinedTable.id;
      }
    }
    return ret;
  }

  get tableType():string {
    let ret:string;
    if (this.currentDataTable) {
      if (this.currentDataTable.joinedTable) {
        ret = 'join';
      } else if (this.currentDataTable.layer) {
        const layer:ILayer = this.currentDataTable.layer;
        ret =  layer.geomType ? 'layer' : 'table';
      }
    }
    return ret;
  }

  get visibleColumns():Attribute[] {
    return this.columns.filter(column => column.inAttr);
  }

  gotoPreviousTable() {
    this.tableStack.pop();
    this.refreshTable();
  }

  printTable() {
    if (!this.currentDataTable) {
        return;
    }
    const table:any = this.currentDataTable.joinedTable ? this.currentDataTable.joinedTable : this.currentDataTable.layer;

    const requestOpt:any = {
        id: table.id,
        attributes: this.visibleColumns.map(col => col.name).join(','),
        filters: table.dataFilter ? table.dataFilter : null,
    };

    if (this.grid.sortOrder) {
        requestOpt.order = this.grid.sortOrder;
    }

    this.tabviewerService
        .printTable(requestOpt)
        .subscribe(data => {
            const frog = window.open('', 'wildebeast');
            frog.document.open();
            //frog.document.open();
            const html = <string><any>data;
            frog.document.write(html);
            frog.document.close();
            frog.print();
        });
  }

  refreshTable() {
    const table = this.currentDataTable;
    if (table) {
      if (table.layer) {
        this.columns = table.layer.columns;
      } else if (table.joinedTable) {
        this.columns = table.joinedTable.attributes;
      }
      this.columns.forEach(cln => cln.inAttr = cln.inAttr === undefined ? true : cln.inAttr);
      if (this.grid) {
        this.grid.updateData();
      }
    }
  }

  selectLayer(layer:ILayer) {
    if (this.currentLayer === layer) {
      return;
    }
    this.tableStack = [];
    this.tableStack.push({
      joinedTable: null,
      layer: layer,
    });
    this.refreshTable();
  }

  flyToFeatures() {
    this.showFeatures();
  }

  get hasSelectedRows():boolean {
    return this.grid ? this.grid.selectedRows.length > 0 : false;
  }

  onOpenJoinedTable(joinedTable:JoinedTable) {
    this.tableStack.push({
      joinedTable: joinedTable,
      layer: null
    });
    this.refreshTable();
  }

  onRowDeleteClick() {
    const pkColumnName:string = this.columns.find((column => column.is_pk)).name;
    const ids:any[] = [];
    for (const row of this.grid.selectedRows) {
      ids.push(row[pkColumnName]);
    }
    const editColumn:any = {[pkColumnName]: ids};
    this.tabviewerService.deleteData(this.tableId, this.tableType, editColumn).subscribe(() => this.refreshTable());
  }

  onRowAddClick() {
    this.grid.addNewRow();
  }

  onGridQueryData(options:QueryDataOptions) {
    this.clearFeatures();

    if (this.currentLayer) {
      const requestOpt:any = {
        id: this.currentLayer.id,
        page: options.page,
        attributes: this.columns.map(col => col.name).join(','),
        filters: this.currentLayer.dataFilter ? this.currentLayer.dataFilter : null,
        limit: options.pageSize
      };

      if (options.sortOrder) {
        requestOpt.order = options.sortOrder;
      }

      //table without geometry
      if (!this.currentLayer.geomType) {
        requestOpt.type = 'table';
      }

      this.tabviewerService
        .getTable(requestOpt)
        .subscribe(data => {
          (options.source as EditableGridComponent).setTableData(data, options);
        });
    } else {
      this.tabviewerService
        .getJoinedTableData(this.baseLayer, options.page, options.pageSize, this.currentDataTable.joinedTable)
        .subscribe(data => {
          options.joinedTable = this.currentDataTable.joinedTable;
          (options.source as EditableGridComponent).setTableData(data, options);
        }, err => {
          this.tableStack.pop();
          this.refreshTable();
        });
    }
  }

  private prepareTableData(layer:ILayer, items:any[]) {
    // подстановка значений из справочников
    const dictColumns = layer.columns.filter(column => column.dictionary);
    if (dictColumns.length) {
      items.forEach(row => {
        dictColumns.forEach(column => {
          const dictValue = column.dictionary.find(item => item.key === `${row[column.name]}`);
          if (dictValue) {
            row[column.name] = dictValue.value;
          }
        });
      });
    }
  }

  private clearFeatures() {
    this.resultPlugins.forEach(plugin => {
      plugin.clearResult();
    });
  }

  private showFeatures() {
    const selectedRows = this.grid.selectedRows;
    const features = selectedRows
      .map(row => {
        const feature = new Feature();
        feature.bounds = null;
        feature.geometry = null;
        feature.layer = this.currentLayer;
        feature.properties = row;
        return feature;
      });
    this.resultPlugins.forEach(plugin => {
      plugin.showFeature(features).then(() => {
        plugin.goToFeature(features);
      });
    });
  }
}
