import {Style} from './SymbolizerImpl.class';
import {ISymbolizer} from './ISymbolizer';

export class DataLayer {
    id:number;
    name = '';
    resource_uri:string;
    datasource:{ type:'', name:'', id:null, mosaic:false, datasource_name:'', resource_name:'', datasource_type:'' };
    map_id:number;
    type:string;
    style:Style;
    symbolizers:ISymbolizer[] = [];
    attributes:Attribute[] = [];
    order = 0;
    time_field:string;
    prefix:string;
    geojson:string;


    constructor(data?:any) {
        this.setData(data);
    }

    setData(data:any) {
        if (!data) {
            return;
        }
        Object.assign(this, data);
    }

    delete_rule_item(rule_item:ISymbolizer) {
        this.style.rules.forEach(rule => {
            rule.rule_items.forEach((item:any, idx:number) => {
                if (item.object_id === rule_item.id) {
                    rule.rule_items.splice(idx, 1);
                }
            });
        });
    }
}

export class Attribute implements AttributeOptions {
    name = '';
    alias = '';
    type = '';
    inPopup = false;
    inAttr = false;
}

export interface AttributeOptions {
    name:string;
    alias:string;
    type:string;
    inPopup?:boolean;
    inAttr?:boolean;
}
