import * as chroma from 'chroma-js';

export function scale(palette:string[], count:number):string[] {
  return chroma.scale(palette).mode('lrgb' as any).colors(count, 'hex');
}

export function gradColor(palette:string[], domain:number):string {
  return chroma.scale(palette)(domain).hex();
}

export function adjustPalette(palette:string[], count:number) {
  while (palette.length < count) {
    palette.push(palette.slice(-1)[0]);
  }
  return palette.slice(0, count);
}
