import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Feature } from '../../classes/Feature';
import { ILayer } from '../../interfaces';

interface IResult {
  [key:number]:any;
  features:Feature[];
}

@Injectable()
export class GeoanalitikaService {
  url:string;

  constructor(private httpClient:HttpClient) {}

  searchId(layer:ILayer, attrName:string, attrValue:any):Promise<Feature[]> {
    const filters = {
      [layer.id]: `${attrName}=${attrValue}`
    };
    return this._getFeatures([layer], null, filters);
  }

  searchPoint(layers:ILayer[], wktGeom:string, filters:any):Promise<Feature[]> {
    return this.searchArea(layers, wktGeom, filters);
  }

  searchArea(layers:ILayer[], wktGeom:string, filters:any):Promise<Feature[]> {
    return this._getFeatures(layers, wktGeom, filters);
  }

  searchText(layers:ILayer[], searchText:string, filters:any):Promise<Feature[]> {
    if (!layers.length) {
      return Promise.resolve([]);
    }

    const layersQuery = this._setQueryLayers(layers);

    return new Promise(async (resolve, reject) => {
      if (!layersQuery.length) {
        resolve([]);
        return;
      }

      this.httpClient
        .post<any>(this.url, {
          LAYERS: layersQuery,
          FILTERS: filters,
          text: searchText
        })
        .subscribe(data => {
          this._prepareFeatures(layers, data).then(result => {
            resolve(result);
          });
        });
    });
  }

  private _getFeatures(layers:ILayer[], wktGeom:string, filters:any):Promise<Feature[]> {
    if (!layers.length) {
      return Promise.resolve([]);
    }

    return new Promise((resolve, reject) => {
      const layersQuery:string = this._setQueryLayers(layers);
      if (!layersQuery.length) {
        resolve([]);
        return;
      }

      this.httpClient
        .post<any>(this.url, {
          LAYERS: layersQuery,
          GEOMETRY: wktGeom,
          FILTERS: filters
        })
        .pipe(catchError(() => of([])))
        .subscribe(data => {
          this._prepareFeatures(layers, data).then(result => {
            resolve(result);
          });
        });
    });
  }

  private _prepareFeatures(layerList:ILayer[], data:IResult[]):Promise<Feature[]> {
    const features:Feature[] = [];

    return new Promise(resolve => {
      if (!data.length) {
        resolve([]);
        return;
      }
      data.forEach((result:IResult, idx:number) => {
        const layerId:number = Number(Object.keys(result)[0]);

        const layer:ILayer = layerList.find(item => item.id === layerId) as ILayer;

        if (layer) {
          if (result[layerId].features) {
            result[layerId].features.forEach((f:any) => {
              const feature = new Feature();
              feature.id = f.properties[layer.pk];
              feature.editable = f.editable;
              feature.message = f.message;
              feature.geometry = f.geometry ? f.geometry : null;
              feature.bounds = f.bounds ? f.bounds : null;
              feature.properties = f.properties ? f.properties : {};
              feature.layer = layer;
              features.push(feature);
            });
          }
        }
        if (idx === data.length - 1) {
          resolve(features);
        }
      });
    });
  }

  private _setQueryLayers(layerList:ILayer[]):string {
    if (!layerList.length) {
      return;
    }
    const layersId = layerList.filter(layer => !layer.notOnScale || layer.type === 'cluster').map(layer => layer.id);
    if (!layersId.length) {
      return '';
    }
    return layersId.join(',');
  }
}
