import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Utils } from 'shared/classes';
import { Chart } from '../classes';

@Injectable()
export class ChartService {
  private apiUrl = `/api/v1/`;

  constructor(private http:HttpClient) {}

  getChartList(projectSlug:string):Observable<any> {
    return this.http.get(`${this.apiUrl}chart/?project__slug=${projectSlug}`).pipe(catchError(this.handleError));
  }

  getData(chart:Chart, reload:boolean = false):Observable<any> {
    let url:string = chart.resource_uri;
    if (reload) {
      url += '?updatecache=true';
    }

    return this.http.get(url).pipe(catchError(this.handleError));
  }

  deleteChart(chart:Chart) {
    return this.http.delete(`${chart.resource_uri}`).pipe(catchError(this.handleError));
  }

  saveChart(data:any) {
    return this.http.post(`${this.apiUrl}chart/`, data).pipe(catchError(this.handleError));
  }

  private handleError(ex:HttpErrorResponse):Observable<Error> {
    const msg = 'Reguest error:' + Utils.getErrorMsg(ex);
    console.error(msg);
    return observableThrowError(ex);
  }
}
