export const COLORS = {
  basic: {
    domain: [
      '#e569b3',
      '#79b705',
      '#b1ed0e',
      '#f1f9a7',
      '#9ff4c8',
      '#26f2d7',
      '#dd65ed',
      '#fcd68a',
      '#efba97',
      '#e23bb6',
      '#6397ce',
      '#ba2e30',
      '#53b701',
      '#0d7491',
      '#17a87a',
      '#e27cff',
      '#efaec8',
      '#74bef7',
      '#fff17a',
      '#9ccafc',
      '#5ef2be',
      '#dd66f4',
      '#8ced6f',
      '#9a6cfc',
      '#dd3753',
      '#01a34d',
      '#a7f9d3',
      '#f7e896',
      '#cbfc85',
      '#5ba2ff',
      '#ff28f4',
      '#9fe85f',
      '#0d8744',
      '#a24bd8',
      '#608ac9',
      '#ea38c6',
      '#f466c3',
      '#6fc5db',
      '#e5c05b',
      '#3e288c',
      '#9b1f21',
      '#019310',
      '#f241cb',
      '#30d3c0',
      '#f96bb7',
      '#a83e1a',
      '#95d155',
      '#afe059',
      '#827cd3',
      '#a9fcb2',
      '#0c8ed3',
      '#e05af4',
      '#dd66c5',
      '#7e16ce',
      '#ffc9f9',
      '#dd3502',
      '#a983ef',
      '#34559b',
      '#afc62b',
      '#db5eaf',
      '#4ee081',
      '#f252a2',
      '#7b72d8',
      '#8172c9',
      '#7af9cf',
      '#56ef7f',
      '#bcdb57',
      '#027537',
      '#c9f287',
      '#e52d43',
      '#f9f098',
      '#eaa6cb',
      '#037722',
      '#f9b6e4',
      '#ba9123',
      '#e06b5c',
      '#efb381',
      '#bc1a53',
      '#efaa69',
      '#f4a1be',
      '#39ddaf',
      '#06e80e',
      '#ccd1ff',
      '#e59cc5',
      '#baf4a1',
      '#c14915',
      '#f2e398',
      '#c2e81b',
      '#c9c600',
      '#f70e50',
      '#bff29d',
      '#ddcd3e',
      '#efab9b',
      '#2225d8',
      '#cc4ad3',
      '#0d8493',
      '#fffc77',
      '#4ae8e5',
      '#7f39b2',
      '#ff07bd'
    ]
  },
  histo: {
    domain: ['#0066FF']
  }
};

export const DATA:any = {
  single: [
    {
      name: 'Группа 1',
      value: 2
    },
    {
      name: 'Группа 2',
      value: 1
    },
    {
      name: 'Группа 3',
      value: 4
    },
    {
      name: 'Группа 4',
      value: 3
    },

    {
      name: 'Группа 5',
      value: 2
    },
    {
      name: 'Группа 6',
      value: 3
    },
    {
      name: 'Группа 7',
      value: 2
    },
    {
      name: 'Группа 8',
      value: 1
    }
  ],
  multi: [
    {
      name: 'Группа 1',
      series: [
        {
          name: 'Атрибут 1',
          value: 2
        },
        {
          name: 'Атрибут 2',
          value: 3
        }
      ]
    },
    {
      name: 'Группа 2',
      series: [
        {
          name: 'Атрибут 1',
          value: 1
        },
        {
          name: 'Атрибут 2',
          value: 4
        }
      ]
    },
    {
      name: 'Группа 3',
      series: [
        {
          name: 'Атрибут 1',
          value: 3
        },
        {
          name: 'Атрибут 2',
          value: 1
        }
      ]
    },
    {
      name: 'Группа 4',
      series: [
        {
          name: 'Атрибут 1',
          value: 4
        },
        {
          name: 'Атрибут 2',
          value: 5
        }
      ]
    },
    {
      name: 'Группа 5',
      series: [
        {
          name: 'Атрибут 1',
          value: 3
        },
        {
          name: 'Атрибут 2',
          value: 2
        }
      ]
    }
  ],
  multiBubble: [
    {
      name: 'Germany',
      series: [
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 80.3,
          r: 80.4
        },
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 80.3,
          r: 78
        },
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 75.4,
          r: 79
        }
      ]
    },
    {
      name: 'USA',
      series: [
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 78.8,
          r: 310
        },
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 76.9,
          r: 283
        },
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 75.4,
          r: 253
        }
      ]
    },
    {
      name: 'France',
      series: [
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 81.4,
          r: 63
        },
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 79.1,
          r: 59.4
        },
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 77.2,
          r: 56.9
        }
      ]
    },
    {
      name: 'United Kingdom',
      series: [
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 80.2,
          r: 62.7
        },
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 77.8,
          r: 58.9
        },
        {
          name: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          x: new Date(Math.floor(1473700105009 + Math.random() * 1000000000)),
          y: 75.7,
          r: 57.1
        }
      ]
    }
  ],
  line: [
    {
      name: 'Группа 1',
      series: [
        {
          name: 'Атрибут 1',
          value: 2
        },
        {
          name: 'Атрибут 2',
          value: 3
        },
        {
          name: 'Атрибут 3',
          value: 1
        },
        {
          name: 'Атрибут 4',
          value: 4
        }
      ]
    },
    {
      name: 'Группа 2',
      series: [
        {
          name: 'Атрибут 1',
          value: 1
        },
        {
          name: 'Атрибут 2',
          value: 4
        },
        {
          name: 'Атрибут 3',
          value: 3
        },
        {
          name: 'Атрибут 4',
          value: 2
        }
      ]
    },
    {
      name: 'Группа 3',
      series: [
        {
          name: 'Атрибут 1',
          value: 3
        },
        {
          name: 'Атрибут 2',
          value: 1
        },
        {
          name: 'Атрибут 3',
          value: 1
        },
        {
          name: 'Атрибут 4',
          value: 5
        }
      ]
    },
    {
      name: 'Группа 4',
      series: [
        {
          name: 'Атрибут 1',
          value: 1
        },
        {
          name: 'Атрибут 2',
          value: 3
        },
        {
          name: 'Атрибут 3',
          value: 2
        },
        {
          name: 'Атрибут 4',
          value: 1
        }
      ]
    }
  ]
};
