import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeoJSON } from 'leaflet';
import { forkJoin, Observable, Observer, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Feature } from '../../classes/Feature';
import { ArcGISLayer } from '../../classes/LeafletLayer/ArcGISLayer.class';
import { Point } from '../../classes/Point';
import { ILayer } from '../../interfaces';

@Injectable()
export class ArcgisService {
  constructor(private httpClient:HttpClient) {}

  searchPoint(layers:ArcGISLayer[], pointXY:Point, tolerance:number):Promise<Feature[]> {
    if (!layers.length) { return Promise.resolve([]); }
    const allRequest$:Observable<Feature[]>[] = [];
    layers.forEach(layer => {
      allRequest$.push(this._searchPoint(layer, pointXY, tolerance));
    });
    const source$:any = forkJoin(allRequest$);
    return this._getResults(source$);
  }

  searchArea(layers:ArcGISLayer[], geoJSON:GeoJSON.DirectGeometryObject):Promise<Feature[]> {
    if (!layers.length) { return Promise.resolve([]); }

    const lgeoJSON = new GeoJSON(geoJSON);

    return new Promise(resolve => {
      const layersToGetIds = layers.filter(layer => !layer.layerIds);

      this._getLayerIds(layersToGetIds).subscribe(data => {
        if (data.length) {
          data.forEach((layer, idx) => {
            layersToGetIds[idx].layerIds = layer.layers.map(l => l.id);
          });
        }

        const allRequest$:Observable<Feature[]>[] = [];
        layers.forEach(layer => {
          layer.layerIds.forEach(id => {
            allRequest$.push(this._searchArea(id, layer, lgeoJSON));
          });
        });
        const source$:any = forkJoin(allRequest$);
        resolve(this._getResults(source$));
      });
    });
  }

  searchText(layers:ArcGISLayer[], text:string):Promise<Feature[]> {
    if (!layers.length) { return Promise.resolve([]); }

    return new Promise(resolve => {
      const layersToGetIds = layers.filter(layer => !layer.layerIds);

      this._getLayerIds(layersToGetIds).subscribe(data => {
        if (data.length) {
          data.forEach((layer, idx) => {
            layersToGetIds[idx].layerIds = layer.layers.map(l => l.id);
          });
        }

        const allRequest$:Observable<Feature[]>[] = [];
        layers.forEach(layer => {
          allRequest$.push(this._searchText(layer, text));
        });
        const source$:any = forkJoin(allRequest$);
        resolve(this._getResults(source$));
      });
    });
  }

  private _getLayerIds(layers:ArcGISLayer[]):Observable<any> {
    const idRequest$:Observable<any>[] = layers
      .filter(layer => !layer.layerIds)
      .map(layer => this.httpClient.get(`${layer.url}/${layer.name}/MapServer?f=pjson`));

    if (idRequest$.length) {
      return forkJoin(idRequest$);
    }

    return of([]);
  }

  private _searchPoint(layer:ArcGISLayer, pointXY:Point, tolerance:number = 5):Observable<Feature[]> {
    const latlngPoint = layer.map.containerPointToLatLng([pointXY.x, pointXY.y]);

    return Observable.create((observer:Observer<any>) => {
      layer.mapService
        .identify()
        .on(layer.map)
        .at(latlngPoint)
        .tolerance(tolerance)
        .layers('all')
        .run((error:any, featureCollection:GeoJSON.FeatureCollection<any>, response:any) => {
          if (error) {
            observer.next([]);
            observer.complete();
          } else {
            observer.next(this._prepareFeatures(layer, featureCollection));
            observer.complete();
          }
        });
    });
  }

  private _searchArea(id:number, layer:ArcGISLayer, geoJSON:GeoJSON) {
    return Observable.create((observer:Observer<any>) => {
      layer.mapService
        .query()
        .intersects(geoJSON)
        .layer(id)
        .run((error:any, featureCollection:GeoJSON.FeatureCollection<any>, response:any) => {
          if (error) {
            observer.next([]);
            observer.complete();
          } else {
            observer.next(this._prepareFeatures(layer, featureCollection));
            observer.complete();
          }
        });
    });
  }

  private _searchText(layer:ArcGISLayer, text:string):Observable<Feature[]> {
    return Observable.create((observer:Observer<any>) => {
      layer.mapService
        .find()
        .layers([layer.layerIds])
        .text(text)
        .contains(true)
        .run((error:any, featureCollection:GeoJSON.FeatureCollection<any>, response:any) => {
          if (error) {
            observer.next([]);
            observer.complete();
          } else {
            observer.next(this._prepareFeatures(layer, featureCollection));
            observer.complete();
          }
        });
    });
  }

  private _prepareFeatures(layer:ArcGISLayer, featureCollection:GeoJSON.FeatureCollection<any>):Feature[] {
    const features:Feature[] = [];
    featureCollection.features.forEach(feature => {
      if (!feature.geometry) { return; }
      const f = new Feature();
      f.geometry = feature.geometry;
      f.properties = feature.properties;
      f.layer = layer as ILayer;
      features.push(f);
    });
    return features;
  }

  private _getResults(source$:Observable<Feature[]>) {
    return source$
      .pipe(
        map((data:any) => {
          let features = [];
          data.forEach(bunch => {
            features = [...features, ...bunch];
          });
          return features;
        }),
        catchError(() => of([]))
      )
      .toPromise();
  }
}
