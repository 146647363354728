import {CircleMarker, FeatureGroup, Icon, LatLng, Map, Marker, Polyline, Tooltip} from 'leaflet';
import {Point} from 'shared/classes';

export class DrawLayer {

  private lealfletLayer:FeatureGroup;
  private map:Map;
  private _visible:boolean;

  constructor(map:Map) {
    this.map = map;
    this.lealfletLayer = new FeatureGroup([]);
    map.addLayer(this.lealfletLayer);
    Icon.Default.prototype.options.shadowSize = [0, 0];
  }

  get visible():boolean {
    return this._visible;
  }

  set visible(visible:boolean) {
    this._visible = visible;
    if (visible) {
      this.map.addLayer(this.lealfletLayer);
    } else {
      this.map.removeLayer(this.lealfletLayer);
    }
  }

  addGeometry(points:Point[], type:string, style?:any) {
    const latlngs:LatLng[] = [];
    for (const point of points) {
      latlngs.push(new LatLng(point.y, point.x));
    }
    let geometryLayer;
    if (type === 'Point') {
      if (style.type === 'icon') {
        const icon = new Icon(style);
        geometryLayer = new Marker(latlngs[0], {icon: icon});
      } else if (style.type === 'circle') {
        geometryLayer = new CircleMarker(latlngs[0], style);
      }
      if (style.text) {
        const tooltip = new Tooltip({
          permanent: true,
          direction: 'center',
          className: style.textClass
        })
          .setContent(style.text)
          .setLatLng(latlngs[0]);
        this.lealfletLayer.addLayer(tooltip);
      }
    } else if (type === 'LineString') {
      geometryLayer = new Polyline(latlngs, style);
    }
    this.lealfletLayer.addLayer(geometryLayer);
  }

  clearDrawFeatures() {
    this.lealfletLayer.clearLayers();
  }
}
