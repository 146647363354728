import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {IContainer, IContentChild, ILayer, ILegend, ILegendStyle, IMenu, IPluginInterface} from '../../';
import {PluginClass} from '../../classes/Plugin';
import {MapService} from '../../services/map.service';
import {LayersStore} from '../../stores/LayersStore';
import {Button} from '../button/button.component';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

@Component({
  selector: 'map-legend',
  templateUrl: 'maplegend.component.html',
  styleUrls: ['maplegend.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapLegend extends PluginClass implements IContentChild, OnInit {
  @Input() active = false;

  parentComponent:IContainer;
  layerList:ILayer[] = [];
  currentScale:number;
  maxSymbolsPerAttribute = 20;

  private menu:IMenu;
  private btn:Button = null;

  constructor(private layersStore:LayersStore, private mapService:MapService, private sanitizer:DomSanitizer,
              private changeDetector:ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    const obsLayers = this.layersStore.getActiveLayers();
    obsLayers.subscribe(data => {
      this.layerList = data.filter(item => this.isLayerActive(item));
      this.changeDetector.detectChanges();
    });
    const obsScale = this.mapService.currentMapScale$;
    obsScale.subscribe(scale => {
      this.currentScale = scale;
      this.changeDetector.detectChanges();
    });
  }

  onLoad() {
    const self = this;
    if (this.parentComponent && self.btn) {
      const oldFunc:() => void = self.parentComponent.close;
      this.parentComponent.close = () => {
        oldFunc.call(self.parentComponent);
        if (self.btn.isActive()) {
          self.btn.setActive(false);
        }
      };
    }
  }

  stringifyStyle(style:CSSStyleDeclaration):SafeStyle {
    let ret:string = '';
    for (const attr in style) {
      ret += attr + ':' + style[attr] + ';';
    }
    return this.sanitizer.bypassSecurityTrustStyle(ret);
  }

  activate() {
    this.active = true;
    this.changeDetector.detectChanges();
  }

  deactivate() {
    this.active = false;
    this.changeDetector.detectChanges();
  }

  enable() {}

  disable() {}

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        const that = this;
        this.menu = pi as IMenu;
        // создание кнопки
        this.menu.createBtn().then(btn => {
          that.btn = btn.instance;

          that.buttonConfig.onClickMe = () => {
            that.activate();
          };

          that.buttonConfig.onDeactivate = () => {
            that.deactivate();
            if (that.buttonConfig.type === 'switcher' && that.parentComponent) {
              that.parentComponent.close();
            }
          };

          that.btn.setOptions(that.buttonConfig);
          // добавить кнопку массив меню
          that.menu.addBtn(that.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  filterLegend(items:ILegend[]):ILegend[] {
    return items.filter(data => {
      return data.scale_min === null
        || (data.scale_min <= this.currentScale && data.scale_max >= this.currentScale)
        || data.type === 'cluster';
    });
  }

  getGradient(items:ILegendStyle[], direction:string):string {
    return `linear-gradient(${direction}, ${items.map(item => item.style.background).join(', ')})`;
  }

  private isLayerActive(layer:ILayer):boolean {
    return layer.visible
      && !layer.hideLegend
      && (!layer.notOnScale || layer.type === 'cluster')
      && (layer.legend || layer.legendUrl) && !layer.isGroup;
  }
}
