import { Component, OnInit } from '@angular/core';
import { ILayer } from 'shared/interfaces';
import { LayersFactory } from 'shared/services';
import { LayersStore } from 'shared/stores/LayersStore';
import { AbstractCartometricOperation } from '../abstract_cartometric_operation_form';
import { CartometricService } from '../cartometric.service';
import { OverlayOp } from '../classes';

const OVERLAY_OPERATIONS = [
  {
    name: 'Пересечение',
    value: 'src-in'
  },
  {
    name: 'Разность',
    value: 'src-out'
  },
  {
    name: 'Объединение',
    value: 'xor'
  }
];

@Component({
  selector: 'overlay-ops',
  templateUrl: 'overlay_operations.component.html',
  styleUrls: ['overlay_operations.component.less']
})
export class OverlayOperationsComponent extends AbstractCartometricOperation implements OnInit {
  settings = new OverlayOp();
  operations = OVERLAY_OPERATIONS;

  targetLayers:ILayer[] = [];

  constructor(layersStore:LayersStore, layersFactory:LayersFactory, cartometricService:CartometricService) {
    super(layersStore, layersFactory, cartometricService);
  }

  ngOnInit() {
    this.settings.operation = 'src-in';
  }

  onSourceChange() {
    this.settings.target = null;
    const source = this.settings.source;

    if (!source) {
      this.targetLayers = [];
    }

    if (this.featuresLimit && source) {
      this.cartometricService.getFeatureCount(source.id).subscribe(data => {
        const count = data[source.id];
        if (count > this.featuresLimit) {
          this.displayError(
            `Количество объектов в слое ${source.name}
             составляет ${count} и превышает лимит в ${this.featuresLimit} объектов,
             установленный для операций наложения. Выберите слой с меньшим количеством объектов.`,
            10000
          );
          this.settings.source = null;
        } else {
          this.targetLayers = this.layers.filter(
            layer => layer.datasource_id === source.datasource_id && layer.id !== source.id
          );
        }
      });
    }
  }

  onTargetChange() {
    const source = this.settings.target;
    if (this.featuresLimit && source) {
      this.cartometricService.getFeatureCount(source.id).subscribe(data => {
        const count = data[source.id];
        if (count > this.featuresLimit) {
          this.displayError(
            `Количество объектов в слое ${source.name}
             составляет ${count} и превышает лимит в ${this.featuresLimit} объектов,
             установленный для операций наложения. Выберите слой с меньшим количеством объектов.`,
            10000
          );
          this.settings.target = null;
        }
      });
    }
  }

  protected reset() {
    this.form.resetForm();
    this.settings = new OverlayOp();
    this.targetLayers = [];
  }

  protected prepareData() {
    const source = this.settings.source;
    const target = this.settings.target;

    return {
      name: this.settings.name,
      operation: this.settings.operation,
      layerId: source.id,
      targetId: target.id,
      srcFilter: source.filter,
      tgtFilter: target.filter,
      mapStyle: 'overlay'
    };
  }
}
