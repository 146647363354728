import { IGeoJson, ILayer } from '../interfaces';
import { Bounds, Document, Image } from './';

export class Feature {
  id:string;
  geometry:IGeoJson;
  bounds:Bounds;
  properties:any = {};
  editable:boolean = false;
  message:string = '';
  layer:ILayer;
  documents:Document[] = [];
  images:Image[] = [];
  rating:Number = -1;
  chartData?:any[];

  get type() {
    if (this.geometry.type) {
      return this.geometry.type.toLowerCase();
    }
    return null;
  }

  // Поменять широту и долготу местами (например,
  // чтобы перевести в формат, совместимый с Leaflet ([lat, lng]))
  invertCoordinates() {
    this.modifyCoordinatesRecursively(this.geometry.coordinates, this.switchCoords);
  }

  // Обрезать значения координат до указанного количества знаков после запятой
  truncateCoordinates(length:number = 6) {
    this.modifyCoordinatesRecursively(this.geometry.coordinates, this.shortenCoords(length));
  }

  getIdentConstarint():string {
    let ret = '';
    for (const attr of this.layer.columns) {
      if (attr.is_pk) {
        if (ret) { ret += ' and '; }
        ret += `${attr.name}=${this.properties[attr.name]}`;
      }
    }
    return ret;
  }

  private modifyCoordinatesRecursively(coords:any[], func:(arg:any[]) => void) {
    if (this.type === 'point') {
      func(coords);
      return;
    }

    coords.forEach(c => {
      if (typeof c[0] === 'number') {
        func(c);
      } else {
        this.modifyCoordinatesRecursively(c, func);
      }
    });
  }

  private switchCoords(coords:any[]) {
    [coords[0], coords[1]] = [coords[1], coords[0]];
  }

  private shortenCoords(length:number) {
    return (coords:number[]) => {
      coords[0] = Number(Number(coords[0]).toFixed(length));
      coords[1] = Number(Number(coords[1]).toFixed(length));
    };
  }
}
