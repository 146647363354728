import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Attribute, Feature } from '../../../';
import { DictionaryService } from '../../../services';

@Component({
  selector: 'geo-feature-edit',
  templateUrl: 'feature_edit.component.html',
  styleUrls: ['feature_edit.component.less']
})
export class FeatureEditComponent {
  @Input() feature:Feature;
  @Input() attributesToDisplay:Attribute[] = [];

  @Output() onEdit = new EventEmitter();

  constructor(public dictService:DictionaryService) {}

  updateAttributes(feature:Feature, attr?:any, event?:any) {
    this.onEdit.emit({ feature, attr, event });
  }

  selectFormDict(editAttr:Attribute, selectedVal:{ key:string; value:string }) {
    this.feature.properties[editAttr.name] = selectedVal.key;
    this.updateAttributes(this.feature);
  }

  getDictValue(editAttr:Attribute) {
    const dictionary = this.dictService.getDictionary(editAttr.dictionary_id);
    return this.feature.properties[editAttr.name]
      ? dictionary.entries.find(item => item.key === `${this.feature.properties[editAttr.name]}`)
      : null;
  }
}
