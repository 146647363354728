import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAppSettings } from '../environment';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  private appSettings:IAppSettings;

  constructor(@Inject('environment') settings:IAppSettings) {
    this.appSettings = settings;
  }

  intercept(req:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
    const Req = req.clone({ url: this.createRequestUrl(req.url) });
    return next.handle(Req);
  }

  private createRequestUrl(url:string):string {
    const base_url:string = this.appSettings.BASE_URL;
    if (!base_url.length || /^(f|ht)tps?:\/\//i.test(url)) {
      return url;
    }

    // если нужно от хоста
    if (url[0] === '/') {
      const pathArray = base_url.split('/');
      const protocol = pathArray[0];
      const host = pathArray[2];
      return `${protocol}//${host}${url}`;
    }
    return `${base_url}/${url}`;
  }
}
