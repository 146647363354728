import { Injectable } from '@angular/core';
import { PluginClass } from '../classes/Plugin';
import { PluginConnect } from '../classes/PluginConnect';
import { IPlugin, IPluginInterface } from '../interfaces';

@Injectable()
export class ConnectionService {
  plugins = new Map<string, IPlugin>();
  notSupported:string[] = []; // компоненты, которые не поддерживаются в загруженном браузере

  addPlugin(name:string, plugin:IPlugin) {
    if (this.plugins.has(name)) { return; }
    this.plugins.set(name, plugin);
  }

  setConnects(connects:Map<string, PluginConnect[]>):Promise<string> {
    return new Promise(resolve => {
      Array.from(connects.keys()).forEach((key:string, int:number) => {
        // получаем компонент для добавления интерфейсов
        const plugIn:IPlugin = this.plugins.get(key);
        if (!plugIn) {
          return;
        }

        // Если не поддерживается браузером
        if (!(plugIn as PluginClass).isSupported) {
          this.destroyPlugin(plugIn);
          return;
        }

        // делаем соединения с плагинами
        connects.get(key).forEach((plug:PluginConnect) => {
          if (!this.plugins.has(plug.component)) { return; }

          const plugOut:IPlugin = this.plugins.get(plug.component);

          // Если не поддерживается браузером
          if (!(plugOut as PluginClass).isSupported) {
            return;
          }

          // находим интерфейс, соответствующий 'разъему'
          const plugOutInter:IPluginInterface = plugOut.getOuterface(plug.connectName);

          plugIn.addInterface(plug.connectName, plugOutInter);
        });

        //
        (plugIn as PluginClass).afterConnect();

        // когда прошли по всем
        if (int === Array.from(connects.keys()).length - 1) {
          resolve('done');
        }
      });
    });
  }

  getNotSupported() {
    return this.notSupported;
  }

  private destroyPlugin(plug:IPlugin) {
    const component:string = (plug as PluginClass).componentName;
    this.notSupported.push(component);
    plug.destroy();
  }
}
