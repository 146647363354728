import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {filter, map, mergeMap, scan, share, skipWhile, tap} from 'rxjs/operators';
import { ILayer } from '../interfaces';
import { LayersService } from '../services/layers.service';
import { MapService } from '../services/map.service';

const initialState:ILayer[] = [];

type ILayersOperation = (layers:ILayer[]) => ILayer[];

@Injectable()
export class LayersStore {
  public layersService:LayersService;
  public tables:ILayer[] = [];
  public selectedLayer:ILayer = null;

  private layers:ILayer[] = [];
  private baseMaps$:BehaviorSubject<ILayer[]> = new BehaviorSubject([]);
  private activeLayersList$:BehaviorSubject<ILayer[]> = new BehaviorSubject([]);
  private editLayers$:BehaviorSubject<ILayer[]> = new BehaviorSubject([]);
  private dopOptionsAreLoaded$:Subject<boolean> = new Subject();

  private changeLayerOptions$:Subject<ILayer> = new Subject<ILayer>();
  private selectedLayer$:Subject<ILayer> = new Subject<ILayer>();
  private layersChanged$ = new Subject<ILayer[]>();

  private legendRequestLayers:number[] = [];
  private dopInfoRequestLayers:number[] = [];
  private activeLayers:ILayer[] = [];
  private activeLayersHash:string = '';

  constructor(mapService:MapService, layerService:LayersService) {
    // все слои
    this.layersService = layerService;

    // изменнение св-в слоя
    this.changeLayerOptions$
      .pipe(
        map(
          (newLayer:ILayer):ILayersOperation => {
            return (state:ILayer[]):ILayer[] => {
              return state.map(function f(layer:ILayer) {
                if (layer.subLayers) {
                  layer.subLayers.map(f);
                }
                return layer.id === newLayer.id ? Object.assign(layer, newLayer) : layer;
              });
            };
          }
        )
      )
      .subscribe(() => this.layersChanged$.next(this.layers));

    //getting tables
    layerService.getTables().subscribe(tables => {
      this.tables = tables;
    });

    // получение активных слоёв
    let currentScale:number;

    combineLatest(this.layersChanged$, mapService.currentMapScale$)
      .pipe(
        map(data => {
          const layers:ILayer[] = data[0];
          currentScale = data[1];

          const active:ILayer[] = [];
          layers.forEach(function f(layer:ILayer) {
            if (!layer.visible && !layer.subLayers.length) {
              return;
            }
            if (layer.subLayers.length) {
              layer.subLayers.forEach(f);
              return;
            }
            active.push(layer);
          });
          return active;
        }),
        mergeMap(data => {
          const requestLayer:number[] = data.filter(item => this.legendRequestLayers.indexOf(item.id) === -1).map(item => item.id);
          this.legendRequestLayers = [...this.legendRequestLayers, ...requestLayer];
          return layerService.getLegend(data, requestLayer);
        }),
        map(layers =>
          layers
            .map(layer => {
              layer.notOnScale = layer.visible_scales.length && this.scaleInRange(layer.visible_scales, currentScale);
              this.legendRequestLayers = this.legendRequestLayers.filter(layerId => layerId !== layer.id);
              return layer;
            })
            .sort((a:ILayer, b:ILayer) => b.order - a.order)
        ),
        filter(layers => this.activeLayersHash !== this.getLayersHash(layers)),
        tap(layers => {
          this.activeLayers = layers;
          this.activeLayersHash = this.getLayersHash(layers);
        })
      )
      .subscribe(this.activeLayersList$);

    // подписываемся на активные слои, чтобы дозапросить необходимые данные
    // TODO: переписать
    this.activeLayersList$.subscribe((requestLayers:ILayer[]) => {
      // фильтруем слои, оставляя те, по которым запрос не был отправлен
      requestLayers = requestLayers.filter(item => this.dopInfoRequestLayers.indexOf(item.id) === -1);
      this.dopInfoRequestLayers = requestLayers.map(layer => layer.id);

      if (!requestLayers.length) {
        return;
      }

      layerService
        .getAttributes(requestLayers)
        .pipe(
          mergeMap(data => layerService.getExtent(requestLayers)),
          map((layersWithOpt:ILayer[]) => {
            layerService.getDictionaries(layersWithOpt);
            return layersWithOpt.map(layer => layer.id);
          })
        )
        .subscribe(layersIds => {
          this.dopInfoRequestLayers = this.dopInfoRequestLayers.filter(id => layersIds.indexOf(id) === -1);
          this.dopOptionsAreLoaded$.next(true);
        });
    });
  }

  getActiveLayers():Observable<ILayer[]> {
    return this.activeLayersList$.asObservable();
  }

  getSelectedLayer():Observable<ILayer> {
    return this.selectedLayer$.asObservable();
  }
  setSelectedLayer(layer:ILayer) {
    this.selectedLayer = layer;
    this.selectedLayer$.next(layer);
  }

  setLayers(layersAr:ILayer[]) {
    this.layers = layersAr;
    this.updateLayers();
  }

  updateLayer(layer:ILayer) {
    this.changeLayerOptions$.next(layer);
    this.activeLayersList$.next(this.activeLayers);
  }

  get layersChanged():Observable<ILayer[]> {
    return this.layersChanged$;
  }

  updateLayers() {
    let count = 0;
    this.walkLayersRecursive(this.layers, (layer:ILayer) => {
      if (!layer.isGroup) { count++; }
      this.setGroupChecked(layer);
      return true;
    });
    this.walkLayersRecursive(this.layers, (layer:ILayer) => {
      if (!layer.isGroup) { layer.order = count--; }
      return true;
    });
    this.layersChanged$.next(this.layers);
  }

  getBaseMaps():Observable<ILayer[]> {
    return this.baseMaps$.asObservable();
  }

  setBaseMaps(layersAr:ILayer[]) {
    this.baseMaps$.next(layersAr);
  }

  getEditLayers():Observable<ILayer[]> {
    return this.editLayers$.asObservable();
  }

  setEditLayers(layers:ILayer[]) {
    this.editLayers$.next(layers);
  }

  onOptionsLoaded():Observable<any> {
    return this.dopOptionsAreLoaded$.asObservable();
  }

  getLayersHash(layers:ILayer[]):string {
    return layers.reduce((acc, layer) => acc += layer.notOnScale ? '' : (',' + layer.id), '');
  }

  private setGroupChecked(layer:ILayer):boolean {
    if (layer.isGroup) {
      let checked = false;
      this.walkLayersRecursive(layer.subLayers, (subLayer:ILayer) => {
        checked = checked || (!subLayer.isGroup && subLayer.checked);
        return !checked;
      });
      layer.checked = checked;
    }
    return true;
  }

  // walk layers until callback return false
  public walkLayersRecursive(layers:ILayer[], callback:((layer:ILayer, layers:ILayer[]) => boolean)):boolean {
    let ret = true;
    for (const layer of layers) {
      if (callback(layer, layers)) {
        if (layer.subLayers && layer.subLayers[0]) {
          if (!this.walkLayersRecursive(layer.subLayers, callback)) {
            ret = false;
            break;
          }
        }
      } else {
        ret = false;
        break;
      }
    }
    return ret;
  }

  private scaleInRange(scales:number[], currentScale:number):boolean {
    let ret = false;
    for (let i = 0; i < scales.length - 1; i++) {
      ret = ret || scales[i] >= currentScale && scales[i + 1] <= currentScale;
    }
    return ret;
  }
}
