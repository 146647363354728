import { LatLng, TileLayer } from 'leaflet';
import { ILayerOptions, LayerType } from 'shared/interfaces';
import { Feature } from '../Feature';
import { WMSLayer } from '../LeafletLayer/WMSLayer.class';
import { Point } from '../Point';

export class WMTSLayer extends WMSLayer {
  type:LayerType = 'wmts';

  private _tileMatrixSet:string;
  private _tileMatrixPrefix:string;
  private _baseURL:string;

  constructor(options?:ILayerOptions) {
    super(options);
    this._tileMatrixSet = this._getTileMatrixSet();
    this._tileMatrixPrefix = this._getTileMatrixPrefix();
    this._baseURL = this.url.substring(0, this.url.search(/wmts/gi) + 'wmts'.length);
  }

  infoUrl(screenClick:Point):string {
    const zoom = this.map.getZoom();
    const latLngClick = this.map.containerPointToLatLng([screenClick.x, screenClick.y]);
    const { tileRow, tileCol } = this._latLngToTileCoords(latLngClick.lat, latLngClick.lng, zoom);

    const tileNWCoords = this._tileCoordsToLatLng(tileCol, tileRow, zoom);
    const tileNWContainerPoint = this.map.latLngToContainerPoint(tileNWCoords);
    const clickPositionInsideTile = new Point(
      screenClick.x - tileNWContainerPoint.x,
      screenClick.y - tileNWContainerPoint.y
    );

    const query_params:any = {
      SERVICE: 'WMTS',
      VERSION: '1.0.0',
      REQUEST: 'GetFeatureInfo',
      QUERY_LAYERS: this.layerName,
      LAYER: this.layerName,
      INFOFORMAT: 'application/json',
      I: clickPositionInsideTile.x,
      J: clickPositionInsideTile.y,
      TILEMATRIX: `${this._tileMatrixPrefix}${zoom}`,
      TILEROW: tileRow,
      TILECOL: tileCol,
      TILEMATRIXSET: this._tileMatrixSet
    };

    const params:string[] = [];

    for (const key in query_params) {
      params.push(`${key}=${query_params[key]}`);
    }

    return `${this._baseURL}?${params.join('&')}`;
  }

  protected createLayerInstance():Promise<any> {
    return new Promise((resolve, reject) => {
      this._layerInstance = this.buildLayerInstance();
      this._layerInstance.on('load', () => {
        this._layerInstance.off('load');
        resolve();
      });
    });
  }


  public buildLayerInstance():any {
    const options = { maxNativeZoom: this.maxNativeZoom, maxZoom: 22 };
    return new TileLayer(this.url, options);
  }

  protected prepareFeatures(data:any):Feature[] {
    return [];
  }

  private _latLngToTileCoords(lat:number, lon:number, zoom:number) {
    const layerPoint = this.map
      .project(new LatLng(lat, lon), zoom)
      .divideBy(256)
      .floor();
    return { tileRow: layerPoint.y, tileCol: layerPoint.x };
  }

  private _tileCoordsToLatLng(x:number, y:number, z:number):LatLng {
    const lng = (x / Math.pow(2, z)) * 360 - 180;
    const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);
    const lat = (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
    return new LatLng(lat, lng);
  }

  private _getTileMatrixSet():string {
    let tileMatrixSet:string;
    this.url.split('&').forEach((param:string) => {
      if (param.startsWith('TileMatrixSet')) {
        tileMatrixSet = param.split('=')[1];
      }
    });
    return tileMatrixSet;
  }

  private _getTileMatrixPrefix() {
    let tileMatrixPrefix:string;
    this.url.split('&').forEach((param:string) => {
      if (param.startsWith('TileMatrix')) {
        tileMatrixPrefix = param.split('=')[1].replace('{z}', '');
      }
    });
    return tileMatrixPrefix;
  }
}
