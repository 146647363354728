import { Component } from '@angular/core';
import { PluginClass } from '../../classes/Plugin';
import { Button } from '../../components/button/button.component';
import { IContainer, IContentChild, ILayer, IMenu, IPluginInterface } from '../../interfaces';
import { MapService } from '../../services/map.service';
import { LayersStore } from '../../stores/LayersStore';

@Component({
  selector: 'base-maps',
  templateUrl: 'base_maps.component.html',
  styleUrls: ['base_maps.component.less']
})
export class BaseMapsComponent extends PluginClass implements IContentChild {
  parentComponent:IContainer;
  noBasemap = false;
  baseMaps:ILayer[];

  active = false;

  protected btn:Button = null;

  private menu:IMenu;

  constructor(protected mapService:MapService, protected layersStore:LayersStore) {
    super();

    this.layersStore.getBaseMaps().subscribe(data => {
      this.baseMaps = data;
    });

    this.mapService.currentBaseMap$.subscribe(layer => {
      if (!layer) {
        this.noBasemap = true;
        return;
      }
      this.noBasemap = false;
    });
  }

  activate() {
    this.active = true;
  }

  deactivate() {
    this.active = false;
  }

  enable() {}

  disable() {}

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;
        this.menu.createBtn().then(btn => {
          this.btn = btn.instance;

          this.buttonConfig.onClickMe = () => {
            this.activate();
            if (this.parentComponent) {
              this.parentComponent.open();
            }
          };
          this.buttonConfig.onDeactivate = () => {
            this.deactivate();
            if (this.parentComponent) {
              this.parentComponent.close();
            }
          };
          this.btn.setOptions(this.buttonConfig);
          this.menu.addBtn(this.btn);
        });
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
    }
  }

  hideBaseMap() {
    if (!this.baseMaps) {
      return;
    }
    this.baseMaps.map((baseMap:ILayer) => (baseMap.visible = false));
    this.noBasemap = true;
    this.mapService.currentBaseMap$.next(null);
  }

  switchBaseMap(layer:ILayer) {
    this.baseMaps.forEach(baseMap => {
      if (baseMap !== layer) {
        baseMap.visible = false;
      }
    });
    layer.visible = true;
    this.mapService.setCurrentBaseMap(layer);
    this.noBasemap = false;
  }

  private close() {
    if (this.parentComponent) {
      this.parentComponent.close();
    }
  }

  private showMenu() {
    this.active = true;
  }
}
