import { IDrawLegend } from '../../interfaces/IDrawLegend';
import { StyleClass } from './StyleClass';

export class GroupStyle extends StyleClass {
  private styleAr:IDrawLegend[] = [];

  constructor() {
    super();
  }

  addStyle(style:IDrawLegend) {
    this.styleAr.push(style);
  }

  draw() {
    const canvas = this.canvas;
    const length = this.styleAr.length;
    if (!canvas || length === 0) { return; }
    canvas.width = this.iconSize * length + (length - 1) * 5;
    this.styleAr.forEach((icon:IDrawLegend, index:number) => {
      icon.canvas = canvas;
      // делаем отступы между иконками
      if (index > 0) {
        icon.startX = this.iconSize * index + index * 5;
      }
      icon.iconSize = this.iconSize;
      icon.draw();
    });
  }
}
