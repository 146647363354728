import {
  AfterContentInit, AfterViewInit,
  Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, QueryList, ViewChild, ViewChildren
} from '@angular/core';
import {KEYS} from 'angular-tree-component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'select-box',
  templateUrl: 'select-box.component.html',
  styleUrls: ['select-box.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectBoxComponent),
    multi: true
  }]
})
export class SelectBoxComponent implements ControlValueAccessor, AfterViewInit {

  @Input() placeholder = '';
  @Input() canSearch:boolean = true;
  @Input() nameAttr = 'name';
  @Input() iconAttr = 'icon';
  @Input() activeIconAttr = 'icon';
  @Input() showIconInDisplay = false;
  @Input() options:any[] = [];
  @Output() select = new EventEmitter<any>();
  //getting data while text input
  @Output() textChange = new EventEmitter<string>();
  @Output() focusIn = new EventEmitter();
  @Output() focusOut = new EventEmitter();

  @ViewChildren('popup') popups:QueryList<ElementRef>;
  @ViewChild('itemsContainer') itemsContainer:ElementRef;

  opened = false;

  private currentText:string = '';
  private _selectedItem:any;
  private popupMaxHeight:number;
  private intervalHandle:any;

  ngAfterViewInit() {
    this.popups.changes.subscribe(_ => {
      if (this.popups.length > 0) {
        const style = getComputedStyle(this.popups.first.nativeElement as HTMLElement);
        let sPopupMaxHeight = style.maxHeight;
        sPopupMaxHeight = sPopupMaxHeight.replace('px', '');
        this.popupMaxHeight = parseFloat(sPopupMaxHeight);
        this.intervalHandle = setInterval(() => this.setPopupHeight(), 100);
      } else {
        clearInterval(this.intervalHandle);
        this.intervalHandle = null;
      }
    });
  }

  get selectedItem():any {
    return this._selectedItem;
  }
  set selectedItem(item:any) {
    this._selectedItem = item;
    this.onChange(item);
  }

  getItemCssClass(item:any):string {
    return (item.className ? item.className : '') +
      ((this._selectedItem && item === this._selectedItem) ? ' select-box_item-selected' : '');
  }

  get displayText():string {
    let ret:string = '';
    if (this._selectedItem) {
      ret = this._selectedItem[this.nameAttr];
    }
    return ret.replace('<b>', '').replace('</b>', '');
  }

  get showClearButton():boolean {
    return this.canSearch && this._selectedItem;
  }

  getIconUrl(item:any):string {
    return item[this.iconAttr];
  }

  onClearClick() {
    this.selectedItem = null;
    this.select.emit(null);
  }

  onOpenClick() {
    this.opened = true;
  }

  onItemClick(item:any) {
    this.selectedItem = item;
    this.opened = false;
    this.select.emit(item);
  }

  onTextChange(event:KeyboardEvent) {
    let text = (event.target as HTMLInputElement).value;
    if (event.charCode > 0 && event.key) {
      text += event.key;
    }
    if (text !== this.currentText) {
      this.currentText = text;
      this.textChange.emit(text);
    }
  }

  onBlur() {
    setTimeout(() => this.focusOut.emit(), 0);
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value:any):void {
    this._selectedItem = value;
  }
  registerOnChange(fn:any):void {
    this.onChange = fn;
  }
  registerOnTouched(fn:any):void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled:boolean):void {
    throw new Error('Method not implemented.');
  }

  private setPopupHeight() {
    if (this.popups.length > 0) {
      const popupElement = this.popups.first.nativeElement as HTMLElement;
      const itemsContainer = this.itemsContainer.nativeElement as HTMLElement;
      const height = itemsContainer.clientHeight;
      if (height > this.popupMaxHeight) {
        popupElement.style.height = this.popupMaxHeight + 'px';
      } else {
        popupElement.style.height = 'auto';
      }
    } else {
      clearInterval(this.intervalHandle);
      this.intervalHandle = null;
    }
  }
}
