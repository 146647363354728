import { Pipe, PipeTransform } from '@angular/core';
import { IDictionary } from '../interfaces/IDictionary';
import { DictionaryService } from '../services/dictionary.service';

@Pipe({ name: 'dictval' })
export class DictValuePipe implements PipeTransform {
  constructor(public dictService:DictionaryService) {}

  transform(value:any, dictId:number):string | number {
    try {
      const dict:IDictionary = this.dictService.getDictionary(dictId);
      const dictVal:{ key:string; value:string } = dict.entries.find(item => item.key === `${value}`);
      return dictVal.value.length ? dictVal.value : value;
    } catch (e) {
      return value;
    }
  }
}
