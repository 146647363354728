import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(text:string, length:number):any {
    if (!text) {
      return;
    }
    if (text.length > length) {
      return `${text.substring(0, length)}...`;
    }
    return text;
  }
}
