import { TileLayer } from 'leaflet';
import { ILayerOptions, ILayerTime, LayerType, TimeInterval } from '../../interfaces';
import { WMSLayer } from '../LeafletLayer/WMSLayer.class';
import { Point } from '../Point';
import { Utils } from '../Utils';

export class WMSTimeLayer extends WMSLayer implements ILayerTime {
  type:LayerType = 'wms-t';

  timeBounds:TimeInterval;
  currentInterval:TimeInterval;
  popupChart:boolean;

  constructor(options?:ILayerOptions) {
    super(options);
  }

  get timeFilter() {
    if (!this.currentInterval || this.geomType === 'raster' || !this.columns) {
      return null;
    }
    const timeColumn = this.columns.find(col => col.timeField);
    if (!timeColumn) {
      return;
    }
    return (
      `${timeColumn.name} >= '${Utils.getDateString(this.currentInterval.start)}'` +
      `AND ${timeColumn.name} <= '${Utils.getDateString(this.currentInterval.end)}'`
    );
  }

  setDateFilter(period?:TimeInterval) {
    if (!period) { this.currentInterval = null; }
    this.currentInterval = period;
    this._layerInstance.setParams({
      time: `${Utils.getISOString(this.currentInterval.start)}/${Utils.getISOString(this.currentInterval.end)}`
    });
  }

  infoUrl(screenClick:Point) {
    const url = super.infoUrl(screenClick);
    let timeParam = '';
    if (!this.popupChart) {
      timeParam = `&time=${Utils.getISOString(this.currentInterval.start)}/${Utils.getISOString(
        this.currentInterval.end
      )}`;
    }
    return url + timeParam;
  }

  protected createLayerInstance():Promise<any> {
    return new Promise((resolve, reject) => {
      this._layerInstance = this.buildLayerInstance();
      this._layerInstance.on('load', () => {
        this._layerInstance.off('load');
        resolve();
      });
    });
  }

  public buildLayerInstance():any {
    const initOptions = {
      layers: this.layerName,
      format: 'image/png',
      transparent: true,
      maxZoom: 22,
      maxNativeZoom: this.maxNativeZoom,
      time: `${Utils.getISOString(this.currentInterval.start)}/${Utils.getISOString(this.currentInterval.end)}`
    };

    return new TileLayer.WMS(this.url, Object.assign(initOptions, this.params));
  }
}
