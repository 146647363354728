import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { Attribute, Feature } from '../../../';
import { IAppSettings } from '../../../environment';

@Component({
  selector: 'geo-feature-info',
  templateUrl: 'feature_info.component.html',
  styleUrls: ['feature_info.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureInfoComponent implements OnInit {
  @Input() feature:Feature;
  @Input() attributesToDisplay:Attribute[] = [];
  @Input() showEmptyAttributes = false;

  private appSettings:IAppSettings;

  constructor(private elementRoot:ElementRef, @Inject('environment') settings:IAppSettings) {
    this.appSettings = settings;
  }

  ngOnInit() {
    // хост сервера
    let host = this.appSettings.BASE_URL;
    let pos = host.indexOf('//');
    if (pos >= 0) {
      pos = host.indexOf('/', pos + 2);
      if (pos) {
        host = host.substring(0, pos);
      }
    }
    // Перехват клика на ссылку
    this.elementRoot.nativeElement.addEventListener(
      'click',
      evt => {
        const elt = evt.target;
        if (elt.tagName === 'A') {
          if (elt.href.indexOf('.m3u8') > 0) {
            // Перенаправление на видео плеер
            evt.preventDefault();
            const src = host + '/video/?src=' + encodeURIComponent(elt.href);
            window.open(src, '_blank');
          }
        }
      },
      true
    );
  }
}
