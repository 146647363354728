import {
  Circle,
  GeoJSON,
  HandlerSnap,
  Icon,
  Layer as LLayer,
  LayerEvent,
  LayerGroup,
  LeafletMouseEvent,
  Map,
  Marker,
  Polygon,
  Polyline,
  Rectangle,
  Snap,
  SnapEvent
} from 'leaflet';
import 'shared/assets/lib/Leaflet.Editable.js';
import { Feature } from '../../../classes/Feature';
import { Utils } from '../../../classes/Utils';
import { IBaseEdit, ICurrent, IEdit, IPluginInterface, ISearch, IVertexPosition } from '../../../interfaces';
import { MapUtils } from './MapUtils';

export class EditTool implements IBaseEdit, IPluginInterface {
  searchPlugin:ISearch;

  private _map:Map;
  private editable:any; // редактор текущего объекта
  private editPlugins:IEdit[] = [];
  private currentPlugins:ICurrent[] = [];
  private editing:boolean;
  private editableFeatures:LLayer[] = [];

  private _highlightMode = false; // подсветка вершин
  private highlightedVertexPosition:IVertexPosition = { objectIdx: null, vertexIdx: null };

  private featureTypes:any = {
    polygon: Polygon,
    multipolygon: Polygon,
    circle: Circle,
    rectangle: Rectangle,
    linestring: Polyline,
    polyline: Polyline,
    multilinestring: Polyline,
    point: Marker,
    multipoint: Marker
  };

  private withSnap = false; // флаг, что включен snapping
  private snapMarker:Marker;
  private snap:Snap;
  private snapLayer:LayerGroup;

  set map(map:Map) {
    this._map = map;
    this.setMapEvents();
  }

  get map():Map {
    return this._map;
  }

  get active() {
    return this.editing;
  }

  showEditableFeatures(features:Feature[]) {
    this.editing = true;

    for (const feature of features) {
      if (!this.featureTypes[feature.type]) {
        console.error(`Нет инструмента для отображения типа геометрии ${feature.type}.`);
        return;
      }

      this.editable = null;

      const coords = feature.geometry.coordinates;
      let newFeature;

      const location:Icon = new Icon({
        iconUrl: '/images/loc.png',
        iconSize: [25, 34], // size of the icon
        iconAnchor: [12, 34] // point of the icon which will correspond to marker's location
      });

      switch (feature.type) {
        case 'point':
          newFeature = new this.featureTypes[feature.type](coords as [number, number], {
            icon: location
          });
          break;
        case 'multipoint':
          // костыль для редактирования геометрии multipoint, состоящей из одной точки
          if (feature.geometry.coordinates.length === 1) {
            newFeature = new this.featureTypes[feature.type](coords[0] as [number, number], {
              icon: location
            });
            break;
          }

          // TODO: сделать редактирование мультиточек
          // сейчас поддерживается только редактирование координат

          // L.geoJSON чувствителен к регистру
          feature.geometry.type = 'MultiPoint';

          // Меняем местами координаты, чтобы соответствовать формату GeoJSON
          feature.invertCoordinates();

          newFeature = new GeoJSON(feature.geometry, {
            pointToLayer: (object, latlng) => {
              return new Marker(latlng, { icon: location });
            }
          });

          newFeature.addTo(this.map);
          this.editableFeatures.push(newFeature);

          // Возвращаем как было
          feature.invertCoordinates();
          return;
        default:
          newFeature = new this.featureTypes[feature.type](coords);
          newFeature.setStyle({ color: '#6289c4' });
          break;
      }

      newFeature.addTo(this.map);
      this.editableFeatures.push(newFeature);

      // Сохраняем редактор нового объекта для работы подсветки вершин
      this.editable = newFeature.enableEdit();

      // Курсор для перетаскивания объекта
      newFeature.on('mouseover', (event:LeafletMouseEvent) => {
        (event.originalEvent.target as HTMLElement).style.cursor = `url('/images/Cursor/move.cur'), move`;
      });
    }
  }

  removeEditedFeaturesByType(geometryType:String) {
    const featuresToStay = [];
    for (const featureLayer of this.editableFeatures) {
      if (geometryType === 'LineString' && featureLayer instanceof Polyline && !(featureLayer instanceof Polygon)) {
        this.map.removeLayer(featureLayer);
      } else {
        featuresToStay.push(featureLayer);
      }
    }
    this.editableFeatures = featuresToStay;
  }

  clearEditedFeatures() {
    this.editing = false;

    for (const featureLayer of this.editableFeatures) {
      this.map.removeLayer(featureLayer);
    }

    this.editableFeatures = [];
  }

  updateEditableFeatures(geometry:GeoJSON.DirectGeometryObject) {
    this.editPlugins.forEach(plugin => {
      plugin.updateEditableFeatures(geometry);
    });
  }

  // Подсветка вершины на редактируемом объекте
  highlightVertex(position:IVertexPosition) {
    if (!this.editable) { return; }

    const { vertexIdx, objectIdx } = position;
    this.highlightedVertexPosition = position;

    if (vertexIdx === null && objectIdx === null) {
      this.resetHighlight();
    } else {
      const vertexMarker = this.editable.vertexMarkers[objectIdx][vertexIdx];
      this.resetHighlight();

      // Подсветить выбранную вершину
      vertexMarker._icon.style.background = '#FFB13B';
      vertexMarker._icon.style.width = '15px';
      vertexMarker._icon.style.height = '15px';
      vertexMarker._icon.style.border = '2px solid #FDFDFD';
    }
  }

  set highlightMode(value:boolean) {
    if (value) {
      this._highlightMode = true;
      return;
    }
    this._highlightMode = false;
    this.highlightedVertexPosition = { objectIdx: null, vertexIdx: null };
  }

  addEditPlugin(plug:IEdit) {
    this.editPlugins.push(plug);
  }

  addCurrentPlugin(plug:ICurrent) {
    this.currentPlugins.push(plug);
  }

  startSnapping(layerName:string) {
    if (!this.snapLayer) {
      const snapLayer:LayerGroup = new LayerGroup([]).addTo(this.map);
      this.setSnapLayer(snapLayer);
    }

    this.withSnap = true;
    this.snap.watchMarker(this.snapMarker);

    if (!this.searchPlugin) { return; }
    // получение объектов в экстенте
    this.getFeaturesInBounds();
  }

  stopSnapping() {
    if (!this.snapLayer) {
      return;
    }
    this.snapLayer.clearLayers();
    this.snap.unwatchMarker(this.snapMarker);
    this.withSnap = false;
    const followMouse = (e:LeafletMouseEvent) => {
      this.snapMarker.setLatLng(e.latlng);
    };

    this.map.off('editable:vertex:dragstart', (e:SnapEvent) => {
      this.snap.watchMarker(e.vertex);
    });

    this.map.off('editable:vertex:dragend', (e:SnapEvent) => {
      this.snap.unwatchMarker(e.vertex);
    });

    this.map.off('editable:drawing:start', (e:LeafletMouseEvent) => {
      this.map.off('mousemove', followMouse);
    });

    this.map.off('editable:drawing:end', (e:LeafletMouseEvent) => {
      this.map.off('mousemove', followMouse);
      this.snapMarker.remove();
    });

    this.map.off('editable:drawing:click', (e:LeafletMouseEvent) => {
      // Leaflet copy event data to another object when firing,
      // so the event object we have here is not the one fired by
      // Leaflet.Editable; it's not a deep copy though, so we can change
      // the other objects that have a reference here.
      const latlng = this.snapMarker.getLatLng();
      e.latlng.lat = latlng.lat;
      e.latlng.lng = latlng.lng;
    });

    this.snapMarker.off('snap', () => {
      this.snapMarker.addTo(this.map);
    });

    this.snapMarker.off('unsnap', () => {
      this.snapMarker.remove();
    });
  }

  // Сбросить подсветку всех вершин на дефолтную
  private resetHighlight() {
    this.editable.vertexMarkers.forEach((object:Marker[]) => {
      object.forEach((marker:any) => {
        marker._icon.style.background = '#548ABF';
        marker._icon.style.width = '12px';
        marker._icon.style.height = '12px';
        marker._icon.style.border = '2px solid #FFFFFF';
      });
    });
  }

  // Snapping
  // TODO: расширить typings для Snapping, чтобы не писать any
  private setSnapLayer(layer:LayerGroup) {
    this.snapLayer = layer;
    this.snap = new HandlerSnap(this.map).MarkerSnap(this.map);
    this.snap.addGuideLayer(layer);

    this.snapMarker = new Marker(this.map.getCenter(), {
      icon: this.map.createVertexIcon({
        className:
          'leaflet-marker-icon measure-line-div-icon leaflet-zoom-hide leaflet-interactive leaflet-marker-draggable'
      }),
      opacity: 1,
      zIndexOffset: 1000
    });
  }

  private getFeaturesInBounds() {
    this.snapLayer.clearLayers();
    const bounds:Polygon = MapUtils.createPolygonFromBounds(this._map.getBounds());
    const wkt:string = Utils.geojsonToWKT(bounds.toGeoJSON());

    this.searchPlugin.searchInWkt(wkt).then(data => {
      // наносим на слой результаты поиска
      data.forEach((result:Feature) => {
        const icon = new Icon.Default();
        icon.options.shadowSize = [0, 0];
        this.snapLayer.addLayer(
          new GeoJSON(result.geometry, {
            pointToLayer: (object, latlng) => new Marker(latlng, { icon })
          })
        );
      });

      this.snapEventsOn();
    });
  }

  private snapEventsOn() {
    const followMouse = (e:LeafletMouseEvent) => {
      this.snapMarker.setLatLng(e.latlng);
    };
    // при изменении экстента карты запрашивать новые объекты для 'прилипания'
    this.map.on('moveend ', () => {
      if (!this.withSnap) {
        return;
      }
      this.getFeaturesInBounds();
    });

    // в editable:vertex:dragstart и editable:vertex:dragend
    // проверяем на наличие флага, так как map.off
    // не отлючает эти события
    this.map.on('editable:vertex:dragstart', (e:any) => {
      if (!this.withSnap) {
        return;
      }
      (this.snap as any).watchMarker(e.vertex);
    });

    this.map.on('editable:vertex:dragend', (e:any) => {
      if (!this.withSnap) { return; }
      (this.snap as any).unwatchMarker(e.vertex);
    });

    this.map.on('editable:drawing:start', (e:LeafletMouseEvent) => {
      this.map.on('mousemove', followMouse);
    });

    this.map.on('editable:drawing:end', (e:LeafletMouseEvent) => {
      this.map.off('mousemove', followMouse);
      (this.snapMarker as any).remove();
    });

    this.map.on('editable:drawing:click', (e:LeafletMouseEvent) => {
      // Leaflet copy event data to another object when firing,
      // so the event object we have here is not the one fired by
      // Leaflet.Editable; it's not a deep copy though, so we can change
      // the other objects that have a reference here.
      const latlng = this.snapMarker.getLatLng();
      e.latlng.lat = latlng.lat;
      e.latlng.lng = latlng.lng;
    });

    this.snapMarker.on('snap', () => {
      this.snapMarker.addTo(this.map);
    });
    this.snapMarker.on('unsnap', () => {
      this.snapMarker.remove();
    });
  }

  private setMapEvents() {
    this.map.on('editable:vertex:dragend editable:dragend', (e:LayerEvent) => {
      if (!this.editing) { return; }
      const geometry = this.getGeoJSONGeometry(e);
      this.updateEditableFeatures(geometry);

      // Если была подсвечена вершина, сохраняем подсветку
      if (
        (this._highlightMode && this.highlightedVertexPosition && this.highlightedVertexPosition.objectIdx !== null) ||
        this.highlightedVertexPosition.vertexIdx !== null
      ) {
        this.highlightVertex(this.highlightedVertexPosition);

        // Сообщить индекс вершины плагинам, которым нужен объект
        this.currentPlugins.forEach(plugin => {
          plugin.vertexPosition = this.highlightedVertexPosition;
        });
      }
    });

    this.map.on('editable:vertex:dragstart', (e:SnapEvent) => {
      if (!this.editing) { return; }
      if (this._highlightMode) {
        this.highlightedVertexPosition = {
          vertexIdx: e.vertex.index,
          objectIdx: e.vertex.objectIdx
        };
        this.highlightVertex(this.highlightedVertexPosition);

        // Сообщить позицию вершины плагинам, которым нужен объект
        this.currentPlugins.forEach(plugin => {
          plugin.vertexPosition = this.highlightedVertexPosition;
        });
      }
    });

    this.map.on('editable:vertex:deleted', (e:LayerEvent) => {
      if (!this.editing) { return; }
      const geometry = this.getGeoJSONGeometry(e);
      this.updateEditableFeatures(geometry);

      // При удалении вершины сбрасываем подсветку
      this.highlightedVertexPosition = null;
    });
  }

  private getGeoJSONGeometry(event:LayerEvent) {
    const geometry:any = (event.layer as any).toGeoJSON().geometry;

    // Удаляем последние точки из частей полигонов, т.к. они дублируют первые
    if (geometry.type === 'Polygon') {
      geometry.coordinates.forEach((polyPart:any[]) => {
        polyPart.pop();
      });
    } else if (geometry.type === 'MultiPolygon') {
      geometry.coordinates.forEach((poly:any[]) => {
        poly.forEach((part:any[]) => {
          part.pop();
        });
      });
    }

    return geometry;
  }
}
