import {GroupVisibleState, ILayer, ILayerLegend, ILayerOptions, LayerType, TimeInterval} from 'shared/interfaces';
import { WMSLayer } from './WMSLayer.class';
import Timeout = NodeJS.Timeout;
import {ArcGISLayer, Attribute, Bounds, DataFilter, GeoJsonLayer, TMSLayer, WMSTimeLayer} from 'shared/classes';
import { Map } from 'leaflet';
import {JoinedTable} from '../JoinedTable';
import {GeoJsonObject} from 'geojson';
import {MarkerClusterLayer} from './MarkerClusterLayer.class';
import {Observable} from 'rxjs';
import {MarkerDynamicLayer} from './MarkerDynamicLayer.class';

//wrapper around any layer except for NativeLayer
export class LayerWrapper implements ILayer {
  private layer:ILayer;

  constructor(layer:ILayer) {
    this.layer = layer;
    NativeLayerRenderer.instance.add(this);
  }

  get innerLayer():ILayer {
    return this.layer;
  }

  get map():Map {
    return this.layer.map;
  }
  set map(map:Map) {
    this.layer.map = map;
  }
  get uuid():string {
    return this.layer.uuid;
  }
  set uuid(id:string) {
    this.layer.uuid = id;
  }
  get id():number {
    return this.layer.id;
  }
  set id(id:number) {
    this.layer.id = id;
  }
  get serviceId():number {
    return this.layer.serviceId;
  }
  set serviceId(id:number) {
    this.layer.serviceId = id;
  }
  get name():string {
    return this.layer.name;
  }
  set name(name:string) {
    this.layer.name = name;
  }
  get type():LayerType {
    return this.layer.type;
  }
  set type(type:LayerType) {
    this.layer.type = type;
  }
  set extent(extent:Bounds) {
    this.layer.extent = extent;
  }
  get extent():Bounds {
    return this.layer.extent;
  }
  get url():string {
    return this.layer.url;
  }
  set url(url:string) {
    this.layer.url = url;
  }
  get tileSize():number {
    return this.layer.tileSize;
  }
  set tileSize(tileSize:number) {
    this.layer.tileSize = tileSize;
  }
  get layerName():string {
    return this.layer.url;
  }
  set layerName(layerName:string) {
    this.layer.layerName = layerName;
  }
  get maxNativeZoom():number {
    return this.layer.maxNativeZoom;
  }
  set maxNativeZoom(maxNativeZoom:number) {
    this.layer.maxNativeZoom = maxNativeZoom;
  }
  get order():number {
    return this.layer.order;
  }
  set order(order:number) {
    this.layer.order = order;
    NativeLayerRenderer.instance.refresh();
  }
  applyOrder(order:number) {
    this.layer.order = order;
  }
  get brightness():number {
    return this.layer.brightness;
  }
  set brightness(brightness:number) {
    this.layer.brightness = brightness;
  }
  get opacity():number {
    return this.layer.opacity;
  }
  set opacity(opacity:number) {
    this.layer.opacity = opacity;
  }
  get contrast():number {
    return this.layer.contrast;
  }
  set contrast(contrast:number) {
    this.layer.contrast = contrast;
  }
  get parentLayer():ILayer {
    return this.layer.parentLayer;
  }
  set parentLayer(parentLayer:ILayer) {
    this.layer.parentLayer = parentLayer;
  }
  get legend():ILayerLegend[] {
    return this.layer.legend;
  }
  set legend(legend:ILayerLegend[]) {
    this.layer.legend = legend;
  }
  get hideLegend():boolean {
    return this.layer.hideLegend;
  }
  set hideLegend(flag:boolean) {
    this.layer.hideLegend = flag;
  }
  get showTooltip():boolean {
    return this.layer.showTooltip;
  }
  set showTooltip(flag:boolean) {
    this.layer.showTooltip = flag;
  }
  get inTooltipDefault():boolean {
    return this.layer.inTooltipDefault;
  }
  set inTooltipDefault(flag:boolean) {
    this.layer.inTooltipDefault = flag;
  }
  get backColor():string {
    return this.layer.backColor;
  }
  set backColor(s:string) {
    this.layer.backColor = s;
  }
  get columns():Attribute[] {
    return this.layer.columns;
  }
  set columns(columns:Attribute[]) {
    this.layer.columns = columns;
  }
  get subLayers():ILayer[] {
    return this.layer.subLayers;
  }
  set subLayers(subLayers:ILayer[]) {
    this.layer.subLayers = subLayers;
  }
  get joinedTables():JoinedTable[] {
    return this.layer.joinedTables;
  }
  set joinedTables(joinedTables:JoinedTable[]) {
    this.layer.joinedTables = joinedTables;
  }
  get groupVisibleState():GroupVisibleState {
    return this.layer.groupVisibleState;
  }
  set groupVisibleState(groupVisibleState:GroupVisibleState) {
    this.layer.groupVisibleState = groupVisibleState;
  }
  get simple():boolean {
    return this.layer.simple;
  }
  set simple(simple:boolean) {
    this.layer.simple = simple;
  }
  get searchable():boolean {
    return this.layer.searchable;
  }
  set searchable(searchable:boolean) {
    this.layer.searchable = searchable;
  }
  get editable():boolean {
    return this.layer.editable;
  }
  set editable(editable:boolean) {
    this.layer.editable = editable;
  }
  get crowdsource():boolean {
    return this.layer.crowdsource;
  }
  set crowdsource(crowdsource:boolean) {
    this.layer.crowdsource = crowdsource;
  }
  get visible():boolean {
    return this.layer.visible;
  }
  set visible(visible:boolean) {
    this.layer.visible = visible;
  }
  get checked():boolean {
    return this.layer.checked;
  }
  set checked(checked:boolean) {
    this.layer.checked = checked;
  }
  get isGroup():boolean {
    return this.layer.isGroup;
  }
  set isGroup(isGroup:boolean) {
    this.layer.isGroup = isGroup;
  }
  get isBaseLayer():boolean {
    return this.layer.isBaseLayer;
  }
  set isBaseLayer(isBaseLayer:boolean) {
    this.layer.isBaseLayer = isBaseLayer;
  }
  get notOnScale():boolean {
    return this.layer.notOnScale;
  }
  set notOnScale(notOnScale:boolean) {
    this.layer.notOnScale = notOnScale;
  }
  get visible_scales():number[] {
    return this.layer.visible_scales;
  }
  set visible_scales(visible_scales:number[]) {
    this.layer.visible_scales = visible_scales;
  }
  get popupImage():boolean {
    return this.layer.popupImage;
  }
  set popupImage(popupImage:boolean) {
    this.layer.popupImage = popupImage;
  }
  get popupChart():boolean {
    return this.layer.popupChart;
  }
  set popupChart(popupChart:boolean) {
    this.layer.popupChart = popupChart;
  }
  get attribution():string {
    return this.layer.attribution;
  }
  set attribution(attribution:string) {
    this.layer.attribution = attribution;
  }
  get pk():string {
    return this.layer.pk;
  }
  set pk(pk:string) {
    this.layer.pk = pk;
  }
  get schema():string {
    return this.layer.schema;
  }
  set schema(schema:string) {
    this.layer.schema = schema;
  }
  get table():string {
    return this.layer.table;
  }
  set table(table:string) {
    this.layer.table = table;
  }
  get geomType():string {
    return this.layer.geomType;
  }
  set geomType(geomType:string) {
    this.layer.geomType = geomType;
  }
  get datasource_id():number {
    return this.layer.datasource_id;
  }
  set datasource_id(datasource_id:number) {
    this.layer.datasource_id = datasource_id;
  }
  get mapnikDatasourceId():number {
    return this.layer.mapnikDatasourceId;
  }
  set mapnikDatasourceId(mapnikDatasourceId:number) {
    this.layer.mapnikDatasourceId = mapnikDatasourceId;
  }
  get mapnikId():number {
    return this.layer.mapnikId;
  }
  set mapnikId(mapnikId:number) {
    this.layer.mapnikId = mapnikId;
  }
  get filter():string {
    return this.layer.filter;
  }
  set filter(filter:string) {
    this.layer.filter = filter;
  }
  get dataFilter():DataFilter {
    return this.layer.dataFilter;
  }
  set dataFilter(dataFilter:DataFilter) {
    this.layer.dataFilter = dataFilter;
  }
  get legendUrl():string {
    return this.layer.legendUrl;
  }
  set legendUrl(legendUrl:string) {
    this.layer.legendUrl = legendUrl;
  }
  get image():string {
    return this.layer.image;
  }
  set image(image:string) {
    this.layer.image = image;
  }
  get isEdited():boolean {
    return this.layer.isEdited;
  }
  set isEdited(isEdited:boolean) {
    this.layer.isEdited = isEdited;
  }
  get timeBounds():TimeInterval {
    return (this.layer as WMSTimeLayer).timeBounds;
  }
  set timeBounds(value:TimeInterval) {
    (this.layer as WMSTimeLayer).timeBounds = value;
  }
  get currentInterval():TimeInterval {
    return (this.layer as WMSTimeLayer).currentInterval;
  }
  set currentInterval(value:TimeInterval) {
    (this.layer as WMSTimeLayer).currentInterval = value;
  }

  get currentVersion():number {
    return this.layer.currentVersion;
  }
  set currentVersion(v:number) {
    this.layer.currentVersion = v;
  }
  get arcgisId():number {
    return this.layer.arcgisId;
  }
  set arcgisId(v:number) {
    this.layer.arcgisId = v;
  }

  get timeFilter() {
    return (this.layer as WMSTimeLayer).timeFilter;
  }

  get rating() {
    return this.layer.rating;
  }

  setDateFilter(period?:TimeInterval) {
    (this.layer as WMSTimeLayer).setDateFilter(period);
  }

  getAttribution():string {
    return this.layer.getAttribution();
  }
  getAttributes():Attribute[] {
    return this.layer.getAttributes();
  }
  refresh():void {
    this.layer.refresh();
  }
  getLayerInstance():any {
    return this.layer.getLayerInstance();
  }
  buildLayerInstance():any {
    return this.layer.buildLayerInstance();
  }
  createExtent(value:string):void {
    this.layer.createExtent(value);
  }
  setData(dataLayers:any[]) {
    (this.layer as MarkerClusterLayer).setData(dataLayers);
  }
  set dataFunction(func:(layer:MarkerClusterLayer) => Observable<void>) {
    (this.layer as MarkerDynamicLayer).dataFunction = func;
  }
  set dataFunctionContext(context:any) {
    (this.layer as MarkerDynamicLayer).dataFunctionContext = context;
  }

  getLegendUrl() {
    return (this.layer as ArcGISLayer).getLegendUrl();
  }
}

//WMS layer from geoanalitika server
export class NativeLayer extends WMSLayer {
  public singleWms:boolean = false;

  constructor(options?:ILayerOptions) {
    super(options);
    // this.singleWms = this.geomType === 'point';
  }

  protected applyVisible(visible:boolean) {
    if (this.singleWms) {
      this.refresh();
    } else {
      NativeLayerRenderer.instance.refresh();
    }
  }

  get brightness():number {
    return this._brightness;
  }
  set brightness(value:number) {
    const isChanged = this._brightness !== value;
    this._brightness = value;
    this.changeSingleWms(isChanged);
  }

  get contrast():number {
    return this._contrast;
  }
  set contrast(value:number) {
    const isChanged = this._contrast !== value;
    this._contrast = value;
    this.changeSingleWms(isChanged);
  }

  get opacity():number {
    return this._opacity;
  }
  set opacity(value:number) {
    const isChanged = this._opacity !== value;
    this._opacity = value;
    this.changeSingleWms(isChanged);
  }

  get filter():string {
    return this._filter;
  }
  set filter(filter:string) {
    const isChanged = this._filter !== filter;
    this._filter = filter;
    this.changeSingleWms(isChanged);
  }

  get order():number {
    return this._order;
  }
  set order(value:number) {
    this._order = value;
    NativeLayerRenderer.instance.refresh();
  }

  changeSingleWms(refreshLayer:boolean) {
    const isSingleWms:boolean = this._brightness !== 0 || this._contrast !== 0 ||
      this._opacity !== 0 || !!this.filter; // || this.geomType === 'point';
    if (!!this.singleWms !== isSingleWms) {
      this.singleWms = isSingleWms;
      NativeLayerRenderer.instance.refresh();
    } else if (refreshLayer) {
      this.refresh();
    }
  }

  refresh() {
    NativeLayerRenderer.instance.refreshLayer(this);
  }

  protected createLayerInstance():Promise<any> {
    return new Promise(resolve => {
      this._layerInstance = this.buildLayerInstance();
      resolve();
    });
  }

  public buildLayerInstance():any {
    NativeLayerRenderer.instance.add(this);
    return null;
  }

  public buildLayerInstanceBase():any {
    return super.buildLayerInstance();
  }
}

export class NativeLayerRenderer {
  static readonly instance = new NativeLayerRenderer();

  private layers:ILayer[] = [];
  //list of grouping layers
  private renderLayersMap:{[key:number]:WMSLayer} = {};
  private timeoutHandle:any = null;

  public add(layer:ILayer) {
    this.layers.push(layer);
  }

  public refreshLayer(layer:NativeLayer) {
    const wmsLayer = this.renderLayersMap[layer.id];
    if (wmsLayer) {
      wmsLayer.visible = layer.visible;
      wmsLayer.filter = layer.filter;
      wmsLayer.opacity = layer.opacity;
      wmsLayer.brightness = layer.brightness;
      wmsLayer.contrast = layer.contrast;
      wmsLayer.refresh();
    }
  }

  public refresh() {
    //timeout swallows repeated calls
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
    this.timeoutHandle = setTimeout(() => {
      this.timeoutHandle = null;
      //full refresh
      this._refresh();
    }, 600);
  }

  private _refresh() {
    let layersParam = '';
    let wmsLayer:WMSLayer;
    let originalOptions:ILayerOptions;
    //remove layers from map
    for (const attr in this.renderLayersMap) {
      this.renderLayersMap[attr].visible = false;
    }
    this.renderLayersMap = {};
    const activeLayers = this.layers.filter(lr => lr.checked);
    let order = activeLayers.length;
    activeLayers.sort((a:ILayer, b:ILayer) => b.order - a.order);
    for (let i = 0; i < activeLayers.length; i++) {
      const layer = activeLayers[i];
      if (layer.visible) {
        if (layer instanceof NativeLayer && layer.singleWms || layer instanceof LayerWrapper) {
          //end native layers sequence
          if (layersParam) {
            //finish with previous not single wms layers
            this.addRenderLayer(layersParam, originalOptions, order);
          }
          if (layer instanceof NativeLayer && layer.singleWms) {
            //add single wms layer
            const options = this.mixinLayerOptions(layer);
            options.order = order;
            options.layerName = options.mapnikId.toString();
            options.visible = true;
            wmsLayer = new WMSLayer(Object.assign({}, options));
            wmsLayer.filter = layer.filter;
            this.renderLayersMap[layer.id] = wmsLayer;
          } else if (layer instanceof LayerWrapper) {
            layer.applyOrder(order);
          }
          layersParam = '';
        } else if (layer instanceof NativeLayer) {
          originalOptions = layer.originalOptions;
          layersParam += layer.mapnikId.toString() + ',';
        }
      }
      order--;
    }
    if (layersParam) {
      originalOptions.visible = true;
      this.addRenderLayer(layersParam, originalOptions, order);
    }
  }

  addRenderLayer(layersParam:string, layerOptions:ILayerOptions, order:number) {
    const options = Object.assign({}, layerOptions, {order: order});
    if (layersParam.endsWith(',')) {
      layersParam = layersParam.substr(0, layersParam.length - 1);
    }
    options.layerName = layersParam;
    options.visible = true;
    const wmsLayer = new WMSLayer(Object.assign({}, options));
    //wmsLayer.refreshLayerInstance();
    //wmsLayer.refresh();
    this.renderLayersMap[wmsLayer.id] = wmsLayer;
  }

  mixinLayerOptions(layer:TMSLayer):ILayerOptions {
    const options = Object.assign({}, layer.originalOptions);
    options.brightness = layer.brightness;
    options.contrast = layer.contrast;
    options.opacity = layer.opacity;
    return options;
  }
}
