import { config as local_settings } from '../local_env_settings';

const urlArr = window.location.href.split('/');

let options = {
  BASE_URL: '',
  HOST: '',
  API_KEY: '',
  PLUGINS_URL: 'plugins.json',
  PROJECT_SLUG: urlArr[urlArr.length - 2],
  FILE_SIZE: 10485760, // 10Mb in bytes
  DOCUMENT_SIZE: 1073741824, // 1Gb in bytes
  IMAGE_FORMATS: ['png', 'jpeg', 'gif', 'jpg'],
  ARCHIVE_FORMATS: ['zip'],
  DOCUMENT_FORMATS: ['pdf', 'txt', 'doc', 'docx', 'xls']
};

if (process.env.NODE_ENV !== 'production') {
  options = Object.assign(options, local_settings);
}

export let Environment = options;

export interface IAppSettings {
  BASE_URL:string;
  HOST:string;
  API_KEY:string;
  PROJECT_SLUG:string;
  PROJECT_IMAGE:string;
  PROJECT_NAME:string;
  readonly PLUGINS_URL:string;
  readonly FILE_SIZE:number;
  readonly IMAGE_FORMATS:string[];
  readonly ARCHIVE_FORMATS:string[];
  readonly DOCUMENT_FORMATS:string[];
  readonly DOCUMENT_SIZE:number;
}

export interface AppData {
  plugins:any[];
  environment:{
    project:{
      slug:string;
    };
  };
}
