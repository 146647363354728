import { Map, TileLayer } from 'leaflet';
import {GroupVisibleState, ILayer, ILayerLegend, ILayerOptions, LayerType} from '../../interfaces';
import { Attribute } from '../Attribute';
import { Bounds } from '../Bounds';
import { DataFilter } from '../FilterGenerator';
import { Point } from '../Point';
import { Utils } from '../Utils';

export class TMSLayer implements ILayer {
  uuid = Math.floor(Math.random() * 1000000).toString();

  id:number;
  url = '';
  name:string;
  type:LayerType = 'tms';
  subLayers:ILayer[] = [];
  map:Map;
  parentLayer:ILayer;
  extent:Bounds;
  attribution = '';
  isGroup = false; // флаг, что это группа
  isBaseLayer = false; // флаг, что это подложка
  layerName:string;
  simple:boolean;
  searchable = false;
  tileSize:number;
  maxNativeZoom = 18;
  rating = false;

  notOnScale = false;
  visible_scales:number[] = [];
  hideLegend = false;
  backColor = '#f4f4f4';
  showTooltip = true;
  inTooltipDefault = true;

  pk:string;
  schema:string;
  table:string;
  datasource_id:number;
  geomType:string;
  mapnikDatasourceId:number;
  mapnikId:number;

  isEdited = false;
  editable = false;
  popupImage = false;
  crowdsource = false;
  crowdsourceBtnText:string;

  dataFilter:DataFilter;
  originalOptions:ILayerOptions;
  serviceId:number;
  currentVersion:number;
  arcgisId:number;

  protected _visible:boolean;
  protected _checked:boolean;
  protected _order:number;
  protected _opacity = 0;
  protected _brightness = 0;
  protected _contrast = 0;
  protected _columns:Attribute[] | null = null;

  protected _legend:ILayerLegend[];
  protected _filter:string;

  // TileLayer | FeatureGroup
  protected _layerInstance:any;

  constructor(options?:any) {
    this.originalOptions = options;
    const optionsClone = Object.assign({}, options);
    const extent:string = optionsClone.extent;
    const visible:boolean = optionsClone.visible || false;
    const opacity:number = optionsClone.opacity || 0;
    const brightness:number = optionsClone.brightness || 0;
    const contrast:number = optionsClone.contrast || 0;
    let order:number = optionsClone.order || 1;

    delete optionsClone.extent;
    delete optionsClone.visible;
    delete optionsClone.opacity;
    delete optionsClone.brightness;
    delete optionsClone.contrast;
    delete optionsClone.order;

    Utils.mixin(this, optionsClone);

    this.createLayerInstance().then(() => {
      if (!this._layerInstance) {
        return;
      }
      this.brightness = brightness;
      this.contrast = contrast;

      this.createExtent(extent);
    });

    // если это подложка меняем zIndex
    if (this.isBaseLayer) {
      order = 0;
    }

    this.visible = visible;
    this.opacity = opacity;
    this.order = order;
  }

  get groupVisibleState():GroupVisibleState {
    let count = 0;
    this.subLayers.forEach((layer:ILayer) => {
      if (layer.isGroup) {
        count += layer.groupVisibleState;
        return;
      }

      if (layer.visible) {
        count++;
      }
    });

    if (count === 0) {
      return GroupVisibleState.none;
    } else if (this.subLayers.length > 0 && count === this.subLayers.length) {
      return GroupVisibleState.all;
    } else {
      return GroupVisibleState.some;
    }
  }

  getAttribution() {
    return this.attribution;
  }

  setDataFilter(dataFilter:DataFilter) {
    this.dataFilter = dataFilter;
  }

  get filter() {
    return this._filter;
  }

  set filter(filter:string) {
    if (!filter || !filter.length) {
      this._filter = null;
      this._layerInstance.setUrl(this.url);
    } else {
      this._filter = filter;
      const newUrl = `${this.url}?sqlFilter=${encodeURIComponent(filter)}`;
      this._layerInstance.setUrl(newUrl);
    }
  }

  get opacity() {
    return this._opacity;
  }

  set opacity(value:number) {
    if (!this._layerInstance) {
      return;
    }
    this._layerInstance.setOpacity((100 - value) / 100);
    this._opacity = value;
  }

  get contrast() {
    return this._contrast;
  }

  set contrast(value:number) {
    this._contrast = value;
    this.createFilterStyle();
  }

  get brightness() {
    return this._brightness;
  }

  set brightness(value:number) {
    this._brightness = value;
    this.createFilterStyle();
  }

  get legend() {
    return this._legend;
  }

  set legend(legend:ILayerLegend[]) {
    this._legend = legend;
  }

  get checked() {
    return this._checked;
  }

  set checked(value) {
    this._checked = value;
    this.visible = value;
  }

  get visible() {
    return this._visible;
  }

  set visible(value) {
    this._checked = value;
    // если хоть 1 из предков выключен, выключаем слой
    if (value) {
      value = this.parentsVisible;
    }

    this._visible = value;
    this.toggleChildVisible(this, value);

    if (!this.isGroup) {
      this.applyVisible(value);
      if (value) {
        this.createFilterStyle();
      }
    }
  }

  get order() {
    return this._order;
  }

  set order(value:number) {
    this._order = value;
    if (this._layerInstance) {
      this._layerInstance.setZIndex(value);
    }
  }

  refresh() {
    if (this.type === 'wms' || this.type === 'tms') {
      const timestamp:number = Date.now();
      let newUrl = `${this.url}?random=${timestamp}`;

      if (this.filter) {
        newUrl += `&sqlFilter=${encodeURIComponent(this.filter)}`;
      }

      this._layerInstance.setUrl(newUrl);
    }
  }

  getAttributes() {
    return this.columns;
  }

  getLayerInstance():TileLayer {
    return this._layerInstance;
  }

  get columns():Attribute[] {
    return this._columns;
  }

  set columns(colums:Attribute[]) {
    if (!colums) {
      return;
    }
    this._columns = [];
    colums.forEach(item => {
      const attr:Attribute = new Attribute();
      Utils.mixin(attr, item);
      this._columns.push(attr);
    });
  }

  createExtent(extent:string | Bounds) {
    if (!extent) {
      return;
    }
    if (extent instanceof Bounds) {
      this.extent = extent;
      return;
    }
    const coords:string[] = extent.split(',');
    const southWest:Point = new Point(Number(coords[0]), Number(coords[1])),
      northEast:Point = new Point(Number(coords[2]), Number(coords[3])),
      southEast:Point = new Point(Number(coords[2]), Number(coords[1])),
      northWest:Point = new Point(Number(coords[0]), Number(coords[1]));
    this.extent = new Bounds(northWest, southWest, southEast, northEast);
  }

  protected applyVisible(visible:boolean) {
    if (this._layerInstance) {
      if (visible) {
        this.map.addLayer(this._layerInstance);
      } else {
        this.map.removeLayer(this._layerInstance);
      }
    }
  }

  protected createLayerInstance():Promise<any> {
    return new Promise((resolve, reject) => {
      this._layerInstance = this.buildLayerInstance();
      this._layerInstance.on('load', () => {
        this._layerInstance.off('load');
        resolve();
      });
    });
  }

  public buildLayerInstance():any {
    let options = {};
    if (this.url.indexOf('maps.yandex') > -1) {
      options = { maxNativeZoom: this.maxNativeZoom, maxZoom: 22 , subdomains: ['01', '02', '03', '04']};
    } else {
      options = { maxNativeZoom: this.maxNativeZoom, maxZoom: 22 };
    }
    return new TileLayer(this.url, options);
  }

  protected toggleChildVisible(layer:ILayer, visible:boolean):void {
    const childLayers = layer.subLayers;
    if (childLayers.length) {
      for (const childLayer of childLayers) {
        if (childLayer.isGroup) {
          return;
        }

        if (visible) {
          childLayer.visible = visible;
          this.toggleChildVisible(childLayer, visible);
        } else {
          childLayer.visible = visible;
        }
      }
    }
  }

  protected createFilterStyle() {
    if (!this._layerInstance) {
      return;
    }
    const layerDiv:HTMLElement = this._layerInstance.getContainer();
    if (!layerDiv) {
      return;
    }
    const filterText:string = 'brightness(' + (this.brightness + 100) + '%) contrast(' + (this.contrast + 100) + '%)';
    (layerDiv.style as any)['-webkit-filter'] = filterText;
    (layerDiv.style as any)['-moz-filter'] = filterText;
    (layerDiv.style as any)['-ms-filter'] = filterText;
    (layerDiv.style as any)['-o-filter'] = filterText;
    layerDiv.style['filter'] = filterText;
    // TODO: Сделать поддержку IE и Safari
  }

  protected get parentsVisible() {
    let visible = true;
    let parent:ILayer = this.parentLayer;
    while (parent) {
      visible = parent.visible || parent.groupVisibleState > 0;
      if (!visible) {
        break;
      }
      parent = parent.parentLayer;
    }
    return visible;
  }
}
