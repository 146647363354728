import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TreeModule } from 'angular-tree-component';
import { DndModule } from 'ng2-dnd';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TableModule } from 'primeng/table';
import { DashboardModule } from 'shared/modules/dashboard/dashboard.module';
import { SharedModule } from 'shared/shared.module';
import { AppComponent } from './app.component';
import {
  AddressSearch,
  AreaSearchComponent,
  AttributeFilter,
  BaseMapsDesktopComponent,
  BookmarksComponent,
  DesktopLightBoxComponent,
  DesktopSearchResultsComponent,
  DesktopSingleResultComponent,
  DesktopSingleResultPopup,
  EditCoordsComponent,
  EditFeatureComponent,
  EditResultPopupComponent,
  PrintFeatureComponent,
  FeatureChartComponent,
  LayerCatalogDesktopComponent,
  LayerManagerDesktopComponent,
  MapRouterDesktopComponent,
  LayersNumBtn,
  Measuring,
  MinimapComponent,
  PermalinkComponent,
  PrintLegendComponent,
  ResizablePanelComponent,
  TabViewerComponent,
  MapTooltipComponent,
  ReportComponent,
  ZoomIn
} from './components';
import { CartometricModule } from './modules/cartometric_module/cartometric_module';
import { TimelineModule } from './modules/timeline/timeline_module';
import { AsyncSelect } from './widgets/widgets';
import {LayerListCatalogDesktopComponent} from './components/layer_list_catalog/layer_list_catalog.component';
import {OptionsPanelComponent} from './components/report/options-panel/options-panel.component';
import {GridsterModule} from 'angular-gridster2';
import {MapViewComponent} from './components/report/map-view/map-view.component';
import {SimpleWindowComponent} from '../../../shared/components';
import {QuillModule} from 'ngx-quill';
import {TextFormComponent} from './components/report/text-form/text-form.component';

@NgModule({
  declarations: [
    BaseMapsDesktopComponent,
    LayerCatalogDesktopComponent,
    ZoomIn,
    AreaSearchComponent,
    Measuring,
    AddressSearch,
    LayersNumBtn,
    EditFeatureComponent,
    PrintFeatureComponent,
    BookmarksComponent,
    EditCoordsComponent,
    AttributeFilter,
    MinimapComponent,
    EditResultPopupComponent,
    PermalinkComponent,
    LayerManagerDesktopComponent,
    LayerListCatalogDesktopComponent,
    MapRouterDesktopComponent,
    ResizablePanelComponent,
    TabViewerComponent,
    OptionsPanelComponent,
    ReportComponent,
    MapTooltipComponent,
    MapViewComponent,
    TextFormComponent,
    AppComponent,
    AsyncSelect,
    PrintLegendComponent,
    FeatureChartComponent,
    DesktopSearchResultsComponent,
    DesktopSingleResultComponent,
    DesktopSingleResultPopup,
    DesktopLightBoxComponent
  ],
  entryComponents: [
    BaseMapsDesktopComponent,
    LayerCatalogDesktopComponent,
    ZoomIn,
    AreaSearchComponent,
    Measuring,
    AddressSearch,
    LayersNumBtn,
    EditFeatureComponent,
    PrintFeatureComponent,
    BookmarksComponent,
    EditCoordsComponent,
    AttributeFilter,
    MinimapComponent,
    EditResultPopupComponent,
    PermalinkComponent,
    LayerManagerDesktopComponent,
    MapRouterDesktopComponent,
    LayerListCatalogDesktopComponent,
    ResizablePanelComponent,
    TabViewerComponent,
    OptionsPanelComponent,
    ReportComponent,
    MapTooltipComponent,
    PrintLegendComponent,
    FeatureChartComponent,
    DesktopSearchResultsComponent,
    DesktopSingleResultPopup,
    DesktopLightBoxComponent,
    SimpleWindowComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken'
    }),
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    InfiniteScrollModule,
    DndModule.forRoot(),
    TreeModule.forRoot(),
    DashboardModule,
    CartometricModule,
    TimelineModule,
    ModalModule.forRoot(),
    TableModule,
    GridsterModule,
    QuillModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }]
})
export class AppModule {}
