import { Map } from 'leaflet';
import { Attribute } from '../classes/Attribute';
import { Bounds } from '../classes/Bounds';
import { DataFilter } from '../classes/FilterGenerator';
import { ILayerLegend } from './';
import { TimeInterval } from './ILayerTime';
import {JoinedTable} from '../classes/JoinedTable';

export enum GroupVisibleState {
  none,
  some,
  all
}

export type LayerType = 'wms' | 'tms' | 'geojson' | 'geoserver' | 'wmts' | 'arcgis' | 'wms-t' | 'group' | 'cluster' | 'dynamic';

export interface ILayerOptions {
  type?:LayerType;
  name?:string;
  opacity?:number;
  brightness?:number;
  contrast?:number;
  backColor?:string;
  visible?:boolean;
  order?:number;
  subLayers?:ILayer[];
  id?:number;
  url?:string;
  layerName?:string;
  columns?:Attribute[];
  attribution?:string;
  extent?:string;
  simple?:boolean;
  pk?:string;
  table?:string;
  schema?:string;
  datasource_id?:number;
  mapnikDatasourceId?:number;
  mapnikId?:number;
  map?:Map;
  isGroup?:boolean;
  isBaseLayer?:boolean;
  geomType?:string;
  editable?:boolean;
  popupImage?:boolean;
  searchable?:boolean;
  maxNativeZoom?:number;
  popupChart?:boolean;
  tileSize?:number;
  crowdsource?:boolean;
  crowdsourceBtnText?:string;
  timeBounds?:TimeInterval;
  currentInterval?:TimeInterval;
  image?:string;
  serviceId?:number;
  currentVersion?:number;
  arcgisId?:number;
  inTooltipDefault?:boolean;
  rating?:boolean;
}

export interface ILayer {
  map:Map;

  uuid:string;
  id:number;
  name:string;
  type:LayerType;
  extent:Bounds;
  url:string;
  tileSize:number;
  layerName:string;
  maxNativeZoom:number;

  order:number;
  brightness:number;
  opacity:number;
  contrast:number;
  backColor:string;

  parentLayer:ILayer;
  legend:ILayerLegend[];
  columns?:Attribute[] | null;
  subLayers?:ILayer[];
  joinedTables?:JoinedTable[];

  groupVisibleState?:GroupVisibleState;
  attribution?:string;

  showTooltip:boolean;
  inTooltipDefault:boolean;
  simple:boolean;
  searchable:boolean;
  editable:boolean;
  crowdsource:boolean;
  visible:boolean;
  checked:boolean;
  isGroup:boolean;
  isBaseLayer?:boolean;
  rating?:boolean;

  notOnScale:boolean; // is layer visible on current map scale
  visible_scales:number[]; // all visible scales

  popupImage:boolean;
  popupChart?:boolean;

  pk:string;
  schema:string;
  table:string;
  datasource_id:number;
  geomType:string;
  mapnikDatasourceId:number;
  mapnikId?:number;

  filter:string; // sql-фильтр
  dataFilter:DataFilter; // структура фильтра

  legendUrl?:string;
  hideLegend:boolean;

  isEdited?:boolean;
  serviceId:number;
  currentVersion:number;
  arcgisId:number;

  // превью для базовой карты
  image?:string;

  getAttribution():string;
  getAttributes():Attribute[];
  refresh():void;
  getLayerInstance():any;
  buildLayerInstance():any;
  createExtent(value:string):void;
}
