import {
  Circle,
  EditableLayer,
  FeatureGroup,
  GeoJSON,
  LayerEvent,
  LeafletMouseEvent,
  Map,
  Icon,
  LatLng,
  Polyline,
  Marker,
  CircleMarker, Tooltip
} from 'leaflet';
import { Feature } from '../../../classes/Feature';
import { IDraw, IDrawFinish, IPluginInterface } from '../../../interfaces';
import { MapUtils } from './MapUtils';
import {Point} from 'shared';

export class DrawTool implements IDraw, IDrawFinish, IPluginInterface {
  private _map:Map;
  private drawLayer:FeatureGroup; // слой для отображения отрисованных объектов
  private drawingBy:IDrawFinish; // компонент, который вызвал рисование последним
  private drawTools:any = {}; // инструменты для рисования
  private isDrawing = false;
  private drawnGeom:string = null; // текущая геометрия для рисования
  private drawnItems:any;

  set map(map:Map) {
    this._map = map;
    this.addDrawControl();
    this.createDrawLayer();
    this.setMapEvents();
  }

  get map():Map {
    return this._map;
  }

  get active() {
    return this.isDrawing;
  }

  startDraw(geom:string, context:IDrawFinish) {
    this.isDrawing = true;
    // запоминаем геометрию для правильной работы эвентов
    this.drawnGeom = geom;

    // если до этого кто-то начал рисовать,
    // то сообщаем ему о прекращении
    if (this.drawingBy && this.drawingBy !== context) {
      this.drawingBy.stopDraw();
    }

    // запоминаем вызвавший рисование компонент
    this.drawingBy = context;

    if (this.drawTools.hasOwnProperty(geom)) {
      const tool = this.drawTools[geom];
      const feature = tool.call(this.map.editTools);

      // Курсор для перетаскивания объекта
      feature.on('mouseover', (event:LeafletMouseEvent) => {
        (event.originalEvent.target as HTMLElement).style.cursor = `url('/images/Cursor/move.cur'), move`;
      });
    }
  }

  finishDraw(feature:Feature) {
    this.isDrawing = false;
    this.drawnGeom = null;
    this.drawingBy.finishDraw(feature);
  }

  updateDrawingFeature(feature:Feature) {
    this.drawingBy.updateDrawingFeature(feature);
  }

  stopDraw() {
    this.isDrawing = false;
    this.drawnGeom = null;
    if (this.map.editTools) {
      this.map.editTools.stopDrawing();
    } else {
      console.error('Отсутствуют инструменты для рисования!');
    }
  }

  addFeature(feature:Feature) {
    // если слоя для рисования пока нет
    if (!this.drawLayer) {
      this.createDrawLayer();
    }

    // если нужно нанести окружность
    if (feature.geometry.circle) {
      const coords:any = feature.geometry.coordinates;
      const circle = new Circle([coords[1], coords[0]], feature.geometry.radius);
      this.drawLayer.addLayer(circle);
    } else {
      this.drawLayer.addLayer(new GeoJSON(feature.geometry));
    }
  }

  addGeometry(points:Point[], type:string, style?:any) {
    const latlngs:LatLng[] = [];
    for (const point of points) {
      latlngs.push(new LatLng(point.y, point.x));
    }
    let geometryLayer;
    if (type === 'Point') {
      if (style.type === 'icon') {
        const icon = new Icon(style);
        geometryLayer = new Marker(latlngs[0], {icon: icon});
      } else if (style.type === 'circle') {
        geometryLayer = new CircleMarker(latlngs[0], style);
      }
      if (style.text) {
        const tooltip = new Tooltip({
          permanent: true,
          direction: 'center',
          className: style.textClass
        })
          .setContent(style.text)
          .setLatLng(latlngs[0]);
        this.drawLayer.addLayer(tooltip);
      }
    } else if (type === 'LineString') {
      geometryLayer = new Polyline(latlngs, style);
    }
    this.drawLayer.addLayer(geometryLayer);
  }

  clearDrawFeatures() {
    if (this.drawLayer) {
      this.drawLayer.clearLayers();
    }

    if (this.drawnItems) {
      this.map.removeLayer(this.drawnItems);
    }
  }

  /**
   * добавляем нужные события на карту
   */
  private setMapEvents() {
    // добавляем события, если подключена библиотека Leaflet.Editable.js
    if (this.map.editTools) {
      this.map.on('editable:drawing:clicked editable:dragend', (e:LayerEvent) => {
        if (!this.isDrawing) {
          return;
        }
        this.updateFeature(e);
      });

      this.map.on('editable:vertex:dragend', (e:LayerEvent) => {
        if (!this.isDrawing) {
          return;
        }
        if (this.drawnGeom === 'circle' || this.drawnGeom === 'rectangle') {
          this.commitFeature(e);
        }
        this.updateFeature(e);
      });

      this.map.on('editable:drawing:end', (e:LayerEvent) => {
        if (this.isDrawing && this.drawnGeom !== 'rectangle') {
          this.commitFeature(e);
        }
      });

      this.map.on('editable:drawing:start', (e:any) => {
        if (!this.isDrawing) {
          return;
        }
        // сохраняем слой с объектом
        this.drawnItems = e.layer;
      });
    }
  }

  /**
   * Создаём слой необходимый для рисования
   */
  private createDrawLayer():void {
    this.drawLayer = new FeatureGroup([]);
    this.map.addLayer(this.drawLayer);
    Icon.Default.prototype.options.shadowSize = [0, 0];
  }

  /**
   * Инструменты для рисования
   */
  private addDrawControl() {
    if (this.map.editTools) {
      this.drawTools = {
        polygon: this.map.editTools.startPolygon,
        multipolygon: this.map.editTools.startPolygon,
        circle: this.map.editTools.startCircle,
        rectangle: this.map.editTools.startRectangle,
        linestring: this.map.editTools.startPolyline,
        multilinestring: this.map.editTools.startPolyline,
        point: this.map.editTools.startMarker,
        multipoint: this.map.editTools.startMarker
      };
    }
  }

  private commitFeature(e:LayerEvent) {
    (e.layer as EditableLayer).disableEdit();
    const feature = MapUtils.createFeature(e);
    this.finishDraw(feature);
  }

  private updateFeature(e:LayerEvent) {
    const feature = MapUtils.createFeature(e);
    this.updateDrawingFeature(feature);
  }
}
