import {
  AfterContentInit,
  Component,
  ElementRef, EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Button, MenuComponent } from 'shared/components';
import { IActivator, IContainer, IContentChild, IDomPanel, IMenu, IPluginInterface, ITool } from 'shared/interfaces';
import { Creator } from '../../services/services';

@Component({
  selector: 'resizable-panel',
  templateUrl: 'resizable_panel.component.html',
  styleUrls: ['resizable_panel.component.less']
})
export class ResizablePanelComponent extends MenuComponent implements IDomPanel, IContainer, ITool, AfterContentInit {
  @Input() id:string;
  @Input() alwaysMaximized:boolean = false;
  @Input() showMaximizeButton:boolean = true;
  @Input() minWidth:string = '550px';
  @Input() startWidth:string = '40%';

  @Output() resizeStart = new EventEmitter<void>();

  @HostBinding('class.resizable-panel-open') opened = false;
  @HostBinding('class.resizable-panel-transition') transitioned = false;
  @ViewChild('buttons', { read: ViewContainerRef }) buttonsDiv:ViewContainerRef;
  @ViewChild('content', { read: ViewContainerRef }) contentDiv:ViewContainerRef;

  maximized:boolean = false;

  contentChildren = Array<IContentChild>();
  activateMode:string = null;
  groupName = '';

  private btn:Button;
  private resizing = false;
  private domNode:HTMLElement;
  private menu:IMenu;
  private active:boolean;
  private activator:IActivator; // плагин, отвечающий за отключение связанных плагинов
  private resizeEvent:any;

  constructor(creator:Creator, el:ElementRef) {
    super(creator, el);
    this.domNode = el.nativeElement;
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
    this.domNode.style.minWidth = this.minWidth;
    // Регистрируем событие window resize
    this.resizeEvent = window.document.createEvent('UIEvents');
    this.resizeEvent.initUIEvent('resize', true, false, window, 0);
  }

  addInterface(name:string, pi:IPluginInterface):void {
    const menu:IMenu = pi as IMenu;
    switch (name) {
      case 'Menu':
        this.menu = pi as IMenu;
        // TODO: Придумать более элегантную реализацию кнопок для 'включения' инструментов
        // создание кнопки в меню для активации
        this.menu.createBtn().then(btnRef => {
          const btn:Button = btnRef.instance;
          btn.className = this.buttonConfig.className;
          btn.title = this.buttonConfig.title;
          btn.position = this.buttonConfig.position;

          btn.onClickMe = () => {
            this.toggle();
          };

          btn.onDeactivate = () => {
            this.close();
          };

          this.btn = btn;
          this.menu.addBtn(btn);
        });
        break;
      case 'Activator':
        this.activator = pi as IActivator;
        this.activator.setConnect(this as ITool);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'Menu':
        this.menu.removeBtn(this.btn);
        this.btn = null;
        this.menu = null;
        break;
      case 'Activator':
        this.activator = null;
        break;
    }
  }

  onMouseDown() {
    this.resizing = true;
    // Выключаем анимацию, когда растягиваем контейнер вручную
    this.transitioned = false;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    this.resizing = false;
    this.transitioned = true;
  }

  addBtn(btn:Button) {
    // Необходимо реализовать, если компонент панели
    // будет включать кнопки меню
  }

  getContainer() {
    return this.contentDiv;
  }

  addChild(child:IContentChild) {
    this.contentChildren.push(child);
  }

  toggle() {
    if (this.opened) {
      // Сворачиваем до начальной ширины
      this.close();
      this.contentChildren.forEach(child => {
        child.deactivate();
      });
    } else {
      this.open();
      if (this.activator) {
        this.activator.activateComponent(this as ITool);
      }
    }
  }

  open() {
    this.domNode.style.width = this.startWidth;
    this.opened = true;
    this.maximized = false;
    // Кидаем событие window resize для обновления элементов dashboard внутри контейнера
    // timeout, т.к. дожидаемся изменения ширины контейнера
    setTimeout(() => {
      window.dispatchEvent(this.resizeEvent);
    }, 500);
  }

  close() {
    this.domNode.style.width = this.minWidth;
    this.opened = false;
    this.maximized = false;
    // Кидаем событие window resize для обновления элементов dashboard внутри контейнера
    // timeout, т.к. дожидаемся изменения ширины контейнера
    setTimeout(() => {
      window.dispatchEvent(this.resizeEvent);
    }, 500);
    this.onHide.emit();
  }

  toggleMaximize() {
    if (this.maximized) {
      this.minimize();
    } else {
      this.maximize();
    }
  }

  maximize() {
    this.domNode.style.width = '100%';
    this.maximized = true;
    this.opened = true;
  }

  minimize() {
    this.domNode.style.width = this.startWidth;
    this.maximized = false;
    this.opened = true;
  }

  activateTool() {
    if (this.alwaysMaximized) {
      this.domNode.style.width = '100%';
      this.maximized = true;
    } else {
      this.domNode.style.width = this.startWidth;
      this.maximized = false;
    }
    this.opened = true;
    this.active = true;
    this.contentChildren.forEach(child => {
      child.activate();
    });
  }

  deactivateTool():Promise<boolean> {
    this.domNode.style.width = this.minWidth;
    this.maximized = false;
    this.opened = false;
    this.active = false;
    this.contentChildren.forEach(child => {
      child.deactivate();
    });
    return Promise.resolve(true);
  }

  isActive() {
    return this.active;
  }

  getGroup() {
    return this.groupName;
  }

  @HostListener('document:mousemove', ['$event'])
  private onMouseMove($event:MouseEvent) {
    if (this.resizing) {
      // предотвращает выделение текста курсором
      $event.preventDefault();

      const percentage = 100 - ($event.pageX / window.innerWidth) * 100;
      this.domNode.style.width = `${percentage}%`;
      this.maximized = false;

      // Кидаем событие window resize для обновления диаграмм dashboard внутри контейнера
      window.dispatchEvent(this.resizeEvent);
    }
  }

  getVisibilityStyle(visible:boolean):string {
    return visible ? 'visible' : 'hidden';
  }
}
