import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IUser } from '../interfaces/IUser';

@Injectable()
export class AuthService {
  currentUser:ReplaySubject<IUser> = new ReplaySubject(1);

  constructor(private http:HttpClient) {
    this.get_user();
  }

  get_user() {
    this.http.get<IUser>('current_user').subscribe(this.currentUser);
  }
}
