import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Attribute } from 'shared/classes';

@Component({
  selector: 'width-attr',
  templateUrl: 'width_attr_input.html',
  styleUrls: ['width_attr_input.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WidthAttrInput),
      multi: true
    }
  ]
})
export class WidthAttrInput implements ControlValueAccessor, OnChanges {
  @Input() attributes:Attribute[] = [];
  @Input() disabled:boolean;

  inputString:string;
  showList = false;
  originalAttributes:Attribute[] = [];

  ngOnChanges(changes:SimpleChanges) {
    if (changes['attributes']) {
      this.originalAttributes = changes['attributes'].currentValue;
      this.writeValue(null);
      this.propagateChange(null);
    }
  }

  writeValue(value:Attribute | number | null) {
    if (!value) { this.inputString = null; }
    if (value instanceof Attribute) { this.inputString = value.alias; }
    this.showList = false;
  }

  registerOnChange(fn:any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  filterAttrs(value:string) {
    if (!value) {
      this.showList = false;
      return;
    }

    const reg = /^\d+$/;

    // Если введены только цифры, считаем это корректным значением
    // и эмитим наверх
    if (value.match(reg)) {
      const width = parseInt(value, 10);
      this.writeValue(width);
      this.propagateChange(width);
      return;
    } else if (parseInt(value, 10)) {
      // Название столбца не может начинаться с цифры (?),
      // игнорируем это значение
    } else {
      // Во всех других случаях фильтруем массив
      this.attributes = this.originalAttributes.filter(attr => attr.alias.toLowerCase().includes(value.toLowerCase()));
      if (this.attributes.length) { this.showList = true; }
    }
  }

  reset() {
    this.propagateChange(null);
  }

  selectAttr(attr:Attribute) {
    this.writeValue(attr);
    this.propagateChange(attr);
  }

  showAttrs() {
    if (!this.disabled) {
      this.showList = !this.showList;
    }
  }

  private propagateChange(_:any) {}
}
