export class DomUtils {
  static calculateOffsetFromSelector(dom:HTMLElement, selector:string):{x:number, y:number} {
    let element = dom;
    const ret = {x: 0, y: 0};
    while (element && !element.matches(selector)) {
      ret.x += element.offsetLeft;
      ret.y += element.offsetTop;
      element = element.offsetParent as HTMLElement;
    }
    return ret;
  }
}
