import { AfterViewInit, Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { PluginClass } from '../../classes/Plugin';
import { Utils } from '../../classes/Utils';
import { ILayer } from '../../interfaces';
import { MapService } from '../../services/map.service';

@Component({
  selector: 'geo-copyrights',
  templateUrl: 'copyrights.component.html',
  styleUrls: ['copyrights.component.less']
})
export class CopyrightsComponent extends PluginClass implements AfterViewInit {
  @ViewChild('copyrightBlock', { read: ViewContainerRef }) copyrightBlock:ViewContainerRef;

  constructor(private el:ElementRef, private mapService:MapService) {
    super();
  }

  ngAfterViewInit() {
    this.mapService.currentBaseMap$.subscribe((layer:ILayer) => {
      let copyright = '';
      if (layer) {
        copyright = layer.getAttribution();
      }
      this.setCopyright(copyright);
    });
  }

  destroy() {
    Utils.removeElement(this.el.nativeElement);
  }

  private setCopyright(copyright:string) {
    this.copyrightBlock.element.nativeElement.innerHTML = copyright;
  }
}
