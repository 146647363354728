import { Attribute } from '../../../classes';
import { ILayer } from '../../../interfaces';

const chart_status:{ [key:string]:string } = {
  rd: 'ready',
  pd: 'pending',
  qe: 'pending',
  fl: 'error'
};

interface JoinedResource {
  resource:ILayer;
  attribute:Attribute;
  method:string;
}

export interface Method {
  title:string;
  value:string;
}

export class Chart {
  type:string;
  name:string;
  resource_uri:string;
  colCount = 10;
  methods:Method[] = [];
  colName:Attribute;
  colValue:Attribute[] = [];
  resource:ILayer;
  joinedResource:JoinedResource = { resource: null, attribute: null, method: null };
  aggregatePercent:number;
  time_attribute:string;
  time_level:string;
  layer_id:number;
  error:string;

  private _status = 'pending';

  constructor(type:string) {
    this.type = type;
  }

  get status():string {
    return this._status;
  }

  set status(status:string) {
    this._status = chart_status[status];
  }

  changeStatus(newStatus:string) {
    this._status = newStatus;
  }
}
