import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractChartFormClass } from '../../classes';
import { ChartService } from '../../services';

@Component({
  selector: 'geo-scatter-form',
  templateUrl: 'scatter-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScatterFormComponent extends AbstractChartFormClass {
  constructor(protected chartService:ChartService, protected builder:FormBuilder) {
    super(chartService, builder);
  }

  protected setForm() {
    this.form = this.builder.group({
      name: [null, [Validators.required, Validators.maxLength(255)]],
      resource: [null, [Validators.required]],
      colValue1: [{ value: null, disabled: true }, [Validators.required]],
      colValue2: [{ value: null, disabled: true }, [Validators.required]],
      colName: [{ value: null, disabled: true }],
      colCount: [10, [Validators.required]]
    });

    this.form.get('resource').valueChanges.subscribe(value => {
      this.attributes = value.columns;
      this.form.get('colValue1').enable();
      this.form.get('colValue2').enable();
      this.form.get('colName').enable();

      this.form.get('colValue1').reset();
      this.form.get('colValue2').reset();
      this.form.get('colName').reset();
    });
  }

  protected getChartSettings(formModel:any) {
    const data:any = {
      attribute_field_name_joined: formModel.colValue1.name,
      joined_attribute: formModel.colValue2.name,
      type: 'scatter',
      bin_count: formModel.colCount
    };

    if (formModel.colName) {
      data.attribute_field_name = formModel.colName.name;
    }

    return data;
  }
}
