import { DirectGeometryObject, FeatureCollection } from 'geojson';
import { GeoJSON } from 'leaflet';
import { ILayerOptions, LayerType } from 'shared/interfaces';
import { Attribute, Feature } from '../../../classes';
import { WMSLayer } from '../WMSLayer.class';
import { FilterGmlGenerator } from './FilterGmlGenerator.class';
import { SearchGmlGenerator } from './SearchGmlGenerator.class';

export class GeoserverLayer extends WMSLayer {
  type:LayerType = 'geoserver';

  readonly legendUrlOrig:string = '';
  legendUrl = '';
  private _gmlSearch:SearchGmlGenerator = null;
  private _geomField = 'the_geom';
  private _gmlFilter:string = null;
  private _filterGen:FilterGmlGenerator = new FilterGmlGenerator();

  constructor(options?:ILayerOptions) {
    super(options);
    this._gmlSearch = new SearchGmlGenerator(this);
    this.legendUrlOrig = this.legendUrl = `${this.url}?REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=${
      this.layerName
    }`;

    if (!this._columns) {
      return;
    }
    const geomFields:Attribute = this._columns.find(item => item.type === 'geometry');
    if (geomFields) {
      this._geomField = geomFields[0].name;
    }
  }

  set filter(filter:string) {
    if (!filter || !filter.length) {
      this._layerInstance.setUrl(this.url);
      this._gmlFilter = null;
    } else {
      filter = this.prepareCql(filter);
      const newUrl = `${this.url}?CQL_FILTER=${encodeURIComponent(filter.trim().replace(/"/g, '\''))}`;
      this._layerInstance.setUrl(newUrl);
      this._gmlFilter = this._filterGen.generate(filter);
    }

    this._filter = filter;
  }

  get filter():string {
    return this._filter;
  }

  prepareCql(filter:string):string {
    filter = filter.replace(/!=/g, '<>');
    filter = filter.replace(/(\[|\])/g, '');
    return filter;
  }

  getGMLForText(text:string) {
    return this._gmlSearch.getForText(text, this._gmlFilter);
  }

  getGMLForArea(geomObject:DirectGeometryObject) {
    // для решения проблемы идентификации объектов пересекающих 180 меридиан
    const copyGeomObject:DirectGeometryObject = Object.assign({}, geomObject);
    let notChangedCoords:number = (copyGeomObject.coordinates[0] as number[][]).length;
    copyGeomObject.coordinates = JSON.parse(JSON.stringify(geomObject.coordinates));
    copyGeomObject.coordinates[0] = (copyGeomObject.coordinates[0] as number[][]).map((item:number[]) => {
      if (item[0] < -180) {
        item[0] = 360 + item[0];
        notChangedCoords--;
      }

      if (item[0] > 180) {
        item[0] = -360 + item[0];
        notChangedCoords--;
      }
      return item;
    });

    // если все координаты оказались вне зоны (-180,180) меняем центр
    if (notChangedCoords === 0) {
      this.map.setView(new GeoJSON(copyGeomObject).getBounds().getCenter(), this.map.getZoom());
    }

    return this._gmlSearch.getForArea(copyGeomObject, this._geomField, this._gmlFilter);
  }

  parseGML(text:string) {
    return this._gmlSearch.parse(text);
  }

  protected prepareFeatures(collection:FeatureCollection<any>):Feature[] {
    const ar:Feature[] = [];
    collection.features.forEach((data:{ geometry:any; properties:any }) => {
      const f:Feature = new Feature();
      f.geometry = data.geometry;
      f.properties = data.properties ? f.properties : {};
      f.layer = this;
      ar.push(f);
    });
    return ar;
  }
}
